import { Injectable } from '@angular/core';
import { ItemStyle, LabelStyle, LineStyle } from './models/chart-options-modal';

@Injectable({
  providedIn: 'root'
})
export class ChartOptionsService {

  constructor() { }

  public applyCurveSettings(seriesData, chartType, chartConfiguration) {

    seriesData['itemStyle'] = new ItemStyle();
    seriesData['lineStyle'] = new LineStyle();
    seriesData['label'] = new LabelStyle();

    if (chartConfiguration.seriesColor !== '') {

      if (chartType === 'line') {
        seriesData.lineStyle.color = chartConfiguration.seriesColor;
      }

      if (chartType === 'bar') {
        seriesData.itemStyle.color = chartConfiguration.seriesColor;
      }
    }

    if (chartConfiguration.seriesThickness) {
      if (chartType === 'line') {
        seriesData.lineStyle.width = chartConfiguration.seriesThickness;
      }
      if (chartType === 'bar') {
        if (!seriesData["barWidth"]) {
          seriesData['barWidth'] = chartConfiguration.seriesThickness;
        }
        else {
          seriesData.barWidth = chartConfiguration.seriesThickness;
        }
      }
    }

    if (chartConfiguration.isBorderEnabled) {
      seriesData.itemStyle.borderWidth = 10;
    }
    else {
      if (seriesData.itemStyle.hasOwnProperty('borderWidth')) {
        delete seriesData.itemStyle.border;
      }
    }

    if (chartConfiguration.seriesSymbol !== '') {
      seriesData.symbol = chartConfiguration.seriesSymbol;
    }

    if (chartConfiguration.curveLabelAxis === 'Y') {
      if (seriesData.label.hasOwnProperty('formatter')) {
        delete seriesData.label.formatter;
      }
    }

    if (chartConfiguration.curveLabelAxis === 'X') {
      seriesData.label['formatter'] = "{b}";
    }

    seriesData.label.show = chartConfiguration.isEnableCurveLables;

    if (chartConfiguration.symbolAngle) {
      seriesData.label.rotate = chartConfiguration.symbolAngle;
    }
  }

  public setCurveFontOptions(chartData, chartConfiguration) {
    for(let item of chartData){
      if (!item['label']) {
        item['label'] = new LabelStyle();
      }
      if (chartConfiguration.curveFontOptions.color !== '') {
        item.label.color = chartConfiguration.curveFontOptions.color;
      }
      if (chartConfiguration.curveFontOptions.fontSize !== '') {
        item.label.fontSize = chartConfiguration.curveFontOptions.fontSize;
      }
    }
  }

  public applySettingsToChartData(chartData: any, chartSettings: any, chartType: any) {
    if (chartData) {
      if (chartSettings) {
        for(let curve of chartData){
          let seriesSettings = chartSettings.seriesSettings.find(x => x.seriesname === curve.series);
          this.applyCurveSettings(curve, chartType, seriesSettings);
        }
        this.setCurveFontOptions(chartData, chartSettings);
      }
    }
  }
}
