import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { JobService } from 'src/app/job/job.service';
import { UtilitiesService } from '../../core/services/utilitiesservice.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { ACTION_TYPE, PAGES, TOASTER_TYPE } from 'src/app/shared/enums/enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorsEx } from 'src/app/core/services/validators-extend.class';
import { LIMITATION, SAVE_DATA } from 'src/app/utils/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../services/projects.service';

@Component({
  selector: 'app-find-existing-job-clone',
  templateUrl: './find-existing-job-clone.component.html',
  styleUrls: ['./find-existing-job-clone.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FindExistingJobCloneComponent implements OnInit, OnDestroy {
  public jobs: any = [];
  public searched: boolean = false;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public headers = [
    { title: 'project.baroidProjectID', uom: false, key: "baroidProjectNumber", type: 'text' },
    { title: 'project.baroidProjectName', uom: false, key: "baralogixName", type: 'text' },
    { title: 'job.name', uom: false, key: "jobName", type: 'text' },
    { title: 'well.name', uom: false, key: "wellName", type: 'text' },
    { title: 'region', uom: false, key: "region", type: 'text' },
    { title: 'country', uom: false, key: "country", type: 'text' },
    { title: 'createdBy', uom: false, key: "jobOwner", type: 'text' },
    { title: 'spudDate', uom: false, key: "spudDate", type: 'date' },
    { title: '', uom: false, link: true, key: "selectLink", type: 'text', icon: 'custom', content:'Select' }
  ];
  public params = {
    jobName: '',
    wellName: ''
  }
  public selectedJob = null;
  public cloneJobForm = new FormGroup({});
  public get inputValid(): boolean {
    return this.params.jobName.length > 0
        || this.params.wellName.length > 0;
  }
  public cloneJobModal: NgxSmartModalComponent;
  public isCloneFormSubmitted: boolean = false;
  public limitName = LIMITATION.INPUT_NAME;
  public syncing: boolean = false;

  constructor(
    public _utilities: UtilitiesService,
    public _jobs: JobService,
    public _project: ProjectService,
    public _ngxSmartModal: NgxSmartModalService,
    public router: Router,
    public formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  initForm() {
    this.cloneJobForm = this.formBuilder.group({
      jobName: [null, ValidatorsEx.requiredText()],
      wellName: [null, ValidatorsEx.requiredText()],
      dTimSpud: [null, [Validators.required, ValidatorsEx.limitDate()]],
      jobId: ['', Validators.required]
    });
  }

  cancel() {
    this.router.navigate([PAGES.JobsDashboard]);
  }

  public searchJobs() {
    this._utilities.showSpinner(true);
    this._project.searchProjects(this.params)
      .pipe(takeUntil(this.componentDestroyed$)
        , finalize(() => {
          this.searched = true;
          this._utilities.showSpinner(false);
        }))
      .subscribe(
        (res) => {
          this.jobs = res;
        },
        (error) => {
          this.jobs = [];
        });
  }

  public onSelectJob(item) {
    if (item && item['tableCellSelected'] === 'selectLink') {
      this.selectedJob = item;
      this.isCloneFormSubmitted = false;
      this.cloneJobForm.reset({});
      this.cloneJobModal = this._ngxSmartModal.getModal('cloneJob');
      this.cloneJobModal.open();
    }
  }

  closeModal() {
    this.selectedJob = null;
    this.cloneJobModal.close();
  }

  submit() {
    if(this.syncing) { return; }

    this.isCloneFormSubmitted = true;
    if(this.cloneJobForm.valid) {
      this.syncing = true;
      this._jobs.cloneJob(this.cloneJobForm.value).then(
        (res) => {
          this.syncing = false;
          if(res instanceof HttpErrorResponse) {
            const error = this._utilities.getErrorMessage(res);
            this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, error, SAVE_DATA.Toastr_Fail);
          } else {
            const msg = this._utilities.getTranslationNow('toastr.SaveSucceeded');
            this._utilities.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
            this.cloneJobModal.close();
            this.router.navigate(['/job', res.jobId, 'jobprofile']);
          }
        }
      );
    }
  }
}
