<!-- PREVIEW START -->
<div class="import-preview pt-4 pl-4 pb-4 mt-4" *ngIf="previewMode">
  <div class="text-center my-1">You are about to import the following items and append them to the existing data. Please, review and approve.</div>
  <!-- SOME ERRORS WARNING HERE! -->
  <div class="preview-container">
    <form [formGroup]="form">
      <table class="table table-top trajectory-table mb-0">
        <thead>
          <tr>
            <th scope="col" *ngFor="let col of columns">{{col.i18n | translate}}
              <app-uom-label *ngIf="isShowUomLabel(col)" [columnSettings]="unitColSettings" [fieldIdentifier]="col.key"></app-uom-label>
            </th>
          </tr>
        </thead>
        <tbody formArrayName="previewForms">
          <tr *ngFor="let item of previewForms.controls; let i=index" [formGroupName]="i">
            <td *ngFor="let col of columns">
              <ng-container [ngSwitch]="col.fieldType">
                <input *ngSwitchCase="FIELD_TYPE.TEXT" 
                  type="text" 
                  [formControlName]="col.key" 
                  class="input-field"
                  [ngClass]="{'input-danger': item.get(col.key).errors}" 
                  [attr.disabled]="true"
                  [attr.readonly]="true">
                <input *ngSwitchCase="FIELD_TYPE.DROPDOWN" 
                  type="text" 
                  [formControlName]="col.key" 
                  class="input-field"
                  [ngClass]="{'input-danger': item.get(col.key).errors}" 
                  [attr.disabled]="true"
                  [attr.readonly]="true">
                <input *ngSwitchCase="FIELD_TYPE.NUMBER" 
                  type="number" 
                  [formControlName]="col.key" 
                  appUomPrecision
                  [fixedDecimals]="unitColSettings | decPrecision:col.key"
                  class="input-field"
                  [pageLoaded]="true" 
                  [ngClass]="{'input-danger': item.get(col.key).errors}" 
                  [attr.disabled]="true"
                  [attr.readonly]="true">
              </ng-container>
              <!-- ERRORS HERE -->
              <app-error-message [controlName] = "item.get(col.key)" [submitted]="true"></app-error-message>
              <!-- <div *ngIf="ImportCatalogSetupForm.get(['ImportCatalogSetupArray', i, col.key]).errors?.duplicate" class="small error-text">Must be unique</div> -->
            </td>
            <td class="empty-col">
              <span class="filter-color-orange icon-btn"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <div class="d-flex align-items-center justify-content-center mt-3">
    <button class="d-flex align-items-center justify-content-center bttn-rectangle bttn-tertiary my-2" (click)="approve()"
      [disabled]="form.invalid || this.previewForms.length == 0">Approve</button>
    <button class="d-flex align-items-center justify-content-center bttn-rectangle bttn-tertiary my-2" (click)="clearImport()">Clear</button>
  </div>
  </div>
<!-- PREVIEW END -->

<ngx-smart-modal #catalogImportModal identifier="catalogImportModal" [closable]="true" customClass="modal-content full-screen no-padding">
  <div class="import-component">
    <div class="modal-header">
      <div class="close-btn">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <img class="closeIcon" />
        </button>
      </div>
    </div>
    <div class="modal-body">
      <div class="d-flex flex-column align-items-center justify-content-center drop-zone p-4 text-center" [ngClass]="{'dragover': dragover}">
        <div *ngIf="!file && errors.length === 0" class="form-group text-center">
          <p>Drag and drop file here</p>
          <p>or</p>
          <label class="d-flex align-items-center justify-content-center bttn-rectangle bttn-tertiary" for="file">Browse for file</label>
          <input class="d-none" type="file" id="file" (change)="handleFileInput($event.target.files)" accept="{{acceptString}}">
        </div>
  
        <div *ngFor="let error of errors" class="text-danger my-2">{{error}}</div>
  
        <div *ngFor="let warning of warnings" class="warning-text my-2">{{warning}}</div>
  
        <div *ngIf="file && errors.length === 0" class="my-2">{{file?.name}}</div>
  
        <div class="d-flex align-items-center justify-content-center">
          <button *ngIf="file && errors.length === 0" class="d-flex align-items-center justify-content-center bttn-rectangle bttn-tertiary px-4 my-2" (click)="submit()">Submit</button>
          <button *ngIf="file || errors.length > 0" class="d-flex align-items-center justify-content-center bttn-rectangle bttn-tertiary px-4 my-2" (click)="clear()">Clear</button>
        </div>
  
        <small>File types supported: {{allowedExtensions.join(', ')}}</small>
  
        <small>Please make sure the import data is under first sheet of import file</small>
  
        <a *ngIf=" !file && errors.length===0" class="template-download link-primary my-2" (click)="exportTemplateFile()">Download template</a>
      </div>
    </div>
  </div>
</ngx-smart-modal>