<div class="chart-area">
  <div echarts
	[options]="baseChartOptions"
	[initOpts]="baseInitOpts"
	[theme]="theme"
	[autoResize]="true"
	class="base-chart"
	(chartInit)="onChartInit($event)"
	(chartClick)="onChartClick($event)">
  </div>
</div>
<label class="xAxisName-label">{{xAxisName}}</label>
<label class="yAxisName-label">{{yAxisName}}</label>

