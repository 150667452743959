import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColumnSettingsInfo } from 'src/app/core/services/columnSettings';
import { UnitConversionService } from 'src/app/core/services/unit-conversion.service';
import { ToolbarColumn } from 'src/app/shared/components/app-table-toolbar/table-toolbar.component';
import { FIELD_TYPE, SORT_TYPE } from 'src/app/shared/enums/enums';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';

const TEMPERATURES_STEP = [40, 80, 120, 160, 200, 240, 280, 320, 360, 400];
const PRESSURE_STEP = [0, 2000, 4000, 6000, 8000, 10000, 12000, 14000, 16000, 18000, 20000];
const COLOR_PALETTE = ["#ebac23", "#b80058", "#008cf9", "#006e00", "#878500", "#d163e6", "#b24502", "#ff9287", "#5954d6", "#00c6f8"];

@Component({
  selector: 'app-base-oil-chart',
  templateUrl: './base-oil-chart.component.html',
  styleUrls: ['./base-oil-chart.component.scss']
})
export class BaseOilChartComponent implements OnInit, OnChanges {

  @Input() public constants: any;
  public colSettings: FieldLevelUoMSettings[] = ColumnSettingsInfo.baseOil;
  public temperatureColSettings = this.colSettings.find(x=>x.colName == "TemperatureStep");
  public pressureColSettings = this.colSettings.find(x=>x.colName == "PressureStep");
  public matrixTable = this.buildMatrix();
  public pressureLabel: string = `Pressure (${this.pressureColSettings.currentUnit})`;
  public columns: Array<ToolbarColumn> = [
    {key: "pressure", path: "pressure", i18n: this.pressureLabel, fieldType: FIELD_TYPE.NUMBER, sort: SORT_TYPE.ASC},
    ...this.temperatureColumns
  ];

  public chartOptions: any = {
    legend: {
      data: this.temperatureColumns.map(x => x.i18n),
      show: true,
      orient: 'vertical',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      top: "5%",
      right: 10,
      itemGap: 20
    },
    color: COLOR_PALETTE,
    xAxis: {
      type: 'value',
      name: this.pressureLabel,
      nameLocation: "middle",
      nameGap: 40,
      inverse: false,
      silent: false,
      boundaryGap: false,
      axisTick: {
        show: true,
        length: 5,
        inside: true,
        lineStyle: {
          shadowOffsetY: 5,
        }
      },
      minorTick: {
        show: true,
        type: 'dot',
      },
      scale: true
    },
    yAxis: {
      type: 'value',
      name: 'Density (g/cc)',
      nameLocation: "middle",
      nameGap: 40,
      inverse: false,
      align: "center",
      axisTick: {
        show: true,
        height: 5,
        lineStyle: {
          shadowOffsetX: 5,
        }
      },
      minorTick: {
        show: true,
        type: 'dot',
      },
      scale: true
    },
    grid: {
      top: "5%",
      left: 50,
      containLabel: true
    }
  };
  public chartData: Array<any> = [];
  public tableData: Array<any> = [];
  public chartTitle: string = '';

  constructor(
    public _unitConversion: UnitConversionService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngOnChanges() {
    if (this.constants) {
      this.calculateDensity();
      this.mapTableData();
      this.mapChartData();
    }
  }

  private buildMatrix(){
    let res = [];
    for(const pressure of PRESSURE_STEP){
      for(const temp of TEMPERATURES_STEP){
        res.push({
          name: temp + "F",
          temperature: this._unitConversion.convertValue(this.temperatureColSettings, temp) ,
          pressure: this._unitConversion.convertValue(this.pressureColSettings, pressure),
          api_pressure: pressure,
          api_temperature: temp,
          density: 0
        })
      }
    }
    return res;
  }

  private calculateDensity() {
    const { constant1, constant2, constant3, constant4, constant5 } = this.constants;
    for(const obj of this.matrixTable){
      obj.density = (constant1 * obj.api_temperature + constant2) * obj.api_pressure ** constant3 - constant4 * obj.api_temperature + constant5;
    }
  }

  get temperatureColumns() {
    let res = [];
    for (const val of this.matrixTable) {
      if (!res.find(x => x.key == val.name)) {
        res.push({ key: val.name, path: val.density, i18n: this.toTempText(val.temperature), fieldType: FIELD_TYPE.NUMBER, readonly: true } as ToolbarColumn)
      }
    }
    return res;
  }

  private mapTableData() {
    for (const val of this.matrixTable) {
      const valPressure = Number(val.pressure.toFixed(this.pressureColSettings.decimalPrecision));
      const currentDensity = Number(val.density.toFixed(3));
      let existedValue = this.tableData.find(x => x.pressure == valPressure);
      if (existedValue) {
        existedValue[val.name] = currentDensity;
      }
      else {
        this.tableData.push({
          pressure: valPressure,
          [val.name]: currentDensity
        })
      }
    }
  }

  private mapChartData() {
    this.chartTitle = `${this.constants.baseFluidName} BASE DENSITY PROFILE `;
    for (const val of this.matrixTable) {
      const valTemperature = this.toTempText(val.temperature);
      const valDensity = val.density;
      const valPressure = val.pressure;
      let existedValue = this.chartData.find(x => x.series == valTemperature);     
      if (existedValue) {
        existedValue.data.push([valPressure, valDensity]);
      }
      else {
        this.chartData.push({
          series: valTemperature,
          symbol: 'line',
          data: [[valPressure, valDensity]]
        })
      }
    }
  }

  private toTempText(temp) {
    return temp.toFixed(this.temperatureColSettings.decimalPrecision) + this.temperatureColSettings.currentUnit;
  }
}
