import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PAGES, ROLES } from 'src/app/shared/enums/enums';
import { environment } from 'src/environments/environment';
import { Settings } from '../models/settingsModel.model';
import { ApiService } from '../services/api.service';
import { SettingsService } from '../services/settings.service';
import { UtilitiesService } from '../services/utilitiesservice.service';


@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(
      public router: Router,
      public _setting: SettingsService,
      public _utilities: UtilitiesService,
      public _auth: MsalService,
      private _api: ApiService,
    ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const roles: string[] = route.routeConfig.data['roles'];
    return this.checkAuthorizationForRouter(roles);
  }

  private checkAuthorizationForRouter(roles: string[]) {
    if(environment.local) {
      this.router.navigate([PAGES.JobsDashboard]);
      return false;
    };
    let cloud = environment.web.isCloud;
    let root = cloud ? environment.api.API_URL_Cloud : environment.api.API_URL_Local;
    let ports = cloud ? environment.api.API_PORTS_Cloud : environment.api.API_PORTS_Local;
    let APIUrlCore = root + ports.core;
    var url = APIUrlCore + environment.user.getProfileSettings.replace("{userId}", this._auth.getAccount().userName);
    return this._api.get<Settings>(url).toPromise().then((data: Settings) => {
        if (roles.includes(data.assignedRole)) return true;
        this.router.navigate([PAGES.JobsDashboard]);
        return false;
    })
  }
}
