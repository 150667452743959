
<div class="container-fluid card-body" [ngClass]="showCatalogSetup?'d-none':''">
  <!-- title-->
  <div class="row">
    <div class="col-12">
      <div class="h1 page-name d-flex">{{'admin.catalogManagement'|translate}}
        <span class="h4 ml-4 align-self-end">{{'admin.version'|translate:{currentVersion: currentVersion} }}</span>
      </div>
    </div>
  </div>

  <div>
    <div class="dashboard-final-details">
        <div class="mt-4">
          <label class="small">{{counter}} {{'Catalogs'|translate}}</label>
          <span class="link-primary float-right mr-0" (click)="updateCatalogVersion()">{{'job.updateVersion'|translate}}</span>
          <span class="link-primary float-right mr-3" (click)="syncUpCatalog()">{{'job.Synchronization'|translate}}</span>        
        </div>
    </div>
  </div>
  <div class="mt-3">
    <app-h-table 
      (row)="cardClick($event)" 
      [fullTable]="true"
      [data]="catalogsView" 
      [headers]="headers" 
      [rowPointer]="false" 
      [pageSize]="pageSize"
      [maxColumnDisplayLength]="20">
    </app-h-table>
  </div>
</div>

<app-catalog-setup *ngIf="showCatalogSetup" 
  [setting] ="catalogSetup" 
  (onCancel) ="cancel($event)"
  (onOpenChildren) ="openChildren($event)"
  >
</app-catalog-setup>