<app-uom-modal [uomModalType]="uomModalType"></app-uom-modal>

<form #userSettingsForm="ngForm" novalidate>
  <div *ngIf="pageLoaded" class="container">
    <!-- title-->
    <div class="row">
      <div class="col-12">
        <div class="h1 page-name">{{'settings.settings'|translate}}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-1"></div>
      <div class="col-3">
        <!--  <div class="h4 default-land">{{'defaultLandingCaps'|translate}}</div>
        <div class="radio-field">
          <input type="radio" name="settingsRadioButton" id="settingsJobDashboard" value="dashboard" (change)="resetJobList(); refreshQuickLink()" [(ngModel)]="user?.settings.defaultLanding">
          <label for="settingsJobDashboard">{{'job.jobsDashboard' | translate }}</label>
          <br>
          <input type="radio" name="settingsRadioButton" id="settingsJobPage" value="job" (change)="refreshQuickLink()" [(ngModel)]="user?.settings.defaultLanding"
            (change)="user?.settings.defaultLanding = 'job';">
          <label for="settingsJobPage">
            <select name="settingsJobChoice" [(ngModel)]="user?.settings.jobChoice" (change)="user?.settings.defaultLanding = 'job';refreshQuickLink()" class="select-dropdown chooseJob-select">
              <option *ngIf="jobs.length > 0" disabled selected="selected" [value]="''">{{'chooseJob' | translate }}</option>
              <option *ngFor="let job of jobs" [value]="job.id" name="jobDropdown">{{job.name}}</option>
            </select>
          </label>
        </div>

        <div class="time-section">
          <div class="h4 time-format">{{'timeDate'|translate}}</div>
          <div class="radio-field">
            <input type="radio" name="timeDateFormatRadio" id="twelvehour" value="12hr" [(ngModel)]="user?.settings.timeDateFormat">
            <label for="twelvehour">{{'12hr' | translate }}</label>
            <br>
            <input type="radio" name="timeDateFormatRadio" id="twentyfourhour" value="24hr" [(ngModel)]="user?.settings.timeDateFormat">
            <label for="twentyfourhour">{{'24hr' | translate }}</label>
            <br>
            <input type="radio" name="timeDateFormatRadio" id="international" value="Int.St.(2017-06-04T19:27:35)" [(ngModel)]="user?.settings.timeDateFormat">
            <label for="international">{{'Int.St.(2017-06-04T19:27:35)' | translate }}</label>
            <br>
            <input type="radio" name="timeDateFormatRadio" id="ddmmyy" value="dd/mm/yy" [(ngModel)]="user?.settings.timeDateFormat">
            <label for="ddmmyy">{{'dateFormat' | translate }}</label>
            <br>
            <br>
          </div>
        </div>-->
      </div>

      <div class="col-3">

        <div class="theme-section pt-4">
          <div class="h4">{{'settings.theme'|translate}}</div>
          <span class="toggle-icon icon_moon"></span>
          <span class="switch-label">{{'dark'|translate}}</span>
          <label class="switch switch-icon">
            <input type="checkbox" name="settingsTheme" (change)="onSettingsUpdate($event, 'theme');"
              [(ngModel)]="IsThemeDark" [checked]="IsThemeDark" #theme>
            <span class="slider round"></span>
          </label>
          <span class="toggle-icon icon_sun"></span>
          <span class="switch-label">{{'light'|translate}}</span>
        </div>

        <div class="time-zone pt-4">
          <div class="h4">{{'settings.timeZone'|translate}}</div>
          <select class=" select-dropdown" (change)="onSettingsUpdate($event, 'timezone');"
            [(ngModel)]="_settings.selectedTimeZone" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let optionItem of _settings.timeZones" [ngValue]="optionItem">
              {{optionItem}}</option>
          </select>
        </div>

        <div class="unit-System pt-4">
          <div class="h4">{{'settings.unitSystem'|translate}}
            <div (click)="openUnitSystemsModal()" class="icon-btn ic-edit-icon"></div>
          </div>
          <select class="select-dropdown" (change)="onSettingsUpdate($event, 'unitSystem');"
            [(ngModel)]="selectedUnitSystemId" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let optionItem of _unitSystems.unitSystems" [ngValue]="optionItem.unitSystemId">
              {{optionItem.name}}</option>
            <option value="_create" class="color-dark-brown">
              {{'unitSystemsProfile.addCustom'|translate}}</option>
          </select>
        </div>

        <div class="unit-System pt-4">
          <div class="h4">{{'settings.uomProfile'|translate}}</div>
          <select class="select-dropdown" (change)="onSettingsUpdate($event, 'uomProfile');"
            [(ngModel)]="selectedUomProfile" [ngModelOptions]="{standalone: true}">
            <option value="job">JOB</option>
            <option value="userAccount">USER ACCOUNT</option>
          </select>
        </div>

        <div *ngIf="!isLocal" class="pt-4">
          <div class="h4">{{'settings.role'|translate}}</div>
          <input [readonly]="true" type="text" class="input-field" [value]="settings.assignedRole"/>
        </div>
      </div>

      <div class="col-md-5 notification-section pt-4" *ngIf="user?.settings.notificationOptions">
        <div class="row">
          <div class="col-md-7">
            <div class="h4">{{'receiveNotify'|translate}}</div>
          </div>
          <div class="col-md-3">
            <div class="h5">{{'notify'|translate}}</div>
          </div>
          <div class="col-md-2">
            <div class="h5">{{'email'|translate}}</div>
          </div>
        </div>

        <!-- Offline/Online status for rig Row -->
        <div class="row">
          <div class="col-md-7">
            <div class="notification-type">{{'notification.offlineOnlineStatusForRig'|translate}}</div>
          </div>
          <div class="col-md-3">
            <label class="check-label"
              [ngClass]="!user?.settings.notificationOptions.offlineOnlineStatusForRig.notificationCanOptOut? 'disabled': ''">
              <input type="checkbox" name="notificationOptOut-1"
                [(ngModel)]="user?.settings.notificationOptions.offlineOnlineStatusForRig.notificationOptOut"
                [checked]="user?.settings.notificationOptions.offlineOnlineStatusForRig.notificationOptOut"
                [disabled]="!user?.settings.notificationOptions.offlineOnlineStatusForRig.notificationCanOptOut" />
              <div class="check-mark"></div>
            </label>
          </div>
          <div class="col-md-2">
            <label class="check-label"
              [ngClass]="!user?.settings.notificationOptions.offlineOnlineStatusForRig.emailNotificationCanOptOut? 'disabled': ''">
              <input type="checkbox" name="emailNotificationOptOut-1"
                [(ngModel)]="user?.settings.notificationOptions.offlineOnlineStatusForRig.emailNotificationOptOut"
                [checked]="user?.settings.notificationOptions.offlineOnlineStatusForRig.emailNotificationOptOut"
                [disabled]="!user?.settings.notificationOptions.offlineOnlineStatusForRig.emailNotificationCanOptOut" />
              <div class="check-mark"></div>
            </label>
          </div>
        </div>

        <!-- manually created title Row -->
        <div class="row">
          <div class="col-md-7">
            <div class="notification-type">{{'notification.manualCreatedTitle'|translate}}</div>
          </div>
          <div class="col-md-3">
            <label class="check-label"
              [ngClass]="!user?.settings.notificationOptions.manualCreatedTitle.notificationCanOptOut? 'disabled': ''">
              <input type="checkbox" name="notificationOptOut-3"
                [(ngModel)]="user?.settings.notificationOptions.manualCreatedTitle.notificationOptOut"
                [checked]="user?.settings.notificationOptions.manualCreatedTitle.notificationOptOut"
                [disabled]="!user?.settings.notificationOptions.manualCreatedTitle.notificationCanOptOut" />
              <div class="check-mark"></div>
            </label>
          </div>
          <div class="col-md-2">
            <label class="check-label"
              [ngClass]="!user?.settings.notificationOptions.manualCreatedTitle.emailNotificationCanOptOut? 'disabled': ''">
              <input type="checkbox" name="emailNotificationOptOut-3"
                [(ngModel)]="user?.settings.notificationOptions.manualCreatedTitle.emailNotificationOptOut"
                [checked]="user?.settings.notificationOptions.manualCreatedTitle.emailNotificationOptOut"
                [disabled]="!user?.settings.notificationOptions.manualCreatedTitle.emailNotificationCanOptOut" />
              <div class="check-mark"></div>
            </label>
          </div>
        </div>

        <!-- userIsAddedToAJob Row -->
        <div class="row">
          <div class="col-md-7">
            <div class="notification-type">{{'notification.userIsAddedToAJob'|translate}}</div>
          </div>
          <div class="col-md-3">
            <label class="check-label"
              [ngClass]="!user?.settings.notificationOptions.userIsAddedToAJob.notificationCanOptOut? 'disabled': ''">
              <input type="checkbox" name="notificationOptOut-11"
                [(ngModel)]="user?.settings.notificationOptions.userIsAddedToAJob.notificationOptOut"
                [checked]="user?.settings.notificationOptions.userIsAddedToAJob.notificationOptOut"
                [disabled]="!user?.settings.notificationOptions.userIsAddedToAJob.notificationCanOptOut" />
              <div class="check-mark"></div>
            </label>
          </div>
          <div class="col-md-2">
            <label class="check-label"
              [ngClass]="!user?.settings.notificationOptions.userIsAddedToAJob.emailNotificationCanOptOut? 'disabled': ''">
              <input type="checkbox" name="emailNotificationOptOut-11"
                [(ngModel)]="user?.settings.notificationOptions.userIsAddedToAJob.emailNotificationOptOut"
                [checked]="user?.settings.notificationOptions.userIsAddedToAJob.emailNotificationOptOut"
                [disabled]="!user?.settings.notificationOptions.userIsAddedToAJob.emailNotificationCanOptOut" />
              <div class="check-mark"></div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="divider pt-4"></div>

    <div class="row pt-4 float-right">
      <button type="cancel" (click)="cancelSettings()" class="bttn bttn-secondary">{{'buttons.back'|translate}}</button>
      <button type="submit" (click)="saveSettings()" [hidden]="isSaveDisabled" [disabled]="isSaveDisabled"
        class="bttn bttn-primary">{{'buttons.save'|translate}}</button>
    </div>

  </div>

</form>