import { Settings } from "./settingsModel.model";

export class UserModel {
    userId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    profileImgUri?: string;
    email?: string;
    role: string;
    currentUnitSystem?: string;
    recentJobs: string[] = [];
    settings: Settings;
    constructor(params?: UserModel) {
        this.userId = params && params.userId;
        this.firstName = params && params.firstName;
        this.lastName = params && params.lastName;
        this.fullName = params && params.fullName;
        this.profileImgUri = params && params.profileImgUri;
        this.email = params && params.email;
        this.role = params && params.role;
        this.currentUnitSystem = params && params.currentUnitSystem;
        this.recentJobs = params && params.recentJobs;
        this.settings = params && params.settings;
    };
}