<div id="divTable" [ngClass]="fullTable ? 'h-table-wrapper-full' : 'h-table-wrapper'" class="h-table-wrapper">

  <!-- ================== Filter Overlay ============== -->
  <div #filterTable *ngIf="showOverlay" [style.left]="overlayLeft" class="div-filter-sort">
    <div class="rig-name-header">
      <label class="card-title">{{overlayHeader|translate}}</label>
    </div>
    <div class="close-btn">
      <span class="filter-color-orange ic-close-icon icon-btn" (click)="dismissOverlayMenu()">  </span>

      <!-- <button type="button" class="close" aria-label="Close" (click)="dismissOverlayMenu()">
        <img class="closeIcon" />
      </button> -->
    </div>
    <div>
      <span class="subheader">{{'Sort'|translate}}</span>
    </div>
    <div class="slider-icon">
      <span class="header">A to Z</span>
      <label class="switch">
        <input type="checkbox" [checked]="sortToggle" (change)="sort()">
        <span class="slider round"></span>
      </label>
      <span class="header">Z to A</span>
    </div>

    <div class="filter-divider-sort"></div>
    <div>
      <span class="subheader">{{'Label'|translate}}</span>
      <button _ngcontent-jde-c34="" class="bttn search-bttn bttn-secondary"
        (click)="clearAll($event.target.checked)">{{'filter.ClearAll'|translate}}</button>
    </div>

    <div class="row search">
      <div class="search-text">
        <input class="" type="text" placeholder="Search" [(ngModel)]="searchText">
        <!-- <img class="custom-icon" src="assets/images/icons/ic-search.svg"> -->
        <span class="custom-icon filter-color-orange"> <span class="ic-search-icon icon-btn"></span> </span>
      </div>
    </div>


    <div class="filter-list">

      <!-- NOT WORKING - SELECT ALL -->

      <!-- <div class="filter-list-items>
        <label class="check-label">
          <input [checked]="checkOrUnchecked(item)" (change)="selectAll($event.target.checked)" type="checkbox" />
          <div class="check-mark"></div>
        </label>
        <div class="medium">{{'select.all'|translate}}</div>
      </div> -->

      <!-- filter checkbox list -->
      <form [formGroup]="myForm">
        <cdk-virtual-scroll-viewport itemSize="20" class="example-viewport">
          <div class="filter-list-items" *cdkVirtualFor="let item of uniqueDisplay | filterData : searchText">
            <label class="check-label">
              <input [checked]="checkSelect(item)" (change)="onChange(item, $event.target.checked)" type="checkbox" />
              <div class="check-mark"></div>
            </label>
            <div *ngIf="item == ''" class="small" [ngClass]="{'item-filtered': checkSelect(item)}">
              {{'(blank)'|translate}}
            </div>
            <div *ngIf="item !== ''" class="small" [ngClass]="{'item-filtered': checkSelect(item)}" appToolTip
              [tooltip]="item" [tooltiplength]="maxFilterDisplayLength">
              {{limitFilterDisplayLength(item)}}
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </form>

    </div>
  </div>
  <!-- ================== Filter Overlay end ============== -->


  <!-- ================== Table ============== -->
  <table class="dynamic-table" [style.width]="tableWidth" [attr.name]="tableName" [attr.id]="tableName">
    <!-- Table Head -->
    <thead class="dynamic-head">

      <tr class="dynamic-header-row  dynamic-header-border" style="border: 0 !important;" sortable-column="header">
        <ng-container *ngIf="hasWarning">
          <th class="cellpad cell-space-icon"></th>
        </ng-container>

        <th class="dynamic-header" [ngClass]="{'icon-column' : header.icon == true }" *ngFor="let header of headers">
          <!-- Header icon -->
          <div *ngIf="header.icon == true || header.link == true" class="pointer" [id]="'header-' + header.key + tableName"
            (click)="overlayMenuToggle($event, header);">
            <i [ngStyle]="header.styleTitle" [ngClass]="header.title"></i>
          </div>

          <div #filterIcons *ngIf="(header.sortFilter == undefined && header.sortFilter == null 
                        && header.icon == undefined && header.link == undefined) || (header.link == true && header.linkStyle=='navigate')"
            class="pointer  dynamic-table-header inline-flex" [id]="'header-' + header.key + tableName"
            (click)="overlayMenuToggle($event, header);"
            [ngClass]="(header.type=='number')?'justify-right':'justify-left'"
           >{{header.title|translate}}

            <span *ngIf="filterValues[header.key] == undefined" class="ic-wrapper">&nbsp;
              <span class="ic-filter"></span>
            </span>

            <span *ngIf="filterValues[header.key] !== undefined" class="ic-wrapper">&nbsp;
              <span class="ic-filter filter-color-orange"></span>
            </span>

            <!-- sort Arrow - Not yet finished -->
            <!-- <div class="sortFilter-arrow-div" *ngIf="key == sortCol.key" [id]="'sortFilter-'+tableName+'-'+header[key]">
                <i *ngIf="sortCol.type == true" class="fa fa-arrow-down" aria-hidden="true"></i>
                <i *ngIf="sortCol.type == false"  class="fa fa-arrow-up" aria-hidden="true"></i>
            </div> -->
          </div>


          <div *ngIf="header.sortFilter == false && header.icon == undefined" class=" dynamic-table-header inline-flex"
            [ngClass]="{'right': isCellTextAlignRight(header)}">
            {{header.title|translate}}</div>
          <div *ngIf="header.uom == true" class="inline-flex">
            <!-- <app-uom-label [columnSettings]="colSettings" [fieldIdentifier]="header.key" [componentId]="tableName"
              [sampleValues]="sampleData" [catalogue]="true"></app-uom-label> -->
          </div>
        </th>
      </tr>

      <tr sortable-column="header">
        <th [ngClass]="{'top-border': header.title != '', 'sub-group' : headers[i].title == ''}"
          *ngFor="let header of subHeaders; let i = index">
          <div *ngIf="header.sortFilter == undefined || header.sortFilter == null" class="pointer   inline-flex"
            [id]="'header-' + header.key + tableName" [ngClass]="{'right': isCellTextAlignRight(header)}"
            (click)="overlayMenuToggle($event, header);">{{header.title|translate}}
          </div>
          <div *ngIf="header.sortFilter == false" class="pointer inline-flex"
            [ngClass]="{'right': isCellTextAlignRight(header)}">{{header.title|translate}}
          </div>
          <div *ngIf="header.uom == true" class="inline-flex" [ngClass]="{'right': isCellTextAlignRight(header)}">
            <!-- <app-uom-label *ngIf="header.uom == true" [columnSettings]="colSettings" [fieldIdentifier]="header.key"
              [componentId]="tableName" [sampleValues]="sampleData" [catalogue]="true" class="inline-flex">
            </app-uom-label> -->
          </div>
        </th>
      </tr>

      <!--Added row to display Enter Custom-->
      <!-- <tr class="no-line">
        <th *ngIf="enterCustom == true" class="enter-custom-header">{{'enter.custom'|translate}}</th>
      </tr>

      <tr class="dynamic-header-row" *ngIf="enterCustom == true">
        <th class="dynamic-header" *ngFor="let header of headers; let i = index;">
          <div>
            <input [hidden]="header.hideInput" [readonly]="header.hideInput" [disabled]="header.hideInput"
              *ngIf="header.uom == true" [ngClass]="{'input-align-right': header.uom == true}"
              [id]="header.key+'-'+tableName" (blur)="blurEvent(header, headers, i)" class="table-input inline-flex"
              type="number" />
            <input [hidden]="header.hideInput" [readonly]="header.hideInput" [disabled]="header.hideInput"
              *ngIf="header.uom == false || header.uom == undefined" [id]="header.key+'-'+tableName"
              (blur)="blurEvent(header, headers, i)" class="table-input inline-flex" />
          </div>

        </th>
      </tr> -->
    </thead>

    <!-- Table Body -->
    <tbody [style.max-height]="maxHeight" class="dynamic-body">
      <tr class="dynamic-body-row" *ngFor="let row of currentData; let i = index; trackBy: trackByFn"
        [ngClass]="{'striped-row': i%2 == 0 && striped == true, 'striped-row': row.highLightedRow == true}"
        [ngClass]="rowPointer ? 'pointer' : ''">

        <ng-container *ngIf="hasWarning">
          <td class="cell-space-icon">
            <span *ngIf="warningStatus(row)" 
            class="ic-2alert-yellow warning-icon"
            appToolTip [tooltip]="warningMess(row)"></span>
          </td>
        </ng-container>

        <td class="dynamic-cell" *ngFor="let header of headers" [ngClass]="{'p-0': header.icon == true}">

          <!-- Checkbox -->
          <div class="checkbox-table" *ngIf="header.checkbox == true">
            <label class="check-label">
              <input [id]="'checkbox-'+ row.id" type="checkbox" (click)="rowEvent($event, row, header.key)"
                [checked]="row[header.key] == true" />
              <div class="check-mark"></div>
            </label>
          </div>

          <!-- Manage Icons -->
          <div *ngIf="header.icon == true" (click)="rowEvent($event, row, header.key)">
            <i [ngStyle]="header.style" class="{{cellIconValue(row,header.key)}}"></i>
            <div *ngIf="header.contextIcon == true" class="ic-arrow-down small-arrow context-icon"></div>
          </div>

          <!-- Manage Links -->
          <div *ngIf="header.link == true && header.linkStyle == undefined" class="link-primary" (click)="rowEvent($event, row, header.key)">
            {{header.content? header.content: (row | cellValue:header | translate)}}
          </div>

          <!-- Manage Links, Link style norrmal -->
          <div *ngIf="header.link == true && header.linkStyle == 'navigate'" 
          appToolTip [tooltip]="row[header.key]" [tooltiplength]="maxColumnDisplayLength"
          class="link-navigate" (click)="rowEvent($event, row, header.key)">
            {{row | cellValue:header}}
          </div>

          <!-- Standard Cell -->
          <div [ngStyle]="header.style"
            *ngIf="header.icon == undefined && header.pill == undefined && header.checkbox == undefined
                    && header.link == undefined"
            [ngClass]="{'right': isCellTextAlignRight(header)}" (click)="rowEvent($event, row, header.key)"
            class="measurement dynamic-div" appToolTip [tooltip]="row[header.key]"
            [tooltiplength]="maxColumnDisplayLength">
            {{useColSettings(header)
            ? calculateUoMCellValue(colSettings | decPrecision:header.key, row | cellValue:header, header )
            : (row | cellValue:header)}}
          </div>

          <!-- Pill Cell -->
          <div *ngIf="header.pill == true" [ngClass]="{'right': isCellTextAlignRight(row, header)}"
            (click)="rowEvent($event, row, header.key)" class="measurement dynamic-div">
            <div class="pill pill-status table-pill">
              {{row | cellValue:header}}
              <div *ngIf="header.contextIcon == true" class="ic-arrow-down small-arrow context-icon"></div>
            </div>
          </div>
        </td>

      </tr>
    </tbody>
  </table>

  <div *ngIf="download == true;" class="download" (click)="downloadCSV({ filename: 'stock-data.csv' });">
    <button class="bttn-xs bttn-tertiary">Copy to Excel</button>
  </div>
</div>

<pagination [boundaryLinks]="isBoundaryLinks" [directionLinks]="isDirectionLinks"
  [previousText]="PAGINATION_PROPERTIES.PREVIOUS_TEXT" [firstText]="PAGINATION_PROPERTIES.FIRST_TEXT"
  [nextText]="PAGINATION_PROPERTIES.NEXT_TEXT" [lastText]="PAGINATION_PROPERTIES.LAST_TEXT" [totalItems]="data?.length"
  [itemsPerPage]="pageSize" [maxSize]="PAGINATION_PROPERTIES.MAX_SIZE" (pageChanged)="pageChanged($event)"
  [rotate]="true">
</pagination>