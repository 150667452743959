<div style="padding-top:54px;"></div>
<div *ngIf="showNotificationBar" class="notification-body" [ngClass]="{
      'toastr-success': errorType == 'success',
      'toastr-warning': errorType == 'warning',
      'toastr-error': errorType == 'error',
      'toastr-info': errorType == 'info'}">
  <div class="toastr-icon" [ngClass]="{
    'ic-check-black':  errorType == 'success',
    '': errorType == 'error',
    '': errorType == 'info',
    '': errorType == 'warning'}"></div>
  <p class="medium toastr-text" [ngClass]="{
    'notification-success-text': errorType == 'success',
    'notification-warning-text': errorType == 'warning',
    'notification-error-text': errorType == 'error',
    'notification-info-text': errorType == 'info'}" [innerHtml]="notificationMessage"></p>
</div>