import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { isNull } from 'src/app/utils/utils';

@Directive({
  selector: '[appToolTip]'
})
export class ToolTipDirective implements OnInit, OnDestroy {
  @Input("tooltip") tooltipTitle: string = "";
  @Input("tooltiplength") tooltipLength: number = 0;
  tooltip: HTMLElement;
  //delay = 500;

  constructor(
    public elRef: ElementRef,
    public renderer: Renderer2) {
  }
  
  ngOnDestroy(): void {
    this.hideTooltip();
  }

  ngOnInit() {
  }

  showTooltip(event: MouseEvent) {
    if (isNull(this.tooltipLength) || isNull(this.tooltipTitle)) return;
    var value = (Array.isArray(this.tooltipTitle)) ? this.tooltipTitle.join(', ') : this.tooltipTitle;
    if (value.length > this.tooltipLength) {
      if (this.tooltip || this.findTooltip() || this.createTooltip()) {
        // get bounding area and mouse coordinates
        const hostPos = this.elRef.nativeElement.getBoundingClientRect();
        let clientX = event.clientX;
        let clientY = event.clientY;
        let pageX = event.pageX;
        let pageY = event.pageY;
        let diffX = pageX - clientX;
        let diffY = pageY - clientY;
        let left = clientX + (hostPos.height / 2);
        //let top = hostPos.top;
        let bottom = hostPos.bottom;

        // add the positioning and show
        this.renderer.setValue(this.tooltip.childNodes[0], value);
        this.renderer.setStyle(this.tooltip, "top", `${bottom + diffY}px`);
        this.renderer.setStyle(this.tooltip, "left", `${left + diffX}px`);
        this.renderer.addClass(this.tooltip, "tooltip_show");
      }
    }
  }

  findTooltip(): boolean {
    this.tooltip = document.getElementById("_tooltip");
    return this.tooltip ? true : false;
  }

  createTooltip(): boolean {
    // create a span
    this.tooltip = this.renderer.createElement("span");
    if (this.tooltip) {
      this.tooltip.id = "_tooltip";

      // add the tooltip text into the tooltip span
      this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));

      // append to the document
      this.renderer.appendChild(document.body, this.tooltip);

      // add the tooltip styles
      this.renderer.addClass(this.tooltip, "tooltip");
    }
    
    return this.tooltip ? true : false;
  }


  hideTooltip() {
    if (this.tooltip) {
      this.renderer.removeClass(this.tooltip, "tooltip_show");
      this.renderer.setStyle(this.tooltip, "top", `${0}px`);
      this.renderer.setStyle(this.tooltip, "left", `${0}px`);
    }
  }

  @HostListener("mouseover", ["$event"]) onMouseEnter(event: MouseEvent) {
    this.showTooltip(event);
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.hideTooltip();
  }

  @HostListener("click") onClick() {
    this.hideTooltip();
  }
}
