<!-- ================== Filter Overlay ============== -->
<div #filterTable [style.left]="popupPosition" class="div-filter-sort">
  <div class="rig-name-header">
    <label class="card-title">{{options.header|translate}}</label>
  </div>
  <div class="close-btn">
    <span class="filter-color-orange ic-close-icon icon-btn" (click)="close()"></span>
  </div>
  <div>
    <span class="subheader">{{'Sort'|translate}}</span>
  </div>
  <div class="slider-icon">
    <span class="header">A to Z</span>
    <label class="switch">
      <input type="checkbox" [checked]="sortToggle" (change)="sort($event.target.checked)">
      <span class="slider round"></span>
    </label>
    <span class="header">Z to A</span>
  </div>

  <div class="filter-divider-sort"></div>
  <div>
    <span class="subheader">{{'Label'|translate}}</span>
    <button _ngcontent-jde-c34="" class="bttn search-bttn bttn-secondary"
      (click)="clearAll()">{{'filter.ClearAll'|translate}}</button>
  </div>

  <div class="filter-search">
    <div class="search-text">
      <input class="" type="text" placeholder="Search" [(ngModel)]="searchText">
      <!-- <img class="custom-icon" src="assets/images/icons/ic-search.svg"> -->
      <span class="custom-icon filter-color-orange"> <span class="ic-search-icon icon-btn"></span> </span>
    </div>
  </div>


  <div class="filter-list">
    <!-- filter checkbox list -->
    <form>
      <cdk-virtual-scroll-viewport itemSize="20" class="example-viewport">
        <div class="filter-list-items" 
          *cdkVirtualFor="let item of unique | filterData : searchText"
        >
          <label class="check-label">
            <input [checked]="isChecked(item)" (change)="onChange(item, $event.target.checked)" type="checkbox" />
            <div class="check-mark"></div>
          </label>
          <div *ngIf="isNull(item)" class="small" [ngClass]="{'item-filtered': isChecked(item)}">
            {{'(blank)'|translate}}
          </div>
          <div *ngIf="!isNull(item)" class="small" [ngClass]="{'item-filtered': isChecked(item)}" appToolTip
            [tooltip]="item" [tooltiplength]="maxFilterDisplayLength">
            {{limitDisplay(item)}}
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </form>
  </div>
</div>
<!-- ================== Filter Overlay end ============== -->