import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { Config } from 'src/app/core/services/config';
import * as LS from 'src/app/utils/localstorage';
import { UtilitiesService } from '../utilitiesservice.service';
import { FormGroup } from '@angular/forms';
import { FluidSets } from 'src/app/core/models/fluid-sets.model';
import { DataLogicService } from '../data-logic.service';
@Injectable({
  providedIn: 'root'
})
export class FluidSetsService {
  //public catFluidSets: any;
  public fluidSetForm: FormGroup;
  //public _utilities: UtilitiesService;

  constructor(
    public _api: ApiService,
    public _utilities: UtilitiesService,
    public dataLogic: DataLogicService) {
  }

  /**
   * Get all fluid sets
   */
  getAllFluidSets() {
    return this._api.get<any>(Config.APIUrlCore + environment.fluid_sets.getAllFluidSets);
  }

  /**
 * Get fluid sets by job id
 * @param jobId 
 */
  getFluidSetsByJobId(jobId: string) {
    const key = LS.LOCAL_STORAGE_KEY.FluidSets;
    const url = Config.APIUrlCore + environment.fluid_sets.getFluidSetsByJobId.replace("{jobId}", jobId);
    return this.dataLogic.get(url, key);
    // return this.api.get<any>(Config.APIUrlCore + environment.fluid_sets.getFluidSetsByJobId.replace("{jobId}", jobId));
  }

  /**
 * Get fluid set by job id
 * @param fluidSetId 
 */
  getFluidSetById(fluidSetId: string) {
    return this._api.get<any>(Config.APIUrlCore + environment.fluid_sets.getFluidSetById.replace("{fluidSetId}", fluidSetId));
  }

  /**
  * Create or update fluid set
  * @param fluidSet
  */
  async createOrUpdateFluidSet(fluidSet) {
    return await this.dataLogic.postAsync(Config.APIUrlCore + environment.fluid_sets.createOrUpdateFluidSet, fluidSet);
  }

  /**
  * delete fluid set by fluid set id
  * @param fluidSetId
  */
  async deleteFluidSets(fluidSetId: string) {
    return await this.dataLogic.postAsync(Config.APIUrlCore + environment.fluid_sets.deleteFluidSet.replace("{fluidSetId}", fluidSetId), null);
  }

  public async loadFluidSetsCatalogs(forceLoad: boolean) {
    forceLoad = this._utilities.checkCatalogExpiration(forceLoad);
    var promises = [];
    // skip load when memory data is present and not forced reload, i.e. as on subsequent page loads
    (!forceLoad && this._utilities.catFluidSets && (this._utilities.catFluidSets.length > 0)) || promises.push(this._utilities.loadCatalogFluidSets(false));
    return Promise.all(promises)
      .then(resp => {
        console.log("FluidSet catalogs loaded");
      })
      .catch(() => {
        console.log("FluidSet catalogs error");
      });
  }

}
