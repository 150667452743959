import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges, OnInit {
  @Input() public chartTitle: string;
  @Input() public chartData: any;
  @Input() public chartOptions: any;
  @Input() public chartFrame: boolean;
  @Input() public chartDataExpand: string;
  @Input() public initOpts: any;

  @Input() public symbol: boolean;
  @Input() public legend: boolean;
  public lineChart: any;

  public lineChartOptions: any;

  constructor(public util: UtilitiesService) {
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.chartOptions = this.chartOptions;
    this.initOpts = this.initOpts;
    this.lineChartOptions = {
      legend: {
        show: this.legend,
        textStyle: {
          fontSize: 11
        }
      },

      //Line Chart Base
      tooltip: {
        formatter: '{a0} <br />{c0}'
      },
      xAxis: {
        type: 'value',
        position: "bottom",
        nameLocation: "middle",
        nameGap: 32,
        scale: true,
        axisTick:
        {
          show: false
        },
        nameTextStyle: {
          fontSize: 11
        },
        axisLabel: {
          // formatter: function (value) {
          //   return Number(value).toFixed(2);
          // }
        }
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        // offset: 25,
        scale: true,
        inverse: true,
        axisTick:
        {
          show: false
        },
        nameTextStyle: {
          fontSize: 11
        },
        axisLabel: {
          // formatter: function (value) {
          //   return Number(value).toFixed(2);
          // }
        }
      },
      grid: {
        right: "6%",
        bottom: "10%",
        top: "6%",
        left: "8%"
      }
    };

    if (this.chartData) {
      let seriesData = this.chartData.map(a => ({
        name: a.series,
        data: a.data,
        type: "line",
        itemStyle: a.itemStyle,
        lineStyle: a.lineStyle || {},
        label: a.label,
        symbol: a.symbol ? a.symbol : 'circle',
        symbolSize: 4,
        color: a.color,
        areaStyle: a.areaStyle
      }));

      this.lineChartOptions.series = seriesData;
      this.lineChartOptions.legend.data = this.chartData.map(a => ({
        name: a.series,
        icon: 'line'
      }));

      if (this.lineChartOptions.series.length === 1) {
        this.lineChartOptions.tooltip.formatter = '{c0}';
      }
    }

    this.mergeDeep(this.lineChartOptions, this.chartOptions);
  }

  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }


  mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.mergeDeep(target, ...sources);
  }
}

