
export class WellHeader {
    public isCriticalFirstWell: boolean = false;
    public isTightHole: boolean = false;
    public jobId: string = "";
    public wellType: string = "";
    wellWITSML: wellWITSML = new wellWITSML();
    public isHighPriorityWell: boolean = false;
    constructor() {
    }
}

export class wellWITSML {
    public county: string = "";
    public basin: string = "";
    public country: string = "";
    public dTimSpud: string = "";
    public field: string = "";
    public block: string = "";
    public district: string = "";
    public region: string = "";
    public latitude: string = "";
    public longitude: string = "";
    public nameLegal: string = "";
    public numAPI: string = "";
    public numPCN: string = "";
    public operator: string = "";
    public purposesWell: Array<any> = new Array([]);
    public state: string = "";
    public statusWell: string = "";
    public timezone: string = "";
    public wellId: string = "";
    waterDepth: waterDepth;
    constructor() {   
     }
}

export class waterDepth {
    public value: number = 0;
    public uom: string = "";
}

