import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PARAM_MAP } from 'src/app/utils/constants';
import { UnitSystemsService } from '../services/unitsystems.service';
//import { UtilitiesService } from '../services/utilitiesservice.service';

@Injectable()
export class CurrentUnitResolver implements Resolve<string> {
  constructor(
    // utilities service injected to ensure token auth is handled first
    //public _utilities: UtilitiesService,
    public _unitSystems: UnitSystemsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    //return "true";
    let jobId = route.paramMap.get(PARAM_MAP.JOB_ID);
    return this._unitSystems.waitActiveUnitSystemComplete(jobId);
  }
}