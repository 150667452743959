import { Component, OnInit, OnChanges, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FieldLevelUoMSettings } from '../models/columnSettings';
import { UtilitiesService } from '../../core/services/utilitiesservice.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JobService } from 'src/app/job/job.service';
import { UnitConversionService } from '../../core/services/unit-conversion.service';

@Component({
  selector: 'app-uom-label',
  templateUrl: './uom-label.component.html',
  styleUrls: ['./uom-label.component.scss']
})
export class UomLabelComponent implements OnInit {
  @Input() columnSettings: FieldLevelUoMSettings[];
  @Input() fieldIdentifier: string;
  @Input() componentId: string;
  @Input() sampleValues: any;
  @Input() catalogue: any;
  @Input() canChangeUnit: boolean = true

  get isFixedUnit(): boolean {
    return !!(this.columnSetting && this.columnSetting.fixedUnit);
  }

  columnSetting: FieldLevelUoMSettings;
  sampleValue: string;
  jobId: string;
  //public currentUnitSystem$ = new BehaviorSubject<any>([]);

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public _utilities: UtilitiesService,
    public _job: JobService,
    //public _unitConversion: UnitConversionService,
  ) { }

  ngOnInit() {
    if (this.columnSettings) {
      // find is much more efficient than filter[0]
      this.columnSetting = this.columnSettings.find(x => x.colName.toLowerCase() == this.fieldIdentifier.toLowerCase());
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  uomModalToggleEvent(event) {
    //let currentUnitSystem: any = this.currentUnitSystem$.value
    this.columnSetting = this.columnSettings.find(x => x.colName.toLowerCase() == this.fieldIdentifier.toLowerCase());

    this._job.jobId$.pipe(takeUntil(this.componentDestroyed$)).subscribe(jobId => {
      this.jobId = jobId;
      this.columnSetting = this.columnSettings.find(x => x.colName.toLowerCase() == this.fieldIdentifier.toLowerCase());
      this.sampleValue = this.sampleValues ? this.sampleValues[this.fieldIdentifier] : null;
      this._utilities.showUOMOverlay(event, this.jobId, this.columnSetting, this.componentId, this.sampleValue, this.catalogue);
    });
  }
}
