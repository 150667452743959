import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { auditTime } from 'rxjs/operators';
@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    'hr.slim {border-top: 1px solid rgb(51, 51, 51) !important;}'
  ]
})
export class StyleGuideComponent implements OnInit {

  public inputVariable: string;
  public inputVariableDanger: string;
  public dropdownSelect: string;
  public radioVariable: any;
  public radioV: any;
  public checked: any;
  public checked1: any;
  public checked2: any;
  public checked3: any;
  public checked4: any;
  public errors: any;
  public dirty: any;
  public touched: any;
  public formationChartDataNorthing: any;
  public formationChartOptions: any;
  public formationChartOptions2: any;
  public formationChartData: any;
  public fillText: string = "text";
  public fillText1: string = "text";
  public fillText3: string = "text";
  public fillText4: string = "text";
  public emptyText: string = "";
  public changeIcon: number;
  public dropdownExpandedOrange: boolean = false;
  public toggle = true;
  autoSaveValueChanged: string;

  @ViewChild(NgForm) formTest;

  constructor() { }

  ngOnInit() {
    // this.formTest.form.valueChanges.pipe(auditTime(2000)).subscribe(
    //   result => {
    //     if (!this.formTest.pristine) {
    //         this.autoSaveValueChanged = "Saving to DB: " + result.firstName + " " + result.lastName;
    //     }
    //   }
    // );
  }

  accordianOpen(ev: any) {
    this.changeIcon = ev.index;
  }

  accordianClose(ev: any) {
    this.changeIcon = -1;
  }

}
