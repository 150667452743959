import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';
import { checkConnection } from 'src/app/utils/utils';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  public pageTitle: string;
  public pageMessage: string;
  subscription: Subscription;

  constructor(
    public _error: ErrorMessageService,
    ) {
    this.subscription = this._error.errorPageMessage$.subscribe(
      message => {
        //var connected = checkConnection();
        if (message.toLowerCase().includes('0 unknown error')) {
          message = message.replace(': 0 Unknown Error', '');
          message = message.replace('failure response for ', 'failure response\nUrl: ');
          message = message
           + "\n\nPossible causes:\nPlease verify your services are running, alternatively restart your services or your computer."
           + "\nIf you continue with the issue, please contact the support team.";
        }
        this.pageMessage = message;
      });
  }
  ngOnInit(): void {
    //this._error.isError = false;
  }

  ngOnDestroy() {
    this._error.isError = false;
    this.subscription.unsubscribe();
  }
}
