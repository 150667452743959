import { Injectable } from '@angular/core';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';
import { UnitConversionService } from 'src/app/core/services/unit-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class UomConversionService {

  constructor(
    public _unitConversion: UnitConversionService
  ) { }

  public calculateConvertedValueDisplay(fixedDecimals: FieldLevelUoMSettings, value: number) {
    return this.calculateValueDisplay(fixedDecimals, value, false, true);
  }

  // modified to allow for optional convert and format
  public calculateValueDisplay(fixedDecimals: FieldLevelUoMSettings, value: number, doConvert: boolean, doFormat: boolean) {
    if (fixedDecimals == undefined) {
      return;
    }
    let displayValue: string;
    let units = this._unitConversion.currentUnitSystem$.value.name;
    if (fixedDecimals.decimalOption == "Default" || fixedDecimals.decimalOption == "Fixed Decimals") {
      displayValue = (units != 'API' && doConvert) ? this._unitConversion.convertValue(fixedDecimals, value) : value;
      displayValue = doFormat ? Number(displayValue).toFixed(fixedDecimals.decimalPrecision) : displayValue;
    }
    if (fixedDecimals.decimalOption == "Significant Digits") {
      displayValue = (units != 'API' && doConvert) ? this._unitConversion.convertValue(fixedDecimals, value) : value;
      displayValue = doFormat ? Number(displayValue).toPrecision(fixedDecimals.significantDigits) : displayValue;
    }
    return displayValue;
  }

  public convertToAPIUnit(colSetting: FieldLevelUoMSettings, value: number) {
    if (colSetting == undefined) {
      return;
    }

    const apiCol: FieldLevelUoMSettings = {
      ...colSetting
    };
    apiCol.currentUnit = colSetting.unit;
    apiCol.unit = colSetting.currentUnit;
    let convertValue = this._unitConversion.convertValue(apiCol, value);

    return {
      value: convertValue,
      uom: colSetting.unit
    }
  }

  public convertObjectToAPIUnit(colSettings: Array<FieldLevelUoMSettings>, obj: any): void {
    for (const col of colSettings) {
      let property = obj[col.colName];
      if (property) {
        let convertValue = this.convertToAPIUnit(col, property.value);
        property.value = convertValue.value;
        property.uom = convertValue.uom;
      }
    }
  }

  public convertObjectToCurrentUnit(colSettings: Array<FieldLevelUoMSettings>, obj: any): void {
    for (const col of colSettings) {
      let property = obj[col.colName];
      if (property) {
        property.value = this._unitConversion.convertValue(col, property.value);
        property.uom = col.currentUnit;
      }
    }
  }
  // public sigFigs(n, sig) {
  //   var mult = Math.pow(10, sig - Math.floor(Math.log(n) / Math.LN10) - 1);
  //   return Math.round(n * mult) / mult;
  // }
}
