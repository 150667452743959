import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { MessageService } from '../../shared/message.service';
import { apiParam } from '../models/apiParam.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public BasicAuthHeaders;
  public JWTAuthHeaders;
  public testOffline: boolean = false;
  public testOffVpn: boolean = false;

  constructor(
    public http: HttpClient,
    public messageService: MessageService
  ) {
    let token = btoa('Secret:Squirrel01@');
    let headers1 = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers1 = headers1.append('Access-Control-Allow-Origin', '*');
    headers1 = headers1.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    headers1 = headers1.append('Authorization', 'Bearer ' + token);
    headers1 = headers1.append('skipIntercept', 'true');
    this.BasicAuthHeaders = headers1;

    let key = environment.api.Ocp_Apim_Subscription_Key;
    var headers2 = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers2 = headers2.append('Access-Control-Allow-Origin', '*');
    headers2 = headers2.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    //headers2 = headers2.append('Ocp-Apim-Subscription-Key', key);
    this.JWTAuthHeaders = headers2;
  }

  public get<T>(url: string, id?: string, params?: apiParam[]): Observable<T> {

    if (id) {
      url = `${url}/${id}`;
    }

    if (params) {
      for (let param of params) {
        if (url) {
          url = url.replace(param.name, param.value);
        }
      }
    }
    console.log("api.get", url);
    let request = this.http.get<T>(url, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public post<T>(url: string, data: T): Observable<T> {
    console.log("api.post", url);
    let request = this.http.post<T>(url, data, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public put<T>(url: string, data: T): Observable<T> {
    console.log("api.put", url);
    let request = this.http.put<T>(url, data, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public patch<T>(url: string, data: T): Observable<T> {
    console.log("api.patch", url);
    let request = this.http.patch<T>(url, data, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  public delete<T>(url: string, id?: string): Observable<T> {
    if (id) {
      url = `${url}/${id}`;
    }
    console.log("api.delete", url);
    let request = this.http.delete<T>(url, this.HeaderJWTAuth()).catch(this.handleError);
    return request;
  }

  // api service call for local token generator
  public localtoken<T>(url: string): Observable<T> {
    if (url.endsWith("username")) {
      return this.http.get<T>(url, this.HeaderBasicAuth()).catch(this.handleError);
    } else {
      const data = {
        upn: "local_user@halliburton.com",
        eMail: "local_user@halliburton.com"
      }
      return this.http.post<T>(url, data, this.HeaderBasicAuth()).catch(this.handleError);
    }
  }

  /** Log a message with the MessageService */
  public log(message: string) {
    this.messageService.add(`HttpService: ${message}`);
  }

  public Header() {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return { headers: header };
  }

  public HeaderBasicAuth() {
    //let header = new HttpHeaders({ 'Authorization': 'Basic ' + btoa('Secret:Squirrel01@') });
    return { headers: this.BasicAuthHeaders };
  }

  public HeaderJWTAuth() {
    // let key = environment.api.Ocp_Apim_Subscription_Key;
    // let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header = header.append('Access-Control-Allow-Origin', '*');
    // header = header.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    // header = header.append('Ocp-Apim-Subscription-Key', key);
    //return { headers: header, options: {observe: 'response'} };
    return { headers: this.JWTAuthHeaders };
  }

  public handleError(errorResponse: any) {
    // this object comes from error-interceptor
    return throwError(errorResponse);
  }
}
