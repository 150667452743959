import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogInput } from 'src/app/shared/components/dialog/dialog.component';
import { MODAL_IDENTIFIERS } from 'src/app/shared/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class DialogModalService {
  constructor(
    private _ngxSmartModal: NgxSmartModalService,
    private router: Router) {      
  }

  private modal: NgxSmartModalComponent;
  private routeSub: any;
  
  private routeChanges() {
    if(!this.routeSub) {
      this.routeSub = this.router.events.subscribe((event) => {
        if(event instanceof NavigationStart){
          if(this.modal && this.modal.visible) {
            this.modal.close(); //Close popup when naviagte
          }
        }
      });
    }
  }

  public showInfo(dialogInput: DialogInput) {
    if(!this.modal) {
      this.modal =  this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.DIALOG);
    }
    this.modal.setData(dialogInput, true);
    this.modal.open();
    this.routeChanges();
  }

  public showConfirmation(dialogInput: DialogInput): Observable<any> {
    this.routeChanges();
    return new Observable<any>(ob => {
      if(!this.modal) {
        this.modal =  this._ngxSmartModal.getModal(MODAL_IDENTIFIERS.DIALOG);
      }
      this.modal.setData(dialogInput, true);
      this.modal.open();
      let dialogResponse$ = new Subject<boolean>();
      this.modal.onClose.pipe(takeUntil(dialogResponse$)).subscribe(action => {
        ob.next(action);
        ob.complete();
        dialogResponse$.next(true);
        dialogResponse$.complete();
      });
    });
  }
}
