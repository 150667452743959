import 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { ACTION_TYPE, FIELD_TYPE, SORT_TYPE } from 'src/app/shared/enums/enums';
import { ToolbarEvent, ToolbarColumn, ToolbarSharing } from 'src/app/shared/components/app-table-toolbar/table-toolbar.component';
import { SettingsService } from '../core/services/settings.service';
import { Settings } from '../core/models/settingsModel.model';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy {

  public toolbarEvent$ = new BehaviorSubject<ToolbarEvent>({ action: ACTION_TYPE.INIT });
  public userRoleForm: FormGroup;
  public toolbarSharing: ToolbarSharing = new ToolbarSharing();
  public componentDestroyed$: Subject<boolean> = new Subject();
  public columns: Array<ToolbarColumn> = [
    { key: "userId", i18n: "User", fieldType: FIELD_TYPE.TEXT, readonly: true, sort: SORT_TYPE.ASC },
    { key: "assignedRole", i18n: "Role", fieldType: FIELD_TYPE.DROPDOWN, data: ["Admin","Engineer","Tech Prof","Manager"]}
  ];
  public tableView: Array<Settings> = [];

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public _utilities: UtilitiesService,
    public _setting: SettingsService,
  ) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initializeForm();
    this.getUsers();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public getUsers() {
    this._utilities.showSpinner(true);
    this._setting.getAllProfileSettings().subscribe(res => {
      this.tableView = res;
      this._utilities.showSpinner(false);
    });
  }

  get validationDataFn() {
    return this.tableView.filter((el, idx) => idx != this.toolbarSharing.indexEditing);
  }

  public initializeForm() {
    this.userRoleForm = this.fb.group({
      userId: ['', [Validators.required]],
      assignedRole: ['', Validators.required]
    });
  }

  public handleAdd(event) {
    let { action, originDataUom } = event;
    let payload = this.buildPayload(originDataUom);
    let req = this._setting.updateUserRoleAsync(payload);

    this._utilities.promiseAll([req], ["User Role"]).then(success => {
      this.toolbarEvent$.next({ action: action, status: success, data: originDataUom });
    });
  }

  public handleReset() {
  }

  public handleEdit(event) {
    this.toolbarEvent$.next({ action: ACTION_TYPE.SHOW_CATALOG, data: false});
  }

  private buildPayload(userRole) {
    return {
      userId: userRole.userId,
      assignedRole: userRole.assignedRole,
      modifiedBy: this._utilities.currentUser.userId
    }
  }

}
