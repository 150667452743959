import { Component, OnInit } from '@angular/core';
import { PAGES } from 'src/app/shared/enums/enums';
import {MsalService} from "@azure/msal-angular";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  public load: boolean = false;

  constructor(private authService: MsalService) { }

  ngOnInit() {
    let loggedIn = this.authService.getAccount() != null;
    if (loggedIn) {
      window.location.href = PAGES.JobsDashboard;
    }
    else {
      this.load = true;
    }
  }

  onLogin(event) {
    window.location.href = PAGES.JobsDashboard; //Fixes can't navigate with router
  }
}
