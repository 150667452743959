import { Validators } from '@angular/forms';
import { ToolbarColumn } from './../../shared/components/app-table-toolbar/table-toolbar.component';
import { CatalogSetup } from './../catalog-setup/catalog-setup.component';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import * as XLSX from 'xlsx';
import { CONFIRMATION_MESSAGES, CONFIRMATION_TYPES, FIELD_TYPE, MODAL_IDENTIFIERS } from './../../shared/enums/enums';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';

@Component({
  selector: 'app-catalog-import',
  templateUrl: './catalog-import.component.html',
  styleUrls: ['./catalog-import.component.scss']
})

export class CatalogImportComponent implements OnInit {
  public dragover = false;
  public errors: string[] = [];
  public warnings: string[] = [];
  public file: File;
  public fileResults;
  public maxSize = 10485760;
  public maxRecords = 500;
  public warningRecords = 150;
  public sizeInMb;
  public allowedExtensions = ['csv', 'xlsx', 'xls'];
  public acceptString: string;
  public arrayBuffer: any;
  public columns: Array<ToolbarColumn>;
  public unitColSettings: FieldLevelUoMSettings[];
  public importCatalogSetup: any[] = [];
  public FIELD_TYPE = FIELD_TYPE;

  @Input() previewMode = false;
  @Input() setting: CatalogSetup;  
  @Input() form: FormGroup;
  @Output() onApprove = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @Output() onClear = new EventEmitter();

  constructor(
    public formBuilder: FormBuilder,
    public _ngxSmartModal: NgxSmartModalService,
    public _utilities: UtilitiesService,
  ) { }

  get validationDataFn(){ return this.importCatalogSetup; }
  get previewForms() { return <FormArray>(this.form.controls.previewForms)}

  ngOnInit() {
    this.initialize();  
  }

  private initialize() {
    if(this.setting) {
      this.columns = this.setting.columns.filter(x => !x.hidden);
      this.unitColSettings = this.setting.unitColSettings;
    }

    this.sizeInMb = (this.maxSize / (1024 * 1024)).toFixed(0);
    this.acceptString = '';
    this.allowedExtensions.forEach(item => {
      this.acceptString += `.${item},`;
    });
  }

  handleFileInput(event) {
    this.file = event[0];
    this.validateImport(this.file);
  }

  public isShowUomLabel(col) {
    if (!this.unitColSettings || !col.key) return false;
    if(col.uom == false) return false;
    const unitColumn = this.getUnitColumn(col.key);
    return (unitColumn && unitColumn.currentUnit) || false;
  }

  private getUnitColumn(key: string) {
    if(this.unitColSettings) {
      return this.unitColSettings.filter(x=>x.colName.toLowerCase() == key.toLowerCase())[0];
    }

    return null;
  }

  private validateImport(file) {
    let fileExtension = file.name.split('.').pop();
    if (!this.allowedExtensions.includes(fileExtension)) {
      this.errors.push(`The file type is invalid. Please upload one of the following types: ${this.allowedExtensions.join()}`);
    }

    if (file.size > this.maxSize) {
      this.errors.push(`The file is too big. Maximum size of the file cannot exceed ${this.sizeInMb}Mb`);
    }

    if(this.errors.length == 0) {
      this.readDataFromFile(file).then(res => {
        this.fileResults = res;
        if(this.fileResults >= this.maxRecords) {
          this.errors.push(`You are about to import more than ${this.maxRecords} entries. This may take a long time.`);
        }
        if(this.fileResults.length > this.warningRecords && this.fileResults.length <= this.maxRecords) {
          this.warnings.push(`You are about to import more than ${this.warningRecords} entries. This may take a long time.`);
        }
      });
    }
  }

  private async readDataFromFile(file) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        let data = new Uint8Array(this.arrayBuffer);
        let array = new Array();
  
        for (let i = 0; i != data.length; i++) {
          array[i] = String.fromCharCode(data[i]);
        }
  
        let bstr = array.join("");
        let workbook = XLSX.read(bstr, { type: 'binary' });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        resolve(XLSX.utils.sheet_to_json(worksheet, { header: 1 }));
      };
      fileReader.onerror = reject;
    });
  }

  exportTemplateFile() {
    const workSheetConfig = {
        name: this.setting.name + 'Template',
        data: this.createTemplateData(this.columns)
    }
    let workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.aoa_to_sheet(workSheetConfig.data);
    XLSX.utils.book_append_sheet(workBook, workSheet, workSheetConfig.name);
    const fileNameExport = workSheetConfig.name.replace(/\s+/g, '') + '.csv';
    XLSX.writeFile(workBook, fileNameExport);
  }

  createTemplateData(columns: ToolbarColumn[]) {
    let header = [];
    let row1 = [];
    let row2 = [];
    columns.forEach(({key, fieldType}) => {
      header.push(key);
      row1.push(this.createSampleData(fieldType));
      row2.push(this.createSampleData(fieldType));
    });
    return [header, row1, row2]
  }

  createSampleData(type) {
    if(type === FIELD_TYPE.TEXT) {
      return "Text_sample";
    } else if (type == FIELD_TYPE.NUMBER) {
      return 1;
    }
  }

  public approve() {
    this.onApprove.emit();
  }

  public clearImport(){
    this.importCatalogSetup = [];
    this.previewForms.clear();
    this.onClear.emit();
  }

  closeModal() {
    this.clear();
    this._ngxSmartModal.getModal('catalogImportModal').close();
  }

  submit() {
    this._utilities.showSpinner(true);
    setTimeout(() => {
      let data = this.mapData(this.fileResults);
      console.log("AAA", data);
      this.closeModal();
      this.onSubmit.emit({data: data});
      this._utilities.showSpinner(false);
    }, 0);
  }

  mapData(data) {
    const header = data[0];
    let res = [];

    for(let row of data) {
      let obj = {};
      for(let j = 0; j < header.length; j++) {
        obj[header[j]] = row[j]; 
      }
      if(row != header) {
        res.push(obj);
      }
    }

    return res;
  }

  clear() {
    this.errors = [];
    this.file = null;
    this.dragover = false;
    this.arrayBuffer = null;
    this.fileResults = null;
    this.warnings = [];
  }

  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = false;
  }

  @HostListener('drop', ['$event']) public ondrop(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.file) {
      let files = event.dataTransfer.files;
      if (files.length == 1) {
        this.file = files[0];
        this.validateImport(this.file);
      } else if (files.length > 1) {
        this.errors.push('You can upload only one file at a time');
      }
    }
  }
}

export class ImportSettings {
  templateUrl: string;
  importType: string;
  importSubType: string;
  allowedExtensions: string[];
}