import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Config } from 'src/app/core/services/config';
import { environment } from '../../../../environments/environment';
import { DataLogicService } from '../data-logic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WellsService {

  constructor(
    public api: ApiService,
    public dataLogic: DataLogicService
  ) { }

  getWellsByJobId(jobId) {
    return this.api.get<any>(Config.APIUrlCore + environment.wells.getByJobId.replace("{jobId}", jobId));
  }
   getWellById(wellId){
    return this.api.get<any>(Config.APIUrlCore+environment.wells.getByWellId.replace("{wellId}", wellId));
  }
  getByJobId(jobId) {
    let url = Config.APIUrlCore + environment.wells.getByJobId.replace("{jobId}", jobId);
    return new Observable<any>(ob => {
      this.api.get<any[]>(url).subscribe(
        (res) => {
          ob.next(res);
          ob.complete();
        },
        (error) => {
          ob.next(null);
          ob.complete();
        }
      );
    });
  }

  createOrUpdateWell(payload) {
    return this.api.post<any>(Config.APIUrlCore + environment.wells.post, payload);
  }
  async createOrUpdateWellAsync(payload) {
    return await this.dataLogic.postAsync(Config.APIUrlCore + environment.wells.post, payload);
  }
}
