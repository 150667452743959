<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="h1 page-name">{{'errors.errorPageTitle'|translate}}</div>
    </div>
  </div>

  <div class="col-12">
      <div class="xcomingSoon">
          {{pageTitle}}
      </div>
      <p></p>
      <div class="comingSoonMessage" style='white-space: pre-wrap'>
        <span>{{pageMessage}}</span>
      </div>
  </div>
</div>
