import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { chartDataOptions, chartOptionViewModel } from './chart-options-base.helper';
import * as OB from 'src/app/utils/objecter';

@Component({
  selector: 'app-chart-options-base',
  templateUrl: './chart-options-base.component.html',
  styleUrls: ['./chart-options-base.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class ChartOptionsBaseComponent implements OnInit {
  public pageLoaded: boolean;
  public catalogs: any = chartDataOptions;
  public legendStyle: any = {};
  public modalName: string = "chartOptionsBaseModal";
  public chartConfig: chartOptionViewModel;

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() {
  }

  public getChartData() {
    this.pageLoaded = false;
    setTimeout(() => {
      this.chartConfig = new chartOptionViewModel();
      let modal = this.ngxSmartModalService.getModalData(this.modalName);
      if (modal && modal.curOptions) {
        this.mapToView(modal.curOptions);
      }
      this.pageLoaded = true;
    }, 0);
  }

  private mapToView(data) {
    if (data.title) {
      this.chartConfig.text_Title = data.title?.text || '';
      this.chartConfig.isShow_Title = data.title?.show || false;
      this.chartConfig.fontSize_Title = data.title?.textStyle?.fontSize || '';
      this.chartConfig.color_Title = data.title?.textStyle.color || '';
    }

    if (data.legend) {
      this.chartConfig.isShow_Legend = data.legend?.show || false;
      this.chartConfig.fontSize_Legend = data.legend?.textStyle?.fontSize || '';
      this.chartConfig.color_Lengend = data.legend?.textStyle?.color || '';
      this.chartConfig.position_Lengend = data.legend?.x || 'default';
    }

    this.chartConfig.xAxis = this.axisToArray(data, 'xAxis');
    this.chartConfig.yAxis = this.axisToArray(data, 'yAxis');

    this.chartConfig.fontSize_Axis = this.chartConfig.xAxis[0]?.nameTextStyle?.fontSize;
    this.chartConfig.color_Axis = this.chartConfig.xAxis[0]?.nameTextStyle?.color;

    this.chartConfig.colorType = this.checkColorType(data);
    this.mapColorToView(data, this.chartConfig.colorType);
  }

  private checkColorType(data: any) {
    if (data?.grid?.backgroundColor?.globalCoord || data?.backgroundColor?.globalCoord) {
      return 'gradient';
    }
    if (data?.grid?.backgroundColor || data?.backgroundColor) {
      return 'solid';
    }
    return '';
  }

  private mapColorToView(data: any, type: string) {
    if (type === 'gradient') {
      if (data?.backgroundColor) {
        this.chartConfig.backgroundColor1 = data.backgroundColor?.colorStops.length > 0 && data.backgroundColor?.colorStops[0]?.color || '';
        this.chartConfig.backgroundColor2 = data.backgroundColor?.colorStops.length > 0 && data.backgroundColor?.colorStops[1]?.color || '';
        this.chartConfig.backgroundColorAngle = data.backgroundColor?.startAngle || 0;
      }

      if (data?.grid) {
        this.chartConfig.chartColor1 = data.grid?.backgroundColor && data.grid?.backgroundColor?.colorStops.length > 0 && data.grid?.backgroundColor?.colorStops[0]?.color || '';
        this.chartConfig.chartColor2 = data.grid?.backgroundColor && data.grid?.backgroundColor?.colorStops.length > 0 && data.grid?.backgroundColor?.colorStops[1]?.color || '';
        this.chartConfig.colorAngle = data.grid?.backgroundColor?.startAngle || 0;
      }
    }
    if (type === 'solid') {
      this.chartConfig.backgroundColor1 = data?.backgroundColor || '';
      this.chartConfig.chartColor1 = data?.grid?.backgroundColor || '';
    }

  }

  private axisToArray(data, axisName) {
    if (Array.isArray(data[axisName])) {
      return data[axisName];
    }

    let axisTemp: Array<any> = [];
    axisTemp.push({ ...data[axisName] });
    return axisTemp;
  }

  private handleBackgroundColor(chartData: any) {
    OB.updateObj(chartData, 'backgroundColor', "");
    OB.updateObj(chartData, 'grid.show', false);
    OB.updateObj(chartData, 'grid.backgroundColor', "");

    if (this.chartConfig.colorType == "gradient") {
      if (this.chartConfig.backgroundColor1 && this.chartConfig.backgroundColor2) {

        let bgGradientProperties = this.angleToPoints(this.chartConfig.backgroundColorAngle);
        chartData.backgroundColor = this.buildGradient(bgGradientProperties, this.chartConfig.backgroundColor1, this.chartConfig.backgroundColor2, this.chartConfig.backgroundColorAngle);
      }

      if (this.chartConfig.chartColor1 && this.chartConfig.chartColor2) {

        let chartGradientProperties = this.angleToPoints(this.chartConfig.colorAngle);
        chartData.grid.show = true;
        chartData.grid.backgroundColor = this.buildGradient(chartGradientProperties, this.chartConfig.chartColor1, this.chartConfig.chartColor2, this.chartConfig.colorAngle);
      }
    }

    if (this.chartConfig.colorType == "solid") {

      if (this.chartConfig.backgroundColor1) {
        chartData.backgroundColor = this.chartConfig.backgroundColor1;
      }

      if (this.chartConfig.chartColor1) {
        chartData.grid.show = true;
        chartData.grid.backgroundColor = this.chartConfig.chartColor1;
      }
    }

    chartData.isAll = false;
  }

  private buildGradient(properties: any, color1: string, color2: string, startAngle: number) {
    return {
      startAngle: startAngle,
      globalCoord: true,
      x: properties.x,
      y: properties.y,
      x2: properties.x2,
      y2: properties.y2,
      colorStops: [
        {
          color: color1,
          offset: 0
        },
        {
          color: color2,
          offset: 1
        }
      ]
    }
  }

  private angleToPoints(angle) {
    if (OB.isNull(angle)) angle = 0;
    let radians = angle / 180 * Math.PI;
    var segment = Math.floor(radians / Math.PI * 2) + 2;
    var diagonal = (1 / 2 * segment + 1 / 4) * Math.PI;
    var op = Math.cos(Math.abs(diagonal - radians)) * Math.sqrt(2);
    var a = op * Math.cos(radians);
    var b = op * Math.sin(radians);

    return {
      x: a < 0 ? 1 : 0,
      y: b < 0 ? 1 : 0,
      x2: a >= 0 ? a : a + 1,
      y2: b >= 0 ? b : b + 1
    };
  }

  public swapBgColor() {
    const temp = this.chartConfig.backgroundColor1;
    this.chartConfig.backgroundColor1 = this.chartConfig.backgroundColor2;
    this.chartConfig.backgroundColor2 = temp;
  }

  public resetBgColor() {
    this.chartConfig.backgroundColor1 = '';
    this.chartConfig.backgroundColor2 = '';
    this.chartConfig.backgroundColorAngle = 0;
  }

  public swapChartColor() {
    const temp = this.chartConfig.chartColor1;
    this.chartConfig.chartColor1 = this.chartConfig.chartColor2;
    this.chartConfig.chartColor2 = temp;
  }

  public resetChartColor() {
    this.chartConfig.chartColor1 = '';
    this.chartConfig.chartColor2 = '';
    this.chartConfig.colorAngle = 0;
  }

  public submitModal() {
    let modal = this.ngxSmartModalService.getModalData(this.modalName);
    if (modal && modal.curOptions) {
      let target = this.mapToUpdateDb(modal.initOptions);
      modal.event$.next(target);
      this.closeModal();
    }
  }

  private mapToUpdateDb(options) {
    let dataUpdate = JSON.parse(JSON.stringify(options));
    OB.updateObj(dataUpdate, 'title.show', this.chartConfig.isShow_Title);
    OB.updateObj(dataUpdate, 'title.text', this.chartConfig.text_Title);
    OB.updateObjIfNotNull(dataUpdate, 'title.textStyle.fontSize', this.chartConfig.fontSize_Title);
    OB.updateObjIfNotNull(dataUpdate, 'title.textStyle.color', this.chartConfig.color_Title);
    OB.updateObj(dataUpdate, 'legend.show', this.chartConfig.isShow_Legend);
    OB.updateObjIfNotNull(dataUpdate, 'legend.textStyle.fontSize', this.chartConfig.fontSize_Legend);
    OB.updateObjIfNotNull(dataUpdate, 'legend.textStyle.color', this.chartConfig.color_Lengend);
    OB.updateObj(dataUpdate, 'legend.x', (this.chartConfig.position_Lengend && this.chartConfig.position_Lengend !== 'default') ? this.chartConfig.position_Lengend : options?.legend?.x);

    let xAxis = this.updateDataAxis(options, this.chartConfig, 'xAxis');
    let yAxis = this.updateDataAxis(options, this.chartConfig, 'yAxis');
    OB.updateObj(dataUpdate, 'xAxis', xAxis);
    OB.updateObj(dataUpdate, 'yAxis', yAxis);

    if (this.chartConfig.colorType != '') this.handleBackgroundColor(dataUpdate);
    return dataUpdate;
  }

  private updateDataAxis(dataOriginal: any, dataUpdate: any, axisName: string) {
    let AxisData = JSON.parse(JSON.stringify(dataUpdate[axisName]));
    let fontSize = OB.isNull(dataUpdate.fontSize_Axis) ? dataOriginal[axisName].nameTextStyle?.fontSize : dataUpdate.fontSize_Axis;
    let fontColor = OB.isNull(dataUpdate.color_Axis) ? dataOriginal[axisName].nameTextStyle?.color : dataUpdate.color_Axis;

    for (let item of AxisData) {
      OB.updateObj(item, 'nameTextStyle.fontSize', fontSize);
      OB.updateObj(item, 'nameTextStyle.color', fontColor);
      this.clearOption(item);
    }

    return !Array.isArray(dataOriginal.xAxis) ? AxisData[0] : AxisData;
  }

  private clearOption(item) {
    if ((Number(item.max) === 0 && Number(item.min) === 0) || Number(item.max) < Number(item.min)) item.max = "";
    if (item.min === '' || !item.min) delete item.min;
    if (item.max === '' || !item.max) delete item.max;
    if (OB.isNull(item.nameTextStyle.fontSize)) delete item.nameTextStyle.fontSize;
    if (OB.isNull(item.nameTextStyle.color)) delete item.nameTextStyle.color;
  }

  public closeModal() {
    this.ngxSmartModalService.resetModalData(this.modalName);
    this.ngxSmartModalService.closeLatestModal();
  }
}
