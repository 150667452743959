import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uom',
  pure: true
})
export class UomPipe implements PipeTransform {

  transform(colSettings: any, colName?: string): string {
    let unit: string = '';
    if(colSettings){
      colSettings.some(col => {
        if (col.colName.toLowerCase() == colName.toLowerCase()){ unit = col.currentUnit };
      });
    }
    return unit;
  }

}
