import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate } from '@angular/router';

export interface CanPageWithErrorsLeave {
  canLeave: () => boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PageHasErrorsGuard implements CanDeactivate<CanPageWithErrorsLeave> {
  canDeactivate(component: CanPageWithErrorsLeave) {
    if (component.canLeave) {
      return component.canLeave();
    }
    return true;
  }
}

