<div class="dropdown position-relative d-flex h-100" #usersDropdownRef>
  <button class="dropbtn mr-4 pr-2 py-0 pl-4 border-0" (click)="usersDropdown()" [ngClass]="userDrpdwn ? 'bg-color' : ''">
    <div class="profile-img float-left">
      <img src= '{{ _utilities.profileImage$ }}'>
      <span class="h4">{{_utilities.logoInitials$ | async}}</span>
    </div>
    <div class="name">
      <span class="body-text menu-bar-override">{{_utilities.fullName$ | async}}</span>
      <div class="user-dropdown" [ngClass]="userDrpdwn ? 'menu-chevron-up-icon' : 'menu-chevron-down-icon'"></div>
    </div>
  </button>
  <div class="dropdown-content w-100 d-block position-absolute t-0" *ngIf="userDrpdwn">
    <a *ngIf="_utilities.showDashboard$" (click)="navigateSettings()" class="p-3 d-block align-middle">
      <span class="setting-icons"></span>
      <label class="body-text menu-bar-override">{{'settings.settings'|translate}}</label>
    </a>
    <a *ngIf="!isLocal" (click)="logOut()" class="p-3 d-block align-middle">
      <span class="logout-icons"></span>
      <label class="body-text menu-bar-override">{{'Logout'|translate}}</label>
    </a>
  </div>
</div>
