import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Config } from 'src/app/core/services/config'
import { environment } from 'src/environments/environment';
import { AppLoadService } from 'src/app/app-load.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';
import * as LS from 'src/app/utils/localstorage';
import { PAGES } from 'src/app/shared/enums/enums';
import { initProgress, myconsole } from 'src/app/utils/utils';
import { ErrorMessageService } from './error-message.service';
import { LocalTokenService } from './localtoken.service';

@Injectable()
export class AppConfigService {
  public clientId;

  constructor(
    public _error: ErrorMessageService,
    public loadService: AppLoadService,
    public http: HttpClient,
    public _router: Router,
    public _localauth: LocalTokenService,
    public _auth: MsalService,
    protected injector: Injector
  ) { 
    
  }
//private _auth:MsalService;
//private _localauth:LocalTokenService;

  loadAppSettings(section: string) {
    myconsole("$timerStart", "loadAppSettings");
    if (window.location.pathname === "/error") {
      this._router.navigate([PAGES.JobsDashboard]);
    }

    return new Promise<void>((resolve, reject) => {
      this.http.get('/assets/appsettings/appSettings.json')
        .toPromise()
        .then(data => {
          if (window.location.pathname.toLowerCase() === environment.redirectLogoutUriFolder) {
            resolve();
            return;
          }
          let key = environment;
          if (environment.local) {
            //let _localauth = this.injector.get(LocalTokenService);
            myconsole("$timerStart", "getTokenGenerator_loadAppSettings");
            initProgress("Obtaining local token...");

            this._localauth.getTokenGenerator(false)
              .then((token) => {
                this.loadService.loadApp();
              })
              .catch((error) => {
                return false;
              })
              .finally(() => {
                myconsole("$timerEnd", "getTokenGenerator_loadAppSettings");
                myconsole("$timerEnd", "loadAppSettings");
                resolve();
              });
          } else {
            //let _auth = this.injector.get(MsalService);
            myconsole("$timerStart", "acquireTokenSilent_loadAppSettings");
            initProgress("Authenticating web token...");
            let appInsights = new ApplicationInsights({
              config: {
                instrumentationKey: Config.AppInsightKey
              }
            });
            appInsights.loadAppInsights();

            this._auth.acquireTokenSilent({ scopes: environment.consentScopes })
              .then((response: AuthResponse) => {
                if (response.idToken) {
                  let token = response.idToken.rawIdToken;
                  LS.setLocalStorageString(LS.LOCAL_STORAGE_KEY.Token.AD, token);
                }
                this.loadService.loadApp();
              })
              .catch(error => {
                myconsole('acquireTokenSilent error', error);
                if (window.location.pathname.toLowerCase() === environment.redirectLogoutUriFolder) {
                  myconsole("redirectLogoutUriFolder", environment.redirectLogoutUriFolder);
                }
                else {
                  initProgress(error.message);
                  if (error.message.toLowerCase().includes("renewal") || error.message.toLowerCase().includes("token") || error.message.toLowerCase().includes("login")) {
                    LS.clearMsalTokenStorage();
                    //this._auth.acquireTokenRedirect(params)
                    if(window.location.pathname.toLowerCase() != environment.redirectUriFolder.toLowerCase()) {
                      LS.setSessionStorage(LS.LOCAL_STORAGE_KEY.RedirectUrl, window.location.pathname);
                    }
                    this._auth.loginRedirect();
                  }
                }
              })
              .finally(() => {
                myconsole("$timerEnd", "acquireTokenSilent_loadAppSettings");
                myconsole("$timerEnd", "loadAppSettings");
                resolve();
              });
          }
        });
    });
  }

}