import { SORT_TYPE } from './../../enums/enums';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import * as OB from 'src/app/utils/objecter';

export interface FilterOptions {
  key: string;
  header: string;
  filters: Array<FilterSettings>;
}

export interface FilterSettings {
  key: string;
  sort: SORT_TYPE;
  fvalues: Array<string>;
}

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class FilterPopupComponent implements OnChanges{
  @Input() public tableView: any = [];
  @Input() public options: FilterOptions;
  @Input() public maxFilterDisplayLength: number = 20;
  @Output() public onCheckField = new EventEmitter();
  @Output() public onSort = new EventEmitter();
  @Output() public onClose = new EventEmitter();
  public unique: any = [];
  public searchText: string;
  public popupPosition: any;
  public divWidth = 0;
  public sortToggle: boolean = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePosition();
    this.updateFilter();
  }

  public close() {
    this.onClose.emit();
  }

  public clearAll() {
    let filter = this.options.filters.filter(x=> x.key == this.options.key)[0];
    if(!OB.isNull(filter)) {
      filter.fvalues = [];
    }
    this.onCheckField.emit(this.options.filters);
  }

  public isChecked(item: string) {
    const filter = this.options.filters.filter(x=> x.key == this.options.key)[0];
    if(OB.isNull(filter)) {
      return false;
    }

    return filter.fvalues.includes(item);
  }

  public onChange(item: string, isChecked: boolean) {
    let filter = this.options.filters.filter(x=> x.key == this.options.key)[0];
    if(OB.isNull(filter)) {
      filter = {
        key: this.options.key,
        sort: SORT_TYPE.ASC,
        fvalues: []
      };
      this.options.filters.push(filter);
    }

    if(isChecked) {
      filter.fvalues.push(item);
    } else {
      let idx = filter.fvalues.indexOf(item);
      if(idx > -1) {
        filter.fvalues.splice(idx, 1);
      }
    }
    console.log("AN", this.options);
    this.onCheckField.emit(this.options.filters)
  }

  public sort(checked) {
    let filter = this.options.filters.filter(x=> x.key == this.options.key)[0];
    if(OB.isNull(filter)) {
      filter = {
        key: this.options.key,
        sort: SORT_TYPE.ASC,
        fvalues: []
      };
      this.options.filters.push(filter);
    }

    filter.sort = checked ? SORT_TYPE.ASC: SORT_TYPE.DESC;
    this.onSort.emit(this.options.filters);
  }

  public limitDisplay(item) {
    try {
      if (typeof item === 'string' && (item.length > this.maxFilterDisplayLength)) {
        item = item.substring(0, this.maxFilterDisplayLength - 3) + '...';
      }
    } catch (error) {
      return item;
    }
    return item;
  }

  public isNull(item) {
    return OB.isNull(item);
  }

  private updatePosition() {
    let newModalLeft = 0;
    const modalWidth = 230;
    const element = document.getElementById('header-' + this.options.key);
    if (this.divWidth === 0) {
      const head = element.parentElement.parentElement.parentElement.parentElement;
      this.divWidth = head.offsetWidth;
    }
    const headerLeft = element.parentElement.offsetLeft;
    let tableWidth = this.divWidth;
    if ((headerLeft + modalWidth) > tableWidth) {
      const pixelOvermax = (headerLeft + modalWidth) - tableWidth;
      newModalLeft = tableWidth - pixelOvermax - modalWidth;
    } else {
      newModalLeft = headerLeft;
    }

    this.popupPosition = newModalLeft + 25 + 'px';
  }

  private updateFilter() {
    let key = this.options.key;
    if(!key) return;
    let values = this.tableView.map(item => {
        return item[key];
      }
    );
    
    this.unique = Array.from(new Set(values));
    this.unique.sort();

    let filter = this.options.filters.filter(x=>x.key == key)[0];
    if(filter) {
      this.sortToggle = filter.sort == SORT_TYPE.ASC ? true: false;
    }
  }
}