import { SORT_TYPE } from "src/app/shared/enums/enums";

export class UnitValue {
  public value: number;
  public uom: string;
  constructor(data = null) {
    this.value = data ? (data.value !== undefined ? data.value : (data.Value !== undefined ? data.Value : null)) : null;
    this.uom = data ? (data.uom || data.Uom || '') : '';
  }
}

export class Item {
  public label: string;
  public value: any;
  public disabled?: boolean;
  public child?: string;
}

export class Range {
  start: number;
  end: number;
}

export interface QueryData {
  collection: string;
  property: string;
  mapper: Item;
  sort: SORT_TYPE;
}

export interface DynamicReadonlyFields {
  colName: string; // it must be mapping with colName of columnSettings (ex: 'id')
  i18nVariable: string; // refer variable in en.json (ex: 'surfaceHydraulics.id')
  uom: string; // the unit of field from api (ex: 'bbl')
  value: number; // the value of field (ex: 1.12)
  fieldType: string; // type of field (ex: FIELD_TYPE.NUMBER)
}

export interface DynamicReadonlyTable {
  colName: string; // it must be mapping with colName of columnSettings (ex: 'id')
  i18nVariable: string; //refer variable in en.json (ex: 'surfaceHydraulics.id')
  mongoVariableName: string; // path of field value (ex: 'innerDiameter.value')
  fieldType: string; // type of field (ex: FIELD_TYPE.NUMBER)
}

export class NetworkStatus {
  Connected: boolean;
  VpnActive: boolean;

  constructor(apiData) {
      this.Connected = false;
      this.VpnActive = false;
  }
}