import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UtilitiesService } from '../services/utilitiesservice.service';

@Injectable()
export class AnalysisCatalogsResolver implements Resolve<void> {
  constructor(
    public _utilities: UtilitiesService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this._utilities.loadAnalysisCatalogs(false);
  }
}