<div class="container m-0">
  <div class="container-fluid card-body">
  <!-- title-->
  <div class="row">
    <div class="col-12">
      <div class="h1 page-name">{{'admin.userRoles'|translate}}</div>
    </div>
  </div>

  <!-- start-->
  <div class="content-body" style="position: relative;">
    <!-- <app-catalog-import [previewMode]="previewMode" [setting]="setting" [form]="importForm" (onSubmit)="submitImport($event)" (onClear)="clearImport()"
      (onApprove)="approveImport()">
    </app-catalog-import>
    <div *ngIf="!previewMode" class="link-primary float-right mr-0" (click)="openImportModal()">{{'admin.importUserList'|translate}}
    </div> -->
    <app-table-toolbar
      [event$] = "toolbarEvent$"
      [columns] ="columns"
      [data]="tableView"
      [sharing]="toolbarSharing"
      [topRowForm] = "userRoleForm"
      [addText] = "'Save'"
      [pagination] ="true"
      [filter] ="true"
      [exportToCsvText]="'Export user list'"
      (onReset) = "handleReset()"
      (onAdd) ="handleAdd($event)"
      (onEdit)="handleEdit($event)">
    </app-table-toolbar>
  </div>
</div>
</div>