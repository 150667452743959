import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { LOCAL_STORAGE_KEY, setLocalStorage, getLocalStorage } from 'src/app/utils/localstorage';
import { DEBOUNCE_TIME_AUTO_SAVE, JobState, JobStatus, LIMITATION } from 'src/app/utils/constants';
import { ValidatorsEx } from 'src/app/core/services/validators-extend.class';
import { PAGES } from '../shared/enums/enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
})
export class CreateJobComponent implements OnInit, OnDestroy {

  public isSubmitted: boolean = false;
  public limitName = LIMITATION.INPUT_NAME;
  public addNewJobForm = new FormGroup({});
  public componentDestroyed$: Subject<boolean> = new Subject();
  public initialJobData = { jobName: "New Job", jobStatus: JobStatus.Inactive, jobTypes: "", jobPurpose: "", jobCustomerType: "", isValid: false };

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public _utilities: UtilitiesService
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.autoSaveForm();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  get jobNameControl() { return this.addNewJobForm && this.addNewJobForm.get('jobName'); }
  get jobTypesControl() { return this.addNewJobForm && this.addNewJobForm.get('jobTypes'); }
  get jobPurposeControl() { return this.addNewJobForm && this.addNewJobForm.get('jobPurpose'); }
  get jobCustomerTypeControl() { return this.addNewJobForm && this.addNewJobForm.get('jobCustomerType'); }
  get defaultUnitProfileControl() { return this.addNewJobForm && this.addNewJobForm.get('defaultUnitProfile'); }

  get isJobNameHasErrors() {
    return this.addNewJobForm && this.addNewJobForm.controls['jobName'] && this.addNewJobForm.controls['jobName'].errors && this.isSubmitted;
  }

  get isJobTypesSelected() {
    return this.jobTypesControl.value && this.jobTypesControl.value.length > 0
  }

  get isJobTypesHasErrors() {
    return !this.isJobTypesSelected && this.isSubmitted;
  }

  get isJobPurposeHasErrors() {
    return this.addNewJobForm && this.addNewJobForm.controls['jobPurpose'] && this.addNewJobForm.controls['jobPurpose'].errors && this.isSubmitted;
  }

  get isJobCustomerTypeHasErrors() {
    return this.addNewJobForm && this.addNewJobForm.controls['jobCustomerType'] && this.addNewJobForm.controls['jobCustomerType'].errors && this.isSubmitted;
  }

  get isDefaultUnitProfileHasErrors() {
    return this.addNewJobForm && this.addNewJobForm.controls['defaultUnitProfile'] && this.addNewJobForm.controls['defaultUnitProfile'].errors && this.isSubmitted;
  }

  private createForm() {
    let cached = getLocalStorage(LOCAL_STORAGE_KEY.Data.Job.CreateNewJob)
    let data = cached ? cached : this.initialJobData

    this.addNewJobForm = this.formBuilder.group({
      jobName: [data.jobName, ValidatorsEx.requiredText()],
      jobStatus: data.jobStatus,
      jobTypes: [data.jobTypes, Validators.required],
      jobPurpose: [data.jobPurpose, Validators.required],
      jobCustomerType: [data.jobCustomerType, Validators.required],
      defaultUnitProfile: [data.defaultUnitProfile, Validators.required]
    });
  }

  nextStepCreate() {
    this.isSubmitted = true;
    if (this.addNewJobForm.valid) {
      this.router.navigate(['jobs/newjob/wellheader']);
    }
  }

  private autoSaveForm() {
    this.addNewJobForm.valueChanges.pipe(debounceTime(DEBOUNCE_TIME_AUTO_SAVE)).pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
      result.isValid = this.addNewJobForm.valid;
      result.jobState = environment.local ? JobState.Execution : JobState.Planning;
      this._utilities.setJobTypeSelected(result.jobTypes);
      setLocalStorage(LOCAL_STORAGE_KEY.Data.Job.CreateNewJob, result);
    });
  }

  public cancelCreateJob() {
    this.router.navigate([PAGES.JobsDashboard]);
  }
}
