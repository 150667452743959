//import { MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { Config } from '../core/services/config';
//import { LocalTokenGuard } from 'src/app/core/guards/localtoken.guard';
//import { LocalTokenInterceptor } from 'src/app/core/services/localtoken.interceptor';
import { myconsole } from './utils';

export class AppConfig {
  public location: string = "";

  constructor(
  ) {
    this.location = window.location.origin;
  }

  static getDeploy(): string {
    let deploy = "";
    let loc = window.location.origin;
    //environment.auth.guard = MsalGuard;
    //environment.auth.intercept = MsalInterceptor;
    if (environment.local) {
      deploy = "local";
      //environment.auth.guard = LocalTokenGuard;
      //environment.auth.intercept = LocalTokenInterceptor;
    }
    else if (loc.includes('baralogix.ienergy')) {
      deploy = "production";
    }
    else if (loc.includes('baralogix-stg.ienergy')) {
      deploy = "stage";
    }
    else if (loc.includes('baralogix-tst.ienergy')) {
      deploy = "test";
    }
    else if (loc.includes('baralogix-dev.ienergy')) {
      deploy = "dev";
    }
    else if (loc.includes('localhost')) {
      deploy = "local_dev";
    }
    myconsole('Final deploy: ', deploy);
    return deploy;
  }

  static setEnvironment(contents, section) {
    myconsole('Version: ', contents.version);
    let deploy = contents.deploy;
    myconsole('Deploy: ', deploy);
    // temp? until pipeline fixed
    deploy = AppConfig.getDeploy();

    let data = contents[deploy];
    myconsole('AppSettings data: ', data);
    myconsole('window.location: ', window.location);
    if (data) {
      AppConfig.setEnvironmentBase(data, deploy);
      (section === "" || section.includes("web")) && AppConfig.setEnvironmentWeb(data);
      (section === "" || section.includes("api")) && AppConfig.setEnvironmentApi(data);
      var env = environment;
    }
  }

  static setEnvironmentBase(data, deploy) {
    environment.deploy = deploy;
    environment.web.production = data.BaseSettings.production;
    environment.web.isCloud = data.BaseSettings.isCloud;
  }

  static setEnvironmentWeb(data) {
    environment.web.app_Insight_Key = data.Web.app_Insight_Key;
    environment.redirectUri = window.location.origin + environment.redirectUriFolder;
    environment.redirectLogoutUri = window.location.origin + environment.redirectLogoutUriFolder;

    Config.AppInsightKey = environment.web.app_Insight_Key;
  }

  static setEnvironmentApi(data) {
    //let services = data.Api.service;
    let services = environment.api.service;
    environment.msal.auth.clientId = data.Auth.clientID;
    environment.msal.auth.clientSecret = data.Auth.clientSecret;
    environment.msal.auth.scope = data.Auth.scope;


    // get the api url (default ienergy) based on origin
    let api_url = data.Api.url_Ienergy;
    myconsole('Default api_url: ', api_url);
    myconsole('Default api_host: ', data.Api.host);
    data.Api.host = "ienergy";
    data.Api.url_Ienergy;
    myconsole('Final api_url: ', api_url);

    // handle the one irregularity in service name for catalogs (is plural in all but these)
    if (api_url.includes('ienergy')) {
      services.catalog = "mscatalog";
    }

    // finalize api service urls
    services.catalog = api_url.replace('{{service}}', services.catalog);
    services.core = api_url.replace('{{service}}', services.core);
    services.downhole = api_url.replace('{{service}}', services.downhole);
    services.hydraulics = api_url.replace('{{service}}', services.hydraulics);
    services.packaging = api_url.replace('{{service}}', services.packaging);
    services.platform = api_url.replace('{{service}}', services.platform);
    services.rheology = api_url.replace('{{service}}', services.rheology);
    services.survey = api_url.replace('{{service}}', services.survey);
    services.volumes = api_url.replace('{{service}}', services.volumes);

    // this is the important stuff actually used by ApiService
    environment.api.API_URL_Cloud = "";
    environment.api.API_PORTS_Cloud = {
      catalogs: services.catalog,
      core: services.core,
      downhole: services.downhole,
      hydraulics: services.hydraulics,
      packaging: services.packaging,
      platform: services.platform,
      rheology: services.rheology,
      survey: services.survey,
      volumes: services.volumes
    };

    let protectedResourceMapKey = environment.msal.auth.scope;
    environment.api.protectedResourceMap = [
      [services.catalog, [protectedResourceMapKey]],
      [services.core, [protectedResourceMapKey]],
      [services.volumes, [protectedResourceMapKey]],
      ['/assets/', null],
      ['/assets/*', null],
      ['/assets/appsettings/appSettings.json', null],
      ['https://graph.microsoft.com/v1.0/me', ['User.Read', 'User.ReadBasic.All']],
      [services.downhole, [protectedResourceMapKey]],
      [services.hydraulics, [protectedResourceMapKey]],
      [services.packaging, [protectedResourceMapKey]],
      [services.platform, [protectedResourceMapKey]],
      [services.rheology, [protectedResourceMapKey]],
      [services.survey, [protectedResourceMapKey]],
    ];

    environment.api.Ocp_Apim_Subscription_Key = data.Api.Ocp_Apim_Subscription_Key;

    let root = environment.web.isCloud ? environment.api.API_URL_Cloud : environment.api.API_URL_Local;
    let ports = environment.web.isCloud ? environment.api.API_PORTS_Cloud : environment.api.API_PORTS_Local;
    Config.APICloud = environment.web.isCloud;
    Config.APIUrlCatalogs = root + ports.catalogs;
    Config.APIUrlCore = root + ports.core;
    Config.APIUrlDownhole = root + ports.downhole;
    Config.APIUrlHydraulics = root + ports.hydraulics;
    Config.APIUrlPackaging = root + ports.packaging;
    Config.APIUrlPlatform = root + ports.platform;
    Config.APIUrlRheology = root + ports.rheology;
    Config.APIUrlSurvey = root + ports.survey;
    Config.APIUrlVolumes = root + ports.volumes;

    console.log('Api root: ', root);
    console.log('Api ports: ', ports);
  }

}