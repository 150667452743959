import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AdditionalFluidPropertiesCatalogService } from '../services/catalogs/additional-fluid-properties-catalog.service';

@Injectable()
export class IntervalCatalogsResolver implements Resolve<void> {
  constructor(
    public _additional: AdditionalFluidPropertiesCatalogService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._additional.getAdditionalFluidPropertiesCatalog().toPromise()
      .then(res => { return res })
      .catch(error => {
        return [];
      });
  }
}