import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'src/app/utils/utils';

@Pipe({
  name: 'cellValue',
  pure: false
})
export class CellValuePipe implements PipeTransform {

  transform(row: any, header?: any): any {
    var key = header?.key;
    if (isNull(key) || isNull(row[key])) return "";
    var value = (Array.isArray(row[key])) ? row[key].join(', ') : (row[key].value === undefined) ? row[key] : row[key].value;
    if (typeof value === 'string' && (header.maxColumnDisplayLength > 0) && (value.length > header.maxColumnDisplayLength)) {
      value = value.substring(0, header.maxColumnDisplayLength - 3) + '...';
    }
    return value;
  }

}
