import { Country } from "src/app/shared/models/country";
import { ColumnSettings } from "./columnSettings.model";

// export class Catalog {

//     roles: string[];
//     analysesReasons: string[];
//     baseFluidTypes: string[];
//     fluidBrines: string[];
//     interventionKeyIndicators: string[];
//     interventionTitles: string[];
//     interventionCommLevel: string[];
//     jobStatuses: string[];
//     jobTypes: string[];
//     jobPurposes: string[];
//     operators: string[];
//     rehologyPredictions: string[];
//     wellboreWallConditions: string[];
//     wellLocations: string[];
//     wellStatuses: string[];
//     wellTypes: string[];
//     casings: [];
//     componentTypes: [];
//     countries: [Country];
//     equipmentTypes: {
//         columnSettings: [ColumnSettings];
//         dataRows: [{
//             name: string;
//             standPipe: {
//                 id: number,
//                 length: number;
//             },
//             drillingHouse: {
//                 id: number,
//                 length: number,
//             },
//             swivel: {
//                 id: number,
//                 length: number,
//             },
//             kelly: {
//                 id: number,
//                 length: number,
//             }
//         }]
//     };
//     lithologies: [];
//     pumps: {
//         columnSettings: [ColumnSettings];
//         dataRows: [{
//             idx: string,
//             description: string,
//             manufacturer: string,
//             type: string,
//             strokeLength: string,
//             rodSize: string,
//             maxStrokeRate: string,
//             maxLinerSize: string
//         }];
//     };
//     risers: [];
//     timeZones: [{
//         value: number,
//         text: string
//     }]
// }

export class CatalogSyncVersion {
    updatesAvailable: boolean;
    localVersion: string;
    latestVersion: string;

    constructor(apiData) {
        this.updatesAvailable = apiData?.updatesAvailable || false;
        this.localVersion = apiData?.localCatalogVersion || "";
        this.latestVersion = apiData?.latestCatalogVersion || "";
    }
}