import { ApiService } from './core/services/api.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/core/services/config'
import { environment } from 'src/environments/environment';
import { Event, NavigationCancel, NavigationEnd, NavigationStart, Router, ActivatedRouteSnapshot, ResolveEnd, NavigationError } from '@angular/router';
import { Logger, CryptoUtils, InteractionRequiredAuthError, AuthResponse } from 'msal';
import { SettingsService } from './core/services/settings.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { UtilitiesService } from './core/services/utilitiesservice.service';
import * as LS from 'src/app/utils/localstorage';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LANGUAGE_CODES, PAGES } from './shared/enums/enums';
import { AppLoadService } from './app-load.service';
import { myconsole } from './utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public componentDestroyed$: Subject<boolean> = new Subject();
  public adasThemeClass: string;
  public showWait: boolean = false;
  public routerSubscription: Subscription;
  public subscriptions: Subscription[] = [];
  public configLoaded = false;
  public isIframe = false;
  public loggedIn = false;

  constructor(
    public _api: ApiService,
    public _router: Router,
    public _settings: SettingsService,
    public _translate: TranslateService,
    public _utilities: UtilitiesService,
    public authService: MsalService,
    public broadcastService: BroadcastService,
    public http: HttpClient,
    public loadService: AppLoadService,
    //public appConfig: AppConfigService
  ) {
    // initial translate
    this._translate.addLangs([LANGUAGE_CODES.ENGLISH, LANGUAGE_CODES.FRENCH, LANGUAGE_CODES.SPANISH]);
    this._translate.setDefaultLang(LANGUAGE_CODES.ENGLISH);

    if (!environment.local) {
      this.appInsights.loadAppInsights();
    }

    this._router.events.subscribe((event: Event) => {
      this.loadingBarInterceptor(event);
    });

    this.routerSubscription = this._router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
      const activatedComponent = this.getActivatedComponent(event.state.root);
      if (activatedComponent) {
        this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
      }
    });
  }

  getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  async hotkeys(event) {
    let control = event.ctrlKey;
    let shift = event.shiftKey;
    if (control && shift) {
      let code = event.code;
      // toggle dark and light themes
      // if (code === "KeyZ") {
      //   let theme = this._settings.getTheTheme().value;
      //   let light = environment.lightTheme;
      //   let dark = environment.darkTheme;
      //   this._settings.setTheTheme(theme === dark ? light : dark);
      // }
      // if (code === "KeyQ") {
      //   LS.clearMsalTokenStorage();
      //   this._router.navigate(['/style-guide']);
      //   //this._utilities.activeCloud$.next(!this._utilities.activeCloud$.getValue());
      // }
      // if (code === "KeyX") {
      //   this._api.testOffline = !this._api.testOffline;
      //   if (this._api.testOffline) this._api.testOffVpn = true;
      //   //this._system.checkConnection().toPromise();
      // }
      // if (code === "KeyZ") {
      //   this._api.testOffVpn = !this._api.testOffVpn;
      //   //this._system.checkConnection().toPromise();
      // }
    }
  }

  loadingBarInterceptor(event: Event) {
    if (event instanceof NavigationStart) {
      myconsole("NavigationStart: ", event);
      // if (event.url.toLowerCase().includes('resolveroutes')) {
      //   this._router.navigate([PAGES.JobsDashboard]);
      // }
      this._utilities.showSpinner(true);
    }
    else if (event instanceof NavigationEnd) {
      myconsole("NavigationEnd: ", event);
      this._utilities.showSpinner(false);
    }
    else if (event instanceof NavigationCancel) {
      this._utilities.showSpinner(false);
    }
    else if (event instanceof NavigationError) {
      this._utilities.showSpinner(false);
      let err = event.error["ErrorCode"];
      // if (err === "token_renewal_error") {
      //   LS.exportLocalStorage("localstorage");
      //   this.logout();
      // }
    }
  }

  public appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: Config.AppInsightKey
    }
  });

  ngOnInit() {
    this.init();
  }

  init() {
    myconsole("$timerEnd", "AppModule");
    myconsole("$timerStart", "AppComponent");
    this.isIframe = window !== window.parent && !window.opener;
    // clear non-catalog data from cache
    this.doUnload(null);

    //Theme
    this._settings.getTheTheme().pipe(takeUntil(this.componentDestroyed$)).subscribe(val => {
      this.adasThemeClass = val;
      this._utilities.adasThemeClass = val;
    });

    // local or cloud
    if (environment.local) {
      myconsole("$timerEnd", "AppComponent");
    }
    else {
      let loginSuccessSubscription: Subscription;
      let loginFailureSubscription: Subscription;

      loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', (response) => {
        this.checkAccount();
        let token = LS.getLocalStorageString(LS.LOCAL_STORAGE_KEY.Token.AD);
        myconsole('msal:loginSuccess', token);
        // return to page user was in
        // let page = LS.getLocalStorageString(LS.LOCAL_STORAGE_KEY.User.ActivePage);
        // this._router.navigate([page ? page : PAGES.JobsDashboard]);
      });

      loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
        myconsole('msal:loginFailure:', error);
        if (error && error.errorCode && !error.toLowerCase().includes("progress")) {
          this._utilities.handleAuthError(error);
        }
      });

      this.broadcastService.subscribe('msal:notAuthorized', (error) => {
        myconsole('msal:notAuthorized:', error);
        //this._utilities.handleAuthError(error);
      });

      this.broadcastService.subscribe('msal:acquireTokenSuccess', (response) => {
        myconsole('msal:acquireTokenSuccess:', response);
      });

      this.broadcastService.subscribe('msal:acquireTokenFailure', (error) => {
        LS.clearMsalTokenStorage();
        myconsole('msal:acquireTokenFailure:', error);
        //this._utilities.handleAuthError(error);
      });

      this.broadcastService.subscribe('msal:handleRedirectEnd', (response) => {
        myconsole('msal:handleRedirectEnd:', response);
      });

      this.subscriptions.push(loginSuccessSubscription);
      this.subscriptions.push(loginFailureSubscription);

      this.authService.handleRedirectCallback((authError, response) => {
        //let page = LS.getLocalStorageString(LS.LOCAL_STORAGE_KEY.User.ActivePage);
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }

        const redirectUrl = LS.getSessionStorage(LS.LOCAL_STORAGE_KEY.RedirectUrl);
        console.log("Redirect current path", window.location.pathname);
        console.log("Redirect path cache", redirectUrl);
        if(redirectUrl && redirectUrl.toLowerCase() != environment.redirectUriFolder.toLowerCase()
          && redirectUrl.toLowerCase() != window.location.pathname.toLowerCase()) {
          console.log("Redirect from cache", window.location.pathname, redirectUrl);
          this._router.navigate([redirectUrl]);
        }
        LS.removeSessionStorage(LS.LOCAL_STORAGE_KEY.RedirectUrl);
        
        myconsole('Redirect Success: ', response.accessToken);
      });

      this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
        myconsole('MSAL Logging: ', message);
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));

      this.getAuthToken();
    }
    this.configLoaded = true;
    environment.debug.indexElement.innerHTML = '';
  }

  getAuthToken() {
    this.authService.acquireTokenSilent({ scopes: environment.consentScopes })
      .then((response: AuthResponse) => {
        //var image = this._utilities.profileImage$;
        this.checkAccount();
        this.getProfilePics().subscribe((data) => {
          this.createImageFromBlob(data);
        }, (error) => {
          myconsole("User photo is not found", "");
        });
        myconsole("StartUrl: ", window.location.pathname);
      })
      .catch(error => {
        if (window.location.pathname.toLowerCase() === environment.redirectLogoutUriFolder) {
          return;
        }
        if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
          this._utilities.handleAuthError(error);
          //this.authService.acquireTokenRedirect(tokenRequest);
        } else {
          this._utilities.handleAuthError(error);
          //this.authService.acquireTokenRedirect(tokenRequest);
        }
      })
      .finally(() => {
        myconsole("$timerEnd", "AppComponent");
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  doBeforeUnload(e) {
    var isDirty = false;
    for (let index = 0; index < window.document.forms.length; index++) {
      const form = window.document.forms[index];
      if (form.className && form.className.includes('dirty')) {
        isDirty = true;
        break;
      }
      for (let num = 0; num < form.elements.length; num++) {
        const element = form.elements[num];
        var cls = element.className;
        if (cls.includes('dirty')) {
          isDirty = true;
          break;
        }
      }
      if (isDirty) break;
    }

    // Alert the user window is closing
    // Note that using custom strings doesn't work anymore in Chrome; www.chromestatus.com/feature/5349061406228480
    if (isDirty) {
      var confirmationMessage = isDirty ? 'Warning!!! If you leave before saving, your changes will be lost.' : false;
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }

  doUnload(e) {
    // Clear session or do something
    LS.clearJobLocalStorage();
  }

  login() {
    this.authService.loginRedirect();
  }

  logout() {
    this.authService.logout();
  }

  checkAccount() {
    let acct = this.authService.getAccount();
    this.loggedIn = !!acct;

    if (acct) {
      if (this._settings.settings && this._settings.settings.userId != acct.userName) {
        this._settings.getUserSettings(false);
      }
    }
  }

  getProfilePics(): Observable<Blob> {
    return this.http.get(environment.web.profilepic, { responseType: 'blob' });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this._utilities.profileImage$ = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
