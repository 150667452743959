<div class="container m-0">
    <div class="container-fluid card-body">
    <!-- title-->
    <div class="row">
      <div class="col-12">
        <div class="h1 page-name">{{'admin.roles'|translate}}</div>
      </div>
    </div>
  
    <!-- start-->
    <div class="content-body">
      <app-table-toolbar
        [event$] = "toolbarEvent$"
        [columns] ="columns"
        [widthOfSection]="'col-md-6'"
        [data]="tableView"
        [sharing]="toolbarSharing"
        [topRowForm] = "formGroup"
        [addText] = "'Add Role'"
        (onReset) = "handleReset()"
        (onAdd) ="handleAdd($event)"
        (onDelete)="handleDelete($event)">
      </app-table-toolbar>
    </div>
  </div>
  </div>