import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiParam } from '../../core/models/apiParam.model';
import { ApiService } from '../../core/services/api.service';
import { Config } from 'src/app/core/services/config';
import { environment } from 'src/environments/environment';
import { DateTimeService } from 'src/app/core/services/date-time.services';
import { map, switchMap } from 'rxjs/operators';
import { DATE_TIME_FORMAT } from 'src/app/shared/enums/enums';
import { SettingsService } from 'src/app/core/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    public api: ApiService,
    public _settings: SettingsService,
    public _dateTime: DateTimeService
  ) { }
  
  public searchProjects(params: Object) {
    const url = Config.APIUrlCore + environment.projects.searchProjects;
    return  this.api.post<any>(url, params).pipe(map(jobs => {
      for(let job of jobs) {
        if(job.spudDate) {
          job.spudDate = this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG);
        }
      }
      
      return jobs;
    }));
  }

  public searchSortPageProjects(payload) {
    const url = Config.APIUrlCore + environment.projects.searchSortPage;
    return this.api.post<any[]>(url, payload).pipe(map((data: any) => {
      for(let job of data.results) {
        if(job.spudDate) {
          job.spudDate = this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG);
        }
      }
      
      return data;
    }));
  }

  
  public getLastVisitedProjects(): Observable<any> {
    return this._settings.getLastVisitedJob().pipe(switchMap(ids => {
      return this.getProjectsByIds(ids).pipe(map(jobs => {
        const result = {
          results: jobs,
          totalCount: jobs.length
        }
        return result;
      }));
    }));
  }

  public getProjectsByIds(jobIds: Array<string>) {
    const url = Config.APIUrlCore + environment.projects.getByProjectIds;
    return this.api.post(url, jobIds).pipe(map((jobs: any) => {
      for(let job of jobs) {
        if(job.spudDate) {
          job.spudDate = this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG);
        }
      }
      
      return jobs;
    }));
  }

}
