import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UtilitiesService } from '../../../core/services/utilitiesservice.service';
import { ChartOptionsService } from '../../chart-options.service';
import { ChartConfigurationViewModel } from '../../models/chart-options-modal';
import * as LS from 'src/app/utils/localstorage';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-chart-frame',
  templateUrl: './chart-frame.component.html',
  styleUrls: ['./chart-frame.component.scss']
})
export class ChartFrameComponent implements OnInit, OnDestroy {
  @Input() public chartTitle: string;
  @Input() public chartOptions: any;
  @Input() public chartData: any;
  @Input() public chartFrame: boolean;
  @Input() public chartDataExpand: string;
  @Input() public chartType: string;
  @Input() public initOpts: any;
  public baseChartInstance: any;
  public showChartZoomModal: string = "chartsPopupModal";

  //Chart Options
  public analysisName: any;
  public chartOptionsTitle: string;
  public chartconfigurationmodel: ChartConfigurationViewModel;
  public chartOptionsData: any;
  public chartOptionsType: string;
  public formattedChartDataTemp: any;
  public customChartOptions: any; //custom settings from Service
  public formattedChartOptionsTemp: any; //temp value
  private onSubmitModal$ = new BehaviorSubject(null);
  private componentDestroyed$: Subject<boolean> = new Subject();
  private initOptions: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public _utilities: UtilitiesService,
    public _chartOptionsService: ChartOptionsService
  ) {
    this.chartconfigurationmodel = new ChartConfigurationViewModel();
  }

  ngOnInit() {
    this.onSubmitModal$.pipe(takeUntil(this.componentDestroyed$)).subscribe(
      res=>{
        if(res){
          this.chartOptions = res;
        }
      }
    )
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges() {
    if (this.chartOptions != undefined) {
      console.log('chartData:', this.chartTitle, this.chartData)
      this.loadChartCustomSettings();
      this.formattedChartOptionsTemp = JSON.parse(JSON.stringify(this.chartOptions));
      this._chartOptionsService.applySettingsToChartData(this.chartData, this.customChartOptions, this.chartType);
      this.initOptions = JSON.parse(JSON.stringify(this.chartOptions));
    }
  }

  chartInstance(e) {
    this.baseChartInstance = e;
  }

  clipboardDownloadChart() {
    var chartDiv = document.getElementById(this.chartTitle);
    html2canvas(chartDiv).then(canvas => {
      var myImage = canvas.toDataURL('image/png');
      let blob = this.dataURItoBlob(myImage);
      FileSaver.saveAs(blob, this.chartTitle + "download.png");
    });
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  showZoomChartModal() {
    let zoomChartObj = new Object();
    zoomChartObj = {
      chartOptions: this.chartOptions,
      chartIdentifier: this.chartTitle,
    };

    this.ngxSmartModalService.setModalData(zoomChartObj, 'chartsPopupModal');
    this.ngxSmartModalService.getModal('chartsPopupModal').open();
  }

  confirmTabOut() {
    let confirmTabOutObj = new Object();
    confirmTabOutObj = {
      chartDataExpand: this.chartDataExpand,
    };

    this.ngxSmartModalService.setModalData(confirmTabOutObj, 'confirmNewTabData')
    this.ngxSmartModalService.getModal('confirmNewTabData').open();
  }

  openChartOptions(chartName: string, chartOptions: any, chartData: any, chartType: string) {
    // this.formattedChartDataTemp = chartData;
    // if (LS.getSessionStorage(chartName)) {
    //   let a= LS.getSessionStorage(chartName);
    //   this.chartconfigurationmodel = JSON.parse(LS.getSessionStorage(chartName));
    // }
    // else {
    //   this.chartconfigurationmodel = new ChartConfigurationViewModel();
    //   this.chartconfigurationmodel.title.text = chartName;
    // this.chartconfigurationmodel.xAxis.push(this.chartOptions.xAxis);
    // this.chartconfigurationmodel.yAxis.push(this.chartOptions.yAxis);
    // }
    // if (!chartData) { return false; }
    // if (chartData.length == 0) { return false; }

    // this.chartOptionsTitle = chartName;
    // this.chartOptionsData = chartData;
    // this.chartOptionsType = chartType;

    // let chartOptionsObj = new Object();
    // chartOptionsObj = {
    //   chartName: this.chartOptionsTitle,
    //   chartData: this.chartOptionsData,
    //   chartConfiguration: this.chartconfigurationmodel,
    //   chartType: this.chartOptionsType
    // };
    setTimeout(() => {
      this.ngxSmartModalService.setModalData({
          event$: this.onSubmitModal$,
          curOptions: this.chartOptions,
          initOptions: this.initOptions
        }, 'chartOptionsBaseModal');
      this.ngxSmartModalService.getModal('chartOptionsBaseModal').open()
    }, 0);
  }

  public updateGraph(data: ChartConfigurationViewModel) {
    LS.setSessionStorage(data.chartName, JSON.stringify(data));
    let setting = JSON.parse(JSON.stringify(data));
    setting.xAxis = setting.xAxis[0];
    setting.yAxis = setting.yAxis[0];
    this.chartOptions = JSON.parse(JSON.stringify(this.formattedChartOptionsTemp));
    this.mergeDeep(this.chartOptions, setting);
    this.formattedChartDataTemp = data.chartData;
    this.chartData = data.chartData.filter(function (obj) {
      return obj.show;
    });
  }

  public loadChartCustomSettings() {
    if (LS.getSessionStorage(this.chartTitle) !== null) {
      this.customChartOptions = (LS.getSessionStorage(this.chartTitle));
      if (this.customChartOptions !== undefined) {
        this.customChartOptions.xAxis = this.customChartOptions.xAxis[0];
        this.customChartOptions.yAxis = this.customChartOptions.yAxis[0];
        this.mergeDeep(this.chartOptions, this.customChartOptions);
      }
    }
  }

  public mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.mergeDeep(target, ...sources);
  }

  public isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
}
