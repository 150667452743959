import { RowToolbarComponent } from './row-toolbar/row-toolbar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TranslateModule } from '@ngx-translate/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { TrendModule } from 'ngx-trend';

// primeng
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { SpinnerModule } from 'primeng/spinner';
import { TableModule } from 'primeng/table';

// Charts
import { ChartsPopupComponent } from './charts-popup/charts-popup.component';
import { ChartBaseComponent } from './charts/chart-base/chart-base.component';
import { ChartBaseLineComponent } from './charts/chart-base-line/chart-base-line.component';
import { CuttingsChartComponent } from './charts/cuttings-chart/cuttings-chart.component';
import { LineChartResponsiveComponent } from './charts/line-chart-responsive/line-chart-responsive.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import  { ChartExtendComponent } from './charts/chart-extend/chart-extend.component';
import { MixedChartComponent } from './charts/mixed-chart/mixed-chart.component';
import { MultipleXaxisChartComponent } from './charts/multiple-xaxis-chart/multiple-xaxis-chart.component';
// import { RainFallChartComponent } from './charts/rainfall-chart/rainfall-chart.component';
import { SpiderChartComponent } from './charts/spider-chart/spider-chart.component';
import { SurfaceChartComponent } from './charts/surface-chart/surface-chart.component';
import { SweepsChartComponent } from './charts/sweeps-chart/sweeps-chart.component';
import { WellboreChartComponent } from './charts/wellbore-chart/wellbore-chart.component';
import { SparkLineChartComponent } from './charts/spark-line-chart/spark-line-chart.component';
import { LithostratigraphyChartComponent } from './charts/lithostratigraphy-chart/lithostratigraphy-chart.component';

// components
import { AnalysisProgressbarComponent } from './analysis-progressbar/analysis-progressbar.component';
import { ChartOptionsComponent } from '../job/job-shared/chart-options/chart-options.component';
import { ChartOptionsBaseComponent } from '../job/job-shared/chart-options-base/chart-options-base.component';
import { DataconfirmPopupComponent } from './dataconfirm-popup/dataconfirm-popup.component';
import { DeletePersonnelConfirmationComponent } from './delete-personnel-confirmation/delete-personnel-confirmation.component';
import { DynamicConfirmationModalComponent } from './dynamic-confirmation-modal/dynamic-confirmation-modal.component';
import { DynamicRowTableComponent } from './dynamic-row-table/dynamic-row-table.component';
import { FilterDataPipe } from './h-table/scripts/search.pipe';
import { FilterSortComponent } from './filter-sort/filter-sort.component';
import { FooterComponent } from './footer/footer.component';
import { HTableComponent } from './h-table/h-table.component';
import { InformationmodalComponent } from './confirm-modal/confirm-modal.component';
import { JobBasicInfoComponent } from './job-basic-info/job-basic-info.component';
import { LabelKPIUnitComponent } from './label-kpiunit/label-kpiunit.component';
import { NotificationbarComponent } from './notificationbar/notificationbar.component';
import { PersonnelcardComponent } from './personnelcard/personnelcard.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { SearchExistingWellComponent } from './search-existing-well/search-existing-well.component';
import { SubHeaderComponent } from '../job/job-shared/sub-header/sub-header.component';
import { tooltipPanelComponent } from './tooltip-panel/tooltip-panel.component';
import { TrendComponent } from './trend/trend.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'
import { UomLabelComponent } from './uom-label/uom-label.component';
import { UomModalComponentComponent } from './uom-modal-component/uom-modal-component.component';
import { ErrorMessageComponent } from './error-message/error-message.component';

//pipes
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { ChartFrameComponent } from './charts/chart-frame/chart-frame.component';
import { CellValuePipe } from './h-table/scripts/cell-value.pipe';
import { UomPipe } from './pipes/uom.pipe';
import { FixedDecimalPipe } from './pipes/fixed-decimal.pipe';
import { UomConversionPipe } from './pipes/uom-conversion.pipe';
import { FirstPipe } from './pipes/first.pipe';

//directives
import { NumberValidators } from './custom-directives/number-validator.directive';
import { UomPrecisionDirective } from './custom-directives/uom-precision.directive';
import { HhmmformatDirective } from './custom-directives/hhmmformat.directive';

//services
import { WellprofileDiagramService } from '../job/services/wellprofile-diagram.service';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DynamicReadonlyFieldsComponent } from './components/dynamic-readonly-fields/dynamic-readonly-fields.component';
import { FindExistingRigComponent } from './find-existing-rig/find-existing-rig.component';
import { FindExistingJobComponent } from  './find-existing-job/find-existing-job.component';
import { ChooseIntervalsComponent } from './choose-intervals/choose-intervals.component';
import { PreviewFormationComponent } from './preview-formation/preview-formation.component';
import { FileImportComponent } from './file-import/file-import.component';
import { RestrictionDirective } from './custom-directives/restriction.directive';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ToolTipDirective } from './custom-directives/tooltip.directive';
import { PreviewTrajectoryComponent } from './preview-trajectory/preview-trajectory.component';
import { ChooseWellboresComponent } from './choose-wellbores/choose-wellbores.component';
import { TableToolbarComponent } from './components/app-table-toolbar/table-toolbar.component';
import { LineChartStackComponent } from './charts/line-chart-stack/line-chart-stack.component';
import { FilterPopupComponent } from './components/filter-popup/filter-popup.component';
import {IdossyncModalComponent} from "../job/job-profile/idossync-modal/idossync-modal.component";

@NgModule({
  imports: [
    AccordionModule,
    CalendarModule,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FormsModule,
    ListboxModule,
    MultiSelectModule,
    NgxSmartModalModule.forChild(),
    OverlayPanelModule,
    PaginationModule.forRoot(),
    ProgressBarModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    DragDropModule,
    SpinnerModule,
    TableModule,
    TrendModule,
    TranslateModule,
    NgxEchartsModule.forRoot(
      {
        echarts: ()=>import('echarts')
      }
    ),
  ],
  exports: [
    AccordionModule,
    AnalysisProgressbarComponent,
    CalendarModule,
    ChartBaseComponent,
    ChartBaseLineComponent,
    ChartFrameComponent,
    ChartOptionsComponent,
    ChartOptionsBaseComponent,
    ChartsPopupComponent,
    CheckboxModule,
    CommonModule,
    ConfirmPopupComponent,
    DialogComponent,
    CuttingsChartComponent,
    DataconfirmPopupComponent,
    DeletePersonnelConfirmationComponent,
    DropdownModule,
    DynamicConfirmationModalComponent,
    DynamicReadonlyFieldsComponent,
    DynamicRowTableComponent,
    TableToolbarComponent,
    FilterPopupComponent,
    ErrorMessageComponent,
    EditorModule,
    FieldsetModule,
    FilterSortComponent,
    FindExistingRigComponent,
    FindExistingJobComponent,
    ChooseIntervalsComponent,
    PreviewFormationComponent,
    FirstPipe,
    FixedDecimalPipe,
    FooterComponent,
    FormsModule,
    HhmmformatDirective,
    HTableComponent,
    InformationmodalComponent,
    JobBasicInfoComponent,
    LabelKPIUnitComponent,
    LineChartComponent,
    LineChartStackComponent,
    ChartExtendComponent,
    LineChartResponsiveComponent,
    ListboxModule,
    MixedChartComponent,
    MultipleXaxisChartComponent,
    MultiSelectModule,
    NotificationbarComponent,
    NumberValidators,
    OverlayPanelModule,
    PersonnelcardComponent,
    ProgressbarComponent,
    RadioButtonModule,
    ReactiveFormsModule,
    SearchExistingWellComponent,
    SearchFilterPipe,
    SparkLineChartComponent,
    SpiderChartComponent,
    SpinnerModule,
    SubHeaderComponent,
    SurfaceChartComponent,
    TableModule,
    RowToolbarComponent,
    ToolTipDirective,
    tooltipPanelComponent,
    TranslateModule,
    UomConversionPipe,
    UomLabelComponent,
    UomModalComponentComponent,
    UomPipe,
    UomPrecisionDirective,
    WellboreChartComponent,
    DragDropModule,
    FileImportComponent,
    RestrictionDirective,
    PreviewTrajectoryComponent,
    ChooseWellboresComponent,
    NgxEchartsModule,
    LithostratigraphyChartComponent,
    PaginationModule,
    CellValuePipe,
    IdossyncModalComponent
  ],
  declarations: [
    AnalysisProgressbarComponent,
    CellValuePipe,
    ChartBaseComponent,
    ChartBaseLineComponent,
    ChartFrameComponent,
    ChartOptionsComponent,
    ChartOptionsBaseComponent,
    ChartsPopupComponent,
    ConfirmPopupComponent,
    DialogComponent,
    CuttingsChartComponent,
    DataconfirmPopupComponent,
    DeletePersonnelConfirmationComponent,
    DynamicConfirmationModalComponent,
    DynamicReadonlyFieldsComponent,
    DynamicRowTableComponent,
    TableToolbarComponent,
    FilterPopupComponent,
    ErrorMessageComponent,
    ErrorPageComponent,
    FilterDataPipe,
    FilterSortComponent,
    FindExistingRigComponent,
    FindExistingJobComponent,
    ChooseIntervalsComponent,
    PreviewFormationComponent,
    FirstPipe,
    FixedDecimalPipe,
    FooterComponent,
    HhmmformatDirective,
    HTableComponent,
    InformationmodalComponent,
    JobBasicInfoComponent,
    LabelKPIUnitComponent,
    LineChartComponent,
    LineChartStackComponent,
    ChartExtendComponent,
    LineChartResponsiveComponent,
    MixedChartComponent,
    MultipleXaxisChartComponent,
    NotificationbarComponent,
    NumberValidators,
    PersonnelcardComponent,
    ProgressbarComponent,
    SearchExistingWellComponent,
    SearchFilterPipe,
    SparkLineChartComponent,
    SpiderChartComponent,
    SpiderChartComponent,
    SubHeaderComponent,
    SurfaceChartComponent,
    SweepsChartComponent,
    RowToolbarComponent,
    ToolTipDirective,
    tooltipPanelComponent,
    TrendComponent,
    UnauthorizedComponent,
    UomConversionPipe,
    UomConversionPipe,
    UomLabelComponent,
    UomModalComponentComponent,
    UomPipe,
    UomPrecisionDirective,
    WellboreChartComponent,
    FileImportComponent,
    RestrictionDirective,
    PreviewTrajectoryComponent,
    ChooseWellboresComponent,
    LithostratigraphyChartComponent,
    IdossyncModalComponent
  ],
  providers: [
    NgxSmartModalService,
    MessageService,
    WellprofileDiagramService
  ],
  entryComponents: [InformationmodalComponent, FileImportComponent]
})
export class SharedModule { }
