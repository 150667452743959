import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SettingsService } from '../core/services/settings.service';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { ApiService } from '../core/services/api.service';
import { MsalService } from '@azure/msal-angular';
import { AuthError, AuthResponse } from 'msal';
import { UnitSystemsService } from '../core/services/unitsystems.service';
import { PAGES } from '../shared/enums/enums';

@Component({
  selector: 'app-resolve-routes',
  template: `
    <p>Resolve-routes: Redirecting...</p>
    <p>If this page hangs, please click on Jobs Dashboard</p>
  `,
  styles: []
})
export class ResolveRoutesComponent implements OnInit {
  public componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public _settings: SettingsService,
    public _api: ApiService,
    public auth: MsalService,
    public router: Router,
  ) { }

  ngOnInit() {
    console.log("Resolve-Routes ngOnInit");
    this.auth.handleRedirectCallback((redirectError: AuthError, redirectResponse: AuthResponse) => {
      console.log("Resolve-Routes handleRedirectCallback");
      if (redirectError) {
        console.error("Redirect error: ", redirectError);
        this.redirectDefaultLanding();
        return;
      }
      console.log("Redirect success: ", redirectResponse);
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public redirectDefaultLanding() {
    this.router.navigate([PAGES.JobsDashboard]);
    // if (this._settings.settings.landingPage) {
    //   this._utilities.showDashboard = true;
    //   this.router.navigate([PAGES.JobsDashboard]);
    // }
  }

}
