import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { API_RESPONSE } from 'src/app/shared/enums/enums';
import * as LS from 'src/app/utils/localstorage';
import { MessageService } from '../../shared/message.service';
import { apiParam } from '../models/apiParam.model';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class DataLogicService {

  constructor(
    public http: HttpClient,
    public messageService: MessageService,
    public api: ApiService) { }


  public get(url: string, key: string) {
    return new Observable<any>(ob => {
      let cached = LS.getLocalStorage(key)
      if (cached) {
        ob.next(cached);
        ob.complete();
        return;
      }

      this.api.get<any>(url).subscribe(res => {
        LS.setLocalStorage(key, res);
        ob.next(res);
        ob.complete();
      },
        error => {
          ob.error(error);
        });
    });
  }

  public post(url: string, payload: any, key: string) {
    return new Observable<any>(ob => {

      this.api.post<any>(url, payload).subscribe(res => {
        LS.setLocalStorage(key, res);
        ob.next(res);
        ob.complete();
      },
        error => {
          ob.error(error);
        });
    });
  }

  public async postAsync(url: string, payload: any, key?: string) {

    return await this.api.post<any>(url, payload).toPromise()
      .then(res => {
        if (key) {
          LS.setLocalStorage(key, res);
        }
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

}
