import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router"
import { Subject } from 'rxjs';
import { PAGES } from 'src/app/shared/enums/enums';
@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']

})
export class AdministratorMenuComponent implements OnInit, OnDestroy {
  @ViewChild('syncDropdownRef') public dropdownElemSync: ElementRef;

  public componentDestroyed$: Subject<boolean> = new Subject();
  public isExpand: boolean = false;

  constructor(
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.dropdownElemSync.nativeElement.contains(targetElement)) {
      this.isExpand = false;
    }
  }

  toggleMenu() {
    this.isExpand = !this.isExpand;
  }

  navigateRoles() {
    this.isExpand = false;
    this.router.navigate([PAGES.Roles]);
  }

  navigateUserRoles() {
    this.isExpand = false;
    this.router.navigate([PAGES.UserRoles]);
  }

  navigateCatalogManagement(){
    this.isExpand = false;
    this.router.navigate([PAGES.CatalogsManagement]);
  }
}
