import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { WellprofileService } from '../services/common/wellprofile.service';
import { UtilitiesService } from '../services/utilitiesservice.service';

@Injectable()
export class TubularCatalogsResolver implements Resolve<void> {
  constructor(
    // utilities service injected to ensure token auth is handled first
    public _utilities: UtilitiesService,
    public _well: WellprofileService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._utilities.loadCatalogTubularComponentTypes(false);
  }
}