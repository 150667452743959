import { FieldLevelUoMSettings } from "./columnSettings";


export class DataRow {
    idx?: string;
    md: string;
    inclination: string;
    azimuth: string;
    tvd: string;
    northing: string;
    easting: string;
    verticalSection: string;
    dogleg: string;
    calculateticalSectionClosure: boolean;
}

export class trajectoryRow {
    constructor(
        idx: string,
        md: number,
        inclination: number,
        azimuth: number,
        tvd: number,
        northing: number,
        easting: number,
        verticalSection: number,
        dogleg: number,
    ) { }
}

export class trajectoryCalcRow {
    idx: string;
    Md: number;
    Incl: number;
    Azi: number;
    Tvd: number;
    DispNs: number;
    DispEw: number;
    VertSect: number;
    Dls: number;

    constructor(
        idx: string,
        Md: number,
        Incl: number,
        Azi: number,
        Tvd: number,
        DispNs: number,
        DispEw: number,
        VertSect: number,
        Dls: number,
    ) { }
}
export class Trajectory{
   
    public calculateTvdFromInclination: boolean=false;
    public wellId:string="";
  
    trajectoryWITSML:trajectoryWITSML;
    
}
export class trajectoryWITSML{
    trajectoryId:string="";
    constructor(){}
}

export class TrajetoryData {
    columnSettings: FieldLevelUoMSettings[];
    trajectoryType: string;
    dataRows: DataRow[];
    trajectoryWITSML:trajectoryWITSML
}



