import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  public isError: boolean = false;
  public errorPageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  errorPageMessage$: Observable<string> = this.errorPageSubject.asObservable();

  constructor(
    public _router: Router,
  ) {
  }

  setErrorPageMessage(message: string) {
    this.isError = true;
    this.errorPageSubject.next(message);
    this._router.navigate(['/error']);
    //var res = window.confirm(errMsg);
  }

}