import { DateTimeService } from '../../core/services/date-time.services';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UtilitiesService } from '../../core/services/utilitiesservice.service';
import { JobService } from '../../job/job.service';
import { JobDashboard } from '../../shared/models/jobs.model';
import * as LS from 'src/app/utils/localstorage';;
import { DATE_TIME_FORMAT, SORT_TYPE } from '../../shared/enums/enums';
import { DialogInput, DialogResult } from '../../shared/components/dialog/dialog.component';
import { DialogModalService } from '../../core/services/dialogModal.service';
import { environment } from 'src/environments/environment';
import { ApiFilterModel } from '../../shared/models/job-dashboard-filter-data.model';
import * as OB from 'src/app/utils/objecter';
import { SettingsService } from '../../core/services/settings.service';
import { ProjectService } from 'src/app/job/services/projects.service';
import { finalize, map } from 'rxjs/operators';

export enum JOB_CHOICES {
  NEW = "new",
  CLONE = "clone"
};

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})

export class ProjectDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  public listProjects = [];
  public recentProjects = [];
  public projectCount: number;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public pageLoaded = false;
  public pageSize = 25;
  public isShowAll = true;
  public filterObj: ApiFilterModel = {} as ApiFilterModel;
  public enableNewFiltering: boolean = true;
  public dialogInput: DialogInput = {
    data: {
      body: ""
    },
    buttons: {
      cancel: false,
      ok: true
    }
  };
  public createJobDialogInput: DialogInput = {
    data: {
      header: "New job",
      options: [
        {
          label: "Create new Job and new Well",
          value: JOB_CHOICES.NEW
        },
        {
          label: "Copy an existing Job",
          value: JOB_CHOICES.CLONE
        }
      ]
    },
    buttons: {
      cancel: true,
      ok: false,
      next: true
    }
  };
  public headers = [
    { title: 'project.baroidProjectID', uom: false, key: "baroidProjectNumber", type: 'text', maxColumnDisplayLength: 10 },
    { title: 'project.baroidProjectName', uom: false, key: "baralogixName", type: 'text', maxColumnDisplayLength: 100 },
    { title: 'region', uom: false, key: "region", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'country', uom: false, key: "country", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'job.createdBy', uom: false, key: "jobOwner", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'spudDate', uom: false, key: "spudDate", type: 'date' }
  ];
  public showInfo: boolean = false;
  public baroidProjectInfo: any = {};
  public jobsQuery: ApiFilterModel;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService,
    public _job: JobService,
    public _project: ProjectService,
    public _dialogModal: DialogModalService,
    public _settings: SettingsService
  ) {
  }

  ngOnInit() {
    this.switchView(true);
  }
  
  
  jobsApi = (filter: any): Observable<any> => {
    let api = this._project.searchSortPageProjects(filter);
    if(!this.isShowAll) {
      api = this._project.getLastVisitedProjects();
    }

    this._utilities.showSpinner(true);
    return api.pipe(
      map((res: any) => {
        this.projectCount = res.totalCount;
        return res;
      }),
      finalize(() => {
        this._utilities.showSpinner(false);
      }));
  }

  ngAfterViewInit() {
    setTimeout(f=>{this.notifyJobAuthentication()}, 0);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private notifyJobAuthentication() {
    if(this._utilities.jobAuthenticationInfo){
      this.dialogInput.data.body = this._utilities.jobAuthenticationInfo?.message;
      this._dialogModal.showInfo(this.dialogInput);
      this._utilities.jobAuthenticationInfo = null;
    }
  }

  openAddJobDashboardModal() {
    if(environment.local) {
      this.router.navigate(['/jobs/newjob/jobprofile']);
      return;
    }
    
    this._dialogModal.showConfirmation(this.createJobDialogInput).subscribe(res => {
      if (res.action && res.action === DialogResult.Ok) {
        switch(res.value) {
          case JOB_CHOICES.NEW:
            this.router.navigate(['/jobs/newjob/jobprofile']);
            break;
          case JOB_CHOICES.CLONE:
            this.router.navigate(['/jobs/newjob/clone']);
        }
      }
    });
  }

  public cardClick(project) {
    this.showInfo = true;
    this.baroidProjectInfo = project;
  }

  public onModalClose() {
    this.showInfo = false;
  }
  
  public switchView(initial = false) {
    if(initial) {
      const cached = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.User.ShowAllProjectStatus);
      this.isShowAll = cached ? cached: false;
    }

    LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.User.ShowAllProjectStatus, this.isShowAll);
    this.jobsQuery = {
      filterColumns: [],
      sortColumns: [
        {
          id: "baralogixName",
          value: SORT_TYPE.ASC.toLowerCase()
        }
      ],
      pageIndex: 1,
      pageSize: 10
    };
  }
}
