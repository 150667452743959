import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalTokenService } from './localtoken.service';
import * as LS from '../../utils/localstorage';

@Injectable({
  providedIn: 'root'
})
export class LocalTokenInterceptor implements HttpInterceptor {
  constructor(
    public _auth: LocalTokenService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var assets = req.url.includes('/assets/');
    if (!assets) {
      var key = LS.LOCAL_STORAGE_KEY.Token.Gen;
      var token = LS.getSessionStorageString(key);
      //let auth1 = req.headers.get('Authorization');
      let skip = req.headers.get('skipIntercept');
      if (token && (skip != 'true')) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    }
    //let auth2 = req.headers.get('Authorization');
    return next.handle(req).pipe(
      catchError(err => {
        if (err) {
          //Authorization: `Bearer ${this._auth.getTokenGenerator(false)}`
          return throwError(err);
        }
      })
    );
  }
}

export const LocalInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LocalTokenInterceptor,
  multi: true,
};