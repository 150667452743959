
//ALL UNITS ARE API BASED
export const ColumnSettingsInfo = {

	// Base oil
    baseOil: [
        {
            colName: "TemperatureStep",
            currentUnit: "°F",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: '°F',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "PressureStep",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: 'psi',
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	// Rig Profile
    rigData: [
        {
            colName: "waterDepth",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "airGap",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        }
    ],
    surfaceEquipment: [
        {
            colName: "surfaceEquipment_name",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "surfaceEquipment_id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "surfaceEquipment_length",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        }
    ],
    serviceLines: [
        {
            colName: "serviceLine_name",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "serviceLine_id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC ",
            unit:"in"
        },
        {
            colName: "serviceLine_length",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        }
    ],
    mudPumps: [
        {
            colName: "lenStroke",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "idLiner",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "odRod",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "spmMx",
            currentUnit: "spm",
            decimalOption: "Default",
            decimalPrecision: 0,
            filterItems: [],
            quantity: "StrokeRate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"spm"
        },
        {
            colName: "strokeRate",
            currentUnit: "spm",
            decimalOption: "Default",
            decimalPrecision: 0,
            filterItems: [],
            quantity: "StrokeRate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"spm"
        },
        {
            colName: "efficiency",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
			sortDirection: "ASC",
        },
        {
            colName: "maxHHP",
            currentUnit: "hp",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"hp",
            filterItems: [],
            quantity: "Power",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "maxPressure",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
			colName: "bPS",
			currentUnit: "bbl",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Volume",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"bbl"
		},
        {
            colName: "maxFlowRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"bpm"
        }
    ],
    mudLiftPumps: [
        {
            colName: "mlpDepth",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
		{
            colName: "returnLineOD",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "returnLineID",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "returnLineLength",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        }
    ],
    pits: [
        {
            colName: "length",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "width",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "diameter",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
			colName: "secondaryDiameter",
			currentUnit: "ft",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Length",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"ft"
		},
        {
			colName: "height",
			currentUnit: "ft",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Length",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"ft"
		},
        {
			colName: "secondaryHeight",
			currentUnit: "ft",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Length",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"ft"
		},
        {
			colName: "volume",
			currentUnit: "bbl",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Volume",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"bbl"
		},
        {
			colName: "capMx",
			currentUnit: "bbl",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Volume",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"bbl"
		}
	],
    solidsControlEquipment: [
        {
            colName: "desilterConeSize",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        },
        {
            colName: "nominalRPM",
            currentUnit: "rpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "RotarySpeed",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"rpm"
        },
        {
            colName: "capFlow",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"bpm"
        },
        {
            colName: "desanderConeSize",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"in"
        }
    ],

	// Well Profile
	wellData: [
        {
            colName: "waterDepth",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        }
    ],
	trajectoryColSettings :[
        {
            colName: "md",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "incl",
            currentUnit: "°",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Angle",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"°"
        },
        {
            colName: "azi",
            currentUnit: "°",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Angle",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"°"
        },
        {
            colName: "tvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "dispNs",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "dispEw",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
		{
            colName: "vertSect",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"ft"
        },
        {
            colName: "dls",
            currentUnit: "°/100ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Dogleg",
            significantDigits: 8,
            sortDirection: "ASC",
            unit:"°/100ft"
        }
    ],
	wellbores:[
		{
            colName: "mdKickoff",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "md",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "tvdKickoff",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "tieOnMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "tieOnTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "tvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],
	risers: [
        {
            colName: "id",
            currentUnit: "in",
            unit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "od",
            currentUnit: "in",
            unit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "topMd",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC"
        },        
        {
            colName: "endMd",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC"            
        },
        {
            colName: "weight",
            currentUnit: "lb/ft",
            unit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
    openHoles: [
        {
            colName: "holeSize",
            currentUnit: "in",
            unit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "topMD",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "endMD",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	casingsCatalogs: [
        {
            colName: "description",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "od",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "grade",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "weight",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        }
    ],
    risersCatalogs: [
        {
            colName: "description",
            currentUnit: "",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "od",
            currentUnit: "",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "id",
            currentUnit: "",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "weight",
            currentUnit: "",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        }
    ],
    casing:[

        {
            colName: "id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "od",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "topMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "endMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "weight",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        }
    ],
	wellBhaRuns: [
        {
            colName: "bitSize",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: 'in',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "startMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "startTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	tubularBHA:[
        {
			colName: "od",
			currentUnit: "in",
			decimalOption: "Default",
			decimalPrecision: 3,
			filterItems: [],
			quantity: "Diameter",
			significantDigits: 8,
			sortDirection: "ASC",
			unit: "in"
		},
		{
			colName: "id",
			currentUnit: "in",
			decimalOption: "Default",
			decimalPrecision: 3,
			filterItems: [],
			quantity: "Diameter",
			significantDigits: 8,
			sortDirection: "ASC",
			unit: "in"
		},
		{
			colName: "length",
			currentUnit: "ft",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Length",
			significantDigits: 8,
			sortDirection: "ASC",
			unit: "ft"
		},
		{
			colName: "md",
			currentUnit: "ft",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Depth",
			significantDigits: 8,
			sortDirection: "ASC",
			unit: "ft"
		},
		{
			colName: "wtPerLen",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
		}
    ],
    tubularPropertiesNozzles:[{
        colName: "diaNozzle", //old name BitJetSize
        currentUnit: "in",
        decimalOption: "Default",
        decimalPrecision: 3,
        filterItems: [],
        quantity: "Diameter",
        significantDigits: 8,
        sortDirection: "ASC",
        unit: "in"

    }],
	FormationLithoscolset: [
        {
            colName: "topTvd",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endTvd",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	lithosCatalogs: [
        {
            colName: "description",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "group",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "period",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "lithologyType",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
        {
            colName: "country",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },
    ],

	// Fluid Sets
	fluidSetsData : [
        {
            colName: "fluidSetId",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "fluidSetName",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "sAPNumber",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "fluidSetDescription",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "fluidCategory",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
		{
            colName: "fluidSystem",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
        },
		{
            colName: "fluidType",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
        },
		{

            colName: "isAerated",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
		{
            colName: "baseFluidType",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
		{
            colName: "calculationBaseFluidType",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "brineType",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        },
		{
            colName: "calculationBrineType",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 4,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],

	// Intervals
	intervals: [
        {
            colName: "expectedHoleSize",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: 'in',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "startMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "startTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
    intervalRisers: [
        {
            colName: "id",
            currentUnit: "in",
            unit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "od",
            currentUnit: "in",
            unit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "weight",
            currentUnit: "lb/ft",
            unit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "topMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "endMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
    ],
	intervalCasings: [
        {
            colName: "id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "od",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "topMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "endMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "weight",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        }
    ],
	intervalOpenHoles: [
        {
            colName: "holeSize",
            currentUnit: "in",
            unit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "topMD",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "endMD",
            currentUnit: "ft",
            unit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	bhaRuns: [
        {
            colName: "bitSize",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "topMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "topTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "endMD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "endTVD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        }
    ],
	intervalsTargetSets: [
        {
            colName: "startMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "startTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endMd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "endTvd",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	generalProperties: [
        {
            colName: "K (HB)",
            currentUnit: "lb s^n/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearRate",
            unit: 'lb s^n/100ft^2',
            significantDigits: 8,            
            sortDirection: "ASC",
            fixedUnit: {si: 'lb s^n/100ft^2', api: 'lb s^n/100ft^2'}
        },
        {
            colName: "n (HB)",
            currentUnit: "decp",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Percent",
            unit: 'decp',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'decp', api: 'decp'}
        },
        {
            colName: "Density",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            unit: 'ppg',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Density Measure Temp",            
            currentUnit: "°F",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: "°F",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "Funnel Vis",
            currentUnit: "sec/qt",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: 'sec/qt',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'sec/qt', api: 'sec/qt'}
        },
        {
            colName: "Plastic Viscosity",
            currentUnit: "cP",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Viscosity",
            unit: 'cP',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Yield Point",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Tau0",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Tau0",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Tau0",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "10 sec Gels",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearStress",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "10 min Gels",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearStress",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "30 min Gels",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearStress",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "API Filtrate",
            currentUnit: "mL/30min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: 'mL/30min',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL/30min', api: 'mL/30min'}
        },
        {
            colName: "HPHT Filtrate",
            currentUnit: "mL/30min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: 'mL/30min',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL/30min', api: 'mL/30min'}
        },
        {
            colName: "NAF Content",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
			sortDirection: "ASC",
        },
        {
            colName: "Water Content",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Sand Content",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Lime Content",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "LGS %",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "LGS",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "ASG",
            currentUnit: "SG",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "",
            unit: 'SG',
            significantDigits: 8,
            sortDirection: "DSC",
        },
        {
            colName: "Temperature",
            currentUnit: "°F",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: '°F',
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],
    nafProperties: [
        {
            colName: "Chlorides",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: null,
        },
        {
            colName: "Electrical Stability",
            currentUnit: "V",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Density",
            unit: 'V',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'V', api: 'V'}
        },
        {
            colName: "Alkalinity",
            currentUnit: "mL",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            unit: 'mL',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL', api: 'mL'}
        },
        {
            colName: "WPS",
            currentUnit: "ppm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: 'ppm',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "THTS",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "AgNO3",
            currentUnit: "mL",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            unit: 'mL',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL', api: 'mL'}
        },
        {
            colName: "CaCl2",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Sol NaCl",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Insol NaCl",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "NAF/Water Ratio",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],
	wbmProperties: [
        {
            colName: "MBT",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "pH",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "Pm",
            currentUnit: "mL",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            unit: 'mL',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL', api: 'mL'}
        },
        {
            colName: "Pf",
            currentUnit: "mL",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            unit: 'mL',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL', api: 'mL'}
        },
        {
            colName: "Mf",
            currentUnit: "mL",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            unit: 'mL',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: {si: 'mL', api: 'mL'}
        },
        {
            colName: "Chlorides",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "Total Hardness",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],
	additionalProperties: [
        {
            colName: "minValue",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "maxValue",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],

	// Hydraulics Inputs
    downHoleParameters: [
        {
            colName: "hoursPumping",
            currentUnit: "hr",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"hr",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'hr', api: 'hr'}
        },
        {
            colName: "timeSinceCirculation",
            currentUnit: "hr",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"hr",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'hr', api: 'hr'}
        },
    ],
    hydraulicsGeothermalProfile: [
      {
          colName: "tvd",
          currentUnit: "ft",
          decimalPrecision: 1,
          quantity: "Depth",
          unit: "ft",
          significantDigits: 8,
          decimalOption: "Default",
          filterItems: [],
          sortDirection: "ASC"
      },
      {
          colName: "temperature",
          currentUnit: "°F",
          decimalPrecision: 1,
          quantity: "Temperature",
          unit: "°F",
          significantDigits: 8,
          decimalOption: "Default",
          filterItems: [],
          sortDirection: "ASC"
      }
    ],
	operatingParameters: [
        {
            colName: "flowRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "minFlowRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "maxFlowRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "cleaningTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "connectionTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "",
        },
        {
            colName: "boosterRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "chokeRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "killRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "backPressure",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "cuttingSize",
            currentUnit: "in",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: "in",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "cuttingSG",
            currentUnit: "SG",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            unit: "SG",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default",
            fixedUnit: { si: 'SG', api: 'SG' }
        },
        {
            colName: "cuttingLoad",
            currentUnit: "%",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Percent",
            unit: "%",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pipeRotation",
            currentUnit: "rpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "RotarySpeed",
            unit: "rpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "bedHeightPercentage",
            currentUnit: "%",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Percent",
            unit: "%",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "suctionTemperature",
            currentUnit: "°F",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: "°F",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "flowlineTemperature",
            currentUnit: "°F",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: "°F",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "activePitVolume",
            currentUnit: "bbl",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            unit: "bbl",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "strokeRate",
            currentUnit: "spm",
            decimalPrecision: 0,
            filterItems: [],
            quantity: "StrokeRate",
            unit: "spm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "bitDepth",
            currentUnit: "ft",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: "ft",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        }
    ],
    tubularProperties: [
	 {
            colName: "fixedPressureDrop",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: 'psi',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "tfa",
            currentUnit: "ft^2",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Area",
            unit:'ft^2',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: { si: 'cm^2', api: 'in^2' },
           
        },
        {
            colName: "jointLength",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "tjOd",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: 'in',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "tjId",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: 'in',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "tjLength",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "stabilizerLength",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            unit: 'ft',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "nominalOd",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: 'in',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "stabilizerEffectiveOd",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: 'in',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "diaNozzle",
            currentUnit: "in",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: "in",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "diaNozzleExtend",
            currentUnit: "in",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: "in",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default",
            fixedUnit: { si: 'cm', api: 'in' }
        }
    ],
	fluidProperties: [
        {
            colName: "oilOfOWR",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "concentration",
            currentUnit: "%",
            decimalOption: "Default",
            decimalPrecision: 0,
            filterItems: [],
            quantity: "Percent",
            unit: '%',
            significantDigits: 8,
            sortDirection: "ASC",
			fixedUnit: { si: 'ppm', api: 'ppm' }
        },
        {
            colName: "asgSolids",
            currentUnit: "SG",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            unit: 'SG',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: { si: 'SG', api: 'SG' },
        },
        {
            colName: "fluidDensity",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            unit: 'ppg',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "densityTemperature",
            currentUnit: "°F",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: '°F',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "bdf568Concentration",
            currentUnit: "ppb",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Concentration",
            unit: 'ppb',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: { si: 'ppb', api: 'ppb' },
        },
        {
            colName: "speed",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 0,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "reading",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "herschelBulkleyModel_n",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "herschelBulkleyModel_K",
            currentUnit: "lb*s^n'/100ft2",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "ShearRate",
            unit: "lb*s^n'/100ft2",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "herschelBulkleyModel_tauO",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Tau0",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "binghamPlasticModel_PV",
            currentUnit: "cP",
            decimalOption: "Default",
            decimalPrecision: 0,
            filterItems: [],
            quantity: "Viscosity",
            unit: 'cP',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "binghamPlasticModel_YP",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 0,
            filterItems: [],
            quantity: "Tau0",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "powerLawModel_n",
            currentUnit: null,
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: null,
            unit: null,
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "powerLawModel_K",
            currentUnit: "lb*s^n'/100ft2",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "ShearRate",
            unit: "lb*s^n'/100ft2",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "gels_10sec",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearStress",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "gels_10min",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearStress",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "gels_30min",
            currentUnit: "lb/100ft^2",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "ShearStress",
            unit: 'lb/100ft^2',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "presRheom",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: 'psi',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "tempRheom",
            currentUnit: "°F",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: '°F',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "thermalExpansionPressure",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: 'psi',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "compressibilityTemperature",
            currentUnit: "°F",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Temperature",
            unit: '°F',
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	geothermalProfile: [
        {
            colName: "tvd",
            currentUnit: "ft",
            decimalPrecision: 1,
            quantity: "Depth",
            unit: "ft",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        },
        {
            colName: "temperture",
            currentUnit: "°F",
            decimalPrecision: 1,
            quantity: "Temperature",
            unit: "°F",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        }
    ],

	// Hydraulics Outputs
	circulatingTimes: [
        {
            colName: "surfaceToBitTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: { si: 'min', api: 'min' }
        },
        {
            colName: "bottomsUpTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: { si: 'min', api: 'min' }
        },
        {
            colName: "totalTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: { si: 'min', api: 'min' }
        }
    ],
	pressureDrops: [
        {
            colName: "pressureDrop",
            currentUnit: "psi",
            decimalOption: "Default",
            unit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	circulatingVolumes: [
        {
            colName: "annular",
            quantity: "Volume",
            unit: "bbl",
            currentUnit: "bbl",
            decimalPrecision: 1,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "circulating",
            quantity: "Volume",
            unit: "bbl",
            currentUnit: "bbl",
            decimalPrecision: 1,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "hole",
            quantity: "Volume",
            unit: "bbl",
            currentUnit: "bbl",
            decimalPrecision: 1,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "toolstring",
            quantity: "Volume",
            unit: "bbl",
            currentUnit: "bbl",
            decimalPrecision: 1,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
    ],
	ecdResults: [
        {
            colName: "ecdAtBit",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ppg"
        },
        {
            colName: "ecdAtBottomHole",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ppg"
        },
        {
            colName: "ecdAtShoe",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ppg"
        },
        {
            colName: "ecdAtBitWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ppg"
        },
        {
            colName: "ecdAtBottomHoleWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ppg"
        },
        {
            colName: "ecdAtShoeWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ppg"
        }
    ],
	bitHydraulics: [
        {
          colName: "hsi",
          currentUnit: null,
          decimalOption: "Default",
          decimalPrecision: 3,
          unit: null,
          filterItems: [],
          quantity: null,
          significantDigits: null,
          sortDirection: null
        },
        {
            colName: "hydraulicHP",
            currentUnit: "hp",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"hp",
            filterItems: [],
            quantity: "Power",
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
          colName: "impactForce",
          currentUnit: "lbf",
          decimalOption: "Default",
          decimalPrecision: 2,
          unit:"lbf",
          filterItems: [],
          quantity: "Force",
          significantDigits: 8,
          sortDirection: "ASC"
        },
        {
          colName: "rateAtBit",
          currentUnit: "bpm",
          decimalOption: "Default",
          decimalPrecision: 1,
          unit:"bpm",
          filterItems: [],
          quantity: "Rate",
          significantDigits: 8,
          sortDirection: "ASC"
        },
    ],
	surfaceHydraulics: [
        {
            colName: "description",
            quantity: null,
            unit: null,
            currentUnit: null,
            decimalPrecision: null,
            significantDigits: null,
            filterItems: [],
            sortDirection: "",
            decimalOption: null
        },
        {
            colName: "id",
            quantity: "Diameter",
            unit: "in",
            currentUnit: "in",
            decimalPrecision: 3,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "length",
            quantity: "Depth",
            unit: "ft",
            currentUnit: "ft",
            decimalPrecision: 1,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pressureDrop",
            quantity: "Pressure",
            unit: "psi",
            currentUnit: "psi",
            decimalPrecision: 1,
            significantDigits: 3,
            filterItems: [],
            sortDirection: "",
            decimalOption: "Default"
        },
    ],
    tubularCatalogs:[{
        colName: "Type",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
    }],
    stepRate: 
    [
        {
            colName: "startRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "endRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "rateStep",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        }
    ],
    hydraulicWellChart: [
        {
            colName: "surface",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pressure_annular",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pressure_toolString",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "bypassSub",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "drillBit",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "standpipe",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pumpRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bpm"
        },
        {
            colName: "boosterRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bpm"
        },
        {
            colName: "lastCsMD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "lastCsTVD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "ecdAtBit",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Density",
            unit: 'ppg',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "ecdAtBitWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Density",
            unit: 'ppg',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "bottomHoleMD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "bottomHoleTVD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "ecdAtBottomHole",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Density",
            unit: 'ppg',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "ecdAtBottomHoleWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Density",
            unit: 'ppg',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "hole",
            currentUnit: "bbl",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bbl"
        },
        {
            colName: "toolString",
            currentUnit: "bbl",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bbl"
        },
        {
            colName: "annular",
            currentUnit: "bbl",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bbl"
        },
        {
            colName: "circulating",
            currentUnit: "bbl",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Volume",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bbl"
        },
        {
            colName: "totalTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "ASC"
        },
        {
            colName: "bottomsUpTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Time",
            unit: 'min',
            significantDigits: 8,
            sortDirection: "ASC"
        }
    ],
	tubular: [
        {
            colName: "od",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "md",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "wtPerLen",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        },
        {
            colName: "pipeId",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "length",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Length",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "topMD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        },
        {
            colName: "pressureDrop",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "psi"
        },
        {
            colName: "criticalFlowRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "bpm"
        },
        {
            colName: "hydrostaticPressure",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "psi"
        },
    ],

    tubularCatalogue: [

        {
            colName: "od",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "id",
            currentUnit: "in",
            decimalOption: "Default",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "in"
        },
        {
            colName: "Grade",
            currentUnit: "Unknown",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "Unknown",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "Unknown"
        },

        {
            colName: "wtPerLen",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        },







    ],
    annularHydralics: [
        {
            colName: "holeSize",
            currentUnit: "in",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: "in",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pipeOd",
            currentUnit: "in",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Diameter",
            unit: "in",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "topMd",
            currentUnit: "ft",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: "ft",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "topTvd",
            currentUnit: "ft",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            unit: "ft",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "pressureDrop",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "criticalFlowRate",
            currentUnit: "bpm",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Rate",
            unit: "bpm",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "velocity",
            currentUnit: "ft/min",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Velocity",
            unit: "ft/min",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "criticalVelocity",
            currentUnit: "ft/min",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Velocity",
            unit: "ft/min",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "hydrostaticPressure",
            currentUnit: "psi",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Pressure",
            unit: "psi",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "EcdAtBottom",
            currentUnit: "ppg",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            unit: "ppg",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "EndEcdIncludingCuttings",
            currentUnit: "ppg",
            decimalPrecision: 3,
            filterItems: [],
            quantity: "Density",
            unit: "ppg",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "chienVelocity",
            currentUnit: "ft/min",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Velocity",
            unit: "ft/min",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        },
        {
            colName: "mooreVelocity",
            currentUnit: "ft/min",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Velocity",
            unit: "ft/min",
            significantDigits: 8,
            sortDirection: "",
            decimalOption: "Default"
        }
    ],
    pumpsOn: [
        {
            colName: "staticTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "minRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"bpm",
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "maxRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"bpm",
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "stepRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"bpm",
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "pumpsOnTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 0,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'sec', api: 'sec'}
        },
        {
            colName: "minTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'sec', api: 'sec'}
        },
        {
            colName: "maxTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'sec', api: 'sec'}
        },
        {
            colName: "stepTime",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'sec', api: 'sec'}
        },
        {
            colName: "pumpRate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"bpm",
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "t1TimeToRampUp",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'sec', api: 'sec'}
        },
        {
            colName: "t2TimeToSteadyState",
            currentUnit: "min",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"min",
            filterItems: [],
            quantity: "Time",
            significantDigits: 8,
            sortDirection: "ASC",
            fixedUnit: {si: 'sec', api: 'sec'}
        },
        {
            colName: "MD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"ft",
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "TVD",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"ft",
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],
    jobDashboard: [
        {
            colName: "md",
            currentUnit: "ft",
            decimalOption: "Default",
            decimalPrecision: 1,
            filterItems: [],
            quantity: "Depth",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "ft"
        }
    ],
    porePressure: [
        {
            colName: "tvd",
            currentUnit: "ft",
            decimalPrecision: 1,
            quantity: "Depth",
            unit: "ft",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        },
        {
            colName: "pressure",
            currentUnit: "ppg",
            decimalPrecision: 3,
            quantity: "Density",
            unit: "ppg",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        }
    ],
    fracture: [
        {
            colName: "tvd",
            currentUnit: "ft",
            decimalPrecision: 1,
            quantity: "Depth",
            unit: "ft",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        },
        {
            colName: "value",
            currentUnit: "ppg",
            decimalPrecision: 3,
            quantity: "Density",
            unit: "ppg",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        }
    ],
    stepRateResults: [
        {
            colName: "rate",
            currentUnit: "bpm",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"bpm",
            filterItems: [],
            quantity: "Rate",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "hHP",
            currentUnit: "hp",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"hp",
            filterItems: [],
            quantity: "Power",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "impactForce",
            currentUnit: "lbf",
            decimalOption: "Default",
            decimalPrecision: 1,
            unit:"lbf",
            filterItems: [],
            quantity: "Force",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "hHSI",
            currentUnit: "",
            decimalOption: "Default",
            decimalPrecision: 3,
            unit:"",
            filterItems: [],
            quantity: "unknown",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "surfacePL",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"psi",
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "toolstringPL",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"psi",
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "bitPL",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"psi",
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "annularPL",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"psi",
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "totalPL",
            currentUnit: "psi",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"psi",
            filterItems: [],
            quantity: "Pressure",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "ecdBottom",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"ppg",
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "ecdBottomWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"ppg",
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "ecdShoe",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"ppg",
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
        },
        {
            colName: "ecdShoeWithCuttings",
            currentUnit: "ppg",
            decimalOption: "Default",
            decimalPrecision: 2,
            unit:"ppg",
            filterItems: [],
            quantity: "Density",
            significantDigits: 8,
            sortDirection: "ASC",
        }
    ],
    chemicalProductCatalog: [
        {
            colName: "weight",
            currentUnit: "lb/ft",
            decimalOption: "Default",
            decimalPrecision: 2,
            filterItems: [],
            quantity: "WeightPerLength",
            significantDigits: 8,
            sortDirection: "ASC",
            unit: "lb/ft"
        },
        {
			colName: "volume",
			currentUnit: "bbl",
			decimalOption: "Default",
			decimalPrecision: 1,
			filterItems: [],
			quantity: "Volume",
			significantDigits: 8,
			sortDirection: "ASC",
			unit:"bbl"
		},
        {
            colName: "specificGravity",
            currentUnit: "ppg",
            decimalPrecision: 3,
            quantity: "Density",
            unit: "ppg",
            significantDigits: 8,
            decimalOption: "Default",
            filterItems: [],
            sortDirection: "ASC"
        }
    ],
}
