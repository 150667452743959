import { Pipe, PipeTransform } from '@angular/core';
import { ColumnSettings, FieldLevelUoMSettings } from '../models/columnSettings';

@Pipe({
  name: 'decPrecision',
  pure: true
})
export class FixedDecimalPipe implements PipeTransform {

  transform(colSettings: any, colName?: string): FieldLevelUoMSettings {

    let decimalPrecision: FieldLevelUoMSettings;
    if (colSettings) {
      colSettings.some(col => {
        if (col.colName.toLowerCase() == colName.toLowerCase()) { decimalPrecision = col  
        };
      });
    }
    return decimalPrecision;
  }
  
}
