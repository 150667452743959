import { AppModule } from './app/app.module';
import { AppConfig } from './app/utils/appConfig.class';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Configuration } from 'msal';
import { MsalAngularConfiguration, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { initProgress, myconsole } from './app/utils/utils';

myconsole("TLH", "2021.10.25");
myconsole("isLocal", environment.local);
myconsole("$timerStart", "main.ts");

environment.debug.indexElement = document.getElementById('index');
environment.debug.indexElementText = "Loading source files...";
if (window.location.pathname.toLowerCase() != environment.redirectLogoutUriFolder) {
  initProgress("Initializing appSettings...");
}

// let loc = window.location;
// let win = window !== window.parent && !window.opener;
// myconsole("$iframe", win);

function MSALConfigFactory(): Configuration {
  //var env = environment;
  return {
    auth: {
      clientId: environment.msal.auth.clientId,
      authority: environment.msal.auth.authority,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.redirectLogoutUri,
      navigateToLoginRequestUrl: true,
      validateAuthority: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
    system: {
      navigateFrameWait: 1000, // 500 default
      loadFrameTimeout: 8000,  // 6000 default
      tokenRenewalOffsetSeconds: 1000,
      //allowRedirectInIframe: false
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  const protectedResourceMap: any = environment.api.protectedResourceMap;
  return {
    popUp: false,
    consentScopes: environment.consentScopes,
    protectedResourceMap: protectedResourceMap,
    extraQueryParameters: {}
  };
}

// Msal 2.x
// function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   const protectedResourceMap: any = environment.protectedResourceMap;
//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap: protectedResourceMap
//   };
// }

// Msal 2.x
// function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     authRequest: authRequest,
//     loginFailedRoute: ''
//   };
// }

function bootstrapApp() {
  var req = new XMLHttpRequest();

  req.addEventListener('abort', (res: any) => {
    myconsole('Abort bootstrapApp: ', res);
  })
  req.addEventListener('error', (res: any) => {
    myconsole('Error bootstrapApp: ', res);
  })
  req.addEventListener('load', (res: any) => {
    myconsole('AppSettings url: ', res.target.responseURL);
    if (res.currentTarget.status === 404) {
      environment.debug.bootstrapError = '404 Not Found. Unable to locate file ' + environment.appSettingsFile;
      myconsole("bootstrapError", environment.debug.bootstrapError);
    }
    else {
      const settings = JSON.parse(res.currentTarget.response);
      AppConfig.setEnvironment(settings, '');
      environment.debug.bootstrapError = '';
    }
    //var env = environment;

    if (environment.web.production || false) {
      enableProdMode();
    }

    myconsole("$timerEnd", "main.ts");
    myconsole("$timerStart", "AppModule");
    platformBrowserDynamic([
      { provide: MSAL_CONFIG, useFactory: MSALConfigFactory, },
      { provide: MSAL_CONFIG_ANGULAR, useFactory: MSALAngularConfigFactory, },
    ]).bootstrapModule(AppModule)
      .then(ref => {
        myconsole("bootstrapModule", ref)
      })
      .catch(err => {
        console.error(err)
      });
  });

  myconsole('AppSettings file: ', environment.appSettingsFile);
  req.open('GET', environment.appSettingsFile);
  req.send(null);
}

bootstrapApp();
