import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
//import { Observable } from 'rxjs/Rx';
//import { Observable } from 'rxjs';
import { UtilitiesService } from '../../core/services/utilitiesservice.service';

@Component({
  selector: 'app-notificationbar',
  templateUrl: './notificationbar.component.html',
  styleUrls: ['./notificationbar.component.scss']
})
export class NotificationbarComponent implements OnInit {
  errorType: string;
  showNotificationBar: boolean = false;
  notificationMessage: string;
  public subscription: Subscription;
  public timer: Observable<any>;
  constructor(
    public utilitiesService$: UtilitiesService
  ) { }

  ngOnInit() {
    this.showNotificationBar = false;
    this.utilitiesService$.getTheNotificationBar().subscribe(notification => {
      this.errorType = notification["type"];
      this.notificationMessage = notification["message"];

      if (notification["timeSpan"]) {
        this.setTimer(notification["timeSpan"]);
      } else {
        this.setTimer(2000);
      }
    });
  }

  public ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  public setTimer(timeSpan: number) {
    this.showNotificationBar = true;
    this.timer = timer(timeSpan);
    this.subscription = this.timer.subscribe(() => {
      this.showNotificationBar = false;
    });
  }
}
