<div class="dropdown" #syncDropdownRef>
  <button class="dropbtn" (click)="toggleMenu()"  [ngClass]="isExpand ? 'bg-color' : ''">
    <div class="name">
      <span class="body-text text-uppercase">{{'job.dataSynchronization'| translate}}</span>
      <div class="ml-3" [ngClass]="isExpand ? 'menu-chevron-up-icon' : 'menu-chevron-down-icon'"></div>
    </div>
  </button>
  <div class="dropdown-content" *ngIf="isExpand">
    <a (click)="navigateJobSync()" class="p-3 d-block align-middle">
      <label class="body-text menu-item">{{'job.jobsSync' | translate}}</label>
    </a>
    <a (click)="navigateCatalogSync()" class="p-3 d-block align-middle">
      <label class="body-text menu-item">{{'job.catalogsSync' | translate}}</label>
    </a>
  </div>
</div>