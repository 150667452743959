<div class="container xjob-padding mt-0 mb-0">
  <div class="container-fluid card-panels">
    <div class="row">
      <div class="col-12">
        <div class="h1 page-name">{{'job.catalogsSync'|translate}}</div>
      </div>
    </div>

    <div *ngIf="syncing; else dataTmpl" class="loading-icon-wellProfile">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
    <ng-template #dataTmpl>
      <div class="main-content">
        <p class="mt-2">{{'localVersion' | translate}}:  {{catalogSyncVersion.localVersion}}</p>
        <p class="mt-4">{{'cloudVersion' | translate}}: {{catalogSyncVersion.latestVersion}}</p>
      </div>
    </ng-template>
    <div class="footer">
      <button [ngClass]="catalogSyncVersion.updatesAvailable? 'bttn bttn-primary' : 'bttn bttn-primary-inactive'"
      [disabled]="!catalogSyncVersion.updatesAvailable || syncing" (click)="catalogSync()"
        type="button">{{'sync'|translate}}</button>
    </div>
  </div>
</div>
