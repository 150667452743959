export class Quantity {
  name: string;
  quantity: string;
  selectedUnit: string;
  units: string[]
}

export class UnitSystem {
  //_id: string;
  unitSystemUserId: string;
  unitSystemId: string;
  name: string;
  quantities: Quantity[];
  jobIds: string[];
  //jobId: string;
  isActive: boolean;
}

export class SetActiveUnitSystem {
  userId: string;
  unitSystemId: string;
  jobId: string;
}

