<div class="container xjob-padding mt-0 mb-0">
    <div class="container-fluid card-panels">
      <!-- title-->
      <div class="row">
        <div class="col-12">
          <div class="h1 page-name">{{'project.projectsDashboard'|translate}}</div>
        </div>
      </div>

      <div class="dashboard-final-details">
          <div class="dashboard-counter">
            <label class="small">{{projectCount}} {{'project.projects'|translate}}</label>
            <button class="bttn-rectangle bttn-tertiary" style="margin-left:18px;"
              (click)="openAddJobDashboardModal()">
              <span class="icon-unit-systems-add-icon add-icon-position"></span>
              <p class="default add-button">{{'job.'|translate}}</p>
            </button>
          </div>
        <div class="p-0 contain-toggle">
          <div>
            <span class="switch-label">{{'job.showAll'|translate}}</span>
  
            <label class="switch switch-icon">
              <input type="checkbox" name="settingsTheme" (change)="switchView();"
                [(ngModel)]="isShowAll">
              <span class="slider round"></span>
            </label>
  
            <span class="switch-label pl-1">{{'job.showRecent'|translate}}</span>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <app-list-view-filter 
          (rowSelected)="cardClick($event)" 
          [headers]="headers"
          [filterable]="isShowAll"
          [sortable]="isShowAll"
          [api] = "jobsApi"
          [apiQuery] = "jobsQuery">
        </app-list-view-filter>
      </div>
    </div>
</div>

<app-project-info-modal 
  [show]="showInfo" 
  [packet]="baroidProjectInfo" 
  (close)="onModalClose()" 
  class="no-padding">
</app-project-info-modal>