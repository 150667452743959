import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { JobService } from 'src/app/job/job.service';
import { ColumnSettingsInfo } from '../../core/services/columnSettings';
import { getLocalStorage, setLocalStorage, removeLocalStorage, LOCAL_STORAGE_KEY } from '../../utils/localstorage';
import { DEBOUNCE_TIME_AUTO_SAVE, LIMITATION, WellStatus } from 'src/app/utils/constants';
import { UomConversionService } from 'src/app/core/services/uom-conversion.service';
import { WellsService } from 'src/app/core/services/wells/wells.service';
import { Well } from 'src/app/core/models/well.model';
import { WellprofileService } from 'src/app/core/services/common/wellprofile.service';
import * as LS from 'src/app/utils/localstorage';
import { SettingsService } from 'src/app/core/services/settings.service';
import { ValidatorsEx } from 'src/app/core/services/validators-extend.class';
import { ACTION_TYPE, PAGES } from 'src/app/shared/enums/enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-well',
  templateUrl: './create-well.component.html',
  styleUrls: ['./create-well.component.scss']
})
export class CreateWellComponent implements OnInit, OnDestroy {

  public readonly TYPE_ON_SHORE = "On-Shore";
  public limitName = LIMITATION.INPUT_NAME;
  public isFormNotValid: boolean = false;
  public isReadonlyWaterDepth = true;
  public wellColSetting: any;
  public states: any;
  public countries: any;

  public promises: Promise<any>[] = []
  public messages: string[] = [];
  public componentDestroyed$: Subject<boolean> = new Subject();

  public addNewWellForm = new FormGroup({
    name: new FormControl()
  });
  public creating: boolean = false;

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public _utilities: UtilitiesService,
    public _uomConversion: UomConversionService,
    public _wellProfile: WellprofileService,
    public _wells: WellsService,
    public _job: JobService,
    public _setting: SettingsService
  ) {
  }

  ngOnInit() {
    this.checkBeforeLoadData();
    this.wellColSetting = ColumnSettingsInfo.wellData;
    this.createForm();
    this.onWellTypeChanged();
    this.autoSaveToCache();
    this.loadDataWell();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  get valueFormWaterDepth() {
    return this.addNewWellForm.get('waterDepth');
  }

  get waterDepthCol() {
    return this.wellColSetting.find(item => item.colName === 'waterDepth') || {};
  }

  get isWellNameHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['name'] && this.addNewWellForm.controls['name'].errors && this.isFormNotValid;
  }

  get isWaterDepthHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['waterDepth'] && this.addNewWellForm.get('waterDepth').errors && this.isFormNotValid;
  }

  get isStudDateHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['studDate'] && this.addNewWellForm.controls['studDate'].errors && this.isFormNotValid;
  }

  get isOperatorHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['operator'] && this.addNewWellForm.controls['operator'].errors && this.isFormNotValid;
  }

  get isTimezoneHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['timezone'] && this.addNewWellForm.controls['timezone'].errors && this.isFormNotValid;
  }

  get isPurposesWellHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['purposesWell'] && this.addNewWellForm.controls['purposesWell'].errors && this.isFormNotValid;
  }

  get isRegionHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['region'] && this.addNewWellForm.controls['region'].errors && this.isFormNotValid;
  }

  get isCountryHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['country'] && this.addNewWellForm.controls['country'].errors && this.isFormNotValid;
  }

  get nameControl() { return this.addNewWellForm && this.addNewWellForm.get('name'); }
  get wellTypeControl() { return this.addNewWellForm && this.addNewWellForm.get('wellType'); }
  get waterDepthControl() { return this.addNewWellForm && this.addNewWellForm.get('waterDepth'); }
  get studDateControl() { return this.addNewWellForm && this.addNewWellForm.get('studDate'); }
  get operatorControl() { return this.addNewWellForm && this.addNewWellForm.get('operator'); }
  get purposesWellControl() { return this.addNewWellForm && this.addNewWellForm.get('purposesWell'); }
  get regionControl() { return this.addNewWellForm && this.addNewWellForm.get('region'); }
  get countryControl() { return this.addNewWellForm && this.addNewWellForm.get('country'); }
  get stateControl() { return this.addNewWellForm && this.addNewWellForm.get('state'); }
  get timezoneControl() { return this.addNewWellForm && this.addNewWellForm.get('timezone'); }

  get purposesWellValue() { return this.purposesWellControl && this.purposesWellControl.value; }
  get isPurposesWellSelected() { return this.purposesWellValue && this.purposesWellValue.length > 0; }

  get isStateHasErrors() {
    return this.addNewWellForm && this.addNewWellForm.controls['state'] && this.addNewWellForm.controls['state'].errors && this.isFormNotValid;
  }

  private createForm() {
    this.addNewWellForm = this.formBuilder.group({
      name: ['New Well', ValidatorsEx.requiredText()],
      wellType: ['On-Shore', Validators.required],
      waterDepth: [0],
      studDate: ['', [Validators.required, ValidatorsEx.limitDate()]],
      operator: ['', Validators.required],
      numAPI: "000000000000",
      purposesWell: ['', Validators.required],
      region: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      latitude: 0,
      longitude: 0,
      timezone: [this._setting.selectedTimeZone ? this._setting.selectedTimeZone : "GMT-06:00 Central Time",
                  Validators.required],
      status: environment.local ? WellStatus.Inactive : WellStatus.Planning
    });
  }

  private checkBeforeLoadData() {
    let jobDataCached = getLocalStorage(LOCAL_STORAGE_KEY.Data.Job.CreateNewJob);
    if (!jobDataCached || (jobDataCached && !jobDataCached.isValid)) {
      this.router.navigate(['jobs/newjob/jobprofile']);
    }
  }

  private loadDataWell() {
    let wellCached = getLocalStorage(LOCAL_STORAGE_KEY.Data.Well.CreateNewWell);
    if (wellCached) {
      let waterDepthVal = this._uomConversion.calculateValueDisplay(this.waterDepthCol, wellCached.waterDepth.value, true, false);
      this.addNewWellForm.patchValue({
        ...wellCached,
        waterDepth: waterDepthVal
      });

      if (wellCached.region) {
        this.onSelectRegion(wellCached.region, true);
        if (wellCached.country) {
          this.getStates(wellCached.country);
        } else {
          this.addNewWellForm.controls.state.setValue("");
        }
      } else {
        this.addNewWellForm.controls.country.setValue("");
        this.addNewWellForm.controls.state.setValue("");
      }
    }
  }

  private getStates(countryName) {
    this.promises = [];
    this.promises.push(this._wellProfile.loadWellHeaderStates(countryName, false));

    Promise.all(this.promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          this.states = data[0];
        } else {
          this.states = [];
        }
      },
        err => {
          this.states = [];
          this.addNewWellForm.controls.state.setValue("");
        });
  }

  onSelectRegion(regionName: string, initLoad: boolean) {
    if(!initLoad){
      this.countries = [];
      this.states = [];
      this.addNewWellForm.controls.country.setValue("");
      this.addNewWellForm.controls.state.setValue("");
    }
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.RegionsCountries + "-" + regionName;
    let regionsCountries = LS.getLocalStorage(key);
    if (regionsCountries && regionsCountries.length > 0) {
      this.countries = regionsCountries;
      return;
    }
    this.promises = [];
    this.promises.push(this._wellProfile.loadWellHeaderCountriesByRegion(regionName, false));

    Promise.all(this.promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          this.countries = data[0];
        } else {
          this.countries = [];
        }
      },
        err => {
          this.countries = [];
          this.addNewWellForm.controls.countries.setValue("");
        });
  }

  onSelectCountry(countryName: string) {
    this.getStates(countryName);
    this.addNewWellForm.controls.state.setValue("");
  }

  public onWellTypeChanged() {
    this.addNewWellForm.get('wellType').valueChanges.subscribe((value) => {
      if (Object.is(value, this.TYPE_ON_SHORE)) {
        this.isReadonlyWaterDepth = true;
        this.valueFormWaterDepth.disable({ emitEvent: false });

        this.valueFormWaterDepth.setValue(0);
        this.valueFormWaterDepth.setValidators([Validators.required]);
      } else {
        this.isReadonlyWaterDepth = false;
        this.valueFormWaterDepth.enable({ emitEvent: false });

        this.valueFormWaterDepth.setValidators([Validators.required, ValidatorsEx.greaterThanZero()]);
      }
      this.valueFormWaterDepth.updateValueAndValidity({ emitEvent: false });
    });
  }


  private autoSaveToCache() {
    this.addNewWellForm.valueChanges.pipe(debounceTime(DEBOUNCE_TIME_AUTO_SAVE)).pipe(takeUntil(this.componentDestroyed$)).subscribe(
      data => {
        if (this.addNewWellForm.dirty) {
          let waterDepthVal = data.waterDepth ? data.waterDepth : 0;
          const waterDepth = this._uomConversion.convertToAPIUnit(this.waterDepthCol, waterDepthVal);
          setLocalStorage(LOCAL_STORAGE_KEY.Data.Well.CreateNewWell, { ...data, waterDepth });
        }
      });
  }

  public goBackCreate() {
    this.router.navigate(['jobs/newjob/jobprofile']);
  }

  public cancelCreateJob() {
    this.router.navigate([PAGES.JobsDashboard]);
  }

  public createJob() {
    if(this.creating) { return; }

    if (this.addNewWellForm.invalid) {
      this.isFormNotValid = true;
      return;
    }

    const newJobCached = getLocalStorage(LOCAL_STORAGE_KEY.Data.Job.CreateNewJob);
    const newWellCached = getLocalStorage(LOCAL_STORAGE_KEY.Data.Well.CreateNewWell);

    if (newJobCached && newWellCached) {
      this.creating = true;
      const jobId = UUID.UUID();
      if(!environment.local) {
        newJobCached.baralogixId = UUID.UUID();
      }

      delete newJobCached.isValid;
      const jobPayload = {
        ...newJobCached,
        jobId, wellName: newWellCached.name,
        spudDate: newWellCached.studDate,
        region: newWellCached.region,
        country: newWellCached.country,
        timezone: newWellCached.timezone
      };

      const wellPayload = new Well({
        ...newWellCached,
        jobId,
        wellId: UUID.UUID(),
        nameLegal: newWellCached.name,
        statusWell: newWellCached.status,
        purposesWell: newWellCached.purposesWell,
        dTimSpud: newWellCached.studDate,
      });

      this.promises = []
      this.messages = [];
      this.messages.push("Create New Job");
      this.messages.push("Create New Well");

      let dataNewJob = this._job.createOrUpdateJobAsync(jobPayload);
      let dataNewWell = this._wells.createOrUpdateWellAsync(wellPayload);
      this.promises.push(dataNewJob);
      this.promises.push(dataNewWell);

      this._utilities.promiseAll(this.promises, this.messages, ACTION_TYPE.CREATE).then(
        success => {
          this.creating = false;

          if (success) {
            removeLocalStorage(LOCAL_STORAGE_KEY.Data.Job.CreateNewJob);
            removeLocalStorage(LOCAL_STORAGE_KEY.Data.Well.CreateNewWell);
            this.router.navigate(['/job', jobId, 'jobprofile']);
          }
        },
      );
    }
  }
}
