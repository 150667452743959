import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MockApiService } from './api_mock.service';
import { MsalService } from '@azure/msal-angular';
//import { Router } from '@angular/router';
import { ErrorMessageService } from './error-message.service';
import { environment } from 'src/environments/environment';
import * as LS from 'src/app/utils/localstorage';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    public request;
    public mockhandler;

    constructor(
        //public router: Router,
        public _error: ErrorMessageService,
        public _auth: MsalService,
        public _mockapi: MockApiService,
        protected injector: Injector) {
    }

    // protected get _mockapi() {
    //     return this.injector.get(MockApiService);
    // }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if we have a mock handler for the request, use it else proceed
        this.mockhandler = this._mockapi.get_mock_handler(req);
        if (this.mockhandler) {
            return this._mockapi.api_mock_response(this.mockhandler);
        }

        this.request = req;
        var assets = this.request.url.includes('/assets/');
        return next.handle(req).pipe(
            catchError(errorResponse => {
                if (errorResponse) {
                    let url: string;
                    let errMsg: string;
                    let errDesc: string;
                    let errCode: number;

                    // ensure HttpErrorResponse object
                    if (errorResponse instanceof HttpErrorResponse) {
                        errDesc = errorResponse.message || JSON.stringify(errorResponse.error);
                    } else {
                        if (errorResponse) {
                            errDesc = errorResponse.message || errorResponse.toString();
                        }
                        errorResponse = new HttpErrorResponse({ status: 0, statusText: errDesc, url: this.request.url, error: true });
                        if (errorResponse instanceof TypeError) errorResponse.name = "TypeError";
                    }
                    errCode = errorResponse.status;
                    errMsg = errorResponse.error;

                    // filter out api valid responses of no data
                    if (errMsg != 'No records could be found.'  && !assets) {
                        errMsg = "Status Code: " + errorResponse.status;
                        errMsg = errMsg + `\nStatus Text: ${errorResponse.statusText || ''}`;
                        errMsg = errMsg + `\nError Type: ${errorResponse.name || ''}`;
                        errMsg = errMsg + `\nMessage: ${errDesc}`;

                        //if (errorResponse.status != 200) { }
                        console.log("ErrorInterceptor", errorResponse, typeof errorResponse, errMsg);
                        if (!assets && errMsg && (errMsg.toLowerCase().includes("token") || errMsg.toLowerCase().includes("login"))) {
                            LS.clearMsalTokenStorage();
                            //this.handleAuthError(errorResponse);
                            this._auth.acquireTokenSilent({ scopes: environment.consentScopes })
                            .then((response: any) => {
                                if (response.idToken) {
                                  let token = response.idToken.rawIdToken;
                                  //let token = response.accessToken;
                                  LS.setLocalStorageString(LS.LOCAL_STORAGE_KEY.Token.AD, token);
                                }
                              })
                              .catch(error => {
                                console.log('interceptor error', error);
                              })
                              .finally(() => {
                                var x = 1;
                              });
                  
                              //this._auth.acquireTokenRedirect({ scopes: environment.consentScopes });
                        }
                        else if (errMsg && (errMsg.toLowerCase().includes("resolve endpoints"))) {
                            this._auth.loginRedirect();
                        }
                        else {
                            var showError = false;
                            // don't show 400x or 500x errors unless an asset request (api returns these for constraint violations)
                            // only show error page for status code 0 or assets requests
                            // don't show if already in error state
                            if (!this._error.isError && (assets || errorResponse.status === 0)) {
                                showError = true;
                            }
                            if (showError) {
                                this._error.setErrorPageMessage(errMsg);
                            }
                        }
                    }
                    return throwError(errorResponse);
                }
            })
        );
    }

}

export const HttpErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};
