//Models

export class WellBoreDiagramModel {
  staticDiagramData: DiagramData[] = new Array<DiagramData>();
  temporalDiagramData: TemporalDiagramData = new TemporalDiagramData();
  wellData: WellData = new WellData();
  constructor() { }
}

export class PolygonModel {
  /*
   * name of the polygon
   */
  name?: string;

  /*
 * type of the polygon
 */
  tooltip?: string;

  /*
   * The style associated with the polygon
   * This can be a string containing a macro defined in polygon-macros.json or
   * an eCharts style object
   */
  style: string | object;

  /*
   * An array of points that define the polygon
   */
  pointSet: Array<point>;

  /*
   * A string value that can be used to reference the polygon in the eCharts API
   */
  id?: string;

  /*
   * A numeric value which determines the back to front display order of the polygons.
   * Polygons with a higher z value are places on top of polygons with a lower z value.
   */
  z?: number;

  /**
   * This constructor can be used to create an object with its properties initalized 
   * from the object passed as a parameter.  
   * @param params an object where the keys names match the properties of the 
     * IPolygonModel interface
   */
  constructor(params?: IPolygonModel) {
    this.name = params && params.name;
    this.tooltip = params && params.tooltip;
    this.style = params && params.style;
    this.pointSet = params && params.pointSet;
    this.id = params && params.id;
    this.z = params && params.z;
  };
}

export class WellData {
  /*
   * x-axis Label
   */
  diaLabel: string;

  /*
   * Maximum diamter value - maximum x-axis value
   */
  diaMax: number;

  /*
   * Minimum diameter value - minimum x-axis value
   */
  diaMin: number;

  /*
   * Unit of Measure of the diameter
   */
  diaUOM: string;

  /*
   * y-axis label
   */
  mdLabel: string;

  /*
   * Maximum Measured Depth Value - maximum y-axis value
   */
  mdMax: number;

  /*
   * Minimum Measured Depth Value - minimum y-axis value
   */
  mdMin: number;

  /*
   * Measured Depth Unit of Measure
   */
  mdUOM: string;
}

export class TemporalDiagramData {
  [timeStep: number]: DiagramData;
}

export class DiagramData {
  EarthModel?: Array<IPolygonModel>;
  OuterStrings?: Array<IPolygonModel>;
  OuterStringsFluids?: Array<IPolygonModel>;
  ToolString?: Array<IPolygonModel>;
  ToolStringFluids?: Array<IPolygonModel>;
}

//Interfaces

export interface IPolygonModel {
  /*
   * name of the polygon
   */
  name?: string;

  /*
   * tooltip
   */
  tooltip?: string;
  /*
   * The style associated with the polygon
   * This can be a string containing a macro defined in polygon-macros.json or
   * an eCharts style object
   */
  style: string | object;

  /*
   * An array of points that define the polygon
   */
  pointSet: Array<point>;

  /*
   * A string value that can be used to reference the polygon in the eCharts API
   */
  id?: string;

  /*
   * A numeric value which determines the back to front display order of the polygons.
   * Polygons with a higher z value are places on top of polygons with a lower z value.
   */
  z?: number;
}

export interface ITooltipModel {
  od?: IUoMValueModel;
  id?: IUoMValueModel;
  grade?: IUoMValueModel;
  weight?: IUoMValueModel;
  holeSize?: IUoMValueModel;
  topMd?: IUoMValueModel;
  endMd?: IUoMValueModel;
}

export interface IUoMValueModel {
  uom: string;
  value: string;
}

//Types 

type point = [number, number];
