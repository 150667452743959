import { ACTION_TYPE, DATE_TIME_FORMAT, SORT_TYPE } from './../shared/enums/enums';
import { DateTimeService } from './../core/services/date-time.services';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { JobService } from '../job/job.service';
import * as LS from 'src/app/utils/localstorage';
import * as OB from 'src/app/utils/objecter';
import { SettingsService } from '../core/services/settings.service';
import { UnitSystemsService } from '../core/services/unitsystems.service';
import { DialogInput, DialogResult } from '../shared/components/dialog/dialog.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DialogModalService } from '../core/services/dialogModal.service';
import { PAGINATION_PROPERTIES, SAVE_DATA } from '../utils/constants';
import { CatalogsManagementService } from '../core/services/catalogs/catalogs-management.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CatalogSetup } from './catalog-setup/catalog-setup.component';
import { ColumnSettingsInfo } from '../core/services/columnSettings';

@Component({
  selector: 'app-catalogs-management',
  templateUrl: './catalogs-management.component.html',
  styleUrls: ['./catalogs-management.component.scss']
})

export class CatalogsManagementComponent implements OnInit, OnDestroy {
  public catalogs = [];
  public catalogsView = [];
  public counter: number;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public pageLoaded = false;
  public pageSize = PAGINATION_PROPERTIES.ITEMS_PER_PAGE;
  public showCatalogSetup: boolean = false;
  public catalogSetup: CatalogSetup;
  public currentVersion: string = "0";
  public hasChilrenUpdate: boolean = false;
  public headers = [
    { title: 'Catalog Name', uom: false, key: "name", type: 'text', sort: SORT_TYPE.ASC},
    { title: 'Modification By', uom: false, key: "modifiedBy", type: 'text' },
    { title: 'Modification Date', uom: false, key: "modificationDate", type: 'text' },
    { title: '', uom: false, icon: true, key: "editIcon" }
  ];
  public syncUpConfirmation: DialogInput = {
    data: {
      body: this._utilities.getTranslationNow("catalogSyncConfirmation")
    },
    buttons: {
      cancel: true,
      ok: true
    }
  };
  private catalogUpdateConfirmMessage = this._utilities.getTranslationNow("catalogUpdateConfirmation");
  public updateVersionMessage: DialogInput = {
    data: {
      body: this.catalogUpdateConfirmMessage
    },
    buttons: {
      cancel: true,
      ok: true
    }
  };

  public keys = {
    latestCatalogVersion: "latestCatalogVersion",
  }

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService,
    public _job: JobService,
    public _settings: SettingsService,
    public _dialogModal: DialogModalService,
    public _catalogsManagement: CatalogsManagementService,
    public _ngxSmartModal: NgxSmartModalService
  ) {
  }

  ngOnInit() {
    this.loadCatalogsTable();
    this.getLatestVersion();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public loadCatalogsTable() {
    this._utilities.showSpinner(true);
    this._catalogsManagement.getCollectionInfo().subscribe(res => {
      this.catalogs = res;
      res = this.recalulateModifier(res);
      this.formatData(res);
      this.catalogsView = res.filter(x => !x.hidden);
      this.counter = this.catalogsView.length;
      this._utilities.showSpinner(false);
    });
  }

  public formatData(data) {
    for(let item of data) {
      item.modificationDate = this._dateTime.utcToTimezone(item.modificationDate, null, DATE_TIME_FORMAT.LONG);
    }
  }

  public recalulateModifier(data) {
    return data.map(item => {
      if(item.children) {
        var childItems = data.filter(x => x.root == item.name);
        for(const child of childItems) {
          if(child.modificationDate > item.modificationDate) {
            item.modificationDate = child.modificationDate;
            item.modifiedBy = child.modifiedBy;
          }
        }
      }
      return item;
    });
  }

  public syncUpCatalog() {
    this._dialogModal.showConfirmation(this.syncUpConfirmation).subscribe(res => {
      if(res === DialogResult.Ok) {
        LS.clearCatalogLocalStorage();
        setTimeout(() => {
          window.location.reload();
        }, SAVE_DATA.Toastr_Success);
      }
    });
  }
  
  private getLatestVersion(){
    this._catalogsManagement.getlatestVersion().subscribe(data => {     
        this.currentVersion = data[this.keys.latestCatalogVersion];
      }
    );
  }

  private increaseVersion(version) {
    const versionNum = Number.parseFloat(version);
    const decimalVersion = version.split('.')[1];
    const incNum = 1/Math.pow(10, decimalVersion.length);

    return (versionNum + incNum).toFixed(3);
  }

  public updateCatalogVersion() {
    const newVersion = this.increaseVersion(this.currentVersion);
    this.updateVersionMessage.data.body = this.catalogUpdateConfirmMessage.replace("{currentVersion}", this.currentVersion).replace("{updatedVersion}", newVersion);
    
    this._dialogModal.showConfirmation(this.updateVersionMessage).subscribe(res => {
      if (res === DialogResult.Ok) {
        let payload = { catalogVersion: newVersion };
        const req = this._catalogsManagement.updateCatalogVersion(payload);
        this._utilities.promiseAll([req], ["Version number"], ACTION_TYPE.UPDATE).then(success => {
          if (success) {
            this.currentVersion = newVersion;
          }
        });
      }
    });
  }

  public cardClick(row) {
    if (row && row.tableCellSelected === 'editIcon') {
      let setting: CatalogSetup = {
        name: row.name,
        header: row.header,
        addText: row.addText,
        importText: row.importText,
        collection: row.collection,
        property: row.property,
        columns: row.columns,
        children: row.children,
        type: row.type,
        hierachy: [],
        root: row.root,
        isPureArray: row.isPureArray,
        deleteConfirmation: row.deleteConfirmation,
        unitColSettings: row.unitColSettings ? ColumnSettingsInfo[row.unitColSettings] : null
      }

      this.catalogSetup = setting;
      this.showCatalogSetup = true;
    }
  }

  public openChildren(event) {
    const {parentSetting, data} = event;

    this.showCatalogSetup = false;
    setTimeout(() => {
      this.catalogSetup = this.catalogs.find(x => x.name == parentSetting.children);
      const primaryCol = parentSetting.columns.find(x=> x.isPrimary == true);
      if(primaryCol) {
        const path = primaryCol.path ?? primaryCol.key;
        const hierachy = parentSetting.hierachy.concat([{
          key: primaryCol.key,
          value: OB.getValue(data, path),
          screen: parentSetting.name
        }]);

        this.catalogSetup.hierachy = hierachy;
      }
      this.showCatalogSetup = true;
    }, 0);
  }

  public cancel(event) {
    const { hasUpdate } = event;
    this.showCatalogSetup = false;

    if(this.catalogSetup.hierachy.length > 0) {
      const oldHierachy = this.catalogSetup.hierachy;
      const lastIndex = this.catalogSetup.hierachy.length -1;
      const backScreen = this.catalogs.find(x => x.name == oldHierachy[lastIndex].screen);
      backScreen.hierachy = oldHierachy;
      backScreen.hierachy.splice(lastIndex, 1);
      this.catalogSetup = backScreen;
      setTimeout(() => {
        this.showCatalogSetup = true;
      }, 0);

      if(hasUpdate) {
        this.hasChilrenUpdate = true;
      }

      return;
    }

    if(hasUpdate || this.hasChilrenUpdate) {
      this.hasChilrenUpdate = false;
      this.loadCatalogsTable();
    }
  }

}
