import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogModalService } from '../core/services/dialogModal.service';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { DialogInput, DialogResult } from 'src/app/shared/components/dialog/dialog.component';
import { ACTION_TYPE, TOASTER_TYPE } from '../shared/enums/enums';
import { takeUntil } from 'rxjs/operators';
import * as LS from 'src/app/utils/localstorage';
import { CatalogSyncVersion } from '../core/models/catalog.model';
import { SAVE_DATA } from '../utils/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-catalogs-transfer',
  templateUrl: './catalogs-transfer.component.html',
  styleUrls: ['./catalogs-transfer.component.scss']
})

export class CatalogsTransferComponent implements OnInit, OnDestroy {

  public catalogSyncVersion: CatalogSyncVersion = new CatalogSyncVersion(null);
  public dialogInput: DialogInput = {
    data: {
      body: "job.catalogsSyncConfirm"
    },
    buttons: {
      ok: true,
      cancel: true
    }
  };
  public syncing: boolean = false;

  public componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private _dialogModal: DialogModalService,
    private _utilities: UtilitiesService
  ) { }

  ngOnInit() {
    //this._utilities.showSpinner(true);
    this._utilities.checkCatalogVersion().pipe(takeUntil(this.componentDestroyed$)).subscribe(
      res => {
          this.catalogSyncVersion = res;
          this._utilities.showSpinner(false);
      },
      err => {
        this._utilities.showSpinner(false);
    }
  )
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public catalogSync() {
    if(this.syncing) { return; }

    if(!environment.web.isOnNetwork) {
      let msg = this._utilities.getTranslationNow('errors.vpnNetwork');
      this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
      return;
    }
    
    if (this.catalogSyncVersion.updatesAvailable) {
      this._dialogModal.showConfirmation(this.dialogInput).pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
        if (result == DialogResult.Ok) {
          this.syncing = true;
          let req = this._utilities.updateCatalogsLocal();
          this._utilities.promiseAll([req], ['Sync-Catalog'], ACTION_TYPE.UPDATE).then(success => {
            if (success) {
              LS.clearCatalogLocalStorage();
              setTimeout(() => {
                window.location.reload();
              }, SAVE_DATA.Toastr_Success)
            }
            this.syncing = false;
          });
        }
      });
    }
  }
}
