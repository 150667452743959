import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DATE_TIME_FORMAT } from 'src/app/shared/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor(public datePipe: DatePipe) {}

  public utcToTimezone(utcDate: string | number | Date, timezone?: string, format = DATE_TIME_FORMAT.ISO) : string {
    
    if (!utcDate) return "";

    if (timezone) {
      return this.datePipe.transform(utcDate, format, timezone.split(' ')[0]);
    }

    return this.datePipe.transform(utcDate, format);
  }
}