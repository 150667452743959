<div class="container job-padding mt-0">
  <div class="row mx-0 mb-4 pt-5">
    <div class="h1 page-name">
      <span>{{'createNewJob'|translate}}</span>
    </div>
  </div>
  <div class="row mb-3 step-section col-md">
    <div class=" h5-gray mb-2">{{'step'|translate}} 2 OF 2</div>
  </div>
  <div class="row">
    <div class="col-md px-0">
      <div class="mt-0 px-3">
        <form [formGroup]="addNewWellForm">
          <div class="row">
            <div class="col-md-4 mb-3">
              <label class="default comp-label">
                {{'well.name'|translate}}</label>
              <input formControlName="name" [maxlength]="limitName" class="input-field" type="text"
                [ngClass]="{'input-danger': isWellNameHasErrors}" />
                <div *ngIf="isWellNameHasErrors">
                  <p *ngIf="nameControl.errors.required" class="small error-text">
                    {{'errors.canNotBeEmpty' | translate}}
                  </p>
                </div>
            </div>
            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'wellType'|translate}}</label>
              <div class="radio-field">
                <input formControlName="wellType" type="radio" id="on-shore" value="On-Shore"  name="wellType"
                   /><label for="on-shore"
                  class="mr-4">On-Shore</label>
                <input formControlName="wellType" type="radio" id="off-shore" value="Off-Shore" name="wellType"
                  /><label for="off-shore">Off-Shore</label>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'wellWaterDepth'|translate}}
                <app-uom-label [columnSettings]="wellColSetting" [fieldIdentifier]="'waterDepth'"></app-uom-label>
              </label>
              <input 
                formControlName="waterDepth" class="input-field" type="number"
                [readonly]="isReadonlyWaterDepth"
                [ngClass]="{'input-danger': isWaterDepthHasErrors}"
                appUomPrecision 
                [fixedDecimals]="wellColSetting | decPrecision:'waterDepth'"
                [attr.data-bk]
                [pageLoaded]="true"
                [attr.data-full-number]="addNewWellForm?.controls?.waterDepth?.value" />

              <div *ngIf="isWaterDepthHasErrors">
                <p *ngIf="waterDepthControl.errors.greaterThanZero" class="small error-text" >
                  {{ "errors.mustBeGreaterThanZero" | translate}}
                </p>
                <p *ngIf="waterDepthControl.errors.required" class="small error-text">
                  {{'errors.canNotBeEmpty' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'spudDate'|translate}}</label>
              <input formControlName="studDate" id="spudDate" class="input-field" type="date" max='9999-12-30' required
              [ngClass]="{'input-danger': isStudDateHasErrors}" />
              <div *ngIf="isStudDateHasErrors">
                <p *ngIf="studDateControl.errors.required" class="small error-text">
                  {{'enter.validdate' | translate}}
                </p>
                <p *ngIf="studDateControl.errors.limitDate" class="small error-text">
                  {{'errors.limitDate' | translate}}
                </p>
              </div>
            </div>



            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'operator'|translate}}</label>
              <select formControlName="operator" class="select-dropdown"
                [ngClass]="{'input-danger': isOperatorHasErrors}">
                <option *ngFor="let item of _utilities.catWellOperators" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isOperatorHasErrors">
                <p *ngIf="operatorControl.errors.required" class="small error-text">
                  {{'select.operator' | translate}}
                </p>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'wellPurpose'|translate}}</label>
              <p-multiSelect
                formControlName="purposesWell"
                [options]="_utilities.catWellPurpose"
                [showHeader]="isPurposesWellSelected"
                optionLabel="opt"
                class='multiselect-custom'
                [filter]="false" [showToggleAll]="false"
                [ngClass]="{'multiselect-custom-error': isPurposesWellHasErrors}">
                <p-header>
                  <span class="cus-item cus-item-value" *ngFor="let selected of purposesWellValue">
                    {{selected}}
                  </span>
                </p-header>
                <ng-template let-value pTemplate="selectedItems">
                  <span class="cus-item cus-item-value" [ngClass]="{'input-danger': isPurposesWellHasErrors}"
                    *ngFor="let selected of purposesWellValue">
                    {{selected}}
                  </span>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <div class="cus-item">
                    <div>{{type.value}}</div>
                  </div>
                </ng-template>
              </p-multiSelect>
              
              <div *ngIf="isPurposesWellHasErrors">
                <p *ngIf="purposesWellControl.errors.required" class="small error-text">
                  {{'select.wellpurpose' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'region'|translate}}</label>
              <select formControlName="region" (change)="onSelectRegion($event.target.value, false)" class="select-dropdown"
                [ngClass]="{'input-danger': isRegionHasErrors}">
                <option *ngFor="let item of _utilities.catGeoRegions" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isRegionHasErrors">
                <p *ngIf="regionControl.errors.required" class="small error-text">
                  {{'select.region' | translate}}
                </p>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'country'|translate}}</label>
              <select formControlName="country" (change)="onSelectCountry($event.target.value)" class="select-dropdown"
                [ngClass]="{'input-danger': isCountryHasErrors}">
                <option default value=""></option>
                <option *ngFor="let item of countries" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isCountryHasErrors">
                <p *ngIf="countryControl.errors.required" class="small error-text">
                  {{'select.country' | translate}}
                </p>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'state/Province'|translate}}</label>
              <select formControlName="state" class="select-dropdown"
                [ngClass]="{'input-danger': isStateHasErrors}">
                <option default value=""></option>
                <option *ngFor="let item of states" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isStateHasErrors">
                <p *ngIf="stateControl.errors.required" class="small error-text">
                  {{'select.state' | translate}}
                </p>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <label class="default comp-label">{{'timezone'|translate}}</label>
              <select formControlName="timezone" class="select-dropdown"
                [ngClass]="{'input-danger': isTimezoneHasErrors}">
                <option *ngFor="let item of _utilities.catGeoTimeZones" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="isTimezoneHasErrors">
                <p *ngIf="timezoneControl.errors.required" class="small error-text">
                  {{'select.timezone' | translate}}
                </p>
              </div>
            </div>
          </div>
        </form>

        <hr class="form-border mb-0">
        <div class="row float-left mt-4">
          <button (click)="goBackCreate()" class="bttn bttn-secondary">
            <label><img src="assets/images/icons/ic-chevron-left.svg" class="mr-2 mb-1" /></label>
            <span class="h5 back-pad">{{'buttons.back'|translate}}</span>
          </button>
        </div>
        <div class="row pull-right mt-4">
          <button (click)="cancelCreateJob()" value="{{'buttons.cancel'|translate}}"
            class="bttn bttn-secondary">{{'buttons.cancel'|translate}}</button>
          <button (click)="createJob()" class="bttn bttn-primary">{{'create job'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>