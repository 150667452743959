import { environment } from 'src/environments/environment'; 
export const Config = {
    AppInsightKey: environment.web.app_Insight_Key,
    APIUrlMock: environment.api.API_URL_Mock,
    APICloud: false,
    APIUrlToken: environment.api.API_URL_Local + environment.api.API_PORTS_Local.tokens + environment.api.token,
    APIUrlTokenUser: environment.api.API_URL_Local + environment.api.API_PORTS_Local.tokens + environment.api.tokenUser,

    APIUrl: environment.api.API_URL_Local,
    APIUrlCore: environment.api.API_URL_Local + environment.api.API_PORTS_Local.core,
    APIUrlVolumes: environment.api.API_URL_Local + environment.api.API_PORTS_Local.volumes,
    APIUrlCatalogs: environment.api.API_URL_Local + environment.api.API_PORTS_Local.catalogs,
    APIUrlPackaging: environment.api.API_URL_Local + environment.api.API_PORTS_Local.packaging,
    APIUrlPlatform: environment.api.API_URL_Local + environment.api.API_PORTS_Local.platform,
    APIUrlRheology: environment.api.API_URL_Local + environment.api.API_PORTS_Local.rheology,
    APIUrlHydraulics: environment.api.API_URL_Local + environment.api.API_PORTS_Local.hydraulics,
    APIUrlSurvey: environment.api.API_URL_Local + environment.api.API_PORTS_Local.survey,
    APIUrlDownhole: environment.api.API_URL_Local + environment.api.API_PORTS_Local.downhole,

    WebAPIUrl: environment.api.API_URL_Cloud,
    WebAPIUrlCore: null,
    WebAPIUrlVolumes: null,
    WebAPIUrlCatalogs: null,
    WebAPIUrlPackaging: null,
    WebAPIUrlPlatform: null,
    WebAPIUrlRheology: null,
    WebAPIUrlHydraulics: null,
    WebAPIUrlSurvey: null,
    WebAPIUrlDownhole: null,
}
