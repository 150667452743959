<ngx-smart-modal #confirmPopup [identifier]="MODAL_IDENTIFIERS.CONFIRM_POPUP" [dismissable]="false" [closable]="false"
  (onOpen)="toggleModal()" (onCloseFinished)="toggleModal()"
  customClass="modal-content modal-custom no-padding confirmNewTabData confirm-modal-container">
  <ng-container *ngIf="showModal">
    <div class="confirmationOnNewTab confirm-modal-inner">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <img class="closeIcon" />
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="!messageValue" class="default whitespace">{{message|translate}}</p>
        <p *ngIf="messageValue" class="default whitespace">{{message|translate:{'value': messageValue|translate} }}</p>
      </div>
      <div class class="modal-footer">
        <button class="bttn bttn-secondary mx-1" (click)="onCancel()">{{'buttons.cancel'|translate}}</button>
        <button class="bttn bttn-primary mx-1" (click)="onConfirm()">{{'buttons.ok'|translate}}</button>
      </div>
    </div>
  </ng-container>
</ngx-smart-modal>