import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { MODAL_IDENTIFIERS } from '../../enums/enums';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit, OnDestroy {
  @Input() message: string;
  @Input() messageValue: string;
  @Output() handleConfirm = new EventEmitter<any>();

  @ViewChild("confirmPopup") confirmPopup: NgxSmartModalComponent;
  public MODAL_IDENTIFIERS = MODAL_IDENTIFIERS;
  public showModal = false;

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.closeModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  closeModal() {
    this.confirmPopup.close();
  }

  onCancel() {
    this.confirmPopup.close();
  }

  onConfirm() {
    if (this.handleConfirm) {
      this.handleConfirm.emit();
    }
    this.confirmPopup.close();
  }
}
