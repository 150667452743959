import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MockApiService {

  constructor(
    public _api: ApiService,
  ) {
  }

  public api_mock_response = (mockhandler): Observable<HttpEvent<any>> => {
    return mockhandler ? mockhandler() : null;
  };

  public get_mock_handler = (req: HttpRequest<any>): any => {
    //let { url, method, headers, body } = req;
    if (req.url.startsWith("/")) return null;
    let url = req.url.toLowerCase();
    let mockEndpoints = this.endpoints[req.method];
    if (mockEndpoints) {
      for (let key of Object.keys(mockEndpoints)) {
        if (key === url || url.endsWith(key)) {
          return mockEndpoints[key];
        }
      }
    }
    return null;
  };

  public async readAssetsDataAsync(filename: string) {
    return await this._api.get("/assets/data/" + filename).toPromise()
      .then(
        data => {
          return data;
        })
      .catch(err => {
        return err;
      });
  }

  // public xreadAssetsData(filename: string) {
  //   this._api.get("/assets/data/" + filename)
  //     .subscribe(
  //       data => {
  //         return data;
  //       },
  //       err => {
  //       });
  // }

  public readAssetsData(filename: string) {
    let root = "/assets/data/";
    var request = new XMLHttpRequest();
    request.open('GET', root + filename, false);
    request.send(null);
    //let stat = request.status;
    let resp = request.response;
    return resp;
  }

  public async getMockDataAsync(path: string) {
    let resp = await this.readAssetsDataAsync(path);
    return this.sendResponse(resp);
  }

  public getMockData(path: string): Observable<HttpResponse<any>> {
    let resp = this.readAssetsData(path);
    //let resp = this.xreadAssetsData(path);
    return this.sendResponse(resp);
  }

  public sendResponse(data) {
    if (typeof data == 'string') {
      data = JSON.parse(data);
    }
    return of(new HttpResponse({ status: 200, body: data }))
  }

  // endpoint handlers
  getQuantityUnits = () => {
    return this.getMockData("/api_catalogs/quantity_units.json");
  };
  getRegions = () => {
    return this.getMockData("/api_catalogs/region/regions.json");
  };
  getRegionsCountries = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/_collections/regions.json");
  };
  getCountriesInRegion1 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_asia_pacific.json");
  };
  getCountriesInRegion2 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_middle_east_north_africa.json");
  };
  getCountriesInRegion3 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_latin_america.json");
  };
  getCountriesInRegion4 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_eurasia.json");
  };
  getCountriesInRegion5 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_europe_subsahara_africa.json");
  };
  getCountriesInRegion6 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_gulf_of_mexico.json");
  };
  getCountriesInRegion7 = (request: HttpRequest<any>) => {
    return this.getMockData("/api_catalogs/region/countries_north_america.json");
  };

  // endpoints, in lower case for more reliable matching
  endpoints = {
    GET: {
      //[environment.catalogs.quantityunits.toLowerCase()]: this.getQuantityUnits,
      // [environment.api.API_URL_Local + environment.api.API_PORTS_Local.core + environment.catalogs.wellCatalogs.regions.toLowerCase()]: this.getRegions,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions': this.getRegions,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1': this.getRegionsCountries,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/asia pacific/countries': this.getCountriesInRegion1,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/middle east / north africa/countries': this.getCountriesInRegion2,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/latin america land/countries': this.getCountriesInRegion3,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/eurasia/countries': this.getCountriesInRegion4,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/europe / sub-sahara africa/countries': this.getCountriesInRegion5,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/gulf of mexico/countries': this.getCountriesInRegion6,
      // 'http://localhost:5001/api/baroid/regioncatalog/v1/regions/north america land/countries': this.getCountriesInRegion7,
    },
    // POST: {
    //   '/api/Baroid/RegionCatalog/v1/regions': null,
    //   }

  }
}
