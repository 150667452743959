import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PAGES } from 'src/app/shared/enums/enums';

@Injectable()
export class LocalGuard implements CanActivate {
  constructor(public router: Router) { }

  canActivate(): boolean {
    if (environment.local) return true;
    this.router.navigate([PAGES.JobsDashboard]);
    return false;
  }
}
