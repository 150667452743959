<div class="dropdown" #syncDropdownRef>
  <button class="dropbtn" (click)="toggleMenu()"  [ngClass]="isExpand ? 'bg-color' : ''">
    <div class="name">
      <span class="body-text text-uppercase">{{'admin.title'| translate}}</span>
      <div class="ml-3" [ngClass]="isExpand ? 'menu-chevron-up-icon' : 'menu-chevron-down-icon'"></div>
    </div>
  </button>
  <div class="dropdown-content" *ngIf="isExpand">
    <!-- <a (click)="navigateRoles()" class="p-3 d-block align-middle">
      <label class="body-text menu-item">{{'admin.roles' | translate}}</label>
    </a> -->
    <a (click)="navigateUserRoles()" class="p-3 d-block align-middle">
      <label class="body-text menu-item">{{'admin.userRoles' | translate}}</label>
    </a>
    <a (click)="navigateCatalogManagement()" class="p-3 d-block align-middle">
      <label class="body-text menu-item">{{'admin.catalogManagement' | translate}}</label>
    </a>
  </div>
</div>