<div class="container-fluid card-body" [ngClass]="showBaseOilGraph ? 'd-none':''">
  <div class="row">
    <div class="col-12">
      <div class="h1 page-name">{{header|translate}}</div>
    </div>
  </div>
  <div *ngIf="subHeader" class="row sub-header-catalog">
    {{subHeader}}
  </div>
<div class="content-body">
  <app-catalog-import [previewMode]="previewMode" [setting]="setting" [form]="importForm" (onSubmit)="submitImport($event)" (onClear)="clearImport()"
    (onApprove)="approveImport()">
  </app-catalog-import>
  <div class="row">
    <div class="d-md-block mt-2 col-md-12">
        <div *ngIf="!previewMode" class="link-primary float-right mr-0" (click)="openImportModal()">{{importText|translate}}
        </div>
        <app-table-toolbar
          [showToolbar]="!previewMode"
          [event$] = "toolbarEvent$"
          [columns] ="columns"
          [data]="table"
          [sharing]="toolbarSharing"
          [topRowForm] = "form"
          [addText] = "addText"
          [pagination] ="true"
          [filter] ="true"
          [colSettings]= "unitColSettings"
          [hideConfigBtn] = "!hasChildren"
          (onReset) = "resetTopRow()"
          (onConfig) = "handleConfig($event)"
          (onAdd) ="handleAdd($event)"
          (onEdit)="handleEdit($event)"
          (onDelete)="handleDelete($event)">
        </app-table-toolbar>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!-- <div class ="add-border"></div> -->
      <div>
        <button (click)="cancel()" class="bttn bttn-secondary float-right mr-0 mt-4">
          {{'buttons.back'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid card-body" *ngIf="showBaseOilGraph">
  <div class="row">
    <div class="col-12">
      <div class="h1 page-name">{{baseOilGraph.baseFluidName}}</div>
    </div>
  </div>
<div class="content-body">
  <div class="row">
    <div class="d-md-block mt-2 col-md-12">
        <app-base-oil-chart [constants]="baseOilGraph" class="baseOilGraph">
        </app-base-oil-chart>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div>
        <button (click)="cancelBaseOil()" class="bttn bttn-secondary float-right mr-0 mt-4">
          {{'buttons.back'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>