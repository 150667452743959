import { HttpClient } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../shared/shared.module';
import { JobsItemComponent } from './menu-bar/jobs-item/jobs-item.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { UsersComponent } from './menu-bar/users/users.component';
import { MatIconModule } from '@angular/material/icon';
import { DataSyncComponent } from './menu-bar/data-synchonization/data-sync.component';
import { AdministratorMenuComponent } from './menu-bar/administrator/administrator.component';

const routes: Routes = [
  { path: '', component: MenuBarComponent }
];

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}
@NgModule({
  imports: [
    MatIconModule,
    MenubarModule,
    RouterModule.forChild(routes),
    SharedModule,
    ToastModule,
  ],
  declarations: [
    JobsItemComponent,
    MenuBarComponent,
    UsersComponent,
    DataSyncComponent,
    AdministratorMenuComponent
  ],
  providers: [
  ],
  exports: [
    MenuBarComponent
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
