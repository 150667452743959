import { DataLogicService } from './../data-logic.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { Config } from 'src/app/core/services/config';
import * as LS from 'src/app/utils/localstorage';

@Injectable({
  providedIn: 'root'
})
export class AdditionalFluidPropertiesCatalogService {
  constructor(
    public dataLogic: DataLogicService
  ) { }

  /**
   * Get Additional Fluid Properties Catalog
   */
  getAdditionalFluidPropertiesCatalog() {
    const url = Config.APIUrlCatalogs + environment.catalogs.getAdditionalFluidPropertiesCatalog
    const cachePath = LS.LOCAL_STORAGE_KEY.Catalogs.FluidProperties.AdditionalFluidProperties
    return this.dataLogic.get(url, cachePath);
  }
}
