import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnSettingsInfo } from '../core/services/columnSettings';
import { DateTimeService } from '../core/services/date-time.services';
import { LocalTokenService } from 'src/app/core/services/localtoken.service';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { JobService } from '../job/job.service';
import { DATE_TIME_FORMAT, TOASTER_TYPE } from '../shared/enums/enums';
import { PAGINATION_PROPERTIES, SAVE_DATA } from '../utils/constants';
import  * as LS from 'src/app/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-jobs-transfer',
  templateUrl: './jobs-transfer.component.html',
  styleUrls: ['./jobs-transfer.component.scss']
})

export class JobsTransferComponent implements OnInit, OnDestroy {
  public componentDestroyed$: Subject<boolean> = new Subject();
  public pageSize = PAGINATION_PROPERTIES.ITEMS_PER_PAGE;
  public colSettings = ColumnSettingsInfo.jobDashboard;
  public listJobs = [];
  public syncing: boolean = false;
  public pageLoaded: boolean = false;
  public jobLoaded: boolean = false;
  public isLocal: boolean = true;
  public headers = [
    { title: 'job.name', uom: false, key: "jobName", type: 'text' },
    { title: 'job.jobNumber', uom: false, key: "jobNumber", type: 'text' },
    { title: 'job.jobType', uom: false, key: "jobTypes", type: 'array' },
    { title: 'rig.name', uom: false, key: "rigName", type: 'text' },
    { title: 'well.name', uom: false, key: "wellName", type: 'text' },
    { title: 'spudDate', uom: false, key: "spudDate", type: 'text' },
    { title: 'job.createdBy', uom: false, key: "jobOwner", type: 'text' },
    { title: 'job.status', uom: false, key: "jobStatus", type: 'text' },
    { title: 'region', uom: false, key: "region", type: 'text' },
    { title: 'country', uom: false, key: "country", type: 'text' },
    { title: 'import', uom: false, link: true, key: "imexLink" }
  ];

  
  get jobCount() { return this.listJobs.length };

  constructor(
    public _auth: LocalTokenService,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService,
    public _job: JobService
  ) {
  }

  ngOnInit() {
    this._utilities.showSpinner(true);
    this.pageLoaded = false;
    this.loadJobs();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public toggleChanged() {
    this.loadJobs();
  }

  public clickRow(row) {
    if (row.tableCellSelected === 'imexLink' && row.imexLink === 'Import') {
      if(this.syncing) {return;}

      if(!environment.web.isOnNetwork) {
        let msg = this._utilities.getTranslationNow('errors.vpnNetwork');
        this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
        return;
      }

      this.syncing = true;
      this._utilities.showSpinner(true);
      const payLoad = {
        jobId: row.jobId,
        isImport: row.imexLink === 'Import'
      }
      let req = this._job.transferJobAsync(payLoad);
      this._utilities.promiseAll([req], ["Job Transfer"]).then(res => {
        LS.clearJobLocalStorage();
        this.syncing = false;
        this._utilities.showSpinner(false);
      });
    }
  }

  private loadJobs() {
    this.listJobs = [];
    this.jobLoaded = false;
    let req = this.isLocal ? this._job.getJobsAsync() : this._job.getJobsCloudAsync();
    req.then((res) => {
      this.listJobs = res.filter(this.filterStatus).sort(this.sortName);
      this.convertData(this.listJobs);
      this.pageLoaded = true;
      this.jobLoaded = true;
      this._utilities.showSpinner(false);
    });
  }

  private convertData(jobs) {
    for (let job of jobs) {
      job.spudDate = job.spudDate ? this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG) : '';
      if (!this.isLocal) {
        job['imexLink'] = 'Import';
      }
    }
  }

  private filterStatus(x) {
    return x.jobStatus &&
    (x.jobStatus.toLowerCase() == 'active' ||
      x.jobStatus.toLowerCase() == 'inactive' ||
      x.jobStatus.toLowerCase() == 'complete');
  }

  private sortName(a, b) {
    return a ? a.jobName.localeCompare(b.jobName) : -1;
  }
  
}
