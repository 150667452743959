export const chartDataOptions = {
    legendPositions: [
        {
            "text": "Left",
            "value": "left"
        },
        {
            "text": "Right",
            "value": "right"
        },
        {
            "text": "Center",
            "value": "center"
        }
    ],
    fontColors: [
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Yellow', value: 'yellow' },
        { label: 'Orange', value: 'orange' },
        { label: 'White', value: 'white' },
        { label: 'Pink', value: 'pink' }
    ],
    fontSizes: [
        {
            label: "10px",
            value: "10"
        },
        {
            label: "11px",
            value: "11"
        },
        {
            label: "12px",
            value: "12"
        },
        {
            label: "13px",
            value: "13"
        },
        {
            label: "14px",
            value: "14"
        },
        {
            label: "15px",
            value: "15"
        },
        {
            label: "16px",
            value: "16"
        },
        {
            label: "17px",
            value: "17"
        },
        {
            label: "18px",
            value: "18"
        }
    ],
    cars: [
        { label: "Select", value: "" },
        { label: 'Red', value: 'Red' },
        { label: 'Green', value: 'Green' },
        { label: 'Yellow', value: 'Yellow' },
        { label: 'Orange', value: 'Orange' },
        { label: 'White', value: 'White' },
        { label: 'Pink', value: 'Pink' }
    ]
}

export class chartOptionViewModel {
    public text_Title: string;
    public isShow_Title: boolean;
    public fontSize_Title: string;
    public color_Title: string;

    public isShow_Legend: boolean;
    public fontSize_Legend: string;
    public color_Lengend: string;
    public position_Lengend: string;

    public fontSize_Axis: string;
    public color_Axis: string;

    public label_XAxis: string;
    public min_XAxis: number;
    public max_XAxis: number;

    public label_YAxis: string;
    public min_YAxis: number;
    public max_YAxis: number;

    public xAxis: Array<any>;
    public yAxis: Array<any>;

    public colorType: string;
    public backgroundColor1: string;
    public backgroundColor2: string;
    public chartColor1: string;
    public chartColor2: string;
    public backgroundColorAngle: number;
    public colorAngle: number;

    constructor() {
        this.text_Title = '';
        this.isShow_Title = true;
        this.fontSize_Title = '';
        this.color_Title = '';
        this.isShow_Legend = true;
        this.fontSize_Legend = '';
        this.color_Lengend = '';
        this.position_Lengend = '';
        this.fontSize_Axis = '';
        this.color_Axis = '';
        this.label_XAxis = '';
        this.min_XAxis = null;
        this.max_XAxis = null;
        this.label_YAxis = '';
        this.min_YAxis = null;
        this.max_YAxis = null;
        this.xAxis = [];
        this.yAxis = [];

        this.colorType = '';
        this.backgroundColor1 = '';
        this.backgroundColor2 = '';
        this.chartColor1 = '';
        this.chartColor2 = '';
        this.backgroundColorAngle = 0;
        this.colorAngle = 0;
        this.color_Lengend = '';
    }
}