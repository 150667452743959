import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SystemService } from '../services/system.service';

@Injectable()
export class LocalInternetGuard implements CanActivate {
  constructor(
    //public _utilities: UtilitiesService,
    //public router: Router,
    public _system: SystemService
  ) {
  }
  //private isConnected;

  canActivate() {
    return this._system.isOnInternet(true);
  }

// canActivate() {
//     return this._system.checkConnection().toPromise()
//       .then((res) => {
//         this.isConnected = environment.web.isOnInternet;
//         return this.isConnected;
//       })
//       .catch((error) => {
//         this.isConnected = false;
//         return false;
//       })
//       .finally(() => {
//         if (!this.isConnected) {
//           this.router.navigate([PAGES.JobsDashboard]);
//           let msg = "You must be connected to the internet to access that page.";
//           this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
//         }
//       });
//   }
}
