import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SettingsService } from '../../../core/services/settings.service';
import { JobService } from '../../../job/job.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chart-base',
  templateUrl: './chart-base.component.html',
  styleUrls: ['./chart-base.component.scss']
})
export class ChartBaseComponent implements OnInit {
  @Input() public chartOptions: any;
  @Input() public chart3D: boolean;
  @Input() public initOpts: any;
  @Output() public chartInstance = new EventEmitter<any>();
  public settingsTheme: string;
  public theme: string;
  public fontColor: string;
  public legendBackgroundColor: string;
  public colorPalette: any;
  public baseChartOptions: any;
  public jobId: any;
  public baseInitOpts: any;
  public publicdetectEventChanges: boolean = true;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public yAxisName : string = '';
  public xAxisName : string = '';

  constructor(
    public settings: SettingsService,
    public jobService: JobService,
    public router: Router
  ) {
    if (this.initOpts) {
      this.baseInitOpts = this.initOpts;
    }
  }

  ngOnInit() {
    this.loadJobData();
    this.settings.getTheTheme().pipe(takeUntil(this.componentDestroyed$)).subscribe(val => {
      this.settingsTheme = val;
      this.updateChart();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges() {
    this.updateChart();
  }

  public loadJobData() {
    this.jobService.jobId$.pipe(takeUntil(this.componentDestroyed$)).subscribe(jobId => {
      this.jobId = jobId;
    });
  }

  updateChart() {
    //Load the Chart Theme
    if (this.settingsTheme == environment.lightTheme) {
      this.theme = "adaslight";
      this.fontColor = "black";
      this.legendBackgroundColor = "white";
      this.colorPalette = ['#50E3C2', '#0081A9', '#A9D321', '#F57322', '#D058E9', '#FFBB08', '#A10506', '#6C04FE', '#05E5DD', '#FFA58D', '#E3170A', '#B7D6EF', '#0052B1', '#1CF888', '#4A00B1'];
    }
    else {
      this.theme = "adasdark";
      this.fontColor = "white";
      this.legendBackgroundColor = '#2B2E2E';
      this.colorPalette = ['#47CBAE', '#0081A9', '#A9D321', '#F57322', '#D058E9', '#FFBB08', '#A10506', '#5603CB', '#1ABB9C', '#FFA58D', '#E3170A', '#345B78', '#0052B1', '#19DE7a', '#4A00B1'];
    }
    if (this.chart3D) {
      this.baseChartOptions = {
        color: this.colorPalette,
        xAxis3D: {
          nameTextStyle: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 14
          },
          axisLabel: {
            textStyle: {
              color: this.fontColor,
              fontFamily: "Arial",
              fontSize: 10
            }
          }
        },
        yAxis3D: {
          nameTextStyle: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 14
          },
          axisLabel: {
            textStyle: {
              color: this.fontColor,
              fontFamily: "Arial",
              fontSize: 10
            }
          }
        },
        zAxis3D: {
          nameTextStyle: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 14
          },
          axisLabel: {
            textStyle: {
              color: this.fontColor,
              fontFamily: "Arial",
              fontSize: 10
            }
          }
        },
        grid3D: {
          axisLabel: {
            textStyle: {
              color: this.fontColor
            }
          },
          axisPointer: {
            lineStyle: {
              color: this.fontColor
            }
          }
        },

      };
    }
    else {
      this.baseChartOptions = {
        color: this.colorPalette,
        xAxis: {
          nameTextStyle: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 14,
            scale: 'true'
          },
          // Axis numbers along the line styling
          axisLabel: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 10,
            show: true
          },

          //The Axis line
          axisLine: {
            lineStyle: {
              color: "#2b2e2e",
              type: "solid"
            }
          },

          //Gridlines on the Chart
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "#272728"
            }
          }
        },
        yAxis: {
          nameTextStyle: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 14,
            scale: 'true'
          },
          // Axis numbers along the line styling
          axisLabel: {
            color: this.fontColor,
            fontFamily: "Arial",
            fontSize: 10,
            show: true
          },

          //The Axis line
          axisLine: {
            lineStyle: {
              color: "#2b2e2e",
              type: "solid"
            }
          },

          //Gridlines on the Chart
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "#272728"
            }
          }
        },
        legend: {
          backgroundColor: this.legendBackgroundColor,
          pageIconColor: this.fontColor,
          textStyle: {
            color: this.fontColor
          }
        },
        tooltip: {
          textStyle:{
            fontSize: 12
          },
          padding:[5,5,5,5]
        }
      };
    }
    this.mergeDeep(this.baseChartOptions, this.chartOptions);
    this.updateAxisName();
  }

  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.mergeDeep(target, ...sources);
  }

  onChartInit(e: any) {
    this.chartInstance.emit(e);
  }

  onChartClick(params: any) {
    if (params.componentType === 'radar' && params.name && this.jobId) {
      this.router.navigate(['/job/' + this.jobId + '/activity-feed/event-indicator/' + params.name]);
    } else {
      return;
    }
  }

  updateAxisName(){
    if (this.baseChartOptions?.yAxis?.nameGap === 'auto') {
      this.yAxisName = this.baseChartOptions.yAxis.name;
      this.baseChartOptions.yAxis.name = '';  
    }

    if (this.baseChartOptions?.xAxis?.nameGap === 'auto') {
      this.xAxisName = this.baseChartOptions.xAxis.name;
      this.baseChartOptions.xAxis.name = '';
    }
  }
}
