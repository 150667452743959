import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { WellBoreDiagramModel, PolygonModel, IPolygonModel, ITooltipModel, IUoMValueModel, DiagramData } from '../../shared/models/wellprofile-diagram-model';
import { WellboreModel } from 'src/app/shared/models/wellboreModel';
import { UomPipe } from 'src/app/shared/pipes/uom.pipe';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';

@Injectable({
  providedIn: 'root'
})

export class WellprofileDiagramService {
  public i: number = 0;
  public diameterMargin = 2.5;
  public totalDepthMargin = 1.03;
  public wellboreDiagramModel: WellBoreDiagramModel;
  public maxDia: number; // max hole size of open hole w/ md margin
  public td: number; // max endMD of open hole and casings 
  public maxDiaUoM: string;
  public tdUoM: string;
  // EarthModel
  public earthModelCollection: IPolygonModel[];
  // OuterStrings
  public outerStringsCollection: IPolygonModel[];
  // Toolstrings
  public toolStringCollection: IPolygonModel[];

  public promiseImages: Promise<any>[] = [];

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public uomPipe: UomPipe,
    public _utilities: UtilitiesService
  ) { }

  public buildWellboreDynamicData(wellboreData: WellboreModel): Observable<Object> {
    this.wellboreDiagramModel = new WellBoreDiagramModel();

    // EarthModel
    this.wellboreDiagramModel.staticDiagramData.push(new DiagramData());
    this.wellboreDiagramModel.staticDiagramData[0].EarthModel = new Array<IPolygonModel>();
    this.earthModelCollection = this.wellboreDiagramModel.staticDiagramData[0].EarthModel;

    // OuterStrings
    this.wellboreDiagramModel.staticDiagramData.push(new DiagramData());
    this.wellboreDiagramModel.staticDiagramData[1].OuterStrings = new Array<IPolygonModel>();
    this.outerStringsCollection = this.wellboreDiagramModel.staticDiagramData[1].OuterStrings;

    // OuterStringsFluids
    this.wellboreDiagramModel.staticDiagramData.push(new DiagramData());

    // Toolstring
    this.wellboreDiagramModel.staticDiagramData.push(new DiagramData());
    this.wellboreDiagramModel.staticDiagramData[3].ToolString = new Array<IPolygonModel>();
    this.toolStringCollection = this.wellboreDiagramModel.staticDiagramData[3].ToolString;

    this.i = 0;

    // MD
    this.maxDia = this.calculateMaxDiameter(wellboreData);

    // TD
    this.td = this.calculateTotalDepth(wellboreData.wellHeader.dataRow, wellboreData.openHole.dataRows, wellboreData.casings.dataRows);

    // WellHeader Profile
    if(wellboreData.wellHeader) {
      this.loadWellHeader(wellboreData.wellHeader.columnSettings, wellboreData.wellHeader.dataRow);
    }

    // Rig Profile
    if(wellboreData.rig) {
      this.loadRigProfileWellbore(wellboreData.rig.columnSettings, wellboreData.rig.dataRows);
    }

    // Riser
    this.loadRiserWellbore(wellboreData.riser.columnSettings, wellboreData.riser.dataRows);

    // Open Hole
    this.loadOpenHoleWellbore(wellboreData.openHole.columnSettings, wellboreData.openHole.dataRows);

    // Casings
    this.loadCasingsLinerWellbore(wellboreData.casings.columnSettings, wellboreData.casings.dataRows);

    // Toolstrings
    if (wellboreData.toolstring) {
      this.loadToolString(wellboreData.toolstring.columnSettings, wellboreData.toolstring.dataRows);
    }

    // Well Data
    this.loadWellData();

    let wellboreObj = Object.assign(this.wellboreDiagramModel);
    return of(wellboreObj);
  }

  // Calculate Helper Functions
  public calculateMaxDiameter(wellboreData) {
    let maxDiameter = [];
    if (wellboreData.riser.dataRows !== undefined && wellboreData.riser.dataRows !== null) {
      maxDiameter.push(Math.max.apply(Math, wellboreData.riser.dataRows.map(x => x.od)));
    }
    if (wellboreData.openHole.dataRows !== undefined && wellboreData.openHole.dataRows !== null) {
      maxDiameter.push(Math.max.apply(Math, wellboreData.openHole.dataRows.map(x => x.holeSize)));
    }
    if (wellboreData.casings.dataRows !== undefined && wellboreData.casings.dataRows !== null) {
      maxDiameter.push(Math.max.apply(Math, wellboreData.casings.dataRows.map(x => x.od)));
    }
    return Math.round(Math.max(...maxDiameter)) + this.diameterMargin;
  }

  public calculateTotalDepth(wellHeader, openHoleData, casingsData) {
    if (openHoleData !== undefined && casingsData !== undefined) {
      let maxEndMdOpenHole = Math.max.apply(Math, openHoleData.map(x => x.endMD));
      let maxEndMdCasings = Math.max.apply(Math, casingsData.map(x => x.endMd));
      let waterDepth = wellHeader.waterDepth ? wellHeader.waterDepth : 0;
      let totalDepth = Math.max(maxEndMdOpenHole, maxEndMdCasings, waterDepth);
      let totalDepthMargin = (totalDepth * this.totalDepthMargin).toFixed();
      return +totalDepthMargin;
    }
    else {
      return 0;
    }
  }

  // Tooltip Generator
  public toolTipGenerator(columnSettings, data, componentKey): string {
    let tooltip: ITooltipModel;
    let od: IUoMValueModel;
    let id: IUoMValueModel;
    let grade: IUoMValueModel;
    let weight: IUoMValueModel;
    let holeSize: IUoMValueModel;
    let topMd: IUoMValueModel;
    let endMd: IUoMValueModel;

    if (columnSettings == undefined || data == undefined) {
      return '';
    }
    switch (componentKey) {
      case 'riser':
        od = { uom: this.uomPipe.transform(columnSettings, 'od'), value: data.od ? data.od.toFixed((this._utilities.getColumn(columnSettings, 'od')).decimalPrecision) : 0 };
        id = { uom: this.uomPipe.transform(columnSettings, 'id'), value: data.id ? data.id.toFixed((this._utilities.getColumn(columnSettings, 'id')).decimalPrecision) : 0 };
        topMd = { uom: this.uomPipe.transform(columnSettings, 'topMd'), value: data.topMd ? data.topMd.toFixed((this._utilities.getColumn(columnSettings, 'topMd')).decimalPrecision) : 0 };
        endMd = { uom: this.uomPipe.transform(columnSettings, 'endMd'), value: data.endMd ? data.endMd.toFixed((this._utilities.getColumn(columnSettings, 'endMd')).decimalPrecision) : 0 };
        tooltip = { od: od, id: id, topMd: topMd, endMd: endMd };
        break;
      case 'openhole':
        topMd = { uom: this.uomPipe.transform(columnSettings, 'topMD'), value: data.topMD ? data.topMD.toFixed((this._utilities.getColumn(columnSettings, 'topMD')).decimalPrecision) : 0 };
        endMd = { uom: this.uomPipe.transform(columnSettings, 'endMD'), value: data.endMD ? data.endMD.toFixed((this._utilities.getColumn(columnSettings, 'endMD')).decimalPrecision) : 0 };
        holeSize = { uom: this.uomPipe.transform(columnSettings, 'holeSize'), value: data.holeSize ? data.holeSize.toFixed((this._utilities.getColumn(columnSettings, 'holeSize')).decimalPrecision) : 0 };
        tooltip = { topMd: topMd, endMd: endMd, holeSize: holeSize };
        break;
      case 'casings':
        od = { uom: this.uomPipe.transform(columnSettings, 'od'), value: data.od ? data.od.toFixed((this._utilities.getColumn(columnSettings, 'od')).decimalPrecision) : 0 };
        id = { uom: this.uomPipe.transform(columnSettings, 'id'), value: data.id ? data.id.toFixed((this._utilities.getColumn(columnSettings, 'id')).decimalPrecision) : 0 };
        topMd = { uom: this.uomPipe.transform(columnSettings, 'topMd'), value: data.topMd ? data.topMd.toFixed((this._utilities.getColumn(columnSettings, 'topMd')).decimalPrecision) : 0 };
        endMd = { uom: this.uomPipe.transform(columnSettings, 'endMd'), value: data.endMd ? data.endMd.toFixed((this._utilities.getColumn(columnSettings, 'endMd')).decimalPrecision) : 0 };
        tooltip = { od: od, id: id, topMd: topMd, endMd: endMd };
        break;
      case 'toolstrings':
        od = { uom: this.uomPipe.transform(columnSettings, 'od'), value: data.od ? data.od.toFixed((this._utilities.getColumn(columnSettings, 'od')).decimalPrecision) : 0 };
        id = { uom: this.uomPipe.transform(columnSettings, 'id'), value: data.id ? data.id.toFixed((this._utilities.getColumn(columnSettings, 'id')).decimalPrecision) : 0 };
        endMd = { uom: this.uomPipe.transform(columnSettings, 'md'), value: data.md ? data.md.toFixed((this._utilities.getColumn(columnSettings, 'md')).decimalPrecision) : 0 };
        tooltip = { od: od, id: id, endMd: endMd };
        break;
      case 'earth':
        topMd = { uom: this.uomPipe.transform(columnSettings, 'waterDepth'), value: data.waterDepth ? data.waterDepth.toFixed((this._utilities.getColumn(columnSettings, 'waterDepth')).decimalPrecision) : 0 };
        endMd = { uom: this.uomPipe.transform(columnSettings, 'waterDepth'), value: this.td ? this.td.toFixed((this._utilities.getColumn(columnSettings, 'waterDepth')).decimalPrecision) : '0' };
        tooltip = { topMd: topMd, endMd: endMd };
        break;
      case 'water':
        topMd = { uom: this.uomPipe.transform(columnSettings, 'waterDepth'), value: '0' };
        endMd = { uom: this.uomPipe.transform(columnSettings, 'waterDepth'), value: data.waterDepth ? data.waterDepth.toFixed((this._utilities.getColumn(columnSettings, 'waterDepth')).decimalPrecision) : 0 };
        tooltip = { topMd: topMd, endMd: endMd };
        break;
      default:
        break;
    }
    return this.tooltipStringify(tooltip);
  }

  public tooltipStringify(tooltip: ITooltipModel) {
    if (tooltip == undefined) { return '' };
    let tooltipStringified: string = '';

    if (tooltip.od != undefined) {
      tooltipStringified = tooltipStringified + ` OD: ${tooltip.od.value + ' ' + tooltip.od.uom} <br />`;
    }

    if (tooltip.id != undefined) {
      tooltipStringified = tooltipStringified + ` ID: ${tooltip.id.value + ' ' + tooltip.id.uom} <br />`;
    }

    if (tooltip.grade != undefined) {
      tooltipStringified = tooltipStringified + ` Grade: ${tooltip.grade.value + ' ' + tooltip.grade.uom} <br />`;
    }

    if (tooltip.weight != undefined) {
      tooltipStringified = tooltipStringified + ` Weight: ${tooltip.weight.value + ' ' + tooltip.weight.uom} <br />`;
    }

    if (tooltip.holeSize != undefined) {
      tooltipStringified = tooltipStringified + ` Hole Size: ${tooltip.holeSize.value + ' ' + tooltip.holeSize.uom} <br />`;
    }

    if (tooltip.topMd != undefined) {
      tooltipStringified = tooltipStringified + ` Top: ${tooltip.topMd.value + ' ' + tooltip.topMd.uom} <br />`;
    }

    if (tooltip.endMd != undefined) {
      tooltipStringified = tooltipStringified + ` End: ${tooltip.endMd.value + ' ' + tooltip.endMd.uom} <br />`;
    }

    return tooltipStringified;
  }

  // Render Transforms
  public renderWellboreData(data: any, macros: any) {
    if ((data.staticDiagramData === undefined) || (data.wellData === undefined)) {
      // "ERROR: renderWellboreData input data shape - FIX - throw exception
      return; // cheat for now
    }

    let renderedStaticData: Object = this.renderDiagramData(data.staticDiagramData, macros);
    // This section supports animation and is currently on hold
    // let temporalDiagramDataArray: Array<Object>;
    // if ( data.temporalDiagramData !== undefined ) {
    //   data.temporalDiagramData.ForEach( (timeStepObject: any) => {
    //     const timeStep = timeStepObject.timeStep;
    //     ... this is still WIP and will not be needed for initial implementation
    //   });
    // }
    return (renderedStaticData); // this "WILL" change in the future with the incorporation of animation
  }

  public renderDiagramData(data, macros) {
    let i = 0;
    let imgs = [];
    // possible groups, pre-definition minimizes undefined warnings
    const renderedData = {
      "EarthModel": [],
      "OuterStrings": [],
      "OuterStringsFluids": [],
      "ToolString": [],
      "ToolStringFluids": []
    };

    // the following should probably be refactored using map
    data.forEach(function (groups) {
      const groupsKeys = Object.keys(groups);
      groupsKeys.forEach(groupKey => {
        const renderDataArray = [];
        groups[groupKey].forEach(y => {
          i++;
          if (y.z === undefined) { y.z = i; }
          if (y.id === undefined) { y.id = 'id' + ('0000' + i).slice(-4); }
          if (typeof y.style === 'string' || y.style instanceof String) {
            let result;
            if (result = y.style.match(/^(\{.+\})$/)) {
              y.style = Object.assign({}, macros[result[1]].style);
              (y.style.fill.image !== undefined)
            }
          }
          if (y.style.fill.image_fn !== undefined) {
            imgs.push(
              new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = y.style.fill.image_fn;
                y.style.fill.image = img;
              })
            )
          }
          renderDataArray.push(y);
        });
        renderedData[groupKey] = renderDataArray;
      });
    });
    this.promiseImages = imgs;
    // last step, update data to trigger onCharges in WellboreDiagramComponent
    // this.data = data;
    // this.renderedData = renderedData;
    return (renderedData);
  }

  public loadWellHeader(headerColumnSettings, wellHeaderData) {
    if (wellHeaderData == undefined) { return; }
    let rkbElevation: number = wellHeaderData.rkbElevation;
    let waterDepth: number = wellHeaderData.waterDepth;
    let earthModeltoolTip: string = this.toolTipGenerator(headerColumnSettings, wellHeaderData, 'earth');
    let waterModeltoolTip: string = this.toolTipGenerator(headerColumnSettings, wellHeaderData, 'water');


    let water = new PolygonModel({
      'id': 'id' + ('0000' + ++this.i).slice(-4),
      'z': this.i,
      'tooltip': waterModeltoolTip,
      'name': "Water",
      'style': "{imgWaterLevel}",
      'pointSet': [
        [-this.maxDia, rkbElevation],
        [this.maxDia, rkbElevation],
        [this.maxDia, waterDepth],
        [-this.maxDia, waterDepth]
      ]
    });

    let earth = new PolygonModel({
      'id': 'id' + ('0000' + ++this.i).slice(-4),
      'z': this.i,
      'tooltip': earthModeltoolTip,
      'name': "Earth",
      'style': "{imgEarth}",
      'pointSet': [
        [-this.maxDia, waterDepth],
        [this.maxDia, waterDepth],
        [this.maxDia, this.td],
        [-this.maxDia, this.td]
      ]
    })
    this.earthModelCollection.push(water, earth);
  }

  public loadRigProfileWellbore(rigColumnSettings, rigData) {
    if (rigData == undefined) { return; }
    let rigProfileKBE: number = 0;
    let rkbElevation: number = rigData.rkbElevation;
    let waterDepth: number = rigData.waterDepth;
    let earthModeltoolTip: string = this.toolTipGenerator(rigColumnSettings, rigData, 'earth');

    let airGap = new PolygonModel({
      'id': 'id' + ('0000' + ++this.i).slice(-4),
      'z': this.i,
      'tooltip': earthModeltoolTip,
      'name': "Air Gap",
      'style': "{Air}",
      'pointSet': [
        [-this.maxDia, rigProfileKBE],
        [this.maxDia, rigProfileKBE],
        [this.maxDia, rkbElevation],
        [-this.maxDia, rkbElevation]
      ]
    });

    let water = new PolygonModel({
      'id': 'id' + ('0000' + ++this.i).slice(-4),
      'z': this.i,
      'tooltip': earthModeltoolTip,
      'name': "Water",
      'style': "{imgWater}",
      'pointSet': [
        [-this.maxDia, rkbElevation],
        [this.maxDia, rkbElevation],
        [this.maxDia, waterDepth],
        [-this.maxDia, waterDepth]
      ]
    });

    let earth = new PolygonModel({
      'id': 'id' + ('0000' + ++this.i).slice(-4),
      'z': this.i,
      'tooltip': earthModeltoolTip,
      'name': "Earth",
      'style': "{imgEarth}",
      'pointSet': [
        [-this.maxDia, waterDepth],
        [this.maxDia, waterDepth],
        [this.maxDia, this.td],
        [-this.maxDia, this.td]
      ]
    })
    this.earthModelCollection.push(airGap, water, earth);
  }

  public loadRiserWellbore(riserColumnSettings, riserData) {
    if (riserData == undefined) { return; }

    riserData.forEach(riser => {
      let toolTip: string = this.toolTipGenerator(riserColumnSettings, riser, 'riser');

      let riserOuter = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': "Riser OD",
        'style': "{RiserOuter}",
        'pointSet': [
          [-riser.od, riser.topMd],
          [riser.od, riser.topMd],
          [riser.od, riser.endMd],
          [-riser.od, riser.endMd]
        ]
      });
  
      let riserInner = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': "Riser ID",
        'style': "{RiserInner}",
        'pointSet': [
          [-riser.id, riser.topMd],
          [riser.id, riser.topMd],
          [riser.id, riser.endMd],
          [-riser.id, riser.endMd]
        ]
      });
      this.outerStringsCollection.push(riserOuter, riserInner);
    })
  }

  public loadOpenHoleWellbore(openHoleColumnSettings, openHoleData) {
    if (openHoleData == undefined) { return; }
    openHoleData = openHoleData.sort(x => x.holeSize);
    openHoleData.forEach(openHole => {
      let toolTip: string = this.toolTipGenerator(openHoleColumnSettings, openHole, 'openhole');
      let openHoleModel = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': openHole.holeSize + "\" Open Hole",
        'style': "{OpenHole}",
        'pointSet': [
          [-openHole.holeSize, openHole.topMD],
          [openHole.holeSize, openHole.topMD],
          [openHole.holeSize, openHole.endMD],
          [-openHole.holeSize, openHole.endMD]
        ]
      });
      this.outerStringsCollection.push(openHoleModel);
    });
    // Get UoM Labels for X and Y Axis
    this.maxDiaUoM = this.uomPipe.transform(openHoleColumnSettings, "holeSize");
    this.tdUoM = this.uomPipe.transform(openHoleColumnSettings, "endMD");
  }

  public loadCasingsLinerWellbore(casingsColumnSettings, casingsData) {
    if (casingsData == undefined) { return; }
    // let filteredCasingsData = casingsData.filter(x => x.endMd >= openHole.topMD && x.endMd <= openHole.endMD).sort(firstBy(y => y.topMd));
    let iCasing = 1;
    casingsData.forEach(casing => {
      let toolTip: string = this.toolTipGenerator(casingsColumnSettings, casing, 'casings');
      // OD
      let casingOD = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': casing.description ? casing.description : "" + " - OD",
        'style': "{Casing" + iCasing + "Outer}",
        'pointSet': [
          [-casing.od, casing.topMd],
          [casing.od, casing.topMd],
          [casing.od, casing.endMd],
          [-casing.od, casing.endMd]
        ]
      });
      // ID
      let casingID = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': casing.description ? casing.description : "" + " - ID",
        'style': "{Casing" + iCasing + "Inner}",
        'pointSet': [
          [-casing.id, casing.topMd],
          [casing.id, casing.topMd],
          [casing.id, casing.endMd],
          [-casing.id, casing.endMd]
        ]
      });
      iCasing = ++iCasing > 4 ? 1 : iCasing;
      this.outerStringsCollection.push(casingOD);
      this.outerStringsCollection.push(casingID);
    });
  }

  public loadToolString(toolstringColumnSettings, toolstringData) {
    if (toolstringData == undefined) { return; }
    toolstringData.forEach(toolstring => {
      let toolTip: string = this.toolTipGenerator(toolstringColumnSettings, toolstring, 'toolstrings');
      let toolStringOuter = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': toolstring.description ? toolstring.description : "" + " - OD",
        'style': "{ToolStringOuter}",
        'pointSet': [
          [
            -toolstring.od,
            toolstring.md - toolstring.length
          ],
          [
            toolstring.od,
            toolstring.md - toolstring.length

          ],
          [
            toolstring.od,
            toolstring.md
          ],
          [
            -toolstring.od,
            toolstring.md
          ]
        ]
      });

      let toolStringInner = new PolygonModel({
        'id': 'id' + ('0000' + ++this.i).slice(-4),
        'z': this.i,
        'tooltip': toolTip,
        'name': toolstring.description ? toolstring.description : "" + " - ID",
        'style': "{ToolStringInner}",
        'pointSet': [
          [
            -toolstring.id,
            toolstring.md - toolstring.length
          ],
          [
            toolstring.id,
            toolstring.md - toolstring.length
          ],
          [
            toolstring.id,
            toolstring.md
          ],
          [
            -toolstring.id,
            toolstring.md
          ]
        ]
      });
      this.toolStringCollection.push(toolStringOuter, toolStringInner);
    });

  }

  public loadWellData() {
    let wellData = this.wellboreDiagramModel.wellData;
    wellData.diaLabel = "Diameter";
    wellData.diaMin = -this.maxDia;
    wellData.diaMax = this.maxDia;
    wellData.diaUOM = this.maxDiaUoM;
    wellData.mdLabel = "MD";
    wellData.mdMax = this.td;
    wellData.mdMin = 0;
    wellData.mdUOM = this.tdUoM;
  }

}