import { FIELD_TYPE, SORT_TYPE } from 'src/app/shared/enums/enums';
import { FieldLevelUoMSettings } from 'src/app/shared/models/columnSettings';
import Big from "big.js";

export function isNull(value): boolean {
	return value === undefined || value === null || value === '';
}

export function clone(obj) {
    if(isNull(obj)){
        return obj;
    }
    return JSON.parse(JSON.stringify(obj));
}

export function getObj(obj: any, dataPath: string, fieldType: string = '') {
    if (obj && dataPath) {
        try {
            dataPath = dataPath.replace(/\[(\w+)\]/g, '.$1');
            dataPath = dataPath.replace(/^\./, '');
            var newStr = dataPath.split('.');
            for (let i = 0, n = newStr.length; i < n; ++i) {
                var k = newStr[i];
                if (k in obj) {
                    obj = obj[k];
                } else {
                    if (fieldType === FIELD_TYPE.TEXT) return '';
                    if (fieldType === FIELD_TYPE.NUMBER) return 0;
                    return null;
                }
            }

            return obj;
        } catch (error) {
            console.log('getValueOfObjectByString - error', error);
            if (fieldType === FIELD_TYPE.TEXT) return '';
            if (fieldType === FIELD_TYPE.NUMBER) return 0;
            return null;
        }
    };
}

export function isUomObject(obj: any) {
    return obj && typeof obj === "object" && obj.hasOwnProperty("value") && obj.hasOwnProperty("uom");
}

export function round(value: number, numberOfDecimal: number) {
    const filler = Math.pow(10, numberOfDecimal);
    return Math.round(value * filler) / filler;
}

export function getValue(obj: any, path: string, defaultValue?: any) {
    if (obj && path) {
        try {
            path = path.replace(/\[(\w+)\]/g, '.$1');
            path = path.replace(/^\./, '');
            var newStr = path.split('.');
            for (let i = 0, n = newStr.length; i < n; ++i) {
                var k = newStr[i];
                if (k in obj) {
                    obj = obj[k];
                } else {
                    return defaultValue;
                }
            }

            return obj;
        } catch (error) {
            return defaultValue;
        }
    };
}

export function getMin(arr: Array<any>, path: string) {
    let min = Number.MAX_VALUE;
    let result = null;

    if(arr) {
        for(const item of arr) {
            const val = getValue(item, path);
            if(val < min) {
                min = val;
                result = item;
            }
        }
    }

    return result;
}

export function getMax(arr: Array<any>, path: string) {
    let max = Number.MIN_VALUE;
    let result = null;

    if(arr) {
        for(const item of arr) {
            const val = getValue(item, path);
            if(val > max) {
                max = val;
                result = item;
            }
        }
    }

    return result;
}

export function getMaxNum(arr: Array<any>, path: string) {
    let max = Number.MIN_VALUE;
    let result = 0;

    if(arr) {
        for(const item of arr) {
            const val = getValue(item, path, Number.MIN_VALUE);
            if(val > max) {
                max = val;
                result = val;
            }
        }
    }

    return result;
}

export function createObjIfNotExist(obj: any, dataPath: string, value: any) {
    let props = toArray(dataPath)
    let len = props.length;

    if (len <= 0) {
        return;
    }

    let lastProp = props[len - 1];
    props.splice(len - 1);
    for (let prop of props) {
        if (prop in obj) {
            obj = obj[prop];
        } else {
            obj[prop] = new Object();
            obj = obj[prop];
        }
    }

    if ((lastProp in obj) === false)
        obj[lastProp] = value;
}

export function updateObj(obj: any, dataPath: string, value: any) {
    let props = toArray(dataPath)
    let len = props.length;

    if (len <= 0) {
        return;
    }

    let lastProp = props[len - 1];
    props.splice(len - 1);
    for (let prop of props) {
        if (prop in obj) {
            obj = obj[prop];
        } else {
            obj[prop] = new Object();
            obj = obj[prop];
        }
    }

    obj[lastProp] = value;
}

export function updateObjIfNotNull(obj: any, dataPath: string, value: any) {
    if (isNull(value)) {
        return;
    }
    updateObj(obj, dataPath, value);
}

export function removeObj(obj: any, dataPath: string) {
    let props = toArray(dataPath)
    let len = props.length;

    if (len <= 0) {
        return;
    }

    let lastProp = props[len - 1];
    props.splice(len - 1);
    for (let prop of props) {
        if (prop in obj) {
            obj = obj[prop];
        } else {
            return;
        }
    }

    delete obj[lastProp];
}

export function groupStringArr(arr: Array<string>) {
    let result = [];
    for(const item of arr) {
        if(!result.includes(item)) {
            result.push(item);
        }
    }

    return result;
}

export function sortArr(arr: Array<any>, dataPath, orderType = SORT_TYPE.ASC) {
    if (arr && Array.isArray(arr)) {
        orderType === SORT_TYPE.ASC ? sortASC(arr, dataPath) : sortDESC(arr, dataPath);
    }
}

function sortASC(arr: Array<any>, dataPath: string) {
    arr.sort((current, next) => {
        let valueCurrent = getObj(current, dataPath);
        let valueNext = getObj(next, dataPath);

        if (valueCurrent < valueNext) {
            return -1;
        }
        if (valueCurrent > valueNext) {
            return 1;
        }
        return 0;
    });
}

export function updateObjInArray(array: any[] = [], obj: any, propertyName: string) {
    const index = array.findIndex(item => item[propertyName] === obj[propertyName]);
    const isNull = obj.hasOwnProperty("value") && (obj.value === null || obj.value === "");

    if(isNull && index >= 0) {
        array.splice(index, 1);
    } else if(!isNull) {
        if(index >= 0) {
            array[index] = obj;
        } else {
            array.push(obj);
        }
    }

    return array;
}

export function removeObjInArray(array: any[] = [], obj: any, propertyName: string) {
    const index = array.findIndex(item => item[propertyName] === obj[propertyName]);

    if (index >= 0) {
        array.splice(index, 1);
    }

    return array;
}

export function updateObjUom(colSettings: Array<FieldLevelUoMSettings>, obj) {
    if(obj == null){
        return;
    }

    for(let col of colSettings){
        if(obj.hasOwnProperty(col.colName)){
            obj[col.colName] = {value: Number(obj[col.colName]), uom: col.unit};
        }
    }
}

function sortDESC(arr: Array<any>, dataPath: string) {
    arr.sort((current, next) => {
        let valueCurrent = getObj(current, dataPath);
        let valueNext = getObj(next, dataPath);

        if (valueCurrent > valueNext) {
            return -1;
        }
        if (valueCurrent < valueNext) {
            return 1;
        }
        return 0;
    });
}

function toArray(dataPath: string) {
    return dataPath.split(".");
}

export const arrToObj = (array, key) => {
	if (array && array.length && key) {
		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			};
		}, initialValue);
	}
	return null;
};

export function fixedDecimals (fixedDecimals, value) {
    let displayValue: string;
    if (fixedDecimals.decimalOption == "Default" || fixedDecimals.decimalOption == "Fixed Decimals") {
      displayValue = Number(value).toFixed(fixedDecimals.decimalPrecision);
    }
    if (fixedDecimals.decimalOption == "Significant Digits") {
      displayValue = Number(value).toPrecision(fixedDecimals.significantDigits);
    }
    return displayValue;
}

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) { Object.assign(target, { [key]: {} }); }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function getColumn(colSettings: any, columnName: string): FieldLevelUoMSettings {
    if(isNull(colSettings)) return null;
    return colSettings.find(x => x.colName.toLowerCase() === columnName.toLowerCase());
}

export function removeUom(obj) {    
    if(!isNull(obj.value)) {
        return obj.value;
    }

    return obj;
}

export function compare(expression, a, b) { 
    if(expression == "=") {
        return compareEqual(a, b);
    }

    if(expression == "!=") {
        return compareDiff(a, b);
    }

    return false;
}

export function compareEqual(a, b) {
    if(a == b) {
      return true;
    }

    if(isNull(a) && isNull(b)) {
      return true;
    }

    return false;
}

export function compareDiff(a, b) {

    if(isNull(a) && isNull(b)) {
      return false;
    }

    if(a != b) {
      return true;
    }

    return false;
}

export function defaultValue(type) {
    if(type == FIELD_TYPE.NUMBER) {
      return 0;
    }

    if(type == FIELD_TYPE.TEXT) {
      return "";
    }

    return null;
}

/**
 * Convert a number from scientific notation (for example, 1.23e-3 or 1.23e+3) to decimal notation
 * @param num
 * @private
 */
export function scientificToDecimalString(num: number): string {
  return new Big(num).toFixed();
}
