<p-toast [style]="{marginTop: '50px'}">
  <ng-template let-message pTemplate="message">
    <div class="ui-g ui-fluid">
      <div class="ui-g-3 toast-icon-wrapper">
        <i class="fa fa-exclamation-triangle toast-icon-size"></i>
      </div>
      <div class="ui-g-9 description-wrapper">
        <div class="toast-detail">{{message.summary}}</div>
        <div class="toast-detail">{{message.detail}}<span class="toast-time-detail"> </span></div>
      </div>
    </div>
  </ng-template>
</p-toast>
<div class="col-12 p-0 m-0" class="main-menu-bar">
  <p-menubar [model]="items" styleClass="navbar navbar-expand-sm flex-inline">
    <a class="flex-inline py-0 pl-2 pr-0 m-0 home-icon-wrapper">
      <img class="hal-logo" src="../../../assets/images/hal-logo.PNG">
    </a>
    <span class="nav-divider"></span>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="baralogix-heading pt-2">{{'baralogixBrandName'|translate}}</a>
      </li>
      <li *ngIf="!logoutPage" [routerLink]="['/jobs-dashboard']" class="nav-item nav-link text-uppercase h5 ml-5 mb-0">
        {{'job.jobsDashboard' | translate }}</li>
      <li *ngIf="isLocal && !logoutPage">
        <app-data-sync></app-data-sync>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li *ngIf="!logoutPage && !isLocal && isAdmin">
        <app-administrator></app-administrator>
      </li>
    </ul>
    <ul class="navbar-nav pos-right h-100">
      <li *ngIf="!logoutPage" class="nav-item h-100" id="main-menu-userprofile">
        <app-users-dropdown (userDropDwn)="backgroundChange($event)"></app-users-dropdown>
      </li>
    </ul>
  </p-menubar>
</div>