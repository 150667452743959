import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
//import { UserModel } from '../../models/userModel';
import { UtilitiesService } from '../../services/utilitiesservice.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-dropdown',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']

})
export class UsersComponent implements OnInit {
  public userDrpdwn: boolean = false;
  public userName: string;
  public logoInitials: string = '';

  @Output() public userDropDwn = new EventEmitter<boolean>()
  @ViewChild('usersDropdownRef') public dropdownElemUsers: ElementRef;

  constructor(
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public _utilities: UtilitiesService,
    public authService: MsalService
    ) {
  }

  ngOnInit() {
  }

  get isLocal(): boolean {
    return environment.local;
  }


  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.dropdownElemUsers.nativeElement.contains(targetElement)) {
      this.userDrpdwn = false;
    }
  }

  usersDropdown() {
    this.userDrpdwn = !this.userDrpdwn;
    this.userDropDwn.emit(this.userDrpdwn);
  }

  navigateSettings() {
    this.router.navigate(['/settings']);
    this.userDrpdwn = false;
  }

  logOut() {
    // this._utilities.setUserModelObj(new UserModel());
    this.authService.logout();
  }

}
