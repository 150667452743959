import { UnitSystem } from "src/app/shared/models/uom-models";
import { defaultEnvironment } from "../../../environments/environment.defaults";

export class PostSettings {
    activeTheme: string = defaultEnvironment.darkTheme;
    activeTimezone: string = "";
    //activeUnitSystem: string = "";
    activeUnitSystemId: string = "";
    uomProfile: string = "";
    userId: string = "";
    assignedRole: string = "";
    constructor(params?: Settings) {
        this.activeTheme = params && params.activeTheme;
        this.activeTimezone = params && params.activeTimezone;
        //this.activeUnitSystem = params && params.activeUnitSystem;
        this.activeUnitSystemId = params && params.activeUnitSystemId;
    };
}
export class Settings extends PostSettings {
    defaultLanding: string = "";
    jobChoice?:string;
    landingPage: boolean = false;
    lastVisitedJob: string[] = [];
    jobUnitSystems: JobUnitSystem[] = [];
    unitSystems: UnitSystem[] = [];
    assignedRole: string = "";
    //notificationOptions?: string[];
    constructor(params?: Settings) {
        super();
        this.landingPage = params && params.landingPage;
        this.defaultLanding = params && params.defaultLanding;
        //this.timeDateFormat = params && params.timeDateFormat;
        this.activeTheme = params && params.activeTheme;
        this.activeTimezone = params && params.activeTimezone;
        //this.activeUnitSystem = params && params.activeUnitSystem;
        this.activeUnitSystemId = params && params.activeUnitSystemId;
        this.assignedRole = params && params.assignedRole;
    };
}

export class JobUnitSystem {
    jobId: string = "";
    unitSystemId: string = "";
    constructor(jobId: string = "", unitSystemId: string = "") {
        this.jobId = jobId;
        this.unitSystemId = unitSystemId;
    };
}

export class JobLastVisited {
    userId: string = "";
    jobId: string = "";
    constructor(jobId: string = "", userId: string = "") {
        this.jobId = jobId;
        this.userId = userId;
    };
}

export class DashboardMenu {
    defaultLanding: string;
    jobChoice?:string;
}
