import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorsEx } from 'src/app/core/services/validators-extend.class';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() controlName: FormControl;
  @Input() submitted: boolean;

  public objError: any = { type: '' };
  private prioritize: Array<string> =  ValidatorsEx.prioritize;

  constructor() { }

  ngOnInit() {
  }

  get errorMessageType() {
    this.objError.type = '';

    if (!this.submitted || !this.controlName?.errors) {
      return this.objError;
    }

    this.prioritize.find(item => {
      if (this.controlName.errors[item]) {
        this.objError = {
          type: item,
          ...this.controlName.errors[item]
        }
        return;
      }
    })
    return this.objError;
  }
}
