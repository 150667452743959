<app-chart-options-base></app-chart-options-base>
<div *ngIf="chartFrame; else chartOnly" class="graph-container mt-4 position-relative" [id]="chartTitle">
  <div class="graph-header clearfix px-4">
    <div class="flex-grow-1 graph-chart-title text-center">{{chartTitle}}
      <div class="icon-content">
        <label *ngIf="chartDataExpand!=undefined" class="export ml-4">
          <a target="chartData" (click)="confirmTabOut(chartDataExpand)">{{'analysisMdl.data'|translate}}
            <div class="ic-dataout-icon"></div>
          </a>
        </label>
        <!-- <span class="settings d-flex ml-4">
          <div class="filter-color-orange ic-gear-icon closeIconOrange" (click)="openChartOptions(chartTitle, chartOptions, chartData, chartType)"></div>
        </span> -->
        <div class="ic-clipboard clipboard1" (click)="clipboardDownloadChart()"></div>
      </div>
    </div>
  </div>
  <div class="graph-body p-1 text-center">
      <app-chart-base [chartOptions]="chartOptions" (chartInstance)="chartInstance($event)" [initOpts]="initOpts"></app-chart-base>
  </div>
  <!-- <div class="zoomIcon p-2 position-absolute" (click)="showZoomChartModal()"><img src="assets/images/icons/ic-zoom_in.svg" /></div> -->
  <div *ngIf="chartOptionsReady">
    <!-- <app-chart-options [chartName]="chartOptionsTitle" [chartData]="chartOptionsData" [chartConfiguration]="chartconfigurationmodel"
      [chartType]='chartOptionsType' (updateGraph)="updateGraph($event)">
    </app-chart-options> -->
  </div>
</div>
<ng-template #chartOnly>
  <app-chart-base [chartOptions]="chartOptions" (chartInstance)="chartInstance($event)" [initOpts]="initOpts"></app-chart-base>
</ng-template>
