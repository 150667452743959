
export class ChartConfiguration {
  title: Title;
  legend: LegendOptions;
  textStyle: TextStyle;
  series: Series;
  xAxis: Axis;
  yAxis: Axis;
}

export class Axis {
  axisLabel: AxisLabel;
}

export class AxisLabel {
  show: boolean;
  backgroundColor: string;
  fontSize: number;
  borderWidth: number;
  borderColor: string;
}

export class ItemStyle {
  color: string;
  border: number;
}

export class LabelStyle {
  formatter: "{b}";
  show: boolean;
  fontSize: string;
  color: string;
  rotate: number;
}

export class Title {
  show: boolean;
  text: string;
  textStyle: TextStyle;
}
export class LegendOptions {
  show: boolean;
  textStyle: TextStyle;
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export class TextStyle {
  fontSize: string;
  color: string;
}

export class Series {
  series: string;
  symbol: string;
  data: string;
  color: string;
  label: SeriesLabel;
  lineStyle: LineStyle;
  constructor() {
    this.lineStyle = new LineStyle();
    this.label = new SeriesLabel();
    this.label.show = false;
  }
}

export class LineStyle {
  color: string;
  width: string;
}

export class SeriesLabel {
  show: boolean = false;
  fontSize: string;
  color: string;
}

//view model   
export class ChartConfigurationViewModel {
  chartType: string;
  areSettingsExist: boolean = false;
  chartName: string;
  chartData: any;
  title: TitleViewModel;
  legend: LegendOptionsViewModel;
  series: Series;
  isLablesEnabled: boolean;
  isBorderEnabled: boolean;
  isFillEnabled: boolean;
  xAxis: any;
  yAxis: any;
  color: any;
  backgroundColor1: any;
  backgroundColor2: any;
  chartColor1: any;
  chartColor2: any;
  colorType: string;
  backgroundColorAngle: string;
  colorAngle: string;
  grid: Grid;
  seriesSymbol: string;
  seriesColor: string;
  seriesThickness: number;
  isEnableCurveLables: boolean;
  symbolAngle: number = 0;
  curveLabelAxis: string;
  selectedSeriesName: string;
  seriesSettings: SeriesSettings[] = [];
  titleFontOptions: TextStyle;
  legendFontOptions: TextStyle;
  curveFontOptions: TextStyle;
  axisFontOptions: TextStyle;
  labelFontOptions: TextStyle;
  hasMultipleXAxis: boolean = false;
  hasMultipleYAxis: boolean = false;
  drillAheadDataOption:string;
  constructor() {

    this.title = new TitleViewModel();
    this.title.x = 'center';
    this.title.show = true;
    this.title.textStyle = new TextStyle();
    this.title.textStyle.color = 'white';
    this.xAxis = [];
    this.yAxis = [];
    this.legend = new LegendOptionsViewModel();
    this.legend.show = false;
    this.legend.left = 0;
    this.legend.position = "";
    this.series = new Series();
    this.series.lineStyle = new LineStyle();
    this.series.label = new SeriesLabel();

    this.colorType = '';
    this.backgroundColor1 = "";
    this.backgroundColor2 = "";
    this.chartColor1 = "";
    this.chartColor2 = "";
    this.colorAngle = "0";
    this.backgroundColorAngle = "0";

    this.selectedSeriesName = '';
    this.seriesSymbol = '';
    this.seriesColor = '';
    this.seriesThickness = 0;
    this.isEnableCurveLables = false;
    this.curveLabelAxis = "Y";

    this.titleFontOptions = new TextStyle();
    this.legendFontOptions = new TextStyle();
    this.curveFontOptions = new TextStyle();
    this.axisFontOptions = new TextStyle();
    this.labelFontOptions = new TextStyle();

    this.axisFontOptions.color = "";
    this.axisFontOptions.fontSize = "";

    this.curveFontOptions.color = "";
    this.curveFontOptions.fontSize = "";

    this.labelFontOptions.color = "";
    this.labelFontOptions.fontSize = "";

    this.legendFontOptions.color = "";
    this.legendFontOptions.fontSize = '';

    this.titleFontOptions.color = "";
    this.titleFontOptions.fontSize = "";
    this.drillAheadDataOption='Hole Angle';
  }
}



export class ChartDetails {
  chartType: string;
  customSettings: ChartConfigurationViewModel;
  defaultTitle: string;
  chartData: any;
  xAxis: any;
  yAxis: any;

  constructor() {
    this.customSettings = new ChartConfigurationViewModel();
  }
}


export class SeriesViewModel {

}

export class SeriesSettings {
  seriesname: string;
  seriesSymbol: string;
  seriesColor: string;
  seriesThickness: number;
  isEnableCurveLables: boolean;
  symbolAngle: number = 0;
  curveLabelAxis: string;
  selectedSeriesName: string;
  isborderEnabled: boolean = false;
  isFillSelected: boolean = false;
}

export class Grid {
  show: boolean;
  backgroundColor: any;
}

export class TitleViewModel {
  show: boolean;
  text: string;
  x: string;
  textStyle: TextStyle;
}
export class LegendOptionsViewModel {
  show: boolean;
  left: number;
  right: number;
  top: number;
  bottom: number;
  position: string;
  textStyle: TextStyle;
}

export class Gradient {
  startAngle: string;
  type: string = "linear";
  x: number;
  y: number;
  x2: number;
  y2: number;
  colorStops: ColorStop[];
  globalCoord: boolean;
}

export class ColorStop {
  offset: number;
  color: string;
}








