import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { REPORTING_STATUS, ROLES, TOASTER_TYPE } from 'src/app/shared/enums/enums';
import { DosStatus, Integration, JobState, RecapStatus, TechSightJobStatus, VARIABLES, SAVE_DATA } from 'src/app/utils/constants';

const slideInOut = trigger('slideInOut', [
  state('open', style({
    width: '450px',
    opacity: 1,
    visibility: 'visible'
  })),
  state('close', style({
    width: '0px',
    opacity: 0,
    visibility: 'hidden'
  })),
  transition('open => close', [
    group([
      animate('400ms',
      style({
        visibility: 'hidden'
      })),
      animate('350ms',
        style({
          width: '0px',
        })
      ),
      animate('200ms',
        style({
          opacity: 0,
        }))
    ])]),
  transition('close => open',
    [
      group([
        animate('1ms',
        style({
          visibility: 'visible'
        })),
        animate('200ms',
          style({
            width: '450px',
          })
        ),
        animate('350ms',
          style({
            opacity: 1,
          }))
      ])]
  )
]);

@Component({
  selector: 'app-project-info-modal',
  templateUrl: './project-info-modal.component.html',
  styleUrls: ['./project-info-modal.component.scss'],
  animations: [slideInOut]
})

export class ProjectInfoModalComponent implements OnInit, OnChanges {
  @Input() public show: boolean;
  @Input() public packet: any;
  @Output() public close = new EventEmitter();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _utilities: UtilitiesService
  ) { }

  public jobs: any;
  public techSightJobTypes: any;
  public showPlanningPacket: boolean = true;
  public get isEngineer() {
    return this._utilities.currentUser?.settings?.assignedRole === ROLES.Engineer;
  };

  ngOnChanges() {
    this.resetJobPacketStatus();
    this.buildPacketStatus(this.packet);
  }

  ngOnInit() {
  }

  onCloseModal() {
    this.close.emit();
  }

  jobDetails(job) {
    if (this.isEngineer && this.packet.jobs[0] && this.packet.jobs[0].importedBy === "TechSight") {
      const msg = this._utilities.getTranslationNow('warnings.notEditableTSImportedEngineer');
      this._utilities.showToastrWithTimer(TOASTER_TYPE.WARNING, msg, SAVE_DATA.Toastr_Fail);
    }
    else
    {
      if(job.jobId) {
        this.router.navigate([`/job/${job.jobId}/jobprofile/jobprofile`]);
      }
    }
  }

  techSightJobDetails(job, jobType?) {
    if(jobType && jobType.dosStatus == DosStatus.Approved) {
      this.decodeAndOpen(jobType.dosSectionUrl);
      return;
    }
    this.decodeAndOpen(job.techSightDosStatuses.jobPacketUrl);
  }

  decodeAndOpen(url) {
    if (url) {
      const decodedUrl = decodeURIComponent(url);
      window.open(decodedUrl, '_blank');
    }
  }

  resetJobPacketStatus() {
    this.jobs = {
      planning: {
        status: 'Not Created Yet'
      },
      actual: {
        status: 'Not Created Yet'
      },
      techSightCompliance: {
        status: 'Not Started'
      },
      wellSightReport: {
        status: 'Not Created Yet'
      },
      hindSightReport: {
        status: 'Not Created Yet'
      }
    };

    this.techSightJobTypes = [];
    this.showPlanningPacket = true;
  }

  buildPacketStatus(project) {
    if (project.jobs) {
      const hasBothPackets = project.jobs.length == 2 ? true: false;
      if(this.isEngineer && hasBothPackets) {
        this.showPlanningPacket = false;
      }

      const planning = project.jobs.find(x => x.jobState == JobState.Planning);
      if(planning) {
        this.jobs.planning.jobId = planning.jobId;
        this.jobs.planning.status = planning.jobStatus;
        this.jobs.planning.techSightDosStatuses = { jobPacketUrl: planning?.techSightDosStatuses?.jobPacketUrl };

        this.techSightJobTypes = planning?.techSightDosStatuses?.jobTypes ?? [];
        if (this.techSightJobTypes?.length) {
          this.techSightJobTypes = this.techSightJobTypes.map(x => {
            x.dosStatus = x.dosStatus == DosStatus.Approved ? 'Approved' : 'Not Approved';
            return x;
          })
        }
      }

      const actual = project.jobs.find(x => x.jobState == JobState.Execution);
      if(actual) {
        this.jobs.actual.jobId = actual.jobId;
        this.jobs.actual.status = actual.jobStatus;
      }

      const recapStatus = project[VARIABLES.recapStatus];
      switch (recapStatus) {
        case RecapStatus.InProgress:
          this.jobs.wellSightReport.status = REPORTING_STATUS.IN_PROGRESS;
          this.jobs.hindSightReport.status = REPORTING_STATUS.IN_PROGRESS;
          break;
        case RecapStatus.Closed:
          this.jobs.wellSightReport.status = REPORTING_STATUS.COMPLETE;
          this.jobs.hindSightReport.status = REPORTING_STATUS.COMPLETE;
          break;
        case RecapStatus.Abandoned:
            this.jobs.wellSightReport.status = REPORTING_STATUS.ABANDONED;
            this.jobs.hindSightReport.status = REPORTING_STATUS.ABANDONED;
            break;
        default:
          this.jobs.wellSightReport.status = REPORTING_STATUS.NOT_STARTED;
          this.jobs.hindSightReport.status = REPORTING_STATUS.NOT_STARTED;
          break;
      }

      if(planning && planning.importedBy == Integration.TechSight) {
        this.jobs.techSightCompliance.status = planning.techSightJobStatus ?? 'In Progress';
      }

      const dosApproved = this.techSightJobTypes.some(x => x.dosStatus == DosStatus.Approved) ?? false;
      if(planning && planning.techSightJobStatus == TechSightJobStatus.Completed && dosApproved) {
        this.jobs.techSightCompliance.status = 'Complete';
      }
    }
  }

  getStatusClass(status: string) {
    let val = status.replace(/\s/g, '-').toLowerCase();

    return "status-" + val;
  }
}
