import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Config } from './config';
import * as LS from '../../utils/localstorage';
import { UtilitiesService } from './utilitiesservice.service';
import { UserModel } from '../models/userModel';
import { environment } from 'src/environments/environment';
import { INTERVAL_TIMES } from 'src/app/shared/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class LocalTokenService {
  constructor(
    public _api: ApiService,
    public _utilities: UtilitiesService,
  ) {
  }

  private setLocalUser(user:string) {
    if (!user) user = "local";
    this._utilities.currentUser = new UserModel();
    this._utilities.currentUser.fullName = user;
    this._utilities.currentUser.email = `${user}@halliburton.com`;
    this._utilities.currentUser.userId = `${user}@halliburton.com`;
  }

  public isLoggedIn() {
    return this.getTokenGenerator(false)
      .then((token) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }

  // checks cache for token expiration date, forces reload if missing or expired
  public checkTokenGeneratorExpiration(forceLoad: boolean): boolean {
    if (!forceLoad) {
      const user = LS.getSessionStorage(LS.LOCAL_STORAGE_KEY.Token.GenUsername);
      if (!user) return true;
      const expiry = LS.getSessionStorage(LS.LOCAL_STORAGE_KEY.Token.GenExpire);
      if (!expiry) return true;
      const now = new Date();
      forceLoad = (now.getTime() > expiry);
    }
    return forceLoad;
  }

  public async getTokenGenerator(forceLoad: boolean): Promise<string> {
    forceLoad = this.checkTokenGeneratorExpiration(forceLoad);
    var key = LS.LOCAL_STORAGE_KEY.Token.Gen;
    if (!forceLoad) { var data = LS.getSessionStorage(key); }
    if (data) {
      this.setLocalUser(LS.LOCAL_STORAGE_KEY.Token.GenUsername);
      return data;
    }
    else {
      var url = environment.local ? Config.APIUrlTokenUser : Config.APIUrlToken;
      return await this._api.localtoken<any>(url).toPromise()
        .then(data => {
          if (data) {
            let token = data.token || data;
            LS.setSessionStorageString(LS.LOCAL_STORAGE_KEY.Token.Gen, token);
            // set expiration
            const now = new Date();
            var expireDate = now.getTime() + INTERVAL_TIMES.TokenExpire;
            LS.setSessionStorage(LS.LOCAL_STORAGE_KEY.Token.GenExpire, expireDate);
            LS.setSessionStorage(LS.LOCAL_STORAGE_KEY.Token.GenUsername, data.username || "local_user");
            this.setLocalUser(data.username);
            return token;
          }
          return data;
        })
        .catch(err => {
          return err;
        });
    }
  }

}
