import * as LS from 'src/app/utils/localstorage';
import { environment } from 'src/environments/environment';
import { FIELD_TYPE, LANGUAGE_CODES, SORT_TYPE } from '../shared/enums/enums';

export function randomizeUrl(url: string): string {
	var rand = Math.round(Math.random() * 10000);
	var app = url.indexOf('?') ? '&' : '?';
	return url + app + 'rand=' + rand;
}

export function getRequest(url: string) {
	function processRequest(e) {
		if (xhr.readyState === 4) {
			if (xhr.status >= 200 && xhr.status < 300) {
				var json = isValidJson(xhr.response);
				var resp = json ? JSON.parse(xhr.response) : xhr.response;
				return resp;
			}
		}
	}

	let resp = null;
	var xhr = new XMLHttpRequest();
	try {
		url = randomizeUrl(url);
		xhr.addEventListener("readystatechange", processRequest, false);
		xhr.open('GET', url, true);
		xhr.send(null);
		return resp;
	} catch (error) {
		return error;
	}
}

export function readFile(filename: string) {
	var xhr = new XMLHttpRequest();
	try {
		filename = randomizeUrl(filename);
		xhr.open('GET', filename, false);
		xhr.send(null);
		var json = isValidJson(xhr.response);
		let resp = xhr.status === 200 ? (json ? JSON.parse(xhr.response) : xhr.response) : null;
		return resp;
	} catch (error) {
		return error;
	}
}

export function checkConnection() {
	var url = '/assets/appsettings/appSettings.json';
	var url = 'https://i.imgur.com/7ofBNix.png';
	var res = readFile(url);
	if (res instanceof DOMException) {
		return res;
	}
	return true;
}

export function formattedDate(date) {
	let month = String(date.getMonth() + 1);
	let day = String(date.getDate());
	const year = String(date.getFullYear());

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return `${month}/${day}/${year}`;
}

export function checkDateFormat(date) {
	var splittedDateArray = date.split("/", 3);
	if (splittedDateArray.length == 3) {
		if (Number(splittedDateArray[0]) <= 12) {
			if (Number(splittedDateArray[1]) <= 31) {
				return false;
			}
			else {
				return true;
			}
		}
		else {
			return true;
		}
	}
	else {
		return true;
	}
}

export function initTranslate() {
	if (this && this._translate) {
		this.lang = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.LANG) || LANGUAGE_CODES.ENGLISH;
		this._translate.setDefaultLang(this.lang);
	}
};

export const sortArrayWithPropertyWB = (arr, orderType = SORT_TYPE.ASC) => {
	// TODO: need to update sort with any property
	console.log("sortArrayWithPropertyWB", orderType, arr);
	if (arr) {
		return orderType === SORT_TYPE.ASC ? arr.sort(_compareFunctionWBASC) : arr.sort(_compareFunctionWBDESC);
	}
	return null;
};

export const sortArrayWithPropertyCS = (arr, orderType = SORT_TYPE.ASC) => {
	// TODO: need to update sort with any property
	console.log("sortArrayWithPropertyCS", orderType, arr);
	if (arr) {
		return orderType === SORT_TYPE.ASC ? arr.sort(_compareFunctionCSASC) : arr.sort(_compareFunctionCSDESC);
	}
	return null;
};

// the function to get a list of element of arr1 and NOT existing in arr2
export const getElementOfArrayNotInOtherArray = (arr1, arr2) => {
	if (!arr1 || !arr2 || !arr1.length) return [];
	if (!arr2.length) return arr1;
	return arr1.filter(item => !new Set(arr2).has(item));
};


function _bhaRuncompareFunctionASC(current, next) {
	if (current.bhaRunWITSML.numStringRun < next.bhaRunWITSML.numStringRun) {
		return -1;
	}
	if (current.bhaRunWITSML.numStringRun > next.bhaRunWITSML.numStringRun) {
		return 1;
	}
	return 0;
}

function _trajectoryMDFunctionASC(current, next) {
	if (current.md.value < next.md.value) {
		return -1;
	}
	if (current.md.value > next.md.value) {
		return 1;
	}
	return 0;
}

function _compareFunctionCSDESC(current, next) {
	if (current.endMd > next.endMd) {
		return -1;
	}
	if (current.endMd < next.endMd) {
		return 1;
	}
	return 0;
}

function _compareFunctionCSASC(current, next) {
	if (current.endMd < next.endMd) {
		return -1;
	}
	if (current.endMd > next.endMd) {
		return 1;
	}
	return 0;
}

function _bhaRuncompareFunctionDESC(current, next) {
	if (current.startMd.value > next.startMd.value) {
		return -1;
	}
	if (current.startMd.value < next.startMd.value) {
		return 1;
	}
	return 0;
}

function _compareFunctionASC(current, next) {
	if (current.startMd.value < next.startMd.value) {
		return -1;
	}
	if (current.startMd.value > next.startMd.value) {
		return 1;
	}
	return 0;
}

function _compareFunctionWBASC(current, next) {
	if (current.md.value < next.md.value) {
		return -1;
	}
	if (current.md.value > next.md.value) {
		return 1;
	}
	return 0;
}

function _compareFunctionWBDESC(current, next) {
	if (current.md.value > next.md.value) {
		return -1;
	}
	if (current.md.value < next.md.value) {
		return 1;
	}
	return 0;
}

function _compareFunctionDESC(current, next) {
	if (current.startMd.value > next.startMd.value) {
		return -1;
	}
	if (current.startMd.value < next.startMd.value) {
		return 1;
	}
	return 0;
}

/**
 * sort a array with any property
 * @param arr 
 * @param orderType 
 */
export const sortArrayWithProperty = (arr, orderType = SORT_TYPE.ASC) => {
	// TODO: need to update sort with any property
	if (arr && Array.isArray(arr)) {
		return orderType === SORT_TYPE.ASC ? arr.sort(_compareFunctionASC) : arr.sort(_compareFunctionDESC);
	}
	return null;
};
export const bhaRunsortArrayWithProperty = (arr, orderType = SORT_TYPE.ASC) => {
	// TODO: need to update sort with any property
	if (arr && Array.isArray(arr)) {
		return orderType === SORT_TYPE.ASC ? arr.sort(_bhaRuncompareFunctionASC) : arr.sort(_bhaRuncompareFunctionDESC);
	}
	return null;
};

export const convertArrayToObject = (array, key) => {
	if (array && array.length && key) {
		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			};
		}, initialValue);
	}
	return null;
};


export function setPropertyOfObject(obj = {}, path, value) {
	let schema = obj;
	let pList = path.split('.');
	const len = pList.length;
	for (let i = 0; i < len - 1; i++) {
		const elem = pList[i];
		if (!schema[elem]) schema[elem] = {}
		schema = schema[elem];
	}

	schema[pList[len - 1]] = value;
	return { ...obj, schema };
}

export const setPropertyOfNestedObject = (obj, prop, value) => {
	let schema = obj;
	if (typeof prop === "string")
		prop = prop.split(".");

	if (prop.length > 1) {
		var e = prop.shift();
		setPropertyOfNestedObject(schema[e] =
			Object.prototype.toString.call(schema[e]) === "[object Object]"
				? schema[e]
				: {},
			prop,
			value);
	} else
		schema[prop[0]] = value;
	return schema;
}

/**
 * 
 * @param obj = {
 * 	annular: {
 * 		uom: 'bbl',
 * 		value: 1.2
 * 	},
 * 	hole: {
 * 		uom: 'bbl',
 * 		value: 2.3
 * 	}
 * }
 * @returns [
 * 	{
 * 		name: annular,
 * 		uom: 'bbl',
 * 		value: 1.2
 * 	},
 * 	{
 * 		name: hole,
 * 		uom: 'bbl',
 * 		value: 2.3
 * 	}
 * ]
 */
export const convertObjectToArray = (obj) => {
	if (obj) {
		return Object.keys(obj).map(key => ({
			...obj[key],
			name: key,
		}));
	}
	return [];
}

/**
 * check a string is a numeric
 * @param str 
 */
export const isNumeric = (str) => {
	//if (typeof str != 'string') return false;
	return isString(str) && !isNaN(str) && !isNaN(parseFloat(str));
}

export const isString = (str) => {
	return typeof str === 'string' || str instanceof String;
}

export const geothermalProfilesortArrayWithProperty = (arr, orderType = SORT_TYPE.DESC) => {
	console.log("geothermalProfilesortArrayWithProperty", orderType, arr);
	// TODO: need to update sort with any property
	if (arr && Array.isArray(arr)) {
		return orderType === SORT_TYPE.ASC ? arr.sort(_geothermalProfileTVDFunctionASC) : arr.sort(_geothermalProfileTVDFunctionASC);
	}
	return null;
};

function _geothermalProfileTVDFunctionASC(current, next) {
	console.log(Number(current[0]))
	console.log(Number(next[0]))
	if (Number(current[0]) < Number(next[0])) {
		return -1;
	}
	if (Number(current[0]) > Number(next[0])) {
		return 1;
	}
	return 0;
}

export function isNull(value): boolean {
	return value === undefined || value === null || value === '';
}

export function isObject(item): boolean {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

export function isValidJson(value): boolean {
	try {
		var res = JSON.parse(value);
		if (res && typeof res === 'object') return true;
	} catch (error) {
	}
	return false;
}

export function deepCopy(obj: any): any {
	return (obj != null && obj != undefined) ? JSON.parse(JSON.stringify(obj)) : obj;
};

export function disableElement(idElement: string) {
	let inputs = document.getElementById(idElement).getElementsByTagName('input');
	let selects = document.getElementById(idElement).getElementsByTagName('select');

	if (inputs.length > 0) {
		let inputElements = Array.prototype.slice.call(inputs);
		for (let input of inputElements) {
			if (input.type === 'radio') {
				!input.checked ? input.setAttribute('disabled', 'true') : null;
			}
			if (input.type === 'checkbox') {
				input.setAttribute('disabled', 'true');
			}
			else {
				input.setAttribute('readonly', 'true');
			}
		}
	}

	if (selects.length > 0) {
		let selectElements = Array.prototype.slice.call(selects);
		for (let input of selectElements) {
			input.setAttribute('disabled', 'true');
		}
	}
}

export function mergeDeep(target, ...sources) {
	if (!sources.length) { return target; }
	const source = sources.shift();

	if (this.isObject(target) && this.isObject(source)) {
		for (const key in source) {
			if (this.isObject(source[key])) {
				if (!target[key]) { Object.assign(target, { [key]: {} }); }
				this.mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}
	return this.mergeDeep(target, ...sources);
}

export function myconsole(title: string, value: any) {
	if (title === "$timerStart") {
		console.log('Begin timer - ', value);
		console.time(value);
	}
	else if (title === "$timerEnd") {
		console.timeEnd(value);
		console.log('End timer - ', value);
	}
	else {
		console.log(title, value);
	}
}

export function initProgress(value: string) {
	let ele = environment.debug.indexElement;
	if (!ele) return;
	let prev = environment.debug.indexElementText;
	ele.innerHTML = ele.innerHTML.replace(prev, value);
	environment.debug.indexElementText = value;
}

