export enum TIMEOUT {
    SHORT = 3000,
    MEDIUM = 10000,
    LONG = 20000
};

export enum INTERVAL_TIMES {
    NetworkStatus = 15000,      // 15 seconds (1000 * 15)
    CatalogExpire = 10368000000,   // 120 days (1000 * 60 * 60 * 24 * 120)
    TokenExpire = 64800000,   // 18 hours (1000 * 60 * 60 * 18)
    //TokenRefresh = 20000,   // 20 seconds, testing only
    TokenRefresh = 43200000,   // 12 hours (1000 * 60 * 60 * 12)
};

export enum DATE_TIME_FORMAT {
    ISO = 'yyyy-MM-dd',
    SHORT = 'MM/dd/yyyy',
    LONG = 'MMMM-dd-yyyy'
};

export enum LANGUAGE_CODES {
    ENGLISH = 'en',
    FRENCH = 'fr',
    SPANISH = 'es',
};

export enum CHARACTERS {
    COMMA = ',',
    DOT = '.',
    PLUS = '+',
    MINUS = '-',
    KeyE = 'e',
    newline = '\r\n',
};

export enum TOASTER_TYPE {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    INFO = "info"
}

export enum MODAL_IDENTIFIERS {
    CONFIRM_POPUP = 'confirmPopup',
    DIALOG = 'dialog',
}

export enum FIELD_TYPE {
    TEXT = 'Text',
    NUMBER = 'Number',
    DROPDOWN = 'Dropdown',
    CHECKBOX = 'Checkbox',
    RADIO_BUTTON = 'RadioButton',
    MULTISELECT='Multiselect',
};

export enum SORT_TYPE {
    ASC = 'ASC',
    DESC = 'DESC',
};

export enum PUMPS_ON_MODAL {
    calculateByOption = "calculateByOption",
    activateNonLinearPumping = "activateNonLinearPumping",
    calculateAtOption = "calculateAtOption",
    MD = "depthOfInterest",
    TVD = "tvd",
    MIN_RATE = "minRate",
    MAX_RATE = "maxRate",
    MIN_TIME = "minTime",
    MAX_TIME = "maxTime",
    STEP_TIME = "stepTime",
    PUMP_RATE = "pumpRate"
}

export const PUMPS_ON_DEFAULT_VALUE = {
    //SURFACE DETAILS
    STATIC_TIME: 30.00,
    INCLUDE_FRACTURE_GRADIENT: false,

    // Pump Rate Range DF value
    MIN_RATE: 5.0,
    MAX_RATE: 10.0,
    STEP_RATE: 5.0,
    PUMPS_ON_TIME: 0.083333, //(5 / 59.99988000024), // min -> sec 5

    // Pumps On Time DF value
    MIN_TIME: 0.083333,  // min -> sec 5
    MAX_TIME: 0.333333, // min -> sec 20
    STEP_TIME: 0.083333, // min -> sec 5
    PUMP_RATE: 10.0,

    // Non-Linear Pumping DF value
    ACTIVATE_NON_LINEAR_PUMPING: false,
    T1: 0.008333, // min -> sec 0.50
    T2: 0.008333, // min -> sec 0.50

    // Do Calculator for ECD
    CALCULATE_AT_OPTION: "AtBottomHole",
    CALCULATE_BY_OPTION: "ByPumpRate",
    MD: 0,
    TVD: 0,
}

export enum CalculateByOption {
    ByPumpRate = "ByPumpRate",
    ByPumpsOnTime = "ByPumpsOnTime"
}

export enum CalculateAtOption {
    AtBit = "AtBit",
    AtBottomHole = "AtBottomHole",
    AtCasingShoe = "AtCasingShoe",
    AtDepth = "AtDepth"
}

export enum DEPTH_UNIT {
    FT = 'ft',
    M = 'm',
};

export enum ACTION_TYPE {
    INIT = "Init",
    CREATE = "Create",
    UPDATE = "Update",
    DELETE = "Delete",
    VIEW = "View",
    CREATING = "Creating",
    UPDATING = "Updating",
    DELETING = "Deleting",
    RESET = "Reset",
    PROCESSING = "Processing",
    DONE = "Done",
    SHOW_CATALOG = "showCatalog"
}

export enum CONFIRMATION_TYPES {
    CLEAR_TUBULARS = 'clearTubulars',
    CLEAR_TRAJECTORIES = 'clearTrajectories',
    IMPORT_AND_CLEAR = 'importAndClear',
};

// TODO language-enable
export enum CONFIRMATION_MESSAGES {
    CLEAR_TUBULARS = 'Do you want to delete all tubular components?',
    CLEAR_TRAJECTORIES = 'Do you want to delete all trajectory stations?',
    IMPORT_AND_CLEAR = 'Do you want to overwrite all existing tubular components?'
};

export enum IMPORT_TYPE {
    TRAJECTORY = "trajectory",
    TUBULAR = "tubular",
}

export enum CUSTOMER_TYPE {
    CONTRACTOR = "Contractor",
    OPERATOR = "Operator",
}

export enum IMPORT_TEMPLATE {
    TRAJECTORY_TEMPLATE = "assets/import-templates/trajectory-import.csv",
    TUBULAR_TEMPLATE = "assets/import-templates/tubular-import.xlsx"
}

// TODO language-enable
export enum TRAJECTORY_COLUMN {
    MD = "md",
    Azimuth = "azimuth",
    INCLINATION = "inclination",
    TVD = "tvd",
}

// TODO language-enable
export enum TUBULAR_COLUMN {
    DESCRIPTION = "description",
    TYPE = "type",
    OD = "od",
    ID = "id",
    LENGTH = "length",
    GRADE = "grade",
    WTLTH = "weight per length",
}

export enum UNITS {
    API = "API",
    API_ID = "0",
    SI = "SI",
    SI_ID = "1"
}

export enum ANALYSIS_GROUP {
    HYDRAULICS = "Hydraulics",
    DISPLACEMENT = "Displacement",
}

export enum ANALYSIS_REASONS {
    CUSTOMER_REQUEST = "Customer request",
    OPERATION_EXECUTION = "Operation execution",
    PROPLEM_RESOLUTION = "Problem resolution",
    SOLUTION_CREATION = "Solution creation",
    TENDER = "Tender"
}

export enum ANALYSIS_STATUS {
    CREATED = "Created",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    ERROR = "Error",
}

export enum REPORTING_STATUS {
    NOT_STARTED = "Not Started",
    IN_PROGRESS = "In Progress",
    ABANDONED = "Abandoned",
    COMPLETE = "Complete"
}

export enum ANALYSIS_CASE_STATUS {
    INCOMPLETE = "Incomplete",
    SUBMITTED = "Submitted",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    PENDING = "Pending",
    ERROR = "Error",
}
export enum API_RESPONSE {
    SUCCESS = "Success",
    CACHE = "Success - from cache",
    FAIL = "Fail"
}

export enum PAGES {
    JobsDashboard = "jobs-dashboard",
    JobsTransfer = "jobs-transfer",
    CatalogsManagement = "catalogs-management",
    CatalogsTransfer = "catalogs-transfer",
    Settings = "settings",
    JobProfile = "jobprofile",
    NewJobProfile = 'jobs/newjob/jobprofile',
    NewWellHeader = 'jobs/newjob/wellheader',
    UserRoles = 'user-roles',
    Roles = 'roles'
}

export enum PAGE_NAMES {
    RigProfile = "Rig Profile",
    WellProfile = "Well Profile",
    Intervals = "Intervals",
}

export enum FLUID_LOCATION_TYPES {
    Well = "Well",
    ServiceLines = "Service Lines"
};

export enum LOCATION_WELL_TYPES {
    Initial = "Initial",
    Spacer = "Spacer",
    Final = "Final"
};

export enum CATALOGS_NAME {
    JobType = "Job Type",
    JobPurpose = "Job Purpose",
    JobCustomer = "Job Customer",
    JobStatus = "Job Status",
    WellStatus = "Well Status",
    WellConcerns = "Well Concerns",
    Region = "Region",
    Country = "Country",
    State = "State",
    Riser = "Riser",
    BaseOilPVT = "Base Oil PVT"
};

export enum PROPERTY_NAME {
    IsWellSightUsed = "isWellSightUsed"
}

export enum ROLES {
    Admin = "Admin",
    Engineer = "Engineer",
    TechProf = "Tech Prof",
    Manager = "Manager"
};