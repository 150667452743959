<div class="container-fluid overlay pl-0" [ngClass]="adasThemeClass" *ngIf="configLoaded">

    <div class="row" id="app-menu-bar">
        <app-menu-bar class="col-12" id="mainMenu"></app-menu-bar>
    </div>
    <div class="main-router m-0 p-0 container-fluid">
        <router-outlet (window:keyup)="hotkeys($event)" ></router-outlet>
        <!-- <router-outlet (window:keyup)="hotkeys($event)" (window:beforeunload)="doBeforeUnload($event)" (window:unload)="doUnload($event)"></router-outlet> -->
    </div>

    <!-- <ng-container *ngIf="showWait"><i  class="fa fa-spinner fa-spin loading-spinner" aria-hidden="true"></i></ng-container> -->
    <!-- Utilities -->
    <ngx-spinner></ngx-spinner>
    <app-dialog></app-dialog>
    <app-footer></app-footer>
</div>

<app-notificationbar></app-notificationbar>