import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UomConversionService } from 'src/app/core/services/uom-conversion.service';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { SORT_TYPE } from 'src/app/shared/enums/enums';
import { ApiFilterModel } from 'src/app/shared/models/job-dashboard-filter-data.model';
import { BaralogixTheme, PAGINATION_PROPERTIES } from 'src/app/utils/constants';
import * as OB from 'src/app/utils/objecter';

export class ComponentNotification {
  constructor() {

  }

  public fetch: Function;
}

@Component({
  selector: 'app-list-view-filter',
  templateUrl: './list-view-filter.component.html',
  styleUrls: ['./list-view-filter.component.scss']
})
export class ListViewFilterComponent implements OnInit, OnChanges {

  @Input() headers: any = [];
  @Input() api: (args: any) => Observable<any>;
  @Input() maxHeight: string = '400px';
  @Input() colSettings: any;
  @Input() pageSize = PAGINATION_PROPERTIES.ITEMS_PER_PAGE;
  @Input() apiQuery: ApiFilterModel;
  @Input() filterable: boolean = false;
  @Input() sortable: boolean = false;
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();

  public filterValues: ApiFilterModel = {} as ApiFilterModel;
  public PAGINATION_PROPERTIES = PAGINATION_PROPERTIES;
  public luColSettings: object = {};
  public filterForm: FormGroup;
  public data$: Observable<any>;
  public totalCount: number;
  public get hasRowSelectEvent() {
    return this.rowSelected && this.rowSelected.observers.length > 0;
  }
  public _api(args: any) {
    if(this.api) {
      this.data$ = this.api(args).pipe(map(data => {
        this.totalCount = data.totalCount;
        return data;
      }));
    }
  }

  constructor(
    public fb: FormBuilder,
    public _uomConversion: UomConversionService,
    public _utilities: UtilitiesService
  ) { 
  }

  ngOnInit() {
    this.initializeForm();
  }

  ngOnChanges(dataChanges) {
    if(dataChanges.hasOwnProperty('apiQuery')){
      this.filterValues = dataChanges.apiQuery.currentValue;
      if(this.filterForm) {
        this.filterForm.reset();
      }
      this._api(dataChanges.apiQuery.currentValue);
    }
  }

  private initializeForm() {
    const form = {};
    this.headers.forEach(header => {
      const filtered = this.filterValues.filterColumns.find(x => x.id == header.key);
      const value = filtered ? filtered.value : "";
      form[header.key] = value;
    });
    this.filterForm = this.fb.group(form);

    this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(val => {
        if(this.filterForm.dirty){
          this.filterData();
        }
      });
  }

  pageChanged(event: PageChangedEvent): void {
    this.filterValues.pageIndex = event.page;
    this._api(this.filterValues);
  }

  onRowSelect(rowData, index, htmlList) {
    if(this.hasRowSelectEvent) {
      const allRows = htmlList.children;
      for(const htmlRow of allRows) {
        htmlRow.style.backgroundColor = '';
      }

      let htmlRow = allRows[index] as HTMLElement;
      if(this._utilities.adasThemeClass == BaralogixTheme.Light) {
        htmlRow.style.backgroundColor = '#7F878D';
      } else {
        htmlRow.style.backgroundColor = '#151515';
      }

      this.rowSelected.emit(rowData);
    }
  }

  cellEvent(event, header, row) {
    delete row.idx;

    this.cellClicked.emit({
      event: event,
      header: header,
      row: row
    });
  }

  sort(key:string) {
    if(!this.sortable) return;
    
    const sorted = this.filterValues.sortColumns.find(x => x.id == key);
    if(sorted) {
      if(sorted.value == SORT_TYPE.ASC.toLowerCase()) {
        sorted.value = SORT_TYPE.DESC.toLowerCase();
      } else if(sorted.value == SORT_TYPE.DESC.toLowerCase()) {
        this.filterValues.sortColumns = this.filterValues.sortColumns.filter(x => x.id != key);
      }
    } else {
      this.filterValues.sortColumns.push({
        id: key,
        value: SORT_TYPE.ASC.toLowerCase()
      })
    }
    
    this._api(this.filterValues);
  }

  sorted(key:string ) {
    const sorted = this.filterValues.sortColumns.find(x => x.id == key);
    return sorted ? sorted.value : null;
  }

  filterData() {
    this.filterValues.filterColumns = [];
    const formValues = this.filterForm.value;
    for(const key in formValues) {
      if(formValues[key]) {
        this.filterValues.filterColumns.push({
          id: key,
          value: formValues[key]
        })
      }
    }

    this._api(this.filterValues);
  }
}
