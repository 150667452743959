import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UtilitiesService } from '../services/utilitiesservice.service';

@Injectable({
  providedIn: 'root'
})
export class FluidPropertiesCatalogResolver implements Resolve<boolean> {

  constructor(
    public _utilities: UtilitiesService,
  ) {
  }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this._utilities.loadFluidPropertiesCatalogs(false);
  }
}
