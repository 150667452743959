import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { PAGES } from 'src/app/shared/enums/enums';
import { UtilitiesService } from '../services/utilitiesservice.service';

@Injectable()
export class JobCatalogsResolver implements Resolve<void> {
  constructor(
    // utilities service injected to ensure token auth is handled first
    public _utilities: UtilitiesService,
    public router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this._utilities.loadJobCatalogs(false)
      .catch(error => {
        this.router.navigate([PAGES.JobsDashboard]);
        false;
      });
  }
}