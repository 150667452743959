<ngx-smart-modal #chartOptionsBaseModal identifier="chartOptionsBaseModal" (onOpen)="getChartData()" [closable]="false"
  customClass="modal-content full-screen no-padding results-model">
  <div *ngIf="!pageLoaded; else pageReady" class="loading-icon-wellProfile">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
  </div>

  <ng-template #pageReady>
    <div class="modal-header">
          <div class="right-section pull-right">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
              <img class="closeIcon" />
            </button>
          </div>
    </div>
    <div class="container mt-5">
      <div class="row header-popup">
        <div class="col-12 h1">{{'chartoptions.chartOptions'|translate}}</div>
      </div>
      <div class="row chartoptions-body">

        <div class="col-12 header-option">
          <div class="h4">{{'chartoptions.titleOptions'|translate}}</div>
          <label class="checkbox-label col-sm-3">
            <input class="input-field" type="checkbox" [(ngModel)]="chartConfig.isShow_Title" />
            <div class="checkbox-mark"></div>
            <label class="checkbox-text">{{'chartoptions.enableTitle'|translate}}</label>
          </label>
          <div class="col-md-3 title-input">
            <input [(ngModel)]="chartConfig.text_Title" maxlength="100" [readonly]="!chartConfig.isShow_Title" class="input-field"/>
          </div>
        </div>

        <div class="col-12 header-option">
          <div class="h4">{{'chartoptions.fontOptions'|translate}}</div>

          <div class="medium sub-header-text">{{'chartoptions.title'|translate}}</div>
          <div class="row">
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.fontSize'|translate}}</label>
              <div>
                <select class="select-dropdown" [(ngModel)]="chartConfig.fontSize_Title">
                  <option class="option-dropdown" value=""></option>
                  <option class="option-dropdown" *ngFor="let item of catalogs.fontSizes" [value]="item.value">
                    {{item.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.fontColor'|translate}}</label>
              <div>
                <select class="select-dropdown" [(ngModel)]="chartConfig.color_Title">
                  <option class="option-dropdown" value=""></option>
                  <option class="option-dropdown" *ngFor="let item of catalogs.fontColors" [value]="item.value">
                    {{item.label}}
                  </option>
                </select>
              </div>
            </div>
          </div>

           <div class="medium sub-header-text">{{'chartoptions.legend'|translate}}</div>
          <div class="row">
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.fontSize'|translate}}</label>
              <div>
                <select class="select-dropdown" [(ngModel)]="chartConfig.fontSize_Legend">
                  <option class="option-dropdown" value=""></option>
                  <option class="option-dropdown" *ngFor="let item of catalogs.fontSizes" [value]="item.value">
                    {{item.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.fontColor'|translate}}</label>
              <div>
                <select class="select-dropdown" [(ngModel)]="chartConfig.color_Lengend">
                  <option class="option-dropdown" value=""></option>
                  <option class="option-dropdown" *ngFor="let item of catalogs.fontColors" [value]="item.value">
                    {{item.label}}
                  </option>
                </select>
              </div>
            </div>
          </div>
         
          <div class="medium sub-header-text">{{'chartoptions.axis'|translate}}</div>
          <div class="row">
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.fontSize'|translate}}</label>
              <div>
                <select class="select-dropdown" [(ngModel)]="chartConfig.fontSize_Axis">
                  <option class="option-dropdown" value=""></option>
                  <option class="option-dropdown" *ngFor="let item of catalogs.fontSizes" [value]="item.value">
                    {{item.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.fontColor'|translate}}</label>
              <div>
                <select class="select-dropdown" [(ngModel)]="chartConfig.color_Axis">
                  <option class="option-dropdown" value=""></option>
                  <option class="option-dropdown" *ngFor="let item of catalogs.fontColors" [value]="item.value">
                    {{item.label}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

       <div class="col-12 header-option">
          <div class="h4">{{'chartoptions.legendOptions'|translate}}</div>

          <label class="checkbox-label checkbox-label-select col-md-3">
            <input class="input-field" type="checkbox" [(ngModel)]="chartConfig.isShow_Legend" />
            <div class="checkbox-mark checkbox-mark-select"></div>
            <select class="select-dropdown checkbox-text" #legendPosition="ngModel" name="legendPosition"
              [ngStyle]="legendStyle" [(ngModel)]=" chartConfig.position_Lengend" [disabled]="!chartConfig.isShow_Legend">
              <option class="option-dropdown" value="default"></option>
              <option class="option-dropdown" *ngFor="let position of catalogs.legendPositions" [value]="position.value">{{position.text}}</option>
            </select>
          </label>
        </div>

        <div class="col-12 header-option">
          <div class="h4">{{'chartoptions.colorOptions'|translate}}</div>
          <div class="row">
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.backgroundColorHeading'|translate}}</label>
              <div>
                <select class="select-dropdown mb-3" [(ngModel)]="chartConfig.colorType">
                  <option class="option-dropdown" value=""> {{'chartoptions.selectLabel'|translate}}</option>
                  <option class="option-dropdown" value="gradient">Gradient</option>
                  <option class="option-dropdown" value="solid">Solid</option>
                </select>
              </div>
            </div>
            <div class="col-md-9 gradients-section">
                        <div class="row">
                          <div class="col-md-4" *ngIf="chartConfig.colorType!==''">
                            <label class="default comp-label">{{'chartoptions.bgColor1'|translate}}</label>
                            <div>
                              <p-dropdown [options]="catalogs.cars" #bgColor1="ngModel"
                                [(ngModel)]="chartConfig.backgroundColor1" dropdownIcon='fa fa-angle-down' placeholder="">
                                <ng-template let-item pTemplate="selectedItem">
                                  <label *ngIf="item.label==='Red'" class="color-swatch color-swatch-red"></label>
                                  <label *ngIf="item.label==='Green'" class="color-swatch color-swatch-green"></label>
                                  <label *ngIf="item.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                                  <label *ngIf="item.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                                  <label *ngIf="item.label==='White'" class="color-swatch color-swatch-white"></label>
                                  <label *ngIf="item.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                                  <span class="dropdownlabel">{{item.label}}</span>
                                </ng-template>
                                <ng-template let-car pTemplate="item">
                                  <label *ngIf="car.label==='Red'" class="color-swatch color-swatch-red"></label>
                                  <label *ngIf="car.label==='Green'" class="color-swatch color-swatch-green"></label>
                                  <label *ngIf="car.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                                  <label *ngIf="car.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                                  <label *ngIf="car.label==='White'" class="color-swatch color-swatch-white"></label>
                                  <label *ngIf="car.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                                  <span class="dropdownlabel">{{car.label}}</span>
                                </ng-template>
                              </p-dropdown>
                            </div>
                           
                          </div>
                          <div class="col-md-1 btn-custom-div" *ngIf="chartConfig.colorType==='gradient'">
                            <button class="fa fa-exchange swapicon" (click)="swapBgColor()"></button>
                          </div>

                          <div class="col-md-4" *ngIf="chartConfig.colorType==='gradient'">
                            <label class="default comp-label">{{'chartoptions.bgColor2'|translate}}</label>
                            <div>
                              <p-dropdown [options]="catalogs.cars" #bgColor2="ngModel"
                                [(ngModel)]="chartConfig.backgroundColor2"
                                dropdownIcon='fa fa-angle-down' placeholder="">
                                <ng-template let-item pTemplate="selectedItem">
                                  <label *ngIf="item.label==='Red'" class="color-swatch color-swatch-red"></label>
                                  <label *ngIf="item.label==='Green'" class="color-swatch color-swatch-green"></label>
                                  <label *ngIf="item.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                                  <label *ngIf="item.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                                  <label *ngIf="item.label==='White'" class="color-swatch color-swatch-white"></label>
                                  <label *ngIf="item.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                                  <span class="dropdownlabel">{{item.label}}</span>
                                </ng-template>
                                <ng-template let-car pTemplate="item">
                                  <label *ngIf="car.label==='Red'" class="color-swatch color-swatch-red"></label>
                                  <label *ngIf="car.label==='Green'" class="color-swatch color-swatch-green"></label>
                                  <label *ngIf="car.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                                  <label *ngIf="car.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                                  <label *ngIf="car.label==='White'" class="color-swatch color-swatch-white"></label>
                                  <label *ngIf="car.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                                  <span class="dropdownlabel">{{car.label}}</span>
                                </ng-template>
                              </p-dropdown>
                            </div>
                          </div>

                          <div class="col-md-1 btn-custom-div" *ngIf="chartConfig.colorType==='gradient'">
                            <button class="link-primary reseticon" (click)="resetBgColor()">
                              {{'chartoptions.reset'|translate}}</button>
                          </div>

                          <div class="col-md-2" *ngIf="chartConfig.colorType==='gradient'">
                            <label class="default comp-label">
                              {{'chartoptions.angle'|translate}}</label>
                            <div>
                              <p-spinner size="3" class="spinnerclass" #bgAngle="ngModel"
                                [(ngModel)]="chartConfig.backgroundColorAngle" [step]="5" loadingIcon='fa-angle-down'>
                              </p-spinner>
                            </div>
                          </div>
                        </div>

              <div class="row mt-4">
                <div class="col-md-4" *ngIf="chartConfig.colorType!==''">
                  <label class="default comp-label"> {{'chartoptions.chartColor1'|translate}}</label>
                  <div>
                    <p-dropdown [options]="catalogs.cars" #chartColor1="ngModel"
                      [(ngModel)]="chartConfig.chartColor1"
                      dropdownIcon='fa fa-angle-down' placeholder="">
                      <ng-template let-item pTemplate="selectedItem">
                        <label *ngIf="item.label==='Red'" class="color-swatch color-swatch-red"></label>
                        <label *ngIf="item.label==='Green'" class="color-swatch color-swatch-green"></label>
                        <label *ngIf="item.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                        <label *ngIf="item.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                        <label *ngIf="item.label==='White'" class="color-swatch color-swatch-white"></label>
                        <label *ngIf="item.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                        <span class="dropdownlabel">{{item.label}}</span>
                      </ng-template>
                      <ng-template let-car pTemplate="item">
                        <label *ngIf="car.label==='Red'" class="color-swatch color-swatch-red"></label>
                        <label *ngIf="car.label==='Green'" class="color-swatch color-swatch-green"></label>
                        <label *ngIf="car.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                        <label *ngIf="car.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                        <label *ngIf="car.label==='White'" class="color-swatch color-swatch-white"></label>
                        <label *ngIf="car.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                        <span class="dropdownlabel">{{car.label}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-md-1 btn-custom-div" *ngIf="chartConfig.colorType==='gradient'">
                  <button class="fa fa-exchange swapicon" (click)="swapChartColor()"></button>
                </div>
                <div class="col-md-4" *ngIf="chartConfig.colorType==='gradient'">
                  <label class="default comp-label">{{'chartoptions.chartColor2'|translate}}</label>
                  <div>
                    <p-dropdown [options]="catalogs.cars" #chartColor2="ngModel"
                      [(ngModel)]="chartConfig.chartColor2"
                      dropdownIcon='fa fa-angle-down' placeholder="">
                      <ng-template let-item pTemplate="selectedItem">
                        <label *ngIf="item.label==='Red'" class="color-swatch color-swatch-red"></label>
                        <label *ngIf="item.label==='Green'" class="color-swatch color-swatch-green"></label>
                        <label *ngIf="item.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                        <label *ngIf="item.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                        <label *ngIf="item.label==='White'" class="color-swatch color-swatch-white"></label>
                        <label *ngIf="item.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                        <span class="dropdownlabel">{{item.label}}</span>
                      </ng-template>
                      <ng-template let-car pTemplate="item">
                        <label *ngIf="car.label==='Red'" class="color-swatch color-swatch-red"></label>
                        <label *ngIf="car.label==='Green'" class="color-swatch color-swatch-green"></label>
                        <label *ngIf="car.label==='Yellow'" class="color-swatch color-swatch-yellow"></label>
                        <label *ngIf="car.label==='Orange'" class="color-swatch color-swatch-orange"></label>
                        <label *ngIf="car.label==='White'" class="color-swatch color-swatch-white"></label>
                        <label *ngIf="car.label==='Pink'" class="color-swatch color-swatch-pink"></label>
                        <span class="dropdownlabel">{{car.label}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-md-1 btn-custom-div" *ngIf="chartConfig.colorType==='gradient'">
                  <button class="link-primary reseticon"
                    (click)="resetChartColor()">{{'chartoptions.reset'|translate}}</button>
                </div>
                <div class="col-md-2" *ngIf="chartConfig.colorType==='gradient'">
                  <label class="default comp-label">{{'chartoptions.angle'|translate}}</label>
                  <div>
                    <p-spinner size="3" #colorAngle="ngModel" class="spinnerclass"
                      [(ngModel)]="chartConfig.colorAngle" [step]="5" loadingIcon='fa-angle-down'></p-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 header-option">
          <div class="h4">{{'chartoptions.axisRange'|translate}}</div>
          <div class="medium sub-header-text">{{'chartoptions.xAxisLabel'|translate}}</div>
          <div class="row" *ngFor="let data of chartConfig.xAxis">
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.labelText'|translate}}</label>
              <div>
                <input class="input-field" [(ngModel)]="data.name" maxlength="50" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <label class="default comp-label">{{'chartoptions.minLabel'|translate}}</label>
                  <div>
                    <input class="input-field" [(ngModel)]="data.min" OnlyNumber="true" />
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="default comp-label">{{'chartoptions.maxLabel'|translate}}</label>
                  <div>
                    <input class="input-field" [(ngModel)]="data.max" OnlyNumber="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="medium sub-header-text">{{'chartoptions.yAxisLabel'|translate}}</div>
          <div class="row" *ngFor="let data of chartConfig.yAxis">
            <div class="col-md-3">
              <label class="default comp-label">{{'chartoptions.labelText'|translate}}</label>
              <div>
                <input class="input-field" [(ngModel)]="data.name" maxlength="50" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <label class="default comp-label">{{'chartoptions.minLabel'|translate}}</label>
                  <div>
                    <input class="input-field" required [(ngModel)]="data.min" OnlyNumber="true" />
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="default comp-label">{{'chartoptions.maxLabel'|translate}}</label>
                  <div>
                    <input class="input-field" [(ngModel)]="data.max" OnlyNumber="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 

      </div>
      <div class="row pull-right">
        <div class="marginTop45">
          <button class="bttn bttn-secondary" (click)="closeModal()">{{'close'|translate}}</button>
          <button (click)="submitModal();" class="bttn bttn-primary">{{'submit'|translate}}</button>
        </div>
      </div>
    </div>
  </ng-template>
</ngx-smart-modal>