import { DateTimeService } from './../date-time.services';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { Config } from "../config";
import * as LS from "src/app/utils/localstorage";
import * as Models from 'src/app/shared/models/wellHeadermodel';
import { SAVE_DATA } from "src/app/utils/constants";
import { UtilitiesService } from "../../../core/services/utilitiesservice.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpErrorResponse } from '@angular/common/http';
import { API_RESPONSE, PAGE_NAMES, TOASTER_TYPE } from 'src/app/shared/enums/enums';
import { DataLogicService } from '../data-logic.service';
import { Trajectory } from 'src/app/shared/models/trajectory.model';
import { WarningService } from './warning.service';

@Injectable({
  providedIn: "root",
})
export class WellprofileService {
  public displayWaterDepth;

  //wellid
  public wpWellId = "";

  //wellboregeometry section
  public openholes: any = [];
  public risers: any = [];
  public selectedRisers: any = [];
  public casingsData: any = [];
  public WellboreGeomatID: any;
  public WellBoreId: any;

  //wellbore
  public wellbores: any = [];
  public parentWellbores: any;

  //bharun
  public bhaRuns: any = [];
  public runNumber: number = 1;

  //litho
  public Litho_ID: string;
  public Lithos: any = [];
  public thermalpageLoaded = false;

  //Well Header
  public isPageLoaded = false;
  public wellHeaderDetails: Models.WellHeader;
  public wellHeaderStringInit: string;
  public wellHeaderSaveError: boolean = false;
  public wellHeaderError: boolean = false;
  public wellHeaderErrors: Array<string> = [];
  public WellHeaderIsChanged: boolean = false;
  public wellHeaderOperators: any;
  public wellHeadertimeZones: any;
  public wellHeaderTypes: any;
  public wellHeaderStatuses: any;
  public wellHeaderBasins: any;
  public wellHeaderCountries: any;
  public wellHeaderPurposes: any;
  public wellHeaderStates: any;
  public wellHeaderCounties: any;
  public componentDestroyed$: Subject<boolean> = new Subject();
  
  //*trajectory component variables //*
  public trajectoryId: string;
  public trajectoryStations: Array<any> = [];
  public trajectoryData: any;
  public trajectoryLoaded$: Subject<boolean> = new Subject();
  public geometryUpdated$: Subject<string> = new Subject();
  public wellHeaderLoaded$ = new BehaviorSubject<boolean>(false);
  public globalTrajectoryId: string;
  public trajectoryLoaded: boolean = true;

  constructor(
    public _api: ApiService,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService,
    public _dataLogic: DataLogicService,
    public _warning: WarningService
  ) { }

  public async loadWellboresbyWellboreId(wellboreId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.wellbores.getWellboreById.replace("{wellboreId}", wellboreId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadThermal(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.temperatureProfiles.get.replace("{wellId}", wellId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }
  public async loadWellboreTypes(forceLoad: boolean) {
    var url = Config.APIUrlCatalogs + environment.wellbores.getWellboreType;
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }
  public async loadWellbores(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.wellbores.getWellboresByWellId.replace("{wellId}", wellId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadWellboresIncludeGeometry(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.wellbores.getWellboresByWellIdIncludeGeometry.replace("{wellId}", wellId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadBha(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.bhaRuns.getByWellId.replace("{wellId}", wellId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadBhaFull(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.bhaRuns.getFullByWellId.replace("{wellId}", wellId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadCasingsTypes(forceLoad: boolean) {
    var url = Config.APIUrlCatalogs + environment.catalogs.tubularCatalogs.casingsLinerTypes;
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadCasingData(wellboreId) {
    var url = Config.APIUrlCore + environment.wellboreGeometry.casings.getByWellboreId;
    url = url.replace("{wellboreId}", wellboreId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadRiserData(wellboreId: string) {
    var url = Config.APIUrlCore + environment.risers.getByWellboreId;
    url = url.replace("{wellboreId}", wellboreId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadOpenHoles(wellboreId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.openHoles.getByWellboreId;
    url = url.replace("{wellboreId}", wellboreId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadLithography(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.lithostratigraphies.get.replace("{wellId}", wellId);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public getWell(jobId: string) {
    var url = Config.APIUrlCore + environment.wellHeader.get.replace("{jobId}", jobId);
    return new Observable(ob => {
      var cached = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Well.WellHeader);
      if (cached) {
        ob.next(cached);
        ob.complete();
      }
      this._api.get<any[]>(url).subscribe(
        (res) => {
          ob.next(res);
          ob.complete();
        },
        (error) => {
          ob.next(null);
          ob.complete();
        }
      );
    });
  }

  public async loadWellHeader(jobId: string, forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Well.WellHeader;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderDetails = data;
      this.wellHeaderStringInit = JSON.stringify(data);
      this.wellHeaderLoaded$.next(true);
    }
    else {
      var url = Config.APIUrlCore + environment.wellHeader.get.replace("{jobId}", jobId);
      return await this._api.get<Models.WellHeader>(url).toPromise()
        .then(data => {
          var def = new Models.WellHeader();
          data = { ...def, ...data };
          data.wellWITSML.dTimSpud = this._dateTime.utcToTimezone(data.wellWITSML.dTimSpud, data.wellWITSML.timezone);
          if (data.wellWITSML["purposeWell"]) {
            delete data.wellWITSML["purposeWell"];
          }
          LS.setLocalStorage(key, data)
          this.wellHeaderDetails = data;
          this.wellHeaderStringInit = JSON.stringify(data);
          this.wellHeaderLoaded$.next(true);
          return data;
        })
        .catch(err => {
          return err;
        });
    }
    return data;
  }

  public async loadWellHeaderTimeZones(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.TimeZones;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeadertimeZones = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.timeZones;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeadertimeZones = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderWellTypes(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Types;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderTypes = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.wellType;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeaderTypes = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderWellStatus(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Statuses;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderStatuses = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.wellStatus;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeaderStatuses = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderBasins(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Basins;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderBasins = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.basins;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeaderBasins = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderPurposes(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Purposes;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderPurposes = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.wellPurpose;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeaderPurposes = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderOperators(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Operators;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderOperators = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.jobs.operators;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeaderOperators = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderCountries(forceLoad: boolean) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Well.Countries;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.wellHeaderCountries = data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.countries;
      return await this._api.get<string[]>(url).toPromise()
        .then(data => {
          this._utilities.storeCatalogs(key, data);
          this.wellHeaderCountries = data;
          return data;
        });
    }
    return data;
  }

  public async loadWellHeaderCountriesByRegion(regionName: string, forceLoad: boolean) {
    var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.regioncountries;
    url = url.replace("{regionName}", regionName);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadWellHeaderStates(countryName: string, forceLoad: boolean) {
    var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.states;
    url = url.replace("{countryName}", countryName);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  public async loadWellHeaderCounties(countryName: string, state: string, forceLoad: boolean) {
    var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.statecounties;
    url = url.replace("{countryName}", countryName).replace("{stateName}", state);
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }


  //wellborespost
  public async postData(obj: any, component) {
    var url = this.getComponent(component, null);
    return await this._api.post<any>(url, obj).toPromise().then(res => API_RESPONSE.SUCCESS).catch(err => err);
  }

  public async postCreateUpdateManyData(obj: any, component, isShowMsg: boolean) {
    var url = this.getComponent(component, null);
    return await this._api.post<any>(url, obj).toPromise()
      .then((resp) => {
        if (isShowMsg) {
          var item = this._utilities.getTranslationNow('item.record');
          var msg = this._utilities.getTranslationNow2('toastr.itemupdated', { item: item });
          this._utilities.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
        }
        this._warning.validateJob();
        return API_RESPONSE.SUCCESS;
      })
      .catch((err) => {
        if (isShowMsg) {
          var msg = this._utilities.getTranslationNow('toastr.SaveFailed');
          if (component === "WellHeader" || component === "ThermalGradient") {
            this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg + " (" + component + ")", SAVE_DATA.Toastr_Fail);
          } else {
            this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
          }
        }
        return err;
      });
  }

  public async deleteData(Id, index, component) {
    var url = this.getComponent(component, Id);
    return await this._api.post<any>(url, null).toPromise().then(resp => API_RESPONSE.SUCCESS).catch(err => err);
  }

  public async deleteManyTubulars(ids) {
    let url = Config.APIUrlCore + environment.tubulars.deleteMany;
    return await this._dataLogic.postAsync(url, ids);
  }

  public async createUpdateManyTubulars(tubulars) {
    let url = Config.APIUrlCore + environment.tubulars.createUpdateMany;
    return await this._dataLogic.postAsync(url, tubulars);
  }

  public async postWellHeader(obj: any) {
    var url = this.getComponent("WellHeader", null);
    return await this._api.post<any>(url, obj).toPromise().then(res => {
      this.wellHeaderStringInit = JSON.stringify(this.wellHeaderDetails);
      this.WellHeaderIsChanged = false;
      this.wellHeaderSaveError = false;
      this.wellHeaderLoaded$.next(true);
      return API_RESPONSE.SUCCESS;
    }).catch(err => {
      if (err instanceof HttpErrorResponse && err.status === 400) {
        LS.removeLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Well.WellHeader)
        this.wellHeaderSaveError = false;
      }
      else {
        this.wellHeaderSaveError = true;
      }
      return err;
    });
  }

  public getComponent(component, id) {
    var url = "";
    switch (component) {
      case "wellborePost":
        url = Config.APIUrlCore + environment.wellbores.createOrUpdateWellbore;
        break;
      case "wellboreDelete":
        url = Config.APIUrlCore + environment.wellbores.deleteWellbore.replace("{wellboreId}", id);
        break;
      case "trajectoryPost":
        url = Config.APIUrlCore + environment.trajectories.trajectoryStation.post;
        break;
      case "trajectoryDelete":
        url = Config.APIUrlCore + environment.trajectories.trajectoryStation.delete.replace("{trajectoryStationId}", id);
        break;
      case "tubularPost":
        url = Config.APIUrlCore + environment.tubulars.post;
        break;
      case "createUpdateManyTubularPost":
        url = Config.APIUrlCore + environment.tubulars.createUpdateMany;
        break;
      case "tubularDelete":
        url = Config.APIUrlCore + environment.tubularComponents.post.replace("{tubularComponentId}", id);
        break;
      case "casingPost":
        url = Config.APIUrlCore + environment.wellboreGeometry.post;
        break;
      case "casingDelete":
        url = Config.APIUrlCore + environment.wellboreGeometry.delete.replace("{wellboreGeometrySectionId}", id);
        break;
      case "riserPost":
        url = Config.APIUrlCore + environment.risers.PostRiser;
        break;
      case "riserDelete":
        url = Config.APIUrlCore + environment.risers.deleteRiser.replace("{wellboreGeometrySectionId}", id);
        break;
      case "openholePost":
        url = Config.APIUrlCore + environment.openHoles.PostopenHoles;
        break;
      case "openholeDelete":
        url = Config.APIUrlCore + environment.openHoles.deleteopenHoles.replace("{wellboreGeometrySectionId}", id);
        break;
      case "lithoPost":
        url = Config.APIUrlCore + environment.lithostratigraphies.lithostratigraphiesStations.post;
        break;
      case "lithoDelete":
        url = Config.APIUrlCore + environment.lithostratigraphies.delete.replace("{lithostratigraphyStationId}", id);
        break;
      case "bitRunPost":
        url = Config.APIUrlCore + environment.bhaRuns.updateBHARun;
        break;
      case "bitRunDelete":
        url = Config.APIUrlCore + environment.bhaRuns.deleteBHARun.replace("{bhaRunId}", id);
        break;
      case "WellHeader":
        url = Config.APIUrlCore + environment.wellHeader.updateWellHeader;
        break;
      case "ThermalGradient":
        url = Config.APIUrlCore + environment.temperatureProfiles.post;
        break;
    }

    return url;
  }


  //loading trajectory with wellid
  public async LoadTrajectory(wellId: string, forceLoad: boolean) {
    var url = Config.APIUrlCore + environment.trajectories.get.replace("{wellId}", wellId);
    return await this._api.get<Trajectory>(url).toPromise()
      .then((resp) => {
        this.trajectoryId = resp.trajectoryWITSML.trajectoryId;
        return resp;
      })
      .catch((err) => {
        this.trajectoryId = null;
        return err;
      });

  }

  //loading trajectory with wellid

  //**loading trajectoryStation with trajectoryId */
  public async LoadTrajectoryStation(trajectoryId) {
    var url = Config.APIUrlCore + environment.trajectories.trajectoryStation.get.replace("{trajectoryId}", trajectoryId)
    return await this._api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }
  //**loading trajectoryStation with trajectoryId */

  public async loadWellGeometryCatalogs(forceLoad: boolean) {
    forceLoad = this._utilities.checkCatalogExpiration(forceLoad);
    var promises = [];
    // skip load when memory data is present and not forced reload, i.e. as on subsequent page loads
    (!forceLoad && this._utilities.catRisers && (this._utilities.catRisers.length > 0)) || promises.push(this._utilities.loadCatalogRisers(forceLoad));
    (!forceLoad && this._utilities.catCasings && (this._utilities.catCasings.length > 0)) || promises.push(this._utilities.loadCatalogCasings(forceLoad));
    //(!forceLoad && this._utilities.catTubular && (this._utilities.catTubular.length > 0)) || promises.push(this._utilities.loadCatalogTubularComponentTypes(forceLoad));

    return Promise.all(promises)
      .then(resp => {
        console.log("Well Geometry catalogs loaded");
        let errs = this._utilities.checkPromiseErrors(resp);
        if (errs > 0) return null;
        return resp;
      })
      .catch(() => {
        console.log("Well Geometry catalogs error");
        return null;
      });
  }

  public async loadWellHeaderCatalogs(forceLoad: boolean) {
    forceLoad = this._utilities.checkCatalogExpiration(forceLoad);
    var promises = [];
    // skip load when memory     
    //(!forceLoad && this.wellHeaderDetails) || promises.push(this.loadWellHeader(this.jobId, forceLoad));
    (!forceLoad && this._utilities.catLithoTypes && (this._utilities.catLithoTypes.length > 0)) || promises.push(this._utilities.loadCatalogLithoTypes(forceLoad));
    (!forceLoad && this._utilities.catLithos && (this._utilities.catLithos.length > 0)) || promises.push(this._utilities.loadCatalogLithos(forceLoad));
    (!forceLoad && this._utilities.catGeoRegionsCountries && (this._utilities.catGeoRegionsCountries.length > 0)) || promises.push(this._utilities.loadCatalogRegionsCountries(false));
    (!forceLoad && this._utilities.catWellPurpose && (this._utilities.catWellPurpose.length > 0)) || promises.push(this._utilities.loadCatalogWellPurpose(forceLoad));
    (!forceLoad && this._utilities.catWellOperators && (this._utilities.catWellOperators.length > 0)) || promises.push(this._utilities.loadCatalogWellOperators(forceLoad));
    (!forceLoad && this.wellHeaderOperators && (this.wellHeaderOperators.length > 0)) || promises.push(this.loadWellHeaderOperators(forceLoad));
    (!forceLoad && this.wellHeadertimeZones && (this.wellHeadertimeZones.length > 0)) || promises.push(this.loadWellHeaderTimeZones(forceLoad));
    (!forceLoad && this.wellHeaderTypes && (this.wellHeaderTypes.length > 0)) || promises.push(this.loadWellHeaderWellTypes(forceLoad));
    (!forceLoad && this.wellHeaderStatuses && (this.wellHeaderStatuses.length > 0)) || promises.push(this.loadWellHeaderWellStatus(forceLoad));
    (!forceLoad && this.wellHeaderPurposes && (this.wellHeaderPurposes.length > 0)) || promises.push(this.loadWellHeaderPurposes(forceLoad));
    (!forceLoad && this.wellHeaderBasins && (this.wellHeaderBasins.length > 0)) || promises.push(this.loadWellHeaderBasins(forceLoad));

    return Promise.all(promises)
      .then(resp => {
        console.log("Well Header catalogs loaded");
        let errs = this._utilities.checkPromiseErrors(resp);
        if (errs > 0) return null;
        return resp;
      })
      .catch(() => {
        console.log("Well Header catalogs error");
        return null;
      });
  }

  //loading formation with wellId
  public geothermal(wellId: string) {
    let url = Config.APIUrlCore + environment.temperatureProfiles.get.replace("{wellId}", wellId);
    return new Observable<any>(ob => {
      this._api.get<any>(url).subscribe(res => {
        ob.next(res);
        ob.complete();
      },
        (error) => {
          ob.next(null);
          ob.complete();
        });
    })
  }

  public porePressure(wellId: string) {
    let url = Config.APIUrlCore + environment.porePressureGradients.get.replace("{wellId}", wellId)
    return new Observable<any>(ob => {
      this._api.get<any>(url).subscribe(res => {
        ob.next(res);
        ob.complete();
      },
        (error) => {
          ob.next(null);
          ob.complete();
        });
    })
  }

  public fractureGradients(wellId: string) {
    let url = Config.APIUrlCore + environment.fractureGradients.get.replace("{wellId}", wellId);
    return new Observable<any>(ob => {
      this._api.get<any>(url).subscribe(res => {
        ob.next(res);
        ob.complete();
      },
        (error) => {
          ob.next(null);
          ob.complete();
        });
    })
  }

  public lithography(wellId: string) {
    let url = Config.APIUrlCore + environment.lithostratigraphies.get.replace("{wellId}", wellId);
    return new Observable<any>(ob => {
      this._api.get<any>(url).subscribe(res => {
        if (res && res.lithostratigraphyId) {
          let urlLitho = Config.APIUrlCore + environment.lithostratigraphies.lithostratigraphiesStations.get.replace("{lithostratigraphyId}", res.lithostratigraphyId);
          this._api.get<any>(urlLitho).subscribe(data => {
            ob.next(data);
            ob.complete();
          },
            (error) => {
              ob.next([]);
              ob.complete();
            });
        } else {
          ob.next([]);
          ob.complete();
        }
      },
        (error) => {
          ob.next([]);
          ob.complete();
        });
    })
  }

  public async copyFormation(payload) {
    return await this._api.post(Config.APIUrlCore + environment.formation.copyFormation, payload).toPromise()
      .then(res => {
        this.Litho_ID = res;
        return API_RESPONSE.SUCCESS
      }).catch(err => {
        return err;
      })
  }
}
