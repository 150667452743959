<div id="divTable">
  <!-- ================== Table ============== -->
  <table class="dynamic-table" [ngClass]="_utilities.adasThemeClass">
    <!-- Table Head -->
    <thead class="dynamic-head">
      <tr class="dynamic-header-row  dynamic-header-border" style="border: 0 !important;" sortable-column="header">
        <th class="dynamic-header" *ngFor="let header of headers">

          <div 
            [ngClass]="sortable ? 'pointer dynamic-table-header inline-flex' : 'dynamic-table-header inline-flex'" 
            [id]="'header-' + header.key + tableName"
            (click)="sort(header.key)">
            
           {{header.title|translate}}

            <span *ngIf="sortable" class="ic-wrapper">&nbsp;
              <span *ngIf="sorted(header.key) == 'desc'" class="ic-arrow-down"></span>
              <span *ngIf="sorted(header.key) == 'asc'" class="ic-arrow-up"></span>
            </span>

          </div>

          <div *ngIf="header.uom == true" class="inline-flex">
          </div>
        </th>
      </tr>

      <!-- Filter input row -->
      <tr *ngIf="filterable" [formGroup]="filterForm" class="dynamic-header-row  dynamic-header-border" sortable-column="header">
          <th class="dynamic-header" *ngFor="let header of headers">
              <input formControlName={{header.key}}
              *ngIf="header.icon !== true"
              [type]="header.type"
              class="input-field"/>
          </th>
      </tr>
    </thead>

    <!-- Table Body -->
    <tbody #listViewBody [style.max-height]="maxHeight" class="dynamic-body">
      <tr [ngClass]="hasRowSelectEvent ? 'h-row event':'h-row'" *ngFor="let row of (data$ | async)?.results; let i = index" (click)="onRowSelect(row, i, listViewBody)">
        <td class ="h-cell" *ngFor="let header of headers">
          <div class="measurement dynamic-div" 
            appToolTip [tooltip]="row[header.key]" [tooltiplength]="header.maxColumnDisplayLength">
            {{row | cellValue:header}}
          </div>

          <div *ngIf="header.icon == true" (click)="cellEvent($event, header, row)">
            <i [ngStyle]="header.style" class="{{header.class}}"></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<pagination [boundaryLinks]="true" [directionLinks]="true"
  [previousText]="PAGINATION_PROPERTIES.PREVIOUS_TEXT" [firstText]="PAGINATION_PROPERTIES.FIRST_TEXT"
  [nextText]="PAGINATION_PROPERTIES.NEXT_TEXT" [lastText]="PAGINATION_PROPERTIES.LAST_TEXT" [totalItems]="totalCount"
  [itemsPerPage]="pageSize" [maxSize]="PAGINATION_PROPERTIES.MAX_SIZE" (pageChanged)="pageChanged($event)"
  [rotate]="true">
</pagination>