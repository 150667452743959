import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]

})
export class MenuBarComponent implements OnInit {

  public componentDestroyed$: Subject<boolean> = new Subject();
  public logoutPage: boolean = false;
  public bgChange;
  // public lang: string;
  // public notificationShow: boolean;
  // public showDashboard: any;;
  // public hideDashboard: boolean = false;
  public items: any;

  get isAdmin() : boolean { return this._settings.isAdmin() }

  constructor(
    private _settings: SettingsService
    //public _translate: TranslateService,
    //public messageService: MessageService
  ) {
    //this.lang = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : 'en';
    //this._translate.setDefaultLang(this.lang);
  }

  ngOnInit() {
    this.logoutPage = window.location.pathname === environment.redirectLogoutUriFolder;
  }

  get isLocal(): boolean {
    return environment.local;
  }

  get isOnInternet(): boolean {
    return environment.web.isOnInternet;
  }

  get isOnNetwork(): boolean {
    return environment.web.isOnNetwork;
  }

  backgroundChange(evt) {
    this.bgChange = evt;
  }

  // showSuccess() {
  //   this.messageService.add({
  //     summary: 'SURGE/SWAB',
  //     detail: 'Black Hornet Well',
  //     closable: true,
  //     severity: 'warn',
  //     life: 3000
  //   });
  //   this.notificationShow = true;
  // }
}
