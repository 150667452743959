import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PAGES, TOASTER_TYPE } from 'src/app/shared/enums/enums';
import { SAVE_DATA } from 'src/app/utils/constants';
import { environment } from 'src/environments/environment';
import { NetworkStatus } from '../models/common.model';
import { ApiService } from './api.service';
import { Config } from './config';
import { UtilitiesService } from './utilitiesservice.service';

@Injectable({
  providedIn: 'root'
})

export class SystemService {

  constructor(
    protected _api: ApiService,
    public _router: Router,
    public _utilities: UtilitiesService,
  ) {
  }

  public checkConnection(): Observable<boolean> {
    const url = Config.APIUrlPlatform + environment.systemStatus.checkConnection;
    return new Observable<boolean>(ob => {
      this._api.get<NetworkStatus>(url).subscribe(res => {
        if (this._api.testOffline) res.Connected = false;
        if (this._api.testOffVpn) res.VpnActive = false;
        if (res.VpnActive) res.Connected = true;
        // if we have vpn we have internet, quick fix
        environment.web.isOnInternet = res.Connected;
        environment.web.isOnNetwork = res.VpnActive;
        ob.next(res?.Connected || false);
        ob.complete();
      },
        error => {
          environment.web.isOnInternet = false;
          environment.web.isOnNetwork = false;
          ob.next(false);
          ob.complete();
        });
    });
  }

  public isOnInternet(showToastrWithTimer: boolean): Promise<boolean> {
    return this.checkConnection().toPromise()
      .then((res) => {
        return environment.web.isOnInternet;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => {
        if (!environment.web.isOnNetwork && showToastrWithTimer) {
          this._router.navigate([PAGES.JobsDashboard]);
          let msg = "You must be connected to the internet to access that page.";
          this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
        }
      });
  }

  public isOnNetwork(showToastrWithTimer: boolean): Promise<boolean> {
    return this.checkConnection().toPromise()
      .then((res) => {
        return environment.web.isOnNetwork;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => {
        if (!environment.web.isOnNetwork && showToastrWithTimer) {
          this._router.navigate([PAGES.JobsDashboard]);
          let msg = this._utilities.getTranslationNow('errors.vpnNetwork');
          this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
        }
      });
  }


}