<div class="container xjob-padding mt-0 mb-0">
  <div *ngIf="pageLoaded" class="container-fluid card-panels">
    <!-- title-->
    <div class="row">
      <div class="col-12">
        <div class="h1 page-name">{{'job.jobsTransfer'|translate}}</div>
      </div>
    </div>

    <div class="d-flex sub-header-info">
      <div class=" d-flex flex-row mr-auto">
        <div class="d-flex align-items-center d-flex justify-content-end">
          <label class="small pr-2">{{jobCount}} {{'job.jobs'|translate}}</label>
        </div>
      </div>
      <div class="d-flex align-items-center d-flex justify-content-end">
        <span class="switch-label">{{'job.showLocal'|translate}}</span>
        <label class="switch switch-icon">
          <input type="checkbox" name="settingsTheme" (change)="toggleChanged();" [(ngModel)]="isLocal">
          <span class="slider round"></span>
        </label>
        <span class="switch-label pl-1">{{'job.showOnline'|translate}}</span>
      </div>
    </div>

    <div *ngIf="!jobLoaded; else dataLoadTmpl" class="loading-icon-wellProfile">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
    <ng-template #dataLoadTmpl>
      <div class="mt-3" >
        <app-h-table (row)="clickRow($event)" [fullTable]="true"
          [hidden]="false" [enterCustom]="false" [download]="false" [data]="listJobs"
          [headers]="headers" [rowPointer]="false" [pageSize]="pageSize" [colSettings]="colSettings"
          [maxColumnDisplayLength]="20">
        </app-h-table>
      </div>
    </ng-template>
  </div>
</div>
