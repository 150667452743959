import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges, OnInit, AfterViewInit } from '@angular/core';
import { FieldLevelUoMSettings } from '../models/columnSettings';
import { UomConversionService } from '../../core/services/uom-conversion.service';
import { UnitConversionService } from '../../core/services/unit-conversion.service';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appUomPrecision]'
})
export class UomPrecisionDirective implements OnChanges, OnInit, AfterViewInit {
  @Input('currentUnit') public currentUnit: FieldLevelUoMSettings;
  @Input('fixedDecimals') public fixedDecimals: FieldLevelUoMSettings;
  @Input('pageLoaded') public pageLoaded: boolean;
  @Input('formControlName') public formControlName: string;
  @Input('formControl') public formControl: AbstractControl;
  @Input('formGroup') public formGroup: AbstractControl;
  public infocus = false

  constructor(
    public elRef: ElementRef,
    public _uomConversion: UomConversionService,
    public _unitConversion: UnitConversionService) {
  }

  ngOnInit() {
    // this.initConvertedValueDisplay();
  }

  ngOnChanges(changes: SimpleChanges) {
    //this.initConvertedValueDisplay();
  }

  ngAfterViewInit(): void {
    this.initConvertedValueDisplay();
  }

  // sets attribute values and formats when code sets a value
  @HostListener("ngModelChange", ["$event"]) onNgModelChange(value) {
    if (!this.checkValueNull()) {
      this.elRef.nativeElement.setAttribute("data-bk", this.elRef.nativeElement.value);
      this.elRef.nativeElement.setAttribute("data-full-number", this.elRef.nativeElement.value);
      if (!this.infocus) {
        this.elRef.nativeElement.value = this._uomConversion.calculateConvertedValueDisplay(this.fixedDecimals, this.elRef.nativeElement.value);
      }
    }
  }

  @HostListener('focus') onfocus() {
    this.infocus = true;
    if (!this.checkValueNull()) {
      this.elRef.nativeElement.setAttribute("data-bk", this.elRef.nativeElement.value);
      this.elRef.nativeElement.value = this.elRef.nativeElement.getAttribute("data-full-number");
    }
  }

  @HostListener('blur') onblur() {
    this.infocus = false
    if (!this.checkValueNull()) {
      //let org = this.elRef.nativeElement.getAttribute("data-original-number");
      let num = this.elRef.nativeElement.getAttribute("data-bk");
      this.elRef.nativeElement.value = this._uomConversion.calculateConvertedValueDisplay(this.fixedDecimals, num);
      this.elRef.nativeElement.setAttribute("data-bk", null);
    }
  }

  // sets attribute values when user enters a value
  @HostListener('change') onchange() {
    if (!this.checkValueNull()) {
      this.elRef.nativeElement.setAttribute("data-bk", this.elRef.nativeElement.value);
      this.elRef.nativeElement.setAttribute("data-full-number", this.elRef.nativeElement.value);
    }
  }

  public checkValueNull() {
    if (this.elRef.nativeElement.value === "" || this.elRef.nativeElement.value === undefined || this.fixedDecimals === undefined) {
      return true;
    }
  }

  public initConvertedValueDisplay() {

    if (!this.checkValueNull() && this.pageLoaded) {
      //this.elRef.nativeElement.setAttribute("data-original-number", this.elRef.nativeElement.value);
      this.elRef.nativeElement.value = this._unitConversion.convertValue(this.currentUnit, this.elRef.nativeElement.value);
      this.elRef.nativeElement.setAttribute("data-full-number", this.elRef.nativeElement.value);
      if (this.formControl) {
        this.formControl.patchValue(this.elRef.nativeElement.value);
      }
      this.elRef.nativeElement.value = this._uomConversion.calculateConvertedValueDisplay(this.fixedDecimals, this.elRef.nativeElement.value);
    }
  }

}
