import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import * as LS from 'src/app/utils/localstorage';
import { Config } from './config';
import { Settings, PostSettings } from '../models/settingsModel.model';
import { defaultEnvironment } from 'src/environments/environment.defaults';
import { ApiService } from './api.service';
import { UtilitiesService } from './utilitiesservice.service';
import { UnitSystemsService } from './unitsystems.service';
import { DataLogicService } from './data-logic.service';
import { SAVE_DATA } from 'src/app/utils/constants';
import { UserModel } from '../models/userModel';
import { MsalService } from '@azure/msal-angular';
import { ROLES, TOASTER_TYPE, UNITS } from 'src/app/shared/enums/enums';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SettingsService {
  public theme$: BehaviorSubject<string>;
  public settings: Settings;
  public timeZones: any;

  // used by components
  public selectedTheme: string;
  public selectedTimeZone: string;

  constructor(
    public _api: ApiService,
    public _auth: MsalService,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public dataLogic: DataLogicService,
    //public http: HttpClient
  ) {
    this.theme$ = new BehaviorSubject<string>("dark");
    //this.loadTimeZones(false);
  }

  getTheTheme(): BehaviorSubject<string> {
    return this.theme$;
  }

  setTheTheme(val: string) {
    this.theme$.next(val);
  }

  loadTimeZones(forceLoad) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.Geo.TimeZones;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this._utilities.catGeoTimeZones = data;
      this.timeZones = data;
      return data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.wellCatalogs.timeZones;
      return this._api.get<any[]>(url).toPromise()
        .then((data) => {
          this._utilities.storeCatalogs(key, data);
          this._utilities.catGeoTimeZones = data;
          this.timeZones = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
  }

  async getUserSettings(forceLoad: boolean) {
    this._utilities.currentUser = new UserModel();
    if (environment.local) {
      const user = LS.getSessionStorage(LS.LOCAL_STORAGE_KEY.Token.GenUsername);
      this._utilities.currentUser.fullName = user;
      this._utilities.currentUser.email = `${user}@halliburton.com`;
      this._utilities.currentUser.userId = `${user}@halliburton.com`;
    } else {
      let acct = this._auth.getAccount();
      if (!acct) return;
      this._utilities.currentUser.email = acct.userName;
      this._utilities.currentUser.userId = acct.userName;
      this._utilities.currentUser.fullName = acct.name;
    }

    this._utilities.fullName$.next(this._utilities.currentUser.fullName);
    this._utilities.userModelObj$.next(this._utilities.currentUser);
    var key = LS.LOCAL_STORAGE_KEY.User.Settings;
    var data = !forceLoad ? LS.getLocalStorage(key) : null;
    if (data && (data.userId === this._utilities.currentUser.userId)) {
      this.initUserSettings(data);
      return data;
    }
    else {
      var url = Config.APIUrlCore + environment.user.getProfileSettings.replace("{userId}", this._utilities.currentUser.userId);
      return await this._api.get<Settings>(url).toPromise()
        .then(data => {
          this.initUserSettings(data);
          return data;
        })
        .catch((err) => {
          if (err && err.status != 404) {
            if (environment.local) {
              const hasDbConnectionError = err.error.some(message => {
                return message.includes("MongoConnectionException") || message.includes("10061");
              });

              if (hasDbConnectionError) {
                throw ("BaraLogixHubLocalDB 1.0 or higher is required");
              }
            }

            throw ("getUserSettings failed: " + err.statusText);
          }
          let data = new Settings();
          data.userId = this._utilities.currentUser.userId;
          //data.activeUnitSystem = UNITS.API;
          data.activeUnitSystemId = UNITS.API_ID;
          data.activeTheme = defaultEnvironment.darkTheme;
          data.activeTimezone = "GMT-06:00 Central Time";
          data.assignedRole = ROLES.Engineer;
          let promise = this.postUserSettings(data);
          return Promise.all([promise])
            .then(data => {
              if (data && data[0] instanceof HttpErrorResponse) return;
              // after creating new profile, go through the startup process of getting it, important!
              return this.getUserSettings(true);
            })
        });
    }
  }

  initUserSettings(data: Settings) {
    var def = new Settings();
    this.settings = { ...def, ...data };
    //this.settings.lastVisitedJob = [];
    this.selectedTheme = data.activeTheme;
    this.selectedTimeZone = data.activeTimezone;

    // merge units to unitsystems
    if (this._unitSystems.catQuantityUnits) {
      this.settings.unitSystems.forEach(function (unitsystem, index, arr) {
        unitsystem.quantities.map(q => delete q.units);
        arr[index].quantities = unitsystem.quantities;
      });
    }
    this._unitSystems.unitSystems = this.settings.unitSystems;

    // validate global system exists, if not reset to API
    let unitsystem = this._unitSystems.findUnitSystem(this.settings.activeUnitSystemId);
    if (!unitsystem) this.settings.activeUnitSystemId = UNITS.API_ID;
    //this.settings.activeUnitSystem = unitsystem ? unitsystem.name : UNITS.API;
    this._unitSystems._utilities.currentUser.settings = this.settings;
    // save to cache
    this._unitSystems.sortUnitSystems();
    LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.User.Settings, this.settings);
    this.setTheTheme(this.selectedTheme);
  }

  postUserSettings(settingsModel: Settings) {
    let obj = new PostSettings();
    obj.userId = settingsModel.userId;
    obj.activeTheme = settingsModel.activeTheme;
    obj.activeTimezone = settingsModel.activeTimezone;
    obj.activeUnitSystemId = settingsModel.activeUnitSystemId;
    obj.assignedRole = settingsModel.assignedRole;
    obj.uomProfile = settingsModel.uomProfile;
    var url = Config.APIUrlCore + environment.user.postProfileSettings;
    return this._api.post<PostSettings>(url, obj).toPromise()
      .then(data => {
        this._utilities.currentUser.settings = settingsModel;
        LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.User.Settings, settingsModel);
        var msg = this._utilities.getTranslationNow2('toastr.itemupdated', { item: 'User Profile' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.SUCCESS, msg, SAVE_DATA.Toastr_Success);
        return settingsModel;
      })
      .catch(err => {
        var msg = this._utilities.getTranslationNow2('toastr.itemnotupdated', { item: 'User Profile' });
        this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, msg, SAVE_DATA.Toastr_Fail);
        return err;
      });
  }

  public postLastVisitedJob(job: any) {
    var key = LS.LOCAL_STORAGE_KEY.User.RecentJobs;
    var userId = this._utilities.currentUser.userId;
    var id = environment.local ? job.jobId : job.baralogixId;
    var url = Config.APIUrlCore + environment.user.postLastVisitedJob;
    var payload = {
      'jobId': id,
      'userId': userId
    }

    this.dataLogic.post(url, payload, key).subscribe(res => {
      console.log("Post last visited job", payload);
    });
  }

  public getLastVisitedJob() {
    var key = LS.LOCAL_STORAGE_KEY.User.RecentJobs;
    var userId = this._utilities.currentUser.userId;
    var url = Config.APIUrlCore + environment.user.getLastVisitedJob.replace("{userId}", userId);

    return this.dataLogic.get(url, key);
  }

  public getAllProfileSettings(): Observable<Array<Settings>> {
    var url = Config.APIUrlCore + environment.user.getAllProfileSettings;

    return new Observable<any>(ob => {
      this._api.get(url).subscribe(res => {
        ob.next(res);
        ob.complete();
        return;
      }, (error) => {
        ob.next([]);
        ob.complete();
        return;
      });
    });
  }

  public async updateUserRoleAsync(payload) {
    const url = Config.APIUrlCore + environment.user.postUserRole;
    return await this.dataLogic.postAsync(url, payload);
  }


  public isAdmin() : boolean {
    return this._utilities.currentUser?.settings?.assignedRole === ROLES.Admin;
  }
}