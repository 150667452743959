<ngx-smart-modal #uomModal identifier="uomModal" (onOpen)="getModalData(); scroll(uomModaltarget)" [closable]="false"
    customClass="modal-content full-screen no-padding">

    <form #customOemForm="ngForm" novalidate>
        <div class="container">
            <div class="h1 page-name">{{pageHeader}}</div>
            <div #uomModaltarget class="modal-body adjust-height">
                <div class="unit-system-container">
                    <div class="row">
                        <div class="col-sm-4 col-md-4">
                            <label>{{'unitSystemsProfile.unitSystemName'|translate}}</label>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <label>{{'unitSystemsProfile.quantity'|translate}}</label>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <label>{{'unitSystemsProfile.unit'|translate}}</label>
                        </div>
                    </div>

                    <div class="row divider col-sm-10 col-md-10"
                        [ngClass]="showCustomDelete || showCustomCreate ? 'xheader-divider-custom' : 'xheader-divider'">
                    </div>

                    <div *ngIf="uomModalReady" class="row unit-system-body">
                        <div class="col-sm-4 col-md-4 unit-system-name-block">

                            <div *ngIf="showCustomCreate" class="unit-row">
                                <p class="small">{{'unitSystemsProfile.baseHeader' | translate}}</p>
                                <select class="select-dropdown" [(ngModel)]="selectedBaseSystemId"
                                    [ngModelOptions]="{standalone: true}"
                                    (change)="setSelectedValue(selectedBaseSystemId); onQuantitySelected();">
                                    <option *ngFor="let optionItem of unitSystems"
                                        value="{{optionItem.unitSystemId}}" [ngvalue]="optionItem.unitSystemId">
                                        {{optionItem.name}}</option>
                                </select>
                            </div>

                            <div *ngIf="showMainSelect && !showCustomCreate" class="unit-row">
                                <select id="unitSystemNameSelect" name="unitSystemNameSelect"
                                    class="select-dropdown unit-box" [(ngModel)]="selectedUnitSystemId"
                                    [ngModelOptions]="{standalone: true}"
                                    (change)="setSelectedUnitSystem(selectedUnitSystemId);">
                                    <option *ngFor="let optionItem of unitSystems"
                                        [ngValue]="optionItem.unitSystemId">
                                        {{optionItem.name}}</option>
                                    <!-- <option *ngIf="uomModalType == 'job'" value="_delete"
                                        class="color-dark-brown">
                                        {{'unitSystemsProfile.clearCustom'|translate}}</option> -->
                                </select>

                                <img id="deleteCustom" *ngIf="showCustomDelete " class="closeIconOrange close-custom"
                                    (click)="deleteUnitSystemConfirm()" src="assets/images/icons/ic-close_orange.svg"
                                    title="{{'unitSystemsProfile.deleteCustom'|translate}}">
                            </div>

                            <input id="customPlaceholder" name="customPlaceholder"
                                [readonly]="isReadOnly && !showCustomCreate"
                                placeholder="{{'unitSystemsProfile.enterCustomName'| translate}}"
                                [(ngModel)]="customUOMname" [ngModelOptions]="{standalone: true}" required
                                [ngClass]="{'input-danger' : isUnitNameError}" #enterCustomSetting>

                            <div *ngIf="isUnitSystemNameError">
                                <p *ngIf="isUnitSystemNameErrorExists" class="small error-text">
                                    {{'errors.alreadyExists' | translate }}</p>
                                <p *ngIf="isUnitSystemNameErrorEmpty" class="small error-text">
                                    {{'errors.canNotBeEmpty' | translate }}</p>
                                <p></p>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 unit-system-name-block">
                            <div *ngFor="let item of _unitSystems.selectedUnitSystem.quantities" name="quantities">
                                <div class="row unit-row" *ngIf="!unusedUnits.includes(item.quantity)">
                                    <div class="col-sm-6 col-md-6">
                                        <label class="unit-quantity unit-box">{{item.quantity}}</label>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <input name="selectedUnit" *ngIf="isReadOnly && !showCustomCreate"
                                            [readonly]="isReadOnly" type="text" value="{{item.selectedUnit}}">
                                        <div *ngIf="showCustomCreate || showCustomDelete">
                                            <select name="{{item.quantity}}" (change)="onQuantitySelected()"
                                                [(ngModel)]="item.selectedUnit">
                                                <option *ngFor="let optionItem of quantityUnits(item)"
                                                    [selected]="optionItem === item?.selectedUnit" [value]="optionItem">
                                                    {{optionItem}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="divider pt-4"></div>

            <div class="row pt-4 float-right">
                <button type="cancel" (click)="closeUOMModal();"
                    class="bttn bttn-secondary">{{'buttons.cancel'|translate}}</button>
                <button type="submit" [hidden]="isSaveDisabled" (click)="createUpdateUnitSystem();"
                    class="bttn bttn-primary">{{'buttons.save'|translate}}</button>
            </div>

        </div>
    </form>
</ngx-smart-modal>

<app-confirm-popup [message]="popupMessage" [messageValue]="" (handleConfirm)="handleConfirmation()">
</app-confirm-popup>