import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LocalTokenService } from '../services/localtoken.service';

@Injectable()
export class LocalTokenGuard implements CanActivate {
  constructor(
    public _auth: LocalTokenService
  ) {
  }

  canActivate() {
    return this._auth.getTokenGenerator(false)
      .then((token) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }
}
