import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FluidSetsService } from '../services/fluid-sets/fluid-sets.service';

@Injectable()
export class FluidSetCatalogsResolver implements Resolve<void> {
  constructor(
    public _fluids: FluidSetsService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._fluids.loadFluidSetsCatalogs(false);
  }
}