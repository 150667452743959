<div *ngIf="chartData" class="chart-container">
  <div class="base-oil-chart">
    <app-line-chart
      [chartTitle]="chartTitle"
      [chartOptions]="chartOptions"
      [chartData]="chartData"
      [chartFrame]="true">
    </app-line-chart>
  </div>
  <div class="base-oil-table">
    <app-table-toolbar
      [columns] ="columns"
      [data]="tableData">
    </app-table-toolbar>
  </div>
</div>