<div class="container" style="margin-top:80px;">
  <div class="h2">Text</div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th>Class</th>
        <th>Style</th>
        <th>Example</th>
        <th>SCSS</th>
      </tr>
    </thead>
    <tr>
      <td class="h3">HEADINGS</td>
      <td>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <td>H1</td>
      <td>
        <div class="h1">H1 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h1">H1 Header Well Profile</div> ]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 52px; ]]>
      </td>
    </tr>
    <tr>
      <td>H1 + Border</td>
      <td>
        <div class="h1 add-border">H1 with Border</div>
      </td>
      <td>
        <![CDATA[ <div class="h1 add-border">H1 with Border</div> ]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 52px; padding-bottom: .5rem; ]]> <br>
        <![CDATA[ border-bottom: 1px solid $primary-grey-4; ]]>
      </td>
    </tr>
    <tr>
      <td>H1 + Border + Link</td>
      <td>
        <div class="h1 add-border">H1 with Border and Link
          <div class="add-link link-primary">
            <span>Link</span>
          </div>
        </div>
      </td>
      <td></td>
      <td>
        <![CDATA[ <div class="h1 add-border">H1 with Border]]> <br>
        <![CDATA[ <div class="add-link link-primary"> ]]> <br>
        <![CDATA[ <span>Link</span> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]>
      </td>
    </tr>
    <tr>
      <td>H1_5</td>
      <td>
        <div class="h1_5">H1 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h1_5">H1_5 Header Well Profile</div> ]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 40px; ]]>
      </td>
    </tr>
    <tr>
      <td>H2</td>
      <td>
        <div class="h2">H2 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h2">H2 Header Well Profile</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 30px; ]]>
      </td>
    </tr>
    <tr>
      <td>H3</td>
      <td>
        <div class="h3">H3 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h3">H3 Header Well Profile</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 26px; ]]>
      </td>
    </tr>
    <tr>
      <td>H3 Uppercase</td>
      <td>
        <div class="h3 h-upper">H3 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h3 h-upper">H3 Header Well Profile</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 26px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]>
      </td>
    </tr>
    <tr>
      <td>H4</td>
      <td>
        <div class="h4">H4 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h4">H4 Header Well Profile</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 20px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]>
      </td>
    </tr>
    <tr>
      <td>H5</td>
      <td>
        <div class="h5">H5 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h5">H5 Header Well Profile</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]>
      </td>
    </tr>
    <tr>
      <td>H5 Gray</td>
      <td>
        <div class="h5-gray">H5 Header Well Profile</div>
      </td>
      <td>
        <![CDATA[ <div class="h5-gray">H5 Header Well Profile</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]> <br>
        <![CDATA[ color: #A8A8A8 ]]>
      </td>
    </tr>
    <tr>
      <td>Table Header</td>
      <td>
        <table>
          <thead>
            <tr>
              <th>Heading 1</th>
              <th>Heading 2</th>
              <th>Heading 3</th>
            </tr>
          </thead>
        </table>
      </td>
      <td>
        <![CDATA[ <table>]]> <br>
        <![CDATA[   <thead>]]><br>
        <![CDATA[     <tr>]]><br>
        <![CDATA[       <th>Heading 1</th>]]><br>
        <![CDATA[       <th>Heading 2</th>]]><br>
        <![CDATA[       <th>Heading 3</th>]]><br>
        <![CDATA[     </tr>]]><br>
        <![CDATA[   </thead>]]><br>
        <![CDATA[ </table>]]>
      </td>
      <td>
        <![CDATA[ font-size: 14px; font-weight: 400; ]]> <br>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ color: white; line-height: 21px; letter-spacing: .8px; ]]>
      </td>
    </tr>
    <tr>
      <td>Graph Chart Title</td>
      <td>
        <div class="graph-chart-title">Graph Chart Title</div>
      </td>
      <td>
        <![CDATA[ <div class="graph-chart-title">Graph Chart Title</div> ]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 57 Condensed" ]]> <br>
        <![CDATA[ font-size: 18px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]>
      </td>
    </tr>
    <tr>
      <td class="h3">BODY</td>
      <td>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <td>Paragraph Large</td>
      <td>
        <p class="large">Paragraph Large</p>
      </td>
      <td>
        <![CDATA[<p class="large">Paragraph Large</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 18px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]>
      </td>
    </tr>
    <tr>
      <td>Paragraph Medium</td>
      <td>
        <p class="medium">Paragraph Medium</p>
      </td>
      <td>
        <![CDATA[<p class="medium">Paragraph Medium</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 16px; ]]>
      </td>
    </tr>
    <tr>
      <td>Navigation Pane Medium</td>
      <td>
        <p class="medium-nav">Navigation Pane Medium</p>
      </td>
      <td>
        <![CDATA[<p class="medium-nav">Navigation Pane Medium</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 16px; ]]> <br>
        <![CDATA[ color: white !important; ]]>
      </td>
    </tr>
    <tr>
      <td>Paragraph Default</td>
      <td>
        <p class="default">Paragraph Default</p>
      </td>
      <td>
        <![CDATA[<p class="default">Paragraph Default</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]>
      </td>
    </tr>
    <tr>
      <td>Paragraph Gray</td>
      <td>
        <p class="default-gray">Paragraph Gray</p>
      </td>
      <td>
        <![CDATA[<p class="default-gray">Paragraph Default</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]> <br>
        <![CDATA[ color: #A8A8A8; ]]>
      </td>
    </tr>
    <tr>
      <td>Paragraph Small</td>
      <td>
        <p class="small">Paragraph Small</p>
      </td>
      <td>
        <![CDATA[<p class="small">Paragraph Small</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 12px; ]]>
      </td>
    </tr>
    <tr>
      <td>Paragraph Small Gray</td>
      <td>
        <p class="small-gray">Paragraph Small Gray</p>
      </td>
      <td>
        <![CDATA[<p class="small-gray">Paragraph Small Gray</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 12px; ]]> <br>
        <![CDATA[ color: #A8A8A8; ]]>
      </td>
    </tr>
    <tr>
      <td>Data Value</td>
      <td>
        <p class="data-val">Data Value</p>
      </td>
      <td>
        <![CDATA[<p class="data-val">Data Value</p>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 25px; ]]>
      </td>
    </tr>
    <tr>
      <td>Realtime Value</td>
      <td>
        <div class="rt-val">RT Value</div>
      </td>
      <td>
        <![CDATA[<div class="rt-val">RT Value</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 24px; ]]>
      </td>
    </tr>
    <tr>
      <td>Realtime Value Grey</td>
      <td>
        <div class="rt-val-grey">RT Value Grey</div>
      </td>
      <td>
        <![CDATA[<div class="rt-val-grey">RT Value Grey</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 24px; color: #A8A8A8; ]]> 
      </td>
    </tr>
    <tr>
      <td>Card Name</td>
      <td>
        <div class="card-name">Card Name</div>
      </td>
      <td>
        <![CDATA[ <div class="card-name">Card Name</div> ]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 16px; ]]> <br>
        <![CDATA[ font-weight: bold; ]]>
      </td>
    </tr>
    <tr>
      <td class="h3">LINKS</td>
      <td>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <td>Primary Link</td>
      <td>
        <div class="link-primary">Primary Link</div>
      </td>
      <td>
        <![CDATA[ <div class="link-primary">Primary Link</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]> <br>
        <![CDATA[ cursor: pointer; ]]> <br>
        <![CDATA[ color: #D57900; display: inline-block;]]> <br>
        <br>
        <![CDATA[ Active: ]]> <br>
        <![CDATA[ color: #AE5C0A; ]]>
      </td>
    </tr>
    <tr>
      <td>Plus Icon + Primary Link</td>
      <td>
        <div class="link-primary">
          <span class="icon-unit-systems-add-icon"></span>
          <span> Primary Link</span>
        </div>
      </td>
      <td>
        <![CDATA[ <div class="link-primary"> ]]> <br>
        <![CDATA[ <span class="icon-unit-systems-add-icon"></span> ]]> <br>
        <![CDATA[ <span> Primary Link</span> ]]> <br>
        <![CDATA[ </div> ]]>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>Time Icon + Primary Link</td>
      <td>
        <span class="link-primary pointer">
          <img class="link-icon" src="assets/images/icons/ic-time.svg" />
          <span>Primary Link</span>
        </span>
      </td>
      <td>
        <![CDATA[ <span class="link-primary pointer"> ]]> <br>
        <![CDATA[ <img class="link-icon" src="assets/images/icons/ic-time.svg"/> ]]> <br>
        <![CDATA[ <span>Primary Link</span> ]]> <br>
        <![CDATA[ </span> ]]>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>Secondary Link</td>
      <td>
        <div class="link-secondary">Secondary Link</div>
      </td>
      <td>
        <![CDATA[ <div class="link-secondary">Secondary Link</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]> <br>
        <![CDATA[ cursor: pointer; ]]> <br>
        <![CDATA[ color: #FFFFFF; ]]> <br>
        <![CDATA[ border-bottom: 1px solid white; ]]> <br>
        <![CDATA[ padding-bottom: 3px; ]]> <br>
        <![CDATA[ width: fit-content; ]]>
        <br>
        <![CDATA[ Active: ]]> <br>
        <![CDATA[ color: #b1b1b1; ]]>
      </td>
    </tr>
    <tr>
      <td>Mail Link</td>
      <td>
        <div class="link-mail">Mail Link</div>
      </td>
      <td>
        <![CDATA[<div class="link-mail">Mail Link</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 12px; ]]> <br>
        <![CDATA[ color: #FFFFFF; ]]> <br>
        <![CDATA[ cursor: pointer; ]]> <br>
        <![CDATA[ text-decoration: underline; ]]> <br>
        <![CDATA[ .light & {color: #121314;}]]> <br>
        <br>
        <![CDATA[ Active: ]]> <br>
        <![CDATA[ color: #b1b1b1]]>
      </td>
    </tr>
    <tr>
      <td class="h3">LABELS</td>
      <td>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <td>Label</td>
      <td>
        <label>Label</label>
      </td>
      <td>
        <![CDATA[<label>Label</label>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 14px; ]]> <br>
        <![CDATA[ color: #A8A8A8; margin-bottom: 0;]]>
      </td>
    </tr>
    <tr>
      <td>Label Large</td>
      <td>
        <label class="large">Label Large</label>
      </td>
      <td>
        <![CDATA[<label class="large">Label Large</label>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 15px; ]]> <br>
        <![CDATA[ .light & {font-weight: bold !important} ]]>
      </td>
    </tr>
    <tr>
      <td>Label Small</td>
      <td>
        <label class="small">Label Small</label>
      </td>
      <td>
        <![CDATA[<label class="small">Label Small</label>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 16px; letter-spacing: 1.8px; ]]> <br>
        <![CDATA[ .light & {font-weight: bold !important} ]]>
      </td>
    </tr>
    <tr>
      <td>Label XSmall</td>
      <td>
        <label class="xsmall">Label XSmall</label>
      </td>
      <td>
        <![CDATA[<label class="xsmall">Label Xsmall</label>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 13px; ]]> <br>
        <![CDATA[ .light & {font-weight: bold !important} ]]>
      </td>
    </tr>
    <tr>
      <td>Label Header</td>
      <td>
        <div class="header">Label Header</div>
      </td>
      <td>
        <![CDATA[<div class="header">Label Header</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
        <![CDATA[ font-size: 12px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]> <br>
        <![CDATA[ color: #b1b1b1; ]]><br>
        <![CDATA[ .light & {color: #707070;} ]]>
      </td>
    </tr>
    <tr>
      <td>Label Subheader</td>
      <td>
        <div class="subheader">Label Subheader</div>
      </td>
      <td>
        <![CDATA[<div class="subheader">Label Subheader</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 15px; ]]> <br>
        <![CDATA[ text-transform: uppercase; ]]> <br>
        <![CDATA[ color: #FFFFFF; ]]><br>
        <![CDATA[ .light & {color: #121314;} ]]>
      </td>
    </tr>
    <tr>
      <td>Measurement</td>
      <td>
        <div class="measurement">Measurement</div>
      </td>
      <td>
        <![CDATA[<div class="measurement">Measurement</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ font-size: 19px; ]]>
    </tr>
    <tr>
      <td>subheaderGrey</td>
      <td>
        <div class="subheaderGrey">subheaderGrey</div>
      </td>
      <td>
        <![CDATA[<div class="subheaderGrey">SubheaderGrey</div>]]>
      </td>
      <td>
        <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]> <br>
        <![CDATA[ color: #b1b1b1"; ]]> <br>
        <![CDATA[ font-size: 20px; ]]>
    </tr>
  </table>
</div>

<div class="container">
  <div class="h2">Buttons</div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Class</th>
        <th>Style</th>
        <th>Example</th>
        <th>SCSS</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>bttn</td>
        <td>Button</td>
        <td>
          <![CDATA[ <button class="bttn"></button> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT Std 47 Light Condensed" !important ]]> <br>
          <![CDATA[ border-radius: 2px; height: 40px; min-width: 120px; ]]> <br>
          <![CDATA[ font-size: 14px !important; letter-spacing: .5px; ]]> <br>
          <![CDATA[ padding-left: 35px; padding-right: 35px; ]]>
        </td>
      </tr>
      <tr>
        <td>bttn bttn-primary</td>
        <td>
          <button class="bttn bttn-primary">Save</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn bttn-primary">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT 67 Bold Condensed" !important; ]]> <br>
          <![CDATA[ border: 1px solid #397d71; ]]> <br>
          <![CDATA[ background-color: #273B2B; ]]> <br>
          <![CDATA[ color: #FFFFFF; ]]><br>
          <![CDATA[ cursor: pointer; ]]> <br>
          <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ color: #4BBFB7 !important;]]>
          <![CDATA[ border: 1px solid #4BBFB7 !important; ]]> <br>
          <![CDATA[ background-color: #000000 !important; ]]> <br>
          <![CDATA[ .light & {background-color: #4BBFB7 !important; ]]><br>
          <![CDATA[ color: #ffffff !important;} ]]>
        </td>
      </tr>
      <tr>
        <td>bttn bttn-primary-inactive</td>
        <td>
          <button class="bttn bttn-primary-inactive">Save</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn bttn-primary-inactive">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ border: none !important; ]]> <br>
          <![CDATA[ background-color: #4D4D4D; ]]> <br>
          <![CDATA[ color: #FFFFFF; ]]><br>
          <![CDATA[ cursor: context-menu; ]]> <br>
          <![CDATA[ .light & {background-color: #D8D8D8;]]> <br>
          <![CDATA[ border: none !important; ]]> <br>
          <![CDATA[ color: #808080;} ]]>
        </td>
      </tr>

      <tr>
        <td>bttn bttn-secondary</td>
        <td>
          <button class="bttn bttn-secondary">Cancel</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn bttn-secondary">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT Std 47 Light Condensed" !important; ]]> <br>
          <![CDATA[ border: 1px solid #D8D8D8 !important; ]]> <br>
          <![CDATA[ background-color: transparent !important; ]]> <br>
          <![CDATA[ color: #FFFFFF !important; ]]><br>
          <![CDATA[ cursor: pointer; ]]> <br>
          <![CDATA[ .light & {color: #121314 !important;]]> <br>
          <![CDATA[ border: 1px solid #808080 !important;} ]]> <br>
          <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ color: $primary-grey-3 !important; font-weight: 600;]]> <br>
          <![CDATA[ border: 1px solid #808080 !important; ]]> <br>
          <![CDATA[ background-color: $text-gray-2 !important; ]]> <br>
          <![CDATA[ .light & {color: #ffffff !important;} ]]>
        </td>
      </tr>
      <tr>
        <td>bttn bttn-secondary-inactive</td>
        <td>
          <button class="bttn bttn-secondary-inactive">Cancel</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn bttn-secondary-inactive">Cancel</button> ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #2B2E2E; ]]> <br>
          <![CDATA[ background-color: #121314; ]]> <br>
          <![CDATA[ color: #b1b1b1; ]]><br>
          <![CDATA[ cursor: context-menu; ]]> <br>
          <![CDATA[ .light & {background-color: #D8D8D8;]]> <br>
          <![CDATA[ border: 1px solid #D8D8D8; ]]> <br>
          <![CDATA[ color: #121314;} ]]> <br>
          <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ border: none; ]]>
        </td>
      </tr>
      <tr>
        <td>bttn-s</td>
        <td>Small Button</td>
        <td>
          <![CDATA[ <button class="bttn-s"></button> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT Std 47 Light Condensed" !important ]]> <br>
          <![CDATA[ border-radius: 2px; height: 35px; width: 115px; ]]> <br>
          <![CDATA[ font-size: 12px; letter-spacing: .2px; ]]>
        </td>
      </tr>
      <tr>
        <td>bttn-s bttn-primary</td>
        <td>
          <button class="bttn-s bttn-primary">Save</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn-s bttn-primary">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT 67 Bold Condensed" !important; ]]> <br>
          <![CDATA[ border: 1px solid #397d71; ]]> <br>
          <![CDATA[ background-color: #273B2B; ]]> <br>
          <![CDATA[ color: #FFFFFF; ]]><br>
          <![CDATA[ cursor: pointer; ]]> <br>
          <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ color: #4BBFB7 !important;]]>
          <![CDATA[ border: 1px solid #4BBFB7 !important; ]]> <br>
          <![CDATA[ background-color: #000000 !important; ]]> <br>
          <![CDATA[ .light & {background-color: #4BBFB7 !important; ]]><br>
          <![CDATA[ color: #ffffff !important;} ]]>
        </td>
      </tr>
      <tr>
        <td>bttn-s bttn-primary-inactive</td>
        <td>
          <button class="bttn-s bttn-primary-inactive">Save</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn-s bttn-primary-inactive">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ border: none !important; ]]> <br>
          <![CDATA[ background-color: #4D4D4D; ]]> <br>
          <![CDATA[ color: #FFFFFF; ]]><br>
          <![CDATA[ cursor: context-menu; ]]> <br>
          <![CDATA[ .light & {background-color: #D8D8D8;]]> <br>
          <![CDATA[ border: none !important; ]]> <br>
          <![CDATA[ color: #808080;} ]]>
        </td>
      </tr>

      <tr>
        <td>bttn-s bttn-secondary</td>
        <td>
          <button class="bttn-s bttn-secondary">Cancel</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn-s bttn-secondary">Cancel</button> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT Std 47 Light Condensed" !important; ]]> <br>
          <![CDATA[ border: 1px solid #D8D8D8 !important; ]]> <br>
          <![CDATA[ background-color: transparent !important; ]]> <br>
          <![CDATA[ color: #FFFFFF !important; ]]><br>
          <![CDATA[ cursor: pointer; ]]> <br>
          <![CDATA[ .light & {color: #121314 !important;]]> <br>
          <![CDATA[ border: 1px solid #808080 !important;} ]]> <br>
          <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ color: $primary-grey-3 !important; font-weight: 600;]]> <br>
          <![CDATA[ border: 1px solid #808080 !important; ]]> <br>
          <![CDATA[ background-color: $text-gray-2 !important; ]]> <br>
          <![CDATA[ .light & {color: #ffffff !important;} ]]>
        </td>
      </tr>

      <tr>
        <td>bttn-s bttn-secondary-inactive</td>
        <td>
          <button class="bttn-s bttn-secondary-inactive">Cancel</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn-s bttn-secondary-inactive">Cancel</button> ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #2B2E2E; ]]> <br>
          <![CDATA[ background-color: #121314; ]]> <br>
          <![CDATA[ color: #b1b1b1; ]]><br>
          <![CDATA[ cursor: context-menu; ]]> <br>
          <![CDATA[ .light & {background-color: #D8D8D8;]]> <br>
          <![CDATA[ border: 1px solid #D8D8D8; ]]> <br>
          <![CDATA[ color: #121314;} ]]> <br>
          <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ border: none; ]]>
        </td>
      </tr>
      <tr>
        <td>bttn-xs</td>
        <td>Extra Small Button</td>
        <td>
          <![CDATA[ <button class="bttn-xs"></button> ]]>
        </td>
        <td>
          <![CDATA[ border-radius: 1px; height: 25px; max-width: 100px; ]]> <br>
          <![CDATA[ font-size: 12px; letter-spacing: .2px; ]]>
        </td>
      </tr>

      <tr>
        <td>bttn-xs bttn-tertiary</td>
        <td>
          <button class="bttn-xs bttn-tertiary">Save</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn-xs bttn-tertiary">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ border-radius: 4%; ]]> <br>
          <![CDATA[ border: 1px solid #AE5C0A; ]]> <br>
          <![CDATA[ background-color: transparent; ]]> <br>
          <![CDATA[ color: #AE5C0A; ]]><br>
          <![CDATA[ cursor: pointer; ]]><br>
          <![CDATA[ ]]> <br>
          <![CDATA[ Hover/Active: ]]> <br>
          <![CDATA[ color: #121314; font-weight: bold;]]> <br>
          <![CDATA[ border: 1px solid #D57900 !important; ]]> <br>
          <![CDATA[ background-color: #D57900 !important; ]]>
        </td>
      </tr>
      <tr>
        <td>bttn-xs bttn-tertiary-inactive</td>
        <td>
          <button class="bttn-xs bttn-tertiary-inactive">Save</button>
        </td>
        <td>
          <![CDATA[ <button class="bttn-xs bttn-tertiary-inactive">Save</button> ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #2B2E2E; ]]> <br>
          <![CDATA[ background-color: #121314; ]]> <br>
          <![CDATA[ color: #b1b1b1; ]]><br>
          <![CDATA[ cursor: context-menu; ]]> <br>
          <![CDATA[ .light & {background-color: #D8D8D8;]]> <br>
          <![CDATA[ border: 1px solid #D8D8D8; ]]> <br>
          <![CDATA[ color: #4D4D4D;} ]]>
        </td>
      </tr>

      <tr>
        <td>pill</td>
        <td>
          <div class="pill">Pill</div>
        </td>
        <td>
          <![CDATA[ <div class="pill">Pill</div> ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #4D4D4D; line-height: 15px; ]]> <br>
          <![CDATA[ background-color: #4D4D4D; height: 24px; ]]> <br>
          <![CDATA[ color: #EEEEEE; font-size: 13px; ]]><br>
          <![CDATA[ padding-left:10px; padding-right:10px; ]]><br>
          <![CDATA[ font-family:  "Univers LT Std 47 Light Condensed"; ]]> <br>
          <![CDATA[ width: auto; padding-top: 3px; padding-left:10px; ]]> <br>
          <![CDATA[ .light & {background-color: #4D4D4D;]]> <br>
          <![CDATA[ border: 1px solid #4D4D4D; ]]> <br>
          <![CDATA[ color: white;} ]]>
        </td>
      </tr>
      <tr>
        <td>pill pill-status</td>
        <td>
          <div class="pill pill-status">Pill</div>
        </td>
        <td>
          <![CDATA[ <div class="pill pill-status">Pill</div> ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #2B2E2E; ]]> <br>
          <![CDATA[ background-color: #2B2E2E; ]]> <br>
          <![CDATA[ .light & {background-color: #808080;]]> <br>
          <![CDATA[ border: 1px solid #808080; ]]> <br>
          <![CDATA[ color: white;} ]]>
        </td>
      </tr>
      <tr>
        <td>pill pill-status table-pill</td>
        <td>
          <div class="pill pill-status table-pill">Table Pill</div>
        </td>
        <td>
          <![CDATA[ <div class="pill pill-status table-pill">Table Pill</div> ]]>
        </td>
        <td>
          <![CDATA[ display: flex; ]]> <br>
          <![CDATA[ justify-content: center; ]]>
        </td>
      </tr>

      <tr>
        <td>bttn-toggle bttn-toggle-active</td>
        <td>
          <div (click)="toggle = !toggle" [ngClass]="!toggle?'bttn-toggle-active':''" class="bttn-toggle">||</div>
          <div (click)="toggle = !toggle" [ngClass]="toggle?'bttn-toggle-active':''" class="bttn-toggle">||</div>
        </td>
        <td>
          <![CDATA[ 
                    <div class="bttn-toggle">||</div>
                    <div class="bttn-toggle bttn-toggle-active">||</div> 
              ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #808080; ]]> <br>
          <![CDATA[ height: 32px; width: 32px; ]]> <br>
          <![CDATA[ border-radius: 4%; font-size: 12px; ]]> <br>
          <![CDATA[ background-color: transparent; ]]> <br>
          <![CDATA[ color: #808080; line-height: 28px; ]]><br>
          <![CDATA[ cursor: pointer; letter-spacing: .2px; ]]><br>
          <![CDATA[  ]]> <br>
          <![CDATA[ Active: ]]> <br>
          <![CDATA[ color: #4BBFB7; ]]><br>
          <![CDATA[ background-color: #2b2e2e; ]]><br>
          <![CDATA[ border: 1px solid #4BBFB7; ]]><br>
          <![CDATA[ .light & {box-shadow: inset 0 0 1px 1px #4BBFB7, 0 0 10px 1px #ffffff;} ]]>
        </td>
      </tr>
      <tr>
        <td>Back Button</td>
        <td>
          <button class="btn-back">
            <label><img src="assets/images/icons/ic-chevron-left.svg" /></label>
            <span class="h5 back-pad">Back</span>
          </button>
        </td>
        <td></td>
        <td>
          <![CDATA[<button class="btn-back">]]> <br>
          <![CDATA[<label><img src="assets/images/icons/ic-chevron-left.svg"/></label>]]> <br>
          <![CDATA[<span class="h5 back-pad">BACK</span>]]> <br>
          <![CDATA[</button>]]> <br>
        </td>
      </tr>
      <tr>
        <td>Add Button</td>
        <td>
          <button class="bttn-rectangle bttn-tertiary">
            <span class="icon-unit-systems-add-icon add-icon-position"></span>
            <p class="default add-button">Add</p>
          </button>
        </td>
        <td></td>
        <td>
          <![CDATA[<button class="bttn-rectangle bttn-tertiary">]]> <br>
          <![CDATA[<span class="icon-unit-systems-add-icon add-icon-position"></span>]]> <br>
          <![CDATA[<p class="default add-button">Add</p>]]> <br>
          <![CDATA[</button>]]> <br>
        </td>
      </tr>
    </tbody>
  </table>
</div>





<!-- =============================================================== -->

<div class="container">
  <div class="h2">Forms</div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Class</th>
        <th>Style</th>
        <th>Value</th>
        <th>Example</th>
        <th>SCSS</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>input-field</td>
        <td>
          <input class="input-field" [(ngModel)]="inputVariable" placeholder="Placeholder..." />
        </td>
        <td>
          {{inputVariable}}
        </td>
        <td>
          <![CDATA[ <input class="input-field"/> ]]>
        </td>
        <td>
          <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
          <![CDATA[ border: 1px solid #4D4D4D; ]]> <br>
          <![CDATA[ font-size: 16px; ]]> <br>
          <![CDATA[ background-color: #000000; ]]><br>
          <![CDATA[ line-height: 20px; ]]><br>
          <![CDATA[ border-radius: 2px; ]]><br>
          <![CDATA[ padding: 7px 7px 4px 7px; ]]><br>
          <![CDATA[ .light & {border: 1px solid #808080;]]> <br>
          <![CDATA[ color: #121314;]]> <br>
          <![CDATA[ background-color: #ffffff !important;} ]]> <br>
          <![CDATA[  ]]> <br>
          <![CDATA[ Focus: ]]> <br>
          <![CDATA[ border: 1px solid $primary-grey-2 !important; ]]><br>
          <![CDATA[ .light & {border: 1px solid $primary-grey-5 !important;} ]]>
        </td>
      </tr>
      <tr>
        <td>Label + Input</td>
        <td>
          <div class="default comp-label">Label</div>
          <input type="text" class="input-field">
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="default comp-label">Label</div> ]]> <br>
          <![CDATA[ <input type="text" class="input-field"> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Label + UOM + Input</td>
        <td>
          <div>
            <div class="default comp-label">Label
              <app-uom-label></app-uom-label>
            </div>
            <div>
              <input type="text" class="input-field" [(ngModel)]="fillText1"
                [ngClass]="fillText1.length == 0?'input-danger':null">
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div> ]]> <br>
          <![CDATA[ <div class="default comp-label">Label ]]> <br>
          <![CDATA[ <app-uom-label></app-uom-label> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ <div> ]]> <br>
          <![CDATA[ <input type="text" class="input-field" [(ngModel)]="fillText" ]]> <br>
          <![CDATA[ [ngClass]="fillText.length == 0?'input-danger':null"> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ </div> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>H5 Gray + Input</td>
        <td>
          <div class="row">
            <div class="col-lg-3 h5-gray label-align">Label</div>
            <div class="col-lg-9">
              <input class="input-field label-input" type="text" />
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="row"> ]]> <br>
          <![CDATA[ <div class="col-lg-3 h5-gray label-align">Label</div> ]]> <br>
          <![CDATA[ <div class="col-lg-9"> ]]> <br>
          <![CDATA[ <input class="input-field label-input" type="text"/> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ </div> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Label + Input Disabled</td>
        <td>
          <div class="row">
            <div class="col-lg-3 h5 mb-0 label-align">Label</div>
            <div class="col-lg-9">
              <input class="input-field label-input" type="text" 
                [attr.disabled]="true" [attr.readonly]="true"/>
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="row"> ]]> <br>
          <![CDATA[ <div class="col-lg-3 h5 mb-0 label-align">Label</div> ]]> <br>
          <![CDATA[ <div class="col-lg-9"> ]]> <br>
          <![CDATA[ <input class="input-field label-input" type="text" ]]> <br>
          <![CDATA[ [attr.disabled]="true" [attr.readonly]="true"/> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ </div> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>H5 Gray + Input + Table</td>
        <td>
          <div class="row">
            <div class="col-lg-3 h5-gray label-align">Label</div>
            <div class="col-lg-9">
              <input class="input-field label-input" type="text" pInputText disabled />
              <div class="ic-icon-table label-table" src="../../../assets/images/icon-table selected.svg"></div>
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="row"> ]]> <br>
          <![CDATA[ <div class="col-lg-3 h5-gray label-align">Label</div> ]]> <br>
          <![CDATA[ <div class="col-lg-9"> ]]> <br>
          <![CDATA[ <input class="input-field label-input" type="text" pInputText disabled />]]> <br>
          <![CDATA[ <div class="ic-icon-table label-table" src="../../../assets/images/icon-table selected.svg"</div>]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ </div> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Input + Table</td>
        <td>
          <div class="col">
            <input class="input-field" type="text" pInputText disabled />
            <div class="ic-icon-table" src="../../../assets/images/icon-table selected.svg"></div>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="col"> ]]> <br>
          <![CDATA[ <input class="input-field" type="text" pInputText disabled />]]> <br>
          <![CDATA[ <div class="ic-icon-table" src="../../../assets/images/icon-table selected.svg"></div>]]> <br>
          <![CDATA[ </div> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>input-field (disabled)</td>
        <td>
          <input class="input-field" [(ngModel)]="inputVariable" readonly placeholder="Placeholder..." />
        </td>
        <td>
          {{inputVariable}}
        </td>
        <td>
          <![CDATA[ <input class="input-field" readonly="true"/> ]]>
        </td>
        <td>
          Update
          <![CDATA[ font-family: "Univers LT Std 45 Light"; ]]> <br>
          <![CDATA[ border: 1px solid #4D4D4D; ]]> <br>
          <![CDATA[ font-size: 16px; ]]> <br>
          <![CDATA[ background-color: #000000; ]]><br>
          <![CDATA[ line-height: 20px; ]]><br>
          <![CDATA[ border-radius: 2px; ]]><br>
          <![CDATA[ padding: 7px 7px 4px 7px; ]]><br>
          <![CDATA[ .light & {border: 1px solid #808080;]]> <br>
          <![CDATA[ color: #121314;]]> <br>
          <![CDATA[ background-color: #ffffff !important;} ]]> <br>
          <![CDATA[  ]]> <br>
          <![CDATA[ Focus: ]]> <br>
          <![CDATA[ border: 1px solid $primary-grey-2 !important; ]]><br>
          <![CDATA[ .light & {border: 1px solid $primary-grey-5 !important;} ]]>
        </td>
      </tr>
      <tr>
        <td>input-field input-danger</td>
        <td>
          <input class="input-field input-danger" [(ngModel)]="inputVariableDanger" placeholder="Placeholder..." />
        </td>
        <td>
          {{inputVariableDanger}}
        </td>
        <td>
          <![CDATA[ <input class="input-field input-danger" /> ]]>
        </td>
        <td>
          <![CDATA[ border: 1px solid #902F24 !important; ]]> <br>
          <![CDATA[ color: #D8D8D8; ]]>
        </td>
      </tr>
      <tr>
        <td>Label + Input + Description</td>
        <td>
          <div class="default comp-label">Label</div>
          <input type="text" class="input-field">
          <p class="small-gray desc-margin">Description</p>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="default comp-label">Label</div> ]]> <br>
          <![CDATA[ <input type="text" class="input-field"> ]]> <br>
          <![CDATA[ <p class="small-gray desc-margin">Description</p> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Label + Input + Error Description</td>
        <td>
          <div class="default comp-label">Label</div>
          <input [(ngModel)]="emptyText" class="input-field" [ngClass]="emptyText.length == 0?'input-danger':null" />
          <p *ngIf="emptyText.length == 0" class="small desc-margin error-text">Error Text</p>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="default comp-label">Label</div> ]]> <br>
          <![CDATA[ <input [(ngModel)]="emptyText" class="input-field" ]]> <br>
          <![CDATA[ [ngClass]="emptyText.length == 0?'input-danger':null"/> ]]> <br>
          <![CDATA[ <p *ngIf="emptyText.length == 0" class="small desc-margin error-text">Error Text</p> ]]> <br>
          <![CDATA[ Error Text</p> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>select-dropdown blocked</td>
        <td>
          <select class="select-dropdown" style="background-color: #2B2E2E" [(ngModel)]="dropdownSelect">
          </select>
        </td>
        <td>
          {{dropdownSelect}}
        </td>
        <td>
          <![CDATA[<select class="select-dropdown" style="background-color: #2B2E2E" [(ngModel)]="dropdownSelect">  ]]>
          <br />
          <![CDATA[ </select>  ]]>
        </td>
        <td>
          <![CDATA[ background: url(/assets/images/icons/ic-chevron-down.svg) no-repeat 94% 50%; ]]> <br>
          <![CDATA[ font-size: 11px; min-width: 100px; color: #2B2E2; line-height: 20px;  ]]> <br>
          <![CDATA[ border-radius: 2px; font-family: "Univers LT Std 47 Light Condensed"; ]]><br>
          <![CDATA[ border: 1px solid #2B2E2E; ]]> <br>
          <![CDATA[ .light & {border: 1px solid #808080; color: #2B2E2E; ]]> <br>
          <![CDATA[ background: url("/assets/images/icons/ic-chevron-down_light.svg") no-repeat
            94% 50%;]]>
        </td>
      </tr>

      <tr>
        <td>select-dropdown
          <br />
          <hr class="slim"> option-dropdown
        </td>
        <td>
          <select class="select-dropdown" [(ngModel)]="dropdownSelect">
            <option class="option-dropdown" value="123">123</option>
            <option class="option-dropdown" value="Option 2">Option 2</option>
          </select>
        </td>
        <td>
          {{dropdownSelect}}
        </td>
        <td>
          <![CDATA[<select class="select-dropdown" [(ngModel)]="dropdownSelect">  ]]>
          <br />
          <![CDATA[ <option class="option-dropdown" value="Option 1">Option 1</option>  ]]>
          <br />
          <![CDATA[ <option class="option-dropdown" value="Option 2">Option 1</option>  ]]>
          <br />
          <![CDATA[ </select>  ]]>
        </td>
        <td>
          <![CDATA[ background: url(/assets/images/icons/ic-chevron-down.svg) no-repeat 94% 50%; ]]> <br>
          <![CDATA[ font-size: 12px; color: #D8D8D8; line-height: 20px; border-radius: 2px; ]]> <br>
          <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]><br>
          <![CDATA[ border: 1px solid #2B2E2E; background-color: #000000; ]]> <br>
          <![CDATA[ .light & {border: 1px solid #808080; color: #2B2E2E; ]]> <br>
          <![CDATA[ background: url("/assets/images/icons/ic-chevron-down_light.svg") no-repeat
            94% 50%;]]>
        </td>
      </tr>
      <tr>
        <td>Orange Dropdown</td>
        <td>
          <div class="dropDownActions float-right">
            <label>Label</label>
            <span class="float-right" *ngIf="!dropdownExpandedOrange">
              <img src="assets/images/icons/ic-chevron-down_orange.svg" (click)="dropdownExpandedOrange = !dropdownExpandedOrange;"/>
            </span>
            <span class="float-right" *ngIf="dropdownExpandedOrange">
              <img src="assets/images/icons/ic-chevron-up_orange.svg" (click)="dropdownExpandedOrange = !dropdownExpandedOrange;"/>
            </span>
            <ul class="options mb-1 pl-0" *ngIf="dropdownExpandedOrange">
              <li class="li-one-margin mb-3 pl-0">
                <label class="mb-0">Option 1</label>
              </li>
              <li class="mb-3 pl-0">
                <label class="mb-0">Option 2</label>
              </li>
              <li class="pl-0">
                <label class="mb-0">Option 3</label>
              </li>
            </ul>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[<div class="dropDownActions float-right">]]><br/>
          <![CDATA[<label>Label</label>]]><br/>
          <![CDATA[<span class="float-right" *ngIf="!dropdownExpandedOrange">]]><br/>
          <![CDATA[<img src="assets/images/icons/ic-chevron-down_orange.svg" (click)="dropdownExpandedOrange = !dropdownExpandedOrange;"/>]]><br/>
          <![CDATA[</span>]]><br/>
          <![CDATA[<span class="float-right" *ngIf="dropdownExpandedOrange">]]><br/>
          <![CDATA[<img src="assets/images/icons/ic-chevron-up_orange.svg" (click)="dropdownExpandedOrange = !dropdownExpandedOrange;"/>]]><br/>
          <![CDATA[</span>]]><br/>
          <![CDATA[<ul class="options mb-1 pl-0" *ngIf="dropdownExpandedOrange">]]><br/>
          <![CDATA[<li class="li-one-margin mb-3 pl-0">]]><br/>
          <![CDATA[<label class="mb-0">Option 1</label>]]><br/>
          <![CDATA[</li>]]><br/>
          <![CDATA[<li class="mb-3 pl-0">]]><br/>
          <![CDATA[<label class="mb-0">Option 2</label>]]><br/>
          <![CDATA[</li>]]><br/>
          <![CDATA[<li class="pl-0">]]><br/>
          <![CDATA[<label class="mb-0">Option 3</label>]]><br/>
          <![CDATA[</li>]]><br/>
          <![CDATA[</ul>]]><br/>
          <![CDATA[</div>]]><br/>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Label + Dropdown</td>
        <td>
          <div class="default comp-label">Label</div>
          <select class="select-dropdown">
            <option class="option-dropdown">Option 1</option>
            <option class="option-dropdown">Option 2</option>
          </select>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="default comp-label">Label</div> ]]> <br>
          <![CDATA[ <select class="select-dropdown"> ]]> <br>
          <![CDATA[   <option class="option-dropdown">Option 1</option> ]]> <br>
          <![CDATA[   <option class="option-dropdown">Option 2</option> ]]> <br>
          <![CDATA[ </select> ]]>
        </td>
        <td></td>
      </tr>
      <!-- <tr>
        <td>Action Dropdown</td>
        <td>
          <div *ngIf="!isIntervention" class="col-6 dropDownActions">
            <i class="fa icon action-icon" (click)="dropdownExpanded =!dropdownExpanded" [ngClass]=" dropdownExpanded ? 'fa-chevron-up': 'fa-chevron-down'"></i>
            <ul class="options" *ngIf="dropdownExpanded"><li (click)="shareScenarios()">
                <label (click)="scenarioListShareModal()">{{'analysisMdl.share'|translate}}</label>
              </li>
              <li (click)="publishScenarios()">
                <label (click)="scenarioListPublishModal()">{{'analysisMdl.publish'|translate}}</label>
              </li>
              <li (click)="deleteScenarios()">
                <label *ngIf="isOnlyDraft">{{'analysisMdl.delete'|translate}}</label>
              </li>
            </ul>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <input [readOnly]="true"> ]]>
        </td>
        <td>
          <![CDATA[ background: #222528 !important; box-shadow: none !important; ]]> <br>
          <![CDATA[ .light & {background: $primary-grey-1 !important; ]]> <br>
          <![CDATA[ box-shadow: none !important;} ]]> <br>
          <br>
          <![CDATA[ After focus: ]]> <br>
          <![CDATA[ border: 0 !important ]]> <br>
          <![CDATA[ .light & {border: 0 !important} ]]> 
        </td>
      </tr> -->
      <tr>
        <td> div class=radio-field
          <br />
          <hr class="slim"> input type=radio name=radio id=x
          <br />
          <hr class="slim"> label for=x
        </td>
        <td>
          <div class="radio-field">
            <input type="radio" name="radio" id="radio1" value="Option1" [(ngModel)]="radioVariable"
              [ngModelOptions]="{standalone: true}">
            <label for="radio1">Option 1</label>
            <br>
            <input type="radio" name="radio" id="radio2" value="Option2" [(ngModel)]="radioVariable"
              [ngModelOptions]="{standalone: true}">
            <label for="radio2">Option 2</label>
            <br>
          </div>
        </td>
        <td>
          {{radioVariable}}
        </td>
        <td>
          <![CDATA[<div  class="radio-field">  ]]>
          <br />
          <![CDATA[ <input type="radio" name="radio" id="radio1" value="Option1" [(ngModel)]="radioVariable" [ngModelOptions]="{standalone: true}">  ]]>
          <br />
          <![CDATA[ <label for="radio1">Option 1</label>  ]]>
          <br />
          <![CDATA[ <br>  ]]>
          <br />
          <![CDATA[ <input type="radio" name="radio" id="radio2" value="Option2" [(ngModel)]="radioVariable" [ngModelOptions]="{standalone: true}">  ]]>
          <br />
          <![CDATA[ <label for="radio2">Option 2</label>  ]]>
          <br />
          <![CDATA[ <br>  ]]>
          <br />
          <![CDATA[</div>  ]]>
          <br />
        </td>
        <td>
          <![CDATA[ Radio { ]]> <br>
          <![CDATA[ Before: ]]> <br>
          <![CDATA[ height: 14px; width: 14px; background: #000000; ]]> <br>
          <![CDATA[ border: 1.2px solid #808080; color: #b1b1b1 !important;]]> <br>
          <![CDATA[ -webkit-box-shadow: inset 0px 0px 0px 2px #000000; ]]><br>
          <![CDATA[ .light & {color: #000000; background: #ffffff; ]]><br>
          <![CDATA[ -webkit-box-shadow: inset 0px 0px 0px 2px #ffffff;} ]]><br>
          <![CDATA[ After: ]]> <br>
          <![CDATA[ background: #4BBFB7; border: 1.2px solid #4BBFB7; ]]><br>
          <![CDATA[ } ]]> <br>
          <![CDATA[  ]]> <br>
          <![CDATA[ Option Text { ]]> <br>
          <![CDATA[ Before: ]]> <br>
          <![CDATA[ font-family: "Univers LT Std 47 Light Condensed"; ]]><br>
          <![CDATA[ font-size: 14px; color: #A8A8A8; ]]><br>
          <![CDATA[ After: ]]> <br>
          <![CDATA[ color: #FFFFFF; ]]> <br>
          <![CDATA[ } ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Radio Field Disabled</td>
        <td>
          <div class="radio-field">
            <input type="radio" name="radioD" id="radio1D" value="Option1D" checked="checked"
              [attr.disabled]="true" [attr.readonly]="true">
            <label for="radio1D">Option 1</label>
            <br>
            <input type="radio" name="radioD" id="radio2D" value="Option2D"
              [attr.disabled]="true" [attr.readonly]="true">
            <label for="radio2D">Option 2</label>
            <br>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[<div  class="radio-field">  ]]>
          <br />
          <![CDATA[ <input type="radio" name="radioD" id="radio1D" checked="checked" value="Option1D" [attr.disabled]="true" [attr.readonly]="true"]]>
          <br />
          <![CDATA[ <label for="radio1D">Option 1</label>  ]]>
          <br />
          <![CDATA[ <br>  ]]>
          <br />
          <![CDATA[ <input type="radio" name="radioD" id="radio2D" value="Option2D" [attr.disabled]="true" [attr.readonly]="true"]]>
          <br />
          <![CDATA[ <label for="radio2D">Option 2</label>  ]]>
          <br />
          <![CDATA[ <br>  ]]>
          <br />
          <![CDATA[</div>  ]]>
          <br />
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Label + Vertical Radio</td>
        <td>
          <div class="default comp-label">Label</div>
          <div class="radio-field radio-spacing">
            <input type="radio" name="radio" id="radioV1" value="Option 1" [(ngModel)]="radioV"
              [ngModelOptions]="{standalone: true}">
            <label for="radioV1">Option 1</label>
            <br>
            <input type="radio" name="radio" id="radioV2" value="Option 2" [(ngModel)]="radioV"
              [ngModelOptions]="{standalone: true}">
            <label for="radioV2">Option 2</label>
            <br>
          </div>
        </td>
        <td>
          {{radioV}}
        </td>
        <td>
          <![CDATA[ <div class="default comp-label">Label</div> ]]> <br />
          <![CDATA[ <div class="radio-field radio-spacing"> ]]> <br />
          <![CDATA[ <input type="radio" name="radio" id="radioV1" value="Option 1"]]> <br />
          <![CDATA[ [(ngModel)]="radioVariable" [ngModelOptions]="{standalone: true}"> ]]> <br />
          <![CDATA[ <label for="radioV1">Option 1</label> ]]> <br />
          <![CDATA[ <br> ]]> <br />
          <![CDATA[ <input type="radio" name="radio" id="radioV2" value="Option 2"]]> <br />
          <![CDATA[ [(ngModel)]="radioVariable" [ngModelOptions]="{standalone: true}"> ]]> <br />
          <![CDATA[ <label for="radioV1">Option 2</label> ]]> <br />
          <![CDATA[ <br> ]]> <br>
          <![CDATA[ </div> ]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td> div class=radio-field horizontal-radio
          <br />
          <hr class="slim"> input type=radio name=radio id=x
          <br />
          <hr class="slim"> label for=x
        </td>
        <td>
          <div class="radio-field horizontal-radio">
            <input type="radio" name="radio_hor" id="radio_1" value="Option1" [(ngModel)]="radioVariable">
            <label for="radio_1">Option 1</label>
            <br>
            <input type="radio" name="radio_hor" id="radio_2" value="Option2" [(ngModel)]="radioVariable">
            <label for="radio_2">Option 2</label>
            <br>
          </div>
        </td>
        <td>
          {{radioVariable}}
        </td>
        <td>
          <![CDATA[<div  class="radio-field horizontal-radio">  ]]>
          <br />
          <![CDATA[ <input type="radio" name="radio" id="radio1" value="Option1" [(ngModel)]="radioVariable"]]>
          <br />
          <![CDATA[ <label for="radio1">Option 1</label>  ]]>
          <br />
          <![CDATA[ <br>  ]]>
          <br />
          <![CDATA[ <input type="radio" name="radio" id="radio2" value="Option2" [(ngModel)]="radioVariable" ]]>
          <br />
          <![CDATA[ <label for="radio2">Option 2</label>  ]]>
          <br />
          <![CDATA[ <br>  ]]>
          <br />
          <![CDATA[</div>  ]]>
          <br />
        </td>
        <td>
          <![CDATA[ Horizontal Radio { ]]> <br>
          <![CDATA[ Additional Styles - display: flex  ]]> <br>
          <![CDATA[ label { margin-right: 25px; } ]]> <br>
          <![CDATA[ } ]]> <br>
        </td>
      </tr>

      <tr>
        <td>Label + Checkbox</td>
        <td>
          <label class="checkbox-label">
            <input [(ngModel)]="checked1" type="checkbox"/>
            <div class="checkbox-mark"></div>
            <label class="checkbox-text">Label</label>
          </label>
        </td>
        <td></td>
        <td>
          <![CDATA[ <label class="check-label">]]> <br>
          <![CDATA[   <input [(ngModel)]="checked1" type="checkbox"/>]]> <br>
          <![CDATA[   <div class="checkbox-mark"></div>]]> <br>
          <![CDATA[   <label class="checkbox-text">Label</label>>]]> <br>
          <![CDATA[ </label>]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Label + Checkbox + Input</td>
        <td>
          <label class="checkbox-label">
            <input [(ngModel)]="checked2" type="checkbox"/>
            <div class="checkbox-mark"></div>
            <label class="checkbox-text">Label</label>
          </label>
          <div class="check-input" *ngIf="checked2">
            <input [(ngModel)]="fillText" maxlength="100" [ngClass]="fillText.length == 0?'input-danger':null"
              class="input-field" placeholder="Title" />
            <p *ngIf="fillText.length == 0" class="small desc-margin error-text">
              Error Text</p>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <label class="check-label">]]> <br>
          <![CDATA[   <input [(ngModel)]="checked2" type="checkbox"/>]]> <br>
          <![CDATA[   <div class="checkbox-mark"></div>]]> <br>
          <![CDATA[   <label class="checkbox-text">Label</label>>]]> <br>
          <![CDATA[ </label>]]>
          <![CDATA[ <div class="check-input" *ngIf="checked2">]]> <br>
          <![CDATA[   <input [(ngModel)]="fillText" maxlength="100"]]> <br>
          <![CDATA[     [ngClass]="fillText.length == 0?'input-danger':null ]]> <br>
          <![CDATA[     class="input-field" placeholder="Title"/>]]> <br>
          <![CDATA[   <p *ngIf="fillText.length == 0" class="small desc-margin error-text">]]> <br>
          <![CDATA[     Error Text</p>]]> <br>
          <![CDATA[ </div>]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Checkbox Label + Double Input</td>
        <td>
          <label class="checkbox-label mb-3">
            <input [(ngModel)]="checked4" type="checkbox"/>
            <div class="checkbox-mark"></div>
            <label class="checkbox-text">Checkbox Label</label>
          </label>
          <div class="check-label-input" *ngIf="checked4">
            <div class="default comp-label">Label 1
              <app-uom-label></app-uom-label>
            </div>
            <div class="mb-3">
              <input type="text" class="input-field" [(ngModel)]="fillText3"
                [ngClass]="fillText3.length == 0?'input-danger':null">
            </div>
            <div class="default comp-label">Label 2
              <app-uom-label></app-uom-label>
            </div>
            <div>
              <input type="text" class="input-field" [(ngModel)]="fillText4"
                [ngClass]="fillText4.length == 0?'input-danger':null">
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <label class="checkbox-label mb-3"> ]]> <br>
          <![CDATA[ <input [(ngModel)]="checked4" type="checkbox"/> ]]> <br>
          <![CDATA[ <div class="checkbox-mark"></div> ]]> <br>
          <![CDATA[ <label class="checkbox-text">Label</label> ]]> <br>
          <![CDATA[ </label>]]>
          <![CDATA[ <div class="check-label-input" *ngIf="checked4"> ]]> <br>
          <![CDATA[ <div class="default comp-label">Label 1 ]]> <br>
          <![CDATA[ <app-uom-label></app-uom-label> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ <div class="mb-3"> ]]> <br>
          <![CDATA[ <input type="text" class="input-field" [(ngModel)]="fillText3" ]]> <br>
          <![CDATA[ [ngClass]="fillText3.length == 0?'input-danger':null"> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ <div class="default comp-label">Label 2 ]]> <br>
          <![CDATA[ <app-uom-label></app-uom-label> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ <div> ]]> <br>
          <![CDATA[ <input type="text" class="input-field" [(ngModel)]="fillText4" ]]> <br>
          <![CDATA[ [ngClass]="fillText4.length == 0?'input-danger':null"> ]]> <br>
          <![CDATA[ </div> ]]> <br>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Checkbox + Dropdown</td>
        <td>
          <div class="check-mark-select">
            <span>
              <label class="check-label">
                <input [(ngModel)]="checked3" type="checkbox"/>
                <div class="check-mark"></div>
              </label>
            </span>
            <div class="check-select">
              <select class="select-dropdown"
                [ngClass]="errors && (dirty || touched)?'input-danger':null">
                <option class="option-dropdown" value="">Select</option>
                <option class="option-dropdown">Option 1</option>
                <option class="option-dropdown">Option 2</option>
              </select>
            </div>
            <p *ngIf="errors && (dirty || touched)"
             class="small error-text check-error">Error Message
            </p>
          </div>
        </td>
        <td></td>
        <td>
          <![CDATA[ <div class="check-mark-select">]]> <br>
          <![CDATA[ <span>]]> <br>
          <![CDATA[ <label class="check-label">]]> <br>
          <![CDATA[ <input [(ngModel)]="checked3" type="checkbox"/>]]> <br>
          <![CDATA[ </label>]]>
          <![CDATA[ </span>]]> <br>
          <![CDATA[ <div class="check-select">]]> <br>
          <![CDATA[ <select class="select-dropdown" ]]> <br>
          <![CDATA[ [ngClass]="errors && (dirty || touched)?'input-danger':null">]]> <br>
          <![CDATA[ <option class="option-dropdown" value="">Select</option>]]> <br>
          <![CDATA[ <option class="option-dropdown">Option 1</option>]]> <br>
          <![CDATA[ <option class="option-dropdown">Option 2</option>]]> <br>
          <![CDATA[ </select>]]> <br>
          <![CDATA[ </div>]]> <br>
          <![CDATA[ <p *ngIf="errors && (dirty || touched)"]]> <br>
          <![CDATA[ class="small error-text check-error">Error Message</p>]]> <br>
          <![CDATA[ </div>]]>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Form AutoSave</td>
        <td>
          <form #formTest="ngForm" novalidate>
            <table>
              <tr>
                <td>
                  <input name="firstName" ngModel>

                </td>
              </tr>
              <tr>
                <td>
                  <input name="lastName" ngModel>
                </td>
              </tr>
            </table>
          </form>
        </td>
        <td></td>
        <td>
          <![CDATA[    
               <form #formTest="ngForm" novalidate>
                  <table>
                    <tr>
                      <td>
                        <input name="firstName" ngModel>
      
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input name="lastName" ngModel>
                      </td>
                    </tr>
                  </table>
                </form>]]>
          <br />
          {{autoSaveValueChanged}}
        </td>
        <td>
          <![CDATA[ Bordering Before & Focus same as input-field ]]> <br>
          <![CDATA[  ]]> <br>
          <![CDATA[ After populated: ]]> <br>
          <![CDATA[ background-color: rgb(250, 255, 189) !important; ]]><br>
          <![CDATA[ background-image: none !important;; ]]><br>
          <![CDATA[ color: rgb(0, 0, 0) !important; ]]>
        </td>
      </tr>
      <tr>
        <td>Form Read-Only</td>
        <td>
          <input [readOnly]="true" value="ReadOnly">
        </td>
        <td></td>
        <td>
          <![CDATA[ <input [readOnly]="true"> value="ReadOnly" ]]>
        </td>
        <td>
          <![CDATA[ background: #222528 !important; box-shadow: none !important; ]]> <br>
          <![CDATA[ .light & {background: $primary-grey-1 !important; ]]> <br>
          <![CDATA[ box-shadow: none !important;} ]]> <br>
          <br>
          <![CDATA[ After focus: ]]> <br>
          <![CDATA[ border: 0 !important ]]> <br>
          <![CDATA[ .light & {border: 0 !important} ]]>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="container">
  <div class="h2">Components</div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Name</th>
        <th>Component</th>
        <th>HTML</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Notes</td>
        <td>
          <div class="row">
            <div class="col-12 notesSection">
              <p-editor [style]="{'height':'320px'}"></p-editor>
            </div>
          </div>
        </td>
        <td>
          <![CDATA[ border: 1px solid #2B2E2E; height: 220px !important; ]]>
        </td>
      </tr>
      <tr>
        <td>Profile Image</td>
        <td>
          <div class="row">
            <div class="profile-img">
              <span class="h4">JD</span>
            </div>
          </div>
        </td>
        <td>
          <![CDATA[ <div class="row"> ]]> <br>
          <![CDATA[ <div class="profile-img"> ]]> <br>
          <![CDATA[ <span class="h4">JD</span> ]]> <br>
          <![CDATA[ </div> ]]> <br>
          <![CDATA[ </div> ]]>
        </td>
      </tr>
      <!-- <tr>
        <td>Dashboard Planning Card</td>
          <td>
            <app-planning-card></app-planning-card>
          </td>
          <td>
            <![CDATA[ <div class="default comp-label">Label</label> ]]> <br>
            <![CDATA[ <input type="text" class="input-field"> ]]> <br>
            <![CDATA[ <p class="small-gray desc-margin">Description</p> ]]> 
          </td>
        </tr> -->
    </tbody>
  </table>
</div>

<div class="container">
  <div class="h2">Icons</div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Class</th>
        <th>Dark</th>
        <th>Light</th>
        <th>File Name(s)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Green Alert</td>
        <td>
          <img src="../../assets/images/icons/ic-alert-green.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-alert-green-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-alert-green.svg; ic-alert-green-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Yellow Alert</td>
        <td>
          <img src="../../assets/images/icons/ic-alert-yellow.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-alert-yellow-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-alert-yellow.svg; ic-alert-yellow-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Red Alert</td>
        <td>
          <img src="../../assets/images/icons/ic-alert-red.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-alert-red-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-alert-red.svg; ic-alert-red-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>One Signal Bar</td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars1.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars1-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-signal_bars1.svg; ic-signal_bars1-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Two Signal Bars</td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars2.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars2-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-signal_bars2.svg; ic-signal_bars2-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Three Signal Bars</td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars3.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars3-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-signal_bars3.svg; ic-signal_bars3-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>No Signal Bars</td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars4_empty.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-signal_bars4_empty-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-signal_bars4_empty.svg; ic-signal_bars4_empty-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Active</td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-active3.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-active3.svg" />
        </td>
        <td>
          <![CDATA[ ic-active3.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Bell</td>
        <td>
          <img src="../../assets/images/icons/ic-bell-icon.png" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-bell-icon_light.png" />
        </td>
        <td>
          <![CDATA[ ic-bell-icon.png; ic-bell-icon_light.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Sun</td>
        <td>
          <img src="../../assets/images/icons/ic-sun.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-sun_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-sun.svg; ic-sun_light ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Moon</td>
        <td>
          <img src="../../assets/images/icons/ic-moon.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-moon_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-moon.svg; ic-moon_light ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Snooze</td>
        <td>
          <img src="../../assets/images/icons/icon-snoozed.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/icon-snoozed.svg" />
        </td>
        <td>
          <![CDATA[ icon-snoozed.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Rejected</td>
        <td>
          <img src="../../assets/images/icons/icon-rejected.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/icon-rejected.svg" />
        </td>
        <td>
          <![CDATA[ icon-rejected.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Confirmation</td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-confirmation.svg" />
        </td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-confirmation.svg" />
        </td>
        <td>
          <![CDATA[ ic-confirmation.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Attach</td>
        <td>
          <img src="../../assets/images/icons/icon-attach.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/icon-attach.svg" />
        </td>
        <td>
          <![CDATA[ icon-attach.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Duplicate</td>
        <td>
          <img src="../../assets/images/icons/ic-duplicate.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-duplicate.svg" />
        </td>
        <td>
          <![CDATA[ ic-duplicate.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Edit</td>
        <td>
          <img src="../../assets/images/icons/ic-edit.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-edit.svg" />
        </td>
        <td>
          <![CDATA[ ic-edit.svg ]]> <br>
        </td>
      <tr>
        <td>Download</td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-download.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-download.svg" />
        </td>
        <td>
          <![CDATA[ ic-download.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Link</td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/icon_open-data.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/icon_open-data.svg" />
        </td>
        <td>
          <![CDATA[ icon_open-data.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Clipboard</td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-clipboard.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-clipboard.svg" />
        </td>
        <td>
          <![CDATA[ ic-clipboard.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Accordion Down</td>
        <td>
          <img src="../../assets/images/icons/ic-accordion-toggle-down.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-accordion-toggle-down_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-accordion-toggle-down.svg; ic-accordion-toggle-down_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Accordion Up</td>
        <td>
          <img src="../../assets/images/icons/ic-accordion-toggle-up.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-accordion-toggle-up_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-accordion-toggle-up.svg; ic-accordion-toggle-up_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Chevron Down</td>
        <td>
          <img src="../../assets/images/icons/ic-chevron-down.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-chevron-down_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-chevron-down.svg; ic-chevron-down_light.svg  ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Chevron Up</td>
        <td>
          <img src="../../assets/images/icons/ic-chevron-up.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-chevron-up_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-chevron-up.svg; ic-chevron-up_light.svg  ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Chevron Left</td>
        <td>
          <img src="../../assets/images/icons/ic-chevron-left.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-chevron-left.svg" />
        </td>
        <td>
          <![CDATA[ ic-chevron-left.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Arrow Down</td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-down.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-down_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-arrow-down.svg; ic-arrow-down_light.svg  ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Arrow Up</td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-up.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-up_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-arrow-up.svg; ic-arrow-up_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Arrow Left</td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-left.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-left_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-arrow-left.svg; ic-arrow-left_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Arrow Right</td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-right.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-arrow-right_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-arrow-right.svg; ic-arrow-right_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Wellbore Diagram Arrow</td>
        <td>
          <img src="../../assets/images/icons/ic-well_diagram_arrow.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-well_diagram_arrow_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-well_diagram_arrow.svg; ic-well_diagram_arrow_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Close</td>
        <td>
          <img src="../../assets/images/icons/ic-close.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-close_light.svg" />
        </td>
        <td>
          <![CDATA[ class = "closeIcon" ]]> <br>
          <![CDATA[ ic-close.svg; ic-close_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Close Dark</td>
        <td>
          <img src="../../assets/images/icons/ic-close.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-close.svg" />
        </td>
        <td>
          <![CDATA[ class = "closeIcon-dark" ]]> <br>
          <![CDATA[ ic-close.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Close Light</td>
        <td>
          <img src="../../assets/images/icons/ic-close_light.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-close_light.svg" />
        </td>
        <td>
          <![CDATA[ class = "closeIcon-light" ]]> <br>
          <![CDATA[ ic-close.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Close Orange</td>
        <td>
          <img src="../../assets/images/icons/ic-cross.png" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-cross.png" />
        </td>
        <td>
          <![CDATA[ ic-cross.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Close Orange Circle</td>
        <td>
          <img src="../../assets/images/icons/ic-close_circle.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-close_circle_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-close_circle.svg; ic-close_circle_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Add</td>
        <td>
          <img src="../../assets/images/icons/ic-add.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-add.svg" />
        </td>
        <td>
          <![CDATA[ ic-add.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Grid</td>
        <td>
          <img src="../../assets/images/icons/ic-grid.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-grid_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-grid.svg; ic-grid_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>List</td>
        <td>
          <img src="../../assets/images/icons/ic-list.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-list_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-list.svg; ic-list_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>List (Notifications Dropdown)</td>
        <td>
          <img src="../../assets/images/icons/ic-list-settings.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-list-settings.svg" />
        </td>
        <td>
          <![CDATA[ ic-list-settings.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Table Dropdown</td>
        <td>
          <img class="icon-table" src="../../assets/images/icon-table selected.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icon-table selected.svg" />
        </td>
        <td>
          <![CDATA[ icon-table selected.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>No Data</td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-no_data.svg" />
        </td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-no_data-light.svg" />
        </td>
        <td>
          <![CDATA[ ic-no_data.svg; ic-no_data-light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Time</td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-time.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-time.svg" />
        </td>
        <td>
          <![CDATA[ icons/ic-time.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Zoom In</td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-zoom_in.svg" />
        </td>
        <td>
          <img class="icon-table" src="../../assets/images/icons/ic-zoom_in.svg" />
        </td>
        <td>
          <![CDATA[ ic-zoom_in.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Not Started</td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-not_started.svg" />
        </td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-not_started_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-not_started.svg; ic-not_started_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>In Progress</td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-in_progress.svg" />
        </td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-in_progress_light.svg" />
        </td>
        <td>
          <![CDATA[ ic-in_progress.svg; ic-in_progress_light.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Sign Out</td>
        <td>
          <img src="../../assets/images/icons/ic-signout-orange.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/ic-signout-orange.svg" />
        </td>
        <td>
          <![CDATA[ ic-signout.orange.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Calculator</td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-calculator.svg" />
        </td>
        <td>
          <img class="icon-table-large" src="../../assets/images/icons/ic-calculator.svg" />
        </td>
        <td>
          <![CDATA[ ic-calculator.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Drilling Fluids Graphic</td>
        <td>
          <img src="../../assets/images/icons/DFGlogo.svg" />
        </td>
        <td>
          <img src="../../assets/images/icons/DFGlogo.svg" />
        </td>
        <td>
          <![CDATA[ DFGlogo.svg ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Wellbore Diagram Thumbnail</td>
        <td>
          <div class="ic-wellbore-thumbnail"></div>
        </td>
        <td>
          <div class="ic-wellbore-thumbnail"></div>
        </td>
        <td>
          <![CDATA[ class="ic-wellbore-thumbnail" ]]> <br>
        </td>
      </tr>
      <tr>
        <td>DrillAhead Off</td>
        <td>
          <img src="../../assets/images/Analysis/01_DrillAhead/01_Dark/DrillAhead_off.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/01_DrillAhead/02_Light/DrillAhead_off.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/DrillAhead_off.pngg; 02_Light/DrillAhead_off.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>DrillAhead On</td>
        <td>
          <img src="../../assets/images/Analysis/01_DrillAhead/01_Dark/DrillAhead_on.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/01_DrillAhead/02_Light/DrillAhead_on.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/DrillAhead_on.png; 02_Light/DrillAhead_on.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>DrillAhead Optimization Off</td>
        <td>
          <img src="../../assets/images/Analysis/02_DrillAhead Optimization/01_Dark/DrillAhead Optimization_off.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/02_DrillAhead Optimization/02_Light/DrillAhead Optimization_off.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/DrillAhead Optimization_off.png; 02_Light/DrillAhead Optimization_off.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>DrillAhead Optimization On</td>
        <td>
          <img src="../../assets/images/Analysis/02_DrillAhead Optimization/01_Dark/DrillAhead Optimization_on.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/02_DrillAhead Optimization/02_Light/DrillAhead Optimization_on.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/DrillAhead Optimization_off.png; 02_Light/DrillAhead Optimization_off.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Hydraulics Off</td>
        <td>
          <img src="../../assets/images/Analysis/03_Hydraulics/01_Dark/Hydraulics_off.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/03_Hydraulics/02_Light/Hydraulics_off.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Hydraulics_off.png; 02_Light/Hydraulics_off.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Hydraulics On</td>
        <td>
          <img src="../../assets/images/Analysis/03_Hydraulics/01_Dark/Hydraulics_On.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/03_Hydraulics/02_Light/Hydraulics_on.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Hydraulics_On.png; 02_Light/Hydraulics_on.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Downhole Off</td>
        <td>
          <img src="../../assets/images/Analysis/04_Downhole/01_Dark/Downhole_off.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/04_Downhole/02_Light/Downhole_off.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Downhole_off.png; 02_Light/Downhole_off.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Downhole On</td>
        <td>
          <img src="../../assets/images/Analysis/04_Downhole/01_Dark/Downhole_on.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/04_Downhole/02_Light/Downhole_on.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Downhole_on.png; 02_Light/Downhole_on.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Surge & Swab Off</td>
        <td>
          <img src="../../assets/images/Analysis/04_Surge_Swab/01_Dark/Swab_off.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/04_Surge_Swab/02_Light/Swab_off.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Swab_off.png; 02_Light/Swab_off.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Surge & Swab On</td>
        <td>
          <img src="../../assets/images/Analysis/04_Surge_Swab/01_Dark/Swab_on.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/04_Surge_Swab/02_Light/Swab_on.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Swab_on.png; 02_Light/Swab_on.png ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Sweep Off</td>
        <td>
          <img src="../../assets/images/Analysis/06_Sweep/01_Dark/Sweep_off.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/06_Sweep/02_Light/Sweep_off.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Sweep_off.png; 02_Light/Sweep_off.png]]> <br>
        </td>
      </tr>
      <tr>
        <td>Sweep On</td>
        <td>
          <img src="../../assets/images/Analysis/06_Sweep/01_Dark/Sweep_On.png" />
        </td>
        <td>
          <img src="../../assets/images/Analysis/06_Sweep/02_Light/Sweep_On.png" />
        </td>
        <td>
          <![CDATA[ 01_Dark/Sweep_On.png; 02_Light/Sweep_On.png]]> <br>
        </td>
      </tr>
      <tr>
        <td>Job Profile</td>
        <td>
          <span class="icon-ic-sidenav-jobProfile"></span>
        </td>
        <td>
          <span class="icon-ic-sidenav-jobProfile"></span>
        </td>
        <td>
          <![CDATA[ <span class="icon-ic-sidenav-jobProfile"></span> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Activity Feed</td>
        <td>
          <span class="icon-ic-sidenav-activity-feed"></span>
        </td>
        <td>
          <span class="icon-ic-sidenav-activity-feed"></span>
        </td>
        <td>
          <![CDATA[ <span class="icon-ic-sidenav-activity-feed"></span> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Analysis</td>
        <td>
          <span class="icon-ic-sidenav-interventions"></span>
        </td>
        <td>
          <span class="icon-ic-sidenav-interventions"></span>
        </td>
        <td>
          <![CDATA[ <span class="icon-ic-sidenav-interventions"></span> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Interventions</td>
        <td>
          <span class="icon-ic-sidenav-job-profile"></span>
        </td>
        <td>
          <span class="icon-ic-sidenav-job-profile"></span>
        </td>
        <td>
          <![CDATA[ <span class="icon-ic-sidenav-job-profile"></span> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Reports</td>
        <td>
          <span class="icon-ic-sidenav-reports"></span>
        </td>
        <td>
          <span class="icon-ic-sidenav-reports"></span>
        </td>
        <td>
          <![CDATA[ <span class="icon-ic-sidenav-reports"></span> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Play Circle</td>
        <td>
          <i class="fa fa-play-circle"></i>
        </td>
        <td>
          <i class="fa fa-play-circle"></i>
        </td>
        <td>
          <![CDATA[ <i class="fa fa-moon-o dark-icon" aria-hidden="true"></i> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Swap</td>
        <td>
          <button class="fa fa-exchange swapicon"></button>
        </td>
        <td>
          <button class="fa fa-exchange swapicon"></button>
        </td>
        <td>
          <![CDATA[ <button class="fa fa-exchange swapicon"></button> ]]> <br>
        </td>
      </tr>
      <tr>
        <td>Log Out</td>
        <td>
          <i class="fa fa-sign-out"></i>
        </td>
        <td>
          <i class="fa fa-sign-out"></i>
        </td>
        <td>
          <![CDATA[ <i class="fa fa-sign-out"></i> ]]> <br>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="container">
  <div class="h2">Annotated Components</div>
  <table class="table table-striped">
    <tr>
      <th class="header-center">Dark Alert Card</th>
    </tr>
    <tr class="header-center">
      <img src="../../assets/screenshots/components/card-alert-dark-annotation.png">
    </tr>
  </table>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Div #</th>
        <th>Major Divs/Features</th>
        <th>Rules</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <![CDATA[ (1) alert-card ]]> <br>
        </td>
        <td>
          <![CDATA[ card-header; ]]> <br>
          <![CDATA[ card-body; ]]>
        </td>
        <td>
          <![CDATA[ border-radius: 5px 40px 5px 5px; ]]> <br>
          <![CDATA[ box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.3); ]]>
        </td>
      </tr>
      <tr>
        <td>
          <![CDATA[ (2) card-header ]]> <br>
        </td>
        <td>
          <![CDATA[ Header ]]> <br>
          <![CDATA[ Subheader ]]>
        </td>
        <td>
          <![CDATA[ Header Text Options: ]]> <br>
          <![CDATA[ class="h4"; color: #EEEEEE ]]> <br>
          <![CDATA[ Subheader Text Options: ]]> <br>
          <![CDATA[ class="label xsmall"; color: #FFFFFF; ]]> <br>
          <![CDATA[ Subheader Icon Options: ]]> <br>
          <![CDATA[ One Signal Bar - /assets/images/icons/ic-signal_bars1.svg  ]]> <br>
          <![CDATA[ Two Signal Bar - /assets/images/icons/ic-signal_bars2.svg ]]> <br>
          <![CDATA[ Three Signal Bar - /assets/images/icons/ic-signal_bars3.svg ]]> <br>
          <![CDATA[ No Signal Bars - /assets/images/icons/ic-signal_bars4_empty.svg: ]]>
        </td>
      </tr>
      <tr>
        <td>
          <![CDATA[ (3) card-body ]]> <br>
        </td>
        <td>
          <![CDATA[ Alerts ]]> <br>
          <![CDATA[ Button ]]>
        </td>
        <td>
          <![CDATA[ Alerts Text Options: ]]> <br>
          <![CDATA[ class="label small"; colors: #35CD6E, #FF4D3A, #B68700;  ]]> <br>
          <![CDATA[ Alerts Icon Options: ]]> <br>
          <![CDATA[ Green Triangle - /assets/images/icons/ic-alert-green.svg  ]]> <br>
          <![CDATA[ Yellow Triangle - /assets/images/icons/ic-alert-yellow.svg ]]> <br>
          <![CDATA[ Red Triangle - /assets/images/icons/ic-alert-red.svg ]]> <br>
          <![CDATA[ Button: ]]>
          <![CDATA[ class="bttn bttn-secondary" ]]> <br>
        </td>
      </tr>
      <tr>
        <td>
          <![CDATA[ (4) card-box-fold ]]> <br>
        </td>
        <td>
          <br>
        </td>
        <td>
          <![CDATA[ border-right: 25px solid #000000; ]]> <br>
          <![CDATA[ border-bottom: 25px solid #17271D, #42201e, #292519; ]]> <br>
          <![CDATA[ width: 25px; height: 25px; z-index: 1; ]]>
      </tr>
    </tbody>
  </table>
  <table class="table table-striped">
    <tr>
      <th class="header-center">Light Alert Card</th>
    </tr>
    <tr class="header-center">
      <img src="../../assets/screenshots/components/card-alert-light-annotation.png">
    </tr>
  </table>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Div #</th>
        <th>Major Divs/Features</th>
        <th>Rules</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <![CDATA[ (1) alert-card ]]> <br>
        </td>
        <td>
          <![CDATA[ card-header; ]]> <br>
          <![CDATA[ card-body; ]]>
        </td>
        <td>
          <![CDATA[ border-radius: 5px 40px 5px 5px; ]]> <br>
          <![CDATA[ box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.3); ]]>
        </td>
      </tr>
      <tr>
        <td>
          <![CDATA[ (2) card-header ]]> <br>
        </td>
        <td>
          <![CDATA[ Header ]]> <br>
          <![CDATA[ Subheader ]]>
        </td>
        <td>
          <![CDATA[ Header Text Options: ]]> <br>
          <![CDATA[ class="h4"; color: #121314 ]]> <br>
          <![CDATA[ Subheader Text Options: ]]> <br>
          <![CDATA[ class="label xsmall"; color: #121314; ]]> <br>
          <![CDATA[ Subheader Icon Options: ]]> <br>
          <![CDATA[ One Signal Bar - /assets/images/icons/ic-signal_bars1-light.svg  ]]> <br>
          <![CDATA[ Two Signal Bar - /assets/images/icons/ic-signal_bars2-light.svg ]]> <br>
          <![CDATA[ Three Signal Bar - /assets/images/icons/ic-signal_bars3-light.svg ]]> <br>
          <![CDATA[ No Signal Bars - /assets/images/icons/ic-signal_bars4_empty-light.svg: ]]>
        </td>
      </tr>
      <tr>
        <td>
          <![CDATA[ (3) card-body ]]> <br>
        </td>
        <td>
          <![CDATA[ Alerts ]]> <br>
          <![CDATA[ Button ]]>
        </td>
        <td>
          <![CDATA[ Alerts Text Options: ]]> <br>
          <![CDATA[ class="label small"; colors: #35CD6E, #FF4D3A, #B68700;  ]]> <br>
          <![CDATA[ Alerts Icon Options: ]]> <br>
          <![CDATA[ Green Triangle - /assets/images/icons/ic-alert-green-light.svg  ]]> <br>
          <![CDATA[ Yellow Triangle - /assets/images/icons/ic-alert-yellow-light.svg ]]> <br>
          <![CDATA[ Red Triangle - /assets/images/icons/ic-alert-red-light.svg ]]> <br>
          <![CDATA[ Button: ]]>
          <![CDATA[ class="bttn bttn-secondary" ]]> <br>
        </td>
      </tr>
      <tr>
        <td>
          <![CDATA[ (4) card-box-fold ]]> <br>
        </td>
        <td>
          <br>
        </td>
        <td>
          <![CDATA[ border-right: 25px solid #FFFFFF; ]]> <br>
          <![CDATA[ border-bottom: 25px solid #B9EECD, #FFCFCA, #F3D9B7; ]]> <br>
          <![CDATA[ width: 25px; height: 25px; z-index: 1; ]]>
      </tr>
    </tbody>
  </table>
</div>
<div class="container">
  <div class="h2">Tables</div>
  <div class="h3">Rig Table</div>
  <div class="table-top">
    <table>
      <thead>
        <tr>
          <th>Heading 1</th>
          <th>Heading 2 <app-uom-label></app-uom-label>
          </th>
          <th>Heading 3 <app-uom-label></app-uom-label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="measurement"><span>Group 1</span></td>
          <td class="custom-margin">
            <input class="input-field">
          </td>
          <td>
            <input class="input-field">
          </td>
        </tr>
        <tr>
          <td class="measurement"><span>Group 2</span></td>
          <td class="custom-margin">
            <input class="input-field">
          </td>
          <td>
            <input class="input-field">
          </td>
        </tr>
        <tr>
          <td class="measurement"><span>Group 3</span></td>
          <td class="custom-margin">
            <input class="input-field">
          </td>
          <td>
            <input class="input-field">
          </td>
        </tr>
        <tr>
          <td class="measurement"><span>Group 4</span></td>
          <td class="custom-margin">
            <input class="input-field">
          </td>
          <td>
            <input class="input-field">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<br>
<br>
<div class="container">
  <table class="table table-striped">
    <thead class="h2">Large Components</thead>
    <tbody>
      <br>
      <br>
      <div class="h4">Button with Lines</div>
      <br>
      <br>
      <tr>
        <div class="divider"></div>
        <div class="pull-right mt-4">
          <button class="bttn bttn-secondary btn-position">CANCEL</button>
          <button class="bttn bttn-primary btn-position bttn-primary-inactive">SUBMIT</button>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="divider"></div> ]]> <br>
        <![CDATA[ <div class="pull-right mt-4"> ]]> <br>
        <![CDATA[ <button class="bttn bttn-secondary btn-position">CANCEL</button> ]]><br>
        <![CDATA[ <button class="bttn bttn-primary btn-position bttn-primary-inactive">SUBMIT</button> ]]><br>
        <![CDATA[ </div> ]]><br>
      </tr>
      <br>
      <br>
      <div class="h4">Label + Textbox</div>
      <br>
      <br>
      <tr>
        <div class="notes small">
          <div class="default comp-label">Label</div>
          <textarea class="desc-box"></textarea>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="notes small"> ]]> <br>
        <![CDATA[ <div class="default comp-label">Label</div> ]]> <br>
        <![CDATA[ <textarea class="desc-box"></textarea> ]]><br>
        <![CDATA[ </div> ]]><br>
      </tr>
      <br>
      <br>
      <div class="h4">Job Footer</div>
      <br>
      <br>
      <tr>
        <![CDATA[ Fixed Component: ]]> <br>
        <![CDATA[ Review at bottom of page ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Global Navigation</div>
      <br>
      <br>
      <tr>
        <![CDATA[ Fixed Component: ]]> <br>
        <![CDATA[ Review at top of page ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Job Subheader</div>
      <br>
      <br>
      <tr>
        <![CDATA[ Fixed Component: ]]> <br>
        <![CDATA[ Located below Global Nav when a job is selected ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Large Radio Buttons</div>
      <br>
      <br>
      <tr>
        <div class="row">
          <div class="col-md-auto radio-field horizontal-radio">
            &nbsp;&nbsp;<input type="radio" name="radio" id="largeRadio1" value="largeOption1">
            <label class="small" for="largeRadio1">Large Option 1</label>
          </div>

          <div class="col-md-auto radio-field horizontal-radio">
            <input type="radio" name="radio" id="largeRadio2" value="largeOption2">
            <label class="small" for="largeRadio2">Large Option 2</label>
          </div>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="row"> ]]> <br>
        <![CDATA[ <div class="col-md-auto radio-field horizontal-radio"> ]]> <br>
        <![CDATA[ &nbsp;&nbsp;<input type="radio" name="radio" id="largeRadio1" value="largeOption1"> ]]> <br>
        <![CDATA[ <label class="small" for="largeRadio1">Large Option 1</label> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ <div class="col-md-auto radio-field horizontal-radio"> ]]> <br>
        <![CDATA[ <input type="radio" name="radio" id="largeRadio2" value="largeOption2"> ]]> <br>
        <![CDATA[ <label class="small" for="largeRadio2">Large Option 2</label> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Toggle Switch</div>
      <br>
      <br>
      <tr>
        <div class="d-inline-flex">
          <span class="switch-left">Left Label</span>
          <label class="switch">
            <input type="checkbox">
            <span class="slider round"></span>
          </label>
          <span class="switch-right">Right Label</span>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="d-inline-flex"> ]]> <br>
        <![CDATA[ <span class="switch-left">Left Label</span> ]]> <br>
        <![CDATA[ <label class="switch"> ]]> <br>
        <![CDATA[ <input type="checkbox"> ]]> <br>
        <![CDATA[ <span class="slider round"></span>> ]]> <br>
        <![CDATA[ </label> ]]> <br>
        <![CDATA[ <span class="switch-right">Right Label</span> ]]> <br>
        <![CDATA[ </div> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Icon + Toggle Switch</div>
      <br>
      <br>
      <tr>
        <div class="d-inline-flex">
          <span class="toggle-icon icon_moon"></span>
          <span class="switch-label">Left Label</span>
          <label class="switch switch-icon">
            <input type="checkbox">
            <span class="slider round"></span>
          </label>
          <span class="toggle-icon icon_sun"></span>
          <span class="switch-label">Right Label</span>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="d-inline-flex"> ]]> <br>
        <![CDATA[ <span class="toggle-icon icon_moon"></span> ]]> <br>
        <![CDATA[ <span class="switch-label">Left Label</span> ]]> <br>
        <![CDATA[ <label class="switch switch-icon"> ]]> <br>
        <![CDATA[ <input type="checkbox"> ]]> <br>
        <![CDATA[ <span class="slider round"></span> ]]> <br>
        <![CDATA[ </label> ]]> <br>
        <![CDATA[ <span class="toggle-icon icon_sun"></span> ]]> <br>
        <![CDATA[ <span class="switch-label">Right Label</span> ]]> <br>
        <![CDATA[ </div> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Chart Container</div>
      <br>
      <br>
      <tr>
        <div class="container px-0">
          <div class="row">
            <div class="d-none d-md-block col-md-12 col-xl-6">
              <app-line-chart [chartTitle]="'X vs Y'" [chartOptions]="formationChartOptions"
                [chartData]="formationChartDataNorthing" [chartFrame]="true"></app-line-chart>
            </div>
            <div class="d-none d-md-block col-md-12 col-xl-6">
              <app-line-chart [chartTitle]="'X vs Y'" [chartOptions]="formationChartOptions2"
                [chartData]="formationChartData" [chartFrame]="true"></app-line-chart>
            </div>
          </div>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ TWO CHARTS: ]]> <br>
        <![CDATA[ <div class="container px-0"> ]]> <br>
        <![CDATA[ <div class="row"> ]]> <br>
        <![CDATA[ <div class="d-none d-md-block col-md-12 col-xl-6"> ]]> <br>
        <![CDATA[ <app-line-chart [chartTitle]="'X vs Y'" [chartOptions]="formationChartOptions" ]]> <br>
        <![CDATA[ [chartData]="formationChartDataNorthing" [chartFrame]="true"></app-line-chart> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ <div class="d-none d-md-block col-md-12 col-xl-6"> ]]> <br>
        <![CDATA[ <app-line-chart [chartTitle]="'X vs Y'" [chartOptions]="formationChartOptions2" ]]> <br>
        <![CDATA[ [chartData]="formationChartData" [chartFrame]="true"></app-line-chart> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]>
        <br>
        <br>
        <![CDATA[ ONE CHART: ]]> <br>
        <![CDATA[ <div class="container px-0"> ]]> <br>
        <![CDATA[ <div class="row"> ]]> <br>
        <![CDATA[ <div class="d-none d-md-block col-md-12 col-xl-6"> ]]> <br>
        <![CDATA[ <app-line-chart [chartTitle]="'X vs Y'" [chartOptions]="formationChartOptions" ]]> <br>
        <![CDATA[ [chartData]="formationChartDataNorthing" [chartFrame]="true"></app-line-chart> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Two Column Input</div>
      <br>
      <br>
      <tr>
        <div class="modal-body">
          <div class="row">
              <div class="col-md-6">
                <div class="default comp-label mt-0">Col 1 Row 1</div>
                <input type="text" class="input-field">
                <div class="default comp-label">Col 1 Row 2</div>
                <input type="text" class="input-field">
              </div>
              <div class="col-md-6">
                <div class="default comp-label mt-0">Col 2 Row 1</div>
                <input type="text" class="input-field">
                <div class="default comp-label">Col 2 Row 2</div>
                <input type="text" class="input-field">
              </div>
          </div>
        </div>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="modal-body"> ]]> <br>
        <![CDATA[ <div class="row"> ]]> <br>
        <![CDATA[ <div class="col-md-6"> ]]> <br>
        <![CDATA[ <div class="comp-label mt-0">Col 1 Row 1</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ <div class="comp-label">Col 1 Row 2</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ <div class="col-md-6"> ]]> <br>
        <![CDATA[ <div class="comp-label mt-0">Col 2 Row 1</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ <div class="comp-label">Col 2 Row 2</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Large Accordion</div>
      <br>
      <br>
      <tr>
        <p-accordion #accordion class="a-large">
          <p-accordionTab>
            <p-header class="col-md-12 paccordion-headings">
              <div class="h3 page-heading">Large Accordion Header</div>
            </p-header>
          </p-accordionTab>
        </p-accordion>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <p-accordion #accordion class="a-large"> ]]> <br>
        <![CDATA[ <p-accordionTab> ]]> <br>
        <![CDATA[ <p-header class="col-md-12 paccordion-headings"> ]]> <br>
        <![CDATA[ <div class="h3 page-heading">Large Accordion Header</div> ]]> <br>
        <![CDATA[ </p-header> ]]> <br>
        <![CDATA[ </p-accordionTab> ]]> <br>
        <![CDATA[ </p-accordion> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Medium Accordion</div>
      <br>
      <br>
      <tr>
        <p-accordion #accordion class="a-medium">
          <p-accordionTab>
            <p-header class="col-md-12 paccordion-headings clearfix">
              <div class="h4 page-heading">Medium Accordion Heading</div>
            </p-header>
          </p-accordionTab>
        </p-accordion>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <p-accordion #accordion class="a-medium"> ]]> <br>
        <![CDATA[ <p-accordionTab> ]]> <br>
        <![CDATA[ <p-header class="col-md-12 paccordion-headings clearfix"> ]]> <br>
        <![CDATA[ <div class="h4 page-heading">Medium Accordion Heading</div> ]]> <br>
        <![CDATA[ </p-header> ]]> <br>
        <![CDATA[ </p-accordionTab> ]]> <br>
        <![CDATA[ </p-accordion> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Small Accordion</div>
      <br>
      <br>
      <tr>
        <p-accordion class="a-small" #accordion (onOpen)="accordianOpen($event)" (onClose)="accordianClose($event)">
          <p-accordionTab>
            <p-header class="col-md-12 paccordion-headings clearfix">
              <div class="h4 page-heading">Small Accordion Header</div>
              <span class="pi pi-fw" [ngClass]="changeIcon==0?'pi-chevron-down':'pi-chevron-right'"></span>
            </p-header>
          </p-accordionTab>
        </p-accordion>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <p-accordion class="a-small" #accordion (onOpen)="accordianOpen($event)" (onClose)="accordianClose($event)"> ]]> <br>
        <![CDATA[ <p-accordionTab> ]]> <br>
        <![CDATA[ <p-header class="col-md-12 paccordion-headings clearfix"> ]]> <br>
        <![CDATA[ <div class="h4 page-heading">Small Accordion Header</div> ]]> <br>
        <![CDATA[ <span class="pi pi-fw" [ngClass]="changeIcon==0?'pi-chevron-down':'pi-chevron-right'"></span> ]]> <br>
        <![CDATA[ </p-header> ]]> <br>
        <![CDATA[ </p-accordionTab> ]]> <br>
        <![CDATA[ </p-accordion> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Dashboard Accordion</div>
      <br>
      <br>
      <tr>
        <p-accordion #accordion>
          <p-accordionTab>
            <p-header class="col-md-12 card-headings">
              <div class="h3 h-upper">Dashboard Accordion Header</div>
              <div class="pill"># Jobs</div>
            </p-header>
          </p-accordionTab>
        </p-accordion>
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <p-accordion #accordion> ]]> <br>
        <![CDATA[ <p-accordionTab> ]]> <br>
        <![CDATA[ <p-header class="col-md-12 card-headings"> ]]> <br>
        <![CDATA[ <div class="h3">Dashboard Accordion Header</div> ]]> <br>
        <![CDATA[ <div class="pill"># Jobs</div> ]]> <br>
        <![CDATA[ </p-header> ]]> <br>
        <![CDATA[ </p-accordionTab> ]]> <br>
        <![CDATA[ </p-accordion> ]]>
      </tr>
      <br>
      <br>
      <div class="h4">Error Message</div>
      <br>
      <br>
      <tr>
        <!-- <p-toast>
          <ng-template let-message pTemplate="message">
            <div class="ui-g ui-fluid">
              <div class="ui-g-3 toast-icon-wrapper">
                <i class="fa fa-exclamation-triangle toast-icon-size"></i>
              </div>
              <div class="ui-g-9 description-wrapper">
                <div class="toast-detail">Summary</div>
                <div class="toast-detail">Detail<span class="toast-time-detail">Date and Time</span></div>
              </div>
            </div>
          </ng-template>
        </p-toast> -->
      </tr>
      <br>
      <br>
      <tr>
        <![CDATA[ <div class="modal-body"> ]]> <br>
        <![CDATA[ <div class="row"> ]]> <br>
        <![CDATA[ <div class="col-md-6"> ]]> <br>
        <![CDATA[ <div class="comp-label mt-0">Col 1 Row 1</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ <div class="comp-label">Col 1 Row 2</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ <div class="col-md-6"> ]]> <br>
        <![CDATA[ <div class="comp-label mt-0">Col 2 Row 1</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ <div class="comp-label">Col 2 Row 2</div> ]]> <br>
        <![CDATA[ <input type="text" class="input-field"> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]> <br>
        <![CDATA[ </div> ]]>
      </tr>
      <br>
      <br>
    </tbody>
  </table>
</div>