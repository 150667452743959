import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RigsService } from '../services/rigs.service';
import { UtilitiesService } from '../services/utilitiesservice.service';

@Injectable()
export class RigCatalogsResolver implements Resolve<void> {
  constructor(
    // utilities service injected to ensure token auth is handled first
    public _utilities: UtilitiesService,
    public _rigs: RigsService
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this._rigs.loadRigCatalogs(false);
  }
}