import 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
// Utils
import * as LS from 'src/app/utils/localstorage';
import { PARAM_MAP } from '../utils/constants';
// Services
import { UtilitiesService } from '../core/services/utilitiesservice.service';

// Models
import { ACTION_TYPE, FIELD_TYPE } from 'src/app/shared/enums/enums';
import { ToolbarEvent, ToolbarCatalog, ToolbarColumn, ToolbarSharing, ToolbarCache } from 'src/app/shared/components/app-table-toolbar/table-toolbar.component';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

  public toolbarEvent$ = new BehaviorSubject<ToolbarEvent>({ action: ACTION_TYPE.INIT });
  public jobId: string = '';
  public formGroup: FormGroup;
  public pageLoaded: boolean = false;
  public toolbarSharing: ToolbarSharing = new ToolbarSharing();
  public componentDestroyed$: Subject<boolean> = new Subject();

  public columns: Array<ToolbarColumn> = [
    { key: "role", i18n: "Role", fieldType: FIELD_TYPE.TEXT}
  ];

  public tableView = [];
  public get validationFn() {
    return this.toolbarSharing.tableView.filter((el, idx) => idx != this.toolbarSharing.indexEditing);
  }

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public _utilities: UtilitiesService,
  ) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.jobId = this.route.snapshot.paramMap.get(PARAM_MAP.JOB_ID);

    this.initializeForm();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public initializeForm() {
    this.formGroup = this.fb.group({
      role: ['', Validators.required],
    });
  }

  public handleAdd(event) {
    let { action, revertDataUom, originDataUom } = event;
    let payload;
    if (action === ACTION_TYPE.CREATE) {
      payload = revertDataUom;
    } else {
      payload = originDataUom;
    }

    this.toolbarEvent$.next({ action: action, status: true, data: payload, cache: payload });
  }

  public handleReset() {

  }

  public handleEdit(event) {
  }

  public handleDelete(event) {
    this.toolbarEvent$.next({ action: ACTION_TYPE.DELETE, status: true });
  }
}
